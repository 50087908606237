import {
  TDeviceChildrenDatapoint,
  TDeviceChildrenDatapointsParams,
  TDeviceChildrenDatapointsResponse,
  useDeviceChildrenDatapointsWithLoadMore,
} from '@marlin/asset/data-access/device';
import { useMemo } from 'react';

export const useInfiniteSystemEquipmentList = ({
  deviceId,
  modelName,
  pageSize,
  datapoints,
}: Omit<TDeviceChildrenDatapointsParams, 'page'>) => {
  const systemEquipmentQuery = useDeviceChildrenDatapointsWithLoadMore({
    pageSize,
    deviceId,
    modelName,
    datapoints,
  });

  const systemEquipmentTotal = systemEquipmentQuery.data?.pages[0].totalItems ?? null;

  const rows: TDeviceChildrenDatapoint[] = useMemo(() => {
    const unfilteredRows: (TDeviceChildrenDatapoint | null)[] =
      systemEquipmentQuery.data?.pages?.flatMap((i: TDeviceChildrenDatapointsResponse) => i.data || []) || [];
    return unfilteredRows.filter((i: TDeviceChildrenDatapoint | null): i is TDeviceChildrenDatapoint => i !== null);
  }, [systemEquipmentQuery.data?.pages]);

  return {
    rows,
    totalItems: systemEquipmentQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: systemEquipmentQuery.hasNextPage,
    fetchNextPage: systemEquipmentQuery.fetchNextPage,
    refetch: systemEquipmentQuery.refetch,
    isLoading: systemEquipmentQuery.isLoading,
    isFetching: systemEquipmentQuery.isFetching,
    isFetchingNextPage: systemEquipmentQuery.isFetchingNextPage,
    isError: systemEquipmentQuery.isError,
    isFetched: systemEquipmentQuery.isFetched,
    error: systemEquipmentQuery.error,
    systemEquipmentTotal,
  };
};

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ maxTriggers: number; triggerIndex: number }>()(
  (theme: MarlinTheme, { triggerIndex, maxTriggers }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    triggerSectionPaper: {
      borderTopLeftRadius: triggerIndex === 0 ? undefined : 0,
      borderTopRightRadius: triggerIndex === 0 ? undefined : 0,
      borderBottomLeftRadius: triggerIndex === maxTriggers - 1 ? undefined : 0,
      borderBottomRightRadius: triggerIndex === maxTriggers - 1 ? undefined : 0,
    },
  })
);

import { useMutation } from '@marlin/shared/utils/react-query';

import { sendDeviceCommand } from '../infrastructure/equipment-commands';

interface ISetValveStateProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useSendDeviceCommand = (props?: ISetValveStateProps) => {
  return useMutation({
    onError: props?.onError,
    onSuccess: () => {
      props?.onSuccess?.();
    },
    mutationFn: sendDeviceCommand,
  });
};

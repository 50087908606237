import { getDataForRangeBarChart } from '@marlin/shared/utils-chart';
import React, { useMemo } from 'react';

import { IPlotComponentProps } from '../../model';
import { PlotComponent } from './plot.component';

export const RangeBarPlotComponent = ({
  data,
  layout,
  config,
  height,
  handleRelayout,
  handleUnhover,
  handleHover,
  to,
}: IPlotComponentProps & { to: number }) => {
  const dataForRangeBar = useMemo(() => getDataForRangeBarChart(data, to), [data, to]);
  return (
    <>
      <PlotComponent
        data={dataForRangeBar.reverse()}
        layout={layout}
        config={config}
        handleRelayout={handleRelayout}
        handleHover={handleHover}
        handleUnhover={handleUnhover}
        height={height}
      />
    </>
  );
};

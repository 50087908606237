import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { SensorType } from '@marlin/shared/ui-device-type';
import { Link } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { EQUIPMENT_TYPE, TEquipment } from '@marlin/shared/utils/zod';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getHubSerialNumber } from '../../shared/utils/get-hub-serial-number.utils';
import { useNavigation } from './use-navigation.hook';

export interface IConfig {
  name: string;
  locationName: string;
  model: string;
  deviceType: string;
  serialNumber: string;
}

export const useCreateConnectedDevicesColumns = (
  gatewayId: string,
  config: Partial<IConfig>
): GridColDef<TDeviceSchema | TEquipment>[] => {
  const isEditable = usePermission(PERMISSIONS.EDIT_GATEWAY_HUB);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const navigation = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  const baseColumn = useMemo(
    () =>
      [
        !!config.name && {
          field: 'name',
          headerName: config.name,
          type: 'string',
          headerAlign: 'left' as GridAlignment,
          flex: 3,
          align: 'left' as GridAlignment,
          renderCell: (cellValues) => {
            const sensorLink = navigation.getDetailsSensorPageLink(cellValues.row.id);
            const hubLink = navigation.getDetailsHubPageLink(gatewayId, cellValues.row.id ?? '');
            const equipmentLink = navigation.getEquipmentDetailsPageLink(cellValues.row.id);

            const link = getLink({ hubLink, sensorLink, equipmentLink }, cellValues);

            return (
              <Link to={link}>
                <div>{cellValues.value}</div>
              </Link>
            );
          },
        },
        !!config.locationName && {
          field: 'locationName',
          headerName: config.locationName,
          type: 'string',
          headerAlign: 'left' as GridAlignment,
          flex: 3,
          align: 'left' as GridAlignment,
          renderCell: (cellValues) => {
            return (
              <Tooltip text={cellValues.row.locationName ?? ''} placement="top">
                <Link to={routes.locations.details.url(cellValues.row.locationId ?? '')}>
                  {cellValues.row.locationName}
                </Link>
              </Tooltip>
            );
          },
        },
        !!config.deviceType && {
          field: 'deviceType',
          headerName: config.deviceType,
          type: 'string',
          flex: isTablet ? 1 : 2.3,
          headerAlign: 'left',
          align: 'left',
          renderCell: (cellValues) => {
            return (
              <SensorType deviceType={cellValues.value} meterType={cellValues.row.meterType} showName={!isTablet} />
            );
          },
        },
        !!config.model && {
          field: 'model',
          headerName: config.model,
          type: 'string',
          headerAlign: 'left' as GridAlignment,
          align: 'left' as GridAlignment,
          flex: 3,
          sortable: false,

          renderCell: (cellValues) => {
            return <div>{cellValues.value}</div>;
          },
        },
        // TODO: Uncomment when status is available
        // {
        //   field: 'isOperational',
        //   headerName: content.HUBS_AND_SENSORS.COLUMNS.STATUS,
        //   type: 'string',
        //   headerAlign: 'left',
        //   align: 'left',
        //   flex: 3,
        //   sortable: false,
        //   renderCell: (cellValues) => {
        //     return <StatusBar isOperational={cellValues.value} tooltip={true} />;
        //   },
        // },
        !!config.serialNumber && {
          field: 'serialNumber',
          headerName: config.serialNumber,
          type: 'string',
          headerAlign: 'left' as GridAlignment,
          align: 'left' as GridAlignment,
          flex: 3,
          renderCell: (cellValues) => {
            const isValveController = cellValues.row.type === EQUIPMENT_TYPE.VALVE_CONTROLLER;

            return (
              <div>
                {getHubSerialNumber({
                  manufacturerId: isValveController
                    ? cellValues.row.devices[0].displayManufacturerId
                    : cellValues.row.displayManufacturerId,
                })}
              </div>
            );
          },
          sortable: false,
        },
        // TODO: Uncomment when status is available
        // {
        //   field: 'powerSource',
        //   headerName: content.HUBS_AND_SENSORS.COLUMNS.POWER_SOURCE,
        //   type: 'string',
        //   headerAlign: 'left',
        //   align: 'left',
        //   flex: 3,
        //   sortable: false,
        //   renderCell: (cellValues) => {
        //     const batteryLevel = cellValues.row.batteryLevel;
        //     return <PowerSource powerSource={cellValues.value} batteryLevel={batteryLevel} />;
        //   },
        // },
      ].filter(Boolean) as GridColDef<TDeviceSchema | TEquipment>[],
    [config, gatewayId, isTablet, navigation]
  );

  return !isEditable
    ? baseColumn
    : [
        ...baseColumn,
        {
          field: 'action',
          headerName: '',
          sortable: false,
          flex: 0.5,
          align: 'center' as GridAlignment,
          renderCell: (cellValues) => {
            const hubLink = navigation.getEditHubPageLink(gatewayId, cellValues.row.id ?? '');
            const sensorLink = navigation.getEditSensorPageLink(cellValues.row.id ?? '');
            const equipmentLink = navigation.getEditEquipmentPageLink(cellValues.row.id ?? '');

            const link = getLink({ hubLink, sensorLink, equipmentLink }, cellValues);

            return (
              <IconButton
                data-testid="edit-hub-button"
                onClick={() =>
                  navigate(link, {
                    state: { prevLocation: location },
                  })
                }
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            );
          },
        },
      ];
};

const getLink = (
  links: {
    hubLink: string;
    sensorLink: string;
    equipmentLink: string;
  },
  cellValues: { row: TDeviceSchema | TEquipment }
) => {
  if ('type' in cellValues.row && cellValues.row.type === EQUIPMENT_TYPE.VALVE_CONTROLLER) {
    return links.equipmentLink;
  }

  if ('deviceType' in cellValues.row && cellValues.row.deviceType === 'LEAK') {
    return links.sensorLink;
  }

  return links.hubLink;
};

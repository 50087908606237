import { IConfig, globalConfigTekmarShp } from '@marlin/asset/shared/equipment-config';
import { tekmarLogo, tekmarSHPImage } from '@marlin/asset/shared/ui/assets';

import { content } from '../../content';
import { aggregationStatusFunction } from './aggregation-status-function';
import { detailsConfig } from './details-config';
import { sectionConfig } from './section-config';

export const tekmarSmartHeatPumpConfig: IConfig = {
  headerBanner: null,
  tabs: {},
  details: detailsConfig,
  dashboard: {
    ...globalConfigTekmarShp.dashboard,
    productDetails: {
      datapoints: [
        {
          name: 'hotTankTarget',
          label: content.DATAPOINT_SHP_LABELS.HOT_TANK_TARGET,
        },
        { name: 'hotTankTemp', label: content.DATAPOINT_SHP_LABELS.HOT_TANK_SUPPLY },
        { name: 'coldTankTarget', label: content.DATAPOINT_SHP_LABELS.COLD_TANK_TARGET },
        { name: 'coldTankTemp', label: content.DATAPOINT_SHP_LABELS.COLD_TANK_SUPPLY },
      ],
      imageSrc: tekmarSHPImage,
      logoSrc: tekmarLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    isLastReadingTimeCounter: false,
    sections: sectionConfig,
    chart: {
      datapointNames: ['hotTankTarget', 'hotTankTemp', 'coldTankTarget', 'coldTankTemp'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: true,
    functionsMapping: {
      hpStatus: aggregationStatusFunction,
      mixingStatus: aggregationStatusFunction,
      pumpsStatus: aggregationStatusFunction,
    },
  },
};

import { useDeviceDetails } from '@marlin/asset/data-access/device';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { hubConfig } from '../../shared/config/hub/hub-config';
import { useHubNavigation } from '../../shared/hooks/navigation/use-hub-navigation.hook';
import { CommonAssetDetails } from '../common-dashboard/common-asset-details-page.component';

export const HubDetails = () => {
  const { gatewayId = '', hubId = '' } = useParams();

  const navigation = useHubNavigation();
  const hubQuery = useDeviceDetails({ deviceId: hubId, datapoints: ['FirmwareVersion'], enabled: !!hubId });
  const hub = useMemo(() => hubQuery?.data, [hubQuery?.data]);
  const isEditable = usePermission(PERMISSIONS.EDIT_GATEWAY_HUB);

  return (
    <CommonAssetDetails
      config={hubConfig}
      navigation={navigation}
      isLoading={hubQuery.isLoading}
      asset={hub}
      isError={hubQuery.isError}
      assetId={hubId}
      isEditable={isEditable}
      assetName={'hub'}
      noHierarchyDisplayed={!hub?.locationId}
      parentId={gatewayId}
    />
  );
};

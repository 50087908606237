import { useCurrentUser } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren, SyntheticEvent, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { isEditRecipientPage, isEditUserPage } from './helper';
import { SettingsPageContainer } from './settings-page-container';
import { SettingsPageHeader } from './settings-page-header';
import { useSettingsPermissions } from './use-settings-permissions.hook';

interface ISettingsPageWrapperProps {
  showTabs?: boolean;
  showHeaderOnMobile?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
}));

export const SettingsPageWrapper: FC<PropsWithChildren<ISettingsPageWrapperProps>> = ({
  children,
  showTabs = false,
  showHeaderOnMobile = true,
}) => {
  const { pathname } = useLocation();
  const { goTo, goBack } = useRouter();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const { classes } = useStyles();
  const { data } = useCurrentUser();
  const isSupport = data?.currentOrganization.roles.find((role) => ['Support', 'BUCS', 'CSM'].includes(role));

  const { allowedToSettings, allowedToOrgPage, allowedToIntegrations } = useSettingsPermissions();

  const handleTabChange = (event: SyntheticEvent, pathname: string) => {
    goTo(pathname);
  };

  const { title, subtitle } = useMemo(() => {
    switch (pathname) {
      case routes.settings.addRecipient.url():
        return {
          title: content.SETTINGS_ADD_RECIPIENT_TITLE,
          subtitle: content.SETTINGS_ADD_RECIPIENT_SUBTITLE,
        };
      case routes.settings.inviteNewUser.url(): {
        return {
          title: content.SETTINGS_INVITE_NEW_USER_TITLE,
          subtitle: content.SETTINGS_INVITE_NEW_USER_SUBTITLE,
        };
      }
      case routes.settings.people.url(): {
        return {
          title: isTablet ? content.SETTINGS_TAB_PEOPLE : content.SETTINGS_TITLE,
          subtitle: isTablet ? undefined : content.SETTINGS_SUBTITLE_MANAGE_USER_SETTINGS,
        };
      }
      case routes.settings.orgSettings.url(): {
        return {
          title: isTablet ? content.SETTINGS_TAB_ORGANIZATION : content.SETTINGS_TITLE,
          subtitle: isTablet ? undefined : content.SETTINGS_SUBTITLE_ORGANIZATION_INFORMATION,
        };
      }
      case routes.settings.hotsos.url(): {
        return { title: content.SETTINGS_TITLE, subtitle: content.SETTINGS_SUBTITLE_SUPPORT };
      }
      case routes.settings.appearance.url(): {
        return {
          title: isTablet ? content.SETTINGS_TAB_MY_PREFERENCES : content.SETTINGS_TITLE,
          subtitle: isTablet ? undefined : content.SETTINGS_SUBTITLE_MY_PREFERENCES_VIEWER,
        };
      }
      case routes.settings.profile.url(): {
        return {
          title: isTablet ? content.SETTINGS_TAB_MY_ACCOUNT : content.SETTINGS_TITLE,
          subtitle: isTablet ? undefined : content.SETTINGS_SUBTITLE_MY_ACCOUNT_VIEWER,
        };
      }
      default: {
        if (isEditUserPage(pathname)) {
          return { title: content.SETTINGS_EDIT_USER_TITLE, subtitle: content.SETTINGS_EDIT_USER_SUBTITLE };
        }
        if (isEditRecipientPage(pathname)) {
          return {
            title: content.SETTINGS_EDIT_RECIPIENT_TITLE,
            subtitle: content.SETTINGS_EDIT_RECIPIENT_SUBTITLE,
          };
        }
        return {
          title: content.SETTINGS_TITLE,
          subtitle: allowedToSettings ? content.SETTINGS_SUBTITLE_SUPPORT : content.SETTINGS_SUBTITLE_VIEWER,
        };
      }
    }
  }, [isTablet, pathname, allowedToSettings]);

  return (
    <SettingsPageContainer>
      {(isTablet ? showHeaderOnMobile : true) && (
        <SettingsPageHeader
          icon={<SettingsIcon />}
          title={title}
          subtitle={subtitle}
          prefix="settings-header"
          topActions={
            isTablet ? (
              <Button
                className={classes.button}
                startIcon={<ArrowBackRoundedIcon />}
                variant="outlined"
                onClick={goBack}
              >
                {content.BACK}
              </Button>
            ) : undefined
          }
        />
      )}

      {showTabs && !isTablet && (
        <Tabs value={pathname} onChange={handleTabChange}>
          {allowedToSettings && (
            <Tab
              value={routes.settings.people.url()}
              label={content.SETTINGS_TAB_PEOPLE}
              data-testid="page-people-tab"
            />
          )}
          {!isSupport && (
            <Tab
              disabled={!data?.id}
              value={routes.settings.profile.url()}
              label={content.SETTINGS_TAB_MY_ACCOUNT}
              data-testid="page-my-account-tab"
            />
          )}
          <Tab
            value={routes.settings.appearance.url()}
            label={content.SETTINGS_TAB_MY_PREFERENCES}
            data-testid="page-appearance-tab"
          />
          {allowedToOrgPage && (
            <Tab
              value={routes.settings.orgSettings.url()}
              label={content.SETTINGS_TAB_ORGANIZATION}
              data-testid="page-org-tab"
            />
          )}
          {allowedToIntegrations && (
            <Tab
              value={routes.settings.integration.url()}
              label={content.SETTINGS_TAB_INTEGRATION}
              data-testid="page-integration-tab"
            />
          )}
        </Tabs>
      )}
      {children}
    </SettingsPageContainer>
  );
};

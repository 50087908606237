import { TDeviceChildrenDatapoint } from '@marlin/asset/data-access/device';
import { CardHeader, DetailsItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { ReadingCard } from '@marlin/shared/ui-asset-card-mobile';
import { Paper } from '@marlin/shared/ui-page';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ICardConfig } from '../types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(12),
    borderRadius: theme.typography.pxToRem(4),
  },
  icon: {
    marginRight: theme.typography.pxToRem(8),
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      fontSize: theme.typography.pxToRem(24),
      color: theme.palette.action.active,
    },
  },
  datapoints: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(12),
  },
}));

interface ICardProps {
  row: TDeviceChildrenDatapoint;
  cardConfig: ICardConfig;
  icon: ReactNode;
}

export const SystemEquipmentCard = ({ row, cardConfig, icon }: ICardProps) => {
  const { classes } = useStyles();

  const { columns, footer } = cardConfig;

  return (
    <Paper data-testid="card">
      <CardHeader
        icon={<div className={classes.icon}>{icon}</div>}
        asset={{
          id: row.id,
          name: row.DeviceName,
        }}
      />
      <div className={classes.datapoints}>
        {columns.map((column) => {
          return (
            <ReadingCard title={column.headerName} testId={column.field} key={column.field} clearBottomMargin>
              {column?.renderValue?.(column.field, row)}
            </ReadingCard>
          );
        })}
      </div>
      {footer.map((item) => (
        <DetailsItem key={item.field} label={item.headerName} value={item?.renderValue?.(item.field, row)} />
      ))}
    </Paper>
  );
};

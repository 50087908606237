import { z } from 'zod';

import { getCreateChannels, mapCriticality } from '../../../infrastructure/dtos/utils';
import { automationBuilderSchema } from './automation-builder.schema';

export const createAutomationSchema = automationBuilderSchema
  .omit({
    id: true,
  })
  .transform((data) => {
    return {
      ...data,
      channels: getCreateChannels(data.channels),
      criticality: mapCriticality(data.criticality),
    };
  });

export type TCreateAutomation = z.infer<typeof createAutomationSchema>;

import { WaterDetectCondition } from '@marlin/alert/ui/automation-builder-components';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';

interface IBooleanConditionProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
}

export const BooleanCondition = ({ datapoint, triggerIndex }: IBooleanConditionProps) => {
  switch (datapoint.unitOfMeasure) {
    case 'WaterDetect': {
      return <WaterDetectCondition triggerIndex={triggerIndex} />;
    }
    default: {
      return null;
    }
  }
};

import { environment } from '@marlin/environment';

export const content = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SENSOR_DETAILS: 'Sensor Details',
  SENSOR_TYPE: 'Sensor Type',
  SENSOR_NAME: 'Sensor Name',
  TAB_SENSOR_READINGS: 'Sensor Readings',
  TAB_SENSOR_DETAILS: 'Sensor details',
  TAB_SENSOR_DETAILS_MOBILE: 'Details',
  TAB_ALERTS: (total: number) => `Alerts (${total} current)`,
  TAB_ALERTS_MOBILE: (total: number) => `Alerts (${total})`,
  TAB_READINGS: 'Readings',
  LOCATION: 'Location',
  LINKED_EQUIPMENT: 'Linked Equipment',
  MANUFACTURER_ID: 'Manufacturer ID',
  MANUFACTURER_ID_TOOLTIP: 'Manufacturer-assigned number found on the back of the sensor.',
  ID: 'ID',
  ID_TOOLTIP: `Unique identifier assigned within the ${environment.app.name} platform.`,
  MANUFACTURER: 'Brand',
  MODEL: 'Model',
  DESCRIPTION: 'Description',
  SENSOR_READINGS: 'Sensor Readings',
  LOWEST: 'Lowest',
  HIGHEST: 'Highest',
  CONFIRMATION_MESSAGE_DELETE_SENSOR_ERROR: `Something went wrong. Your sensor hasn't been deleted`,
  CONFIRMATION_MESSAGE_DELETE_SENSOR_SUCCESS: 'Your sensor has been deleted',
  MODAL_TITLE: 'Delete Sensor?',
  MODAL_DELETE_NO_AUTOMATION_QUESTION: (name: string) => `Are you sure you want to delete ${name}?`,
  MODAL_DELETE_NO_AUTOMATION_CONFIRMATION: 'This action cannot be undone.',
  MODAL_DELETE_EXISTING_AUTOMATION_WARNING: (total: number) =>
    `There are ${total} automations linked to this sensor. If you delete this sensor, then its automations will also be deleted.`,
  MODAL_DELETE_EXISTING_AUTOMATION_QUESTION: 'Are you sure you want to delete this sensor and all of its automations?',
  MODAL_BUTTON_CANCEL_TEXT: 'Cancel',
  MODAL_BUTTON_SUBMIT_TEXT: 'Delete',
  MANAGE_AUTOMATIONS_BUTTON_CREATE: 'Create new automation',
  MANAGE_AUTOMATIONS_BUTTON_MANAGE: `Manage automations`,
  MANAGE_AUTOMATIONS_BUTTON_VIEW: `View automations`,
  SENSOR_READINGS_CHART_LEAK: 'Leak',
  SENSOR_READINGS_CHART_NO_LEAK: 'No Leak',
  SENSOR_READINGS_TEMPERATURE: (value: string) => `${value}°F`,
  SENSOR_READINGS_PRESSURE: (value: string) => `${value} PSI`,
  SENSOR_READINGS_LEAK: 'Leak',
  SENSOR_READINGS_NO_LEAK: 'No Leak',
  SENSOR_READINGS_NOT_APPLICABLE: 'N/A',
  TOOLTIP: 'This sensor has a current alert',
  VIEW_MORE: 'View alert.',
  TEMPERATURE_ITEM: 'Temperature Sensor',
  FLOW_METER_ITEM: 'Flow Meter',
  PRESSURE_ITEM: 'Pressure Sensor',
  LEAK_ITEM: 'Leak Detector',
  SENSOR_READINGS_RANGE_PREFIX: 'View the past',
  SENSOR_READINGS_RANGE_HOURS_8: '8 hours',
  SENSOR_READINGS_RANGE_HOURS_24: '24 hours',
  SENSOR_READINGS_RANGE_DAYS_7: '7 days',
  CONFIRMATION_MESSAGE_PIN_SENSOR: 'Your sensor has been pinned',
  CONFIRMATION_MESSAGE_UNPIN_SENSOR: 'Your sensor has been unpinned',
  PIN_SENSOR_ERROR: `Something went wrong. Your sensor hasn't been pinned`,
  UNPIN_SENSOR_ERROR: `Something went wrong. Your sensor hasn't been unpinned`,
  EMPTY_CHART_DATA_MESSAGE: `There is no data for selected time range`,
  TIMEZONE: (timeZoneAbbr: string) => `Timezone: ${timeZoneAbbr}`,
  LOST_COMMUNICATION: 'Lost Communication',
  SENSOR_OPERATIONAL: 'Sensor Operational',
  LOW_BATTERY: 'Low Battery',
  REPLACE_BATTERY: 'Replace Battery',
  SHOW_AUTOMATIONS: 'Show automations on chart',
  RESET_VIEW: 'Reset view',
  TRIGGER: 'Trigger',
  ALERTS: 'Alerts',
  DEADBAND: 'Deadband',
  CLEAR_ALL: 'Clear all',
  ANNOTATION_TOOLTIP_ALERT_HIGH: (reading: string) => `${reading} - Alert Trigger High`,
  ANNOTATION_TOOLTIP_ALERT_LOW: (reading: string) => `${reading} - Alert Trigger Low`,
  ANNOTATION_TOOLTIP_DEADBAND_HIGH: (reading: string) => `${reading} - Deadband High`,
  ANNOTATION_TOOLTIP_DEADBAND_LOW: (reading: string) => `${reading} - Deadband Low`,
  CUSTOM: 'Custom',
  NO_DATA_ON_MAINCHART: `No data for selected range`,
  NO_DATA_ON_MAINCHART_RANGE: (from: string, to: string) => `${from} to ${to}`,
  NO_ACTIVE_AUTOMATIONS: 'No active automations available',
  GATEWAY_ID: 'Gateway ID',
  NO_DATA_AVAILABLE: 'No data for selected range',
  CHART_TOOLTIP_READING: (reading: string) => `Reading: ${reading}`,
  CHART_TOOLTIP_TRIGGERED: (trigger: string) => `Triggered: ${trigger}`,
  VIEW_ALERTS: 'View alerts',
  MORE_ALERTS: (number: number) => `+${number} more alerts`,
};

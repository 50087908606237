import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import isNil from 'lodash/isNil';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { FieldError, FieldPath, useFormContext } from 'react-hook-form';

import { useConditionField } from './use-condition-field.hook';

interface IDeadbandFieldProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
  operator: 'lt' | 'gt';
}

export const useDeadbandField = ({ triggerIndex, operator, datapoint }: IDeadbandFieldProps) => {
  const { trigger } = useFormContext<TAutomationBuilder>();
  const { threshold, deadband, rules, onChange, error } = useConditionField({ triggerIndex, operator, datapoint });

  const isThresholdValid = useMemo(() => {
    return !isNil(threshold);
  }, [threshold]);

  const handleBlur = useCallback(() => {
    return trigger(`triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>);
  }, [trigger, triggerIndex]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        type: 'number',
        rules: {
          ...rules,
          [operator]: {
            ...rules[operator],
            deadband: event.target.value === '' ? null : event.target.value,
            threshold,
          },
        },
      });
      trigger(`triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>);
    },
    [onChange, operator, rules, threshold, trigger, triggerIndex]
  );

  const handleClear = useCallback(() => {
    onChange({
      type: 'number',
      rules: {
        ...rules,
        [operator]: {
          ...rules[operator],
          deadband: null,
          threshold,
        },
      },
    });
  }, [onChange, operator, rules, threshold]);

  return {
    value: deadband,
    handleChange,
    handleBlur,
    handleClear,
    isThresholdValid,
    error: error?.['rules']?.[operator]?.['deadband'] as FieldError | undefined,
  };
};

import { MarlinTheme } from '@marlin/shared/theme';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxWidth: '50%',
      maxHeight: '50%',
      objectFit: 'contain',
    },
  },
  footer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
    },
  },
}));

export const ImageModal = ({
  handleOnClose,
  imgSrc,
  alt,
}: {
  handleOnClose: () => void;
  imgSrc: string | undefined;
  alt: string;
}) => {
  const { classes } = useStyles();

  return (
    <CustomSizedModal
      width={600}
      onClose={handleOnClose}
      body={
        <div className={classes.image}>
          <img src={imgSrc} alt={alt} />
        </div>
      }
      footer={
        <div className={classes.footer}>
          <Button variant="text" onClick={handleOnClose}>
            {content.CLOSE}
          </Button>
        </div>
      }
    />
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

interface ICardListAccordionProps extends PropsWithChildren {
  title: string;
  defaultExpanded?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  accordion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.primary,
  },
  accordionDetails: {
    boxShadow: 'none',

    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
  },
}));
export const CardListAccordion = ({ title, children, defaultExpanded }: ICardListAccordionProps) => {
  const { classes } = useStyles();

  return (
    <Accordion square disableGutters className={classes.accordion} defaultExpanded={defaultExpanded}>
      <AccordionSummary
        className={classes.accordionSummary}
        aria-controls="panel1-content"
        id="panel1-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.title} component="span">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};

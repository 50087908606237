import { MarlinTheme } from '@marlin/shared/theme';
import { Avatar, Icon, Typography } from '@mui/material';
import { alpha } from '@mui/system';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  color?: keyof MarlinTheme['palette'];
}

interface IMessageProps extends PropsWithChildren {
  color?: IStyles['color'];
  muiIconSymbol?: string;
}

const backgroundOpacity = 0.08;

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { color }) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 'calc(infinity * 1px)',
    border: `1px solid ${theme.palette[color || 'info'].main}`,
    backgroundColor: alpha(theme.palette[color || 'info'].main, backgroundOpacity),
    color: theme.palette[color || 'info'].dark,
    padding: theme.typography.pxToRem(8),
    gap: theme.typography.pxToRem(4),
  },
  avatar: {
    width: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
    backgroundColor: theme.palette[color || 'info'].main,
  },
  icon: {
    color: 'white',
    opacity: 1 - backgroundOpacity,
    '&.material-symbols-outlined': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export const Message = ({ children, color, muiIconSymbol }: IMessageProps) => {
  const { classes } = useStyles({ color: color ?? ('info' as keyof MarlinTheme['palette']) });

  return (
    <div className={classes.container}>
      <Avatar color={color} className={classes.avatar}>
        <Icon className={classes.icon} baseClassName="material-symbols-outlined" fontSize={'small'}>
          {muiIconSymbol ?? 'notifications'}
        </Icon>
      </Avatar>
      <Typography variant="caption">{children}</Typography>
    </div>
  );
};

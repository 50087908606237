import { boilerImage, waterHeaterImage } from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { AERCO_UNIT_STATUS, TEquipment } from '@marlin/shared/utils/zod';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../shared/content';
import { useEquipmentsTelemetryContext } from '../../../shared/context/equipments-telemetry.context';
import { usePlantConfigContext } from '../../../shared/context/plant-config.context';
import { useEquipmentTelemetry } from '../../../shared/hooks/use-equipments-telemetry';
import { PLANT_TYPE, onStatuses } from '../../../shared/types';
import { getDatapoint } from '../../../shared/utils/get-datapoint';
import { getLegendItemByStatus } from '../../../shared/utils/get-legend-item-based-on-status';
import { StatusIcon } from './status-icon.component';

interface IDeviceTileProps extends TEquipment {
  plantType: PLANT_TYPE;
  status: AERCO_UNIT_STATUS;
  backUpManagerAddress: string | undefined;
  managerAddress: string | undefined;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tile: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
    maxWidth: theme.typography.pxToRem(240),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  },
  tileHeader: {
    backgroundColor: theme.palette.background.alternative,
    padding: theme.typography.pxToRem(8),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tileBody: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  tileInfo: {
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(4),
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
  },
  tileFooter: {
    textTransform: 'uppercase',
    backgroundColor: theme.palette.alternative.main,
    color: theme.palette.alternative.contrastText,
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(4)}`,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(13),
    letterSpacing: theme.typography.pxToRem(0.46),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightMedium,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  dot: {
    width: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(18),
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
  },
  tileImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.typography.pxToRem(16)} 0 ${theme.typography.pxToRem(8)}`,
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
  },
  image: {
    height: theme.typography.pxToRem(200),
  },
  box: {
    display: 'flex',
    gap: theme.typography.pxToRem(4),
    alignItems: 'flex-start',
  },
  value: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(19.6),
  },
  wrappedText: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
}));

export const DeviceTile = ({
  name = '',
  model,
  serialNumber,
  devices = [],
  plantType,
  backUpManagerAddress,
  managerAddress,
  id,
  status,
}: IDeviceTileProps) => {
  const { classes, cx } = useStyles();
  const [{ lastReadingTime = '', lastReadingValues = [], manufacturerId = '' } = {}] = devices;
  useEquipmentTelemetry(manufacturerId);
  const {
    config: { activeSetpointDatapointName },
  } = usePlantConfigContext();
  const { equipmentsTelemetry } = useEquipmentsTelemetryContext();
  const data = equipmentsTelemetry[manufacturerId] ?? undefined;

  const lastReadingTimeValue = data?.lastReadingTime ? data.lastReadingTime : lastReadingTime;
  const setpoint = getDatapoint(activeSetpointDatapointName, lastReadingValues, data);
  const outlet = getDatapoint('OutletTemp', lastReadingValues, data);
  const unitSize = getDatapoint('UnitSize', lastReadingValues, data);
  const commAddr = getDatapoint('CommAddr', lastReadingValues, data);
  const legendItem = getLegendItemByStatus(status) || {};
  const isStatusOn = onStatuses.includes(status);
  const isManager = managerAddress === manufacturerId || managerAddress === commAddr;

  return (
    <div className={classes.tile} data-testid="tile">
      <div>
        <div className={classes.tileHeader}>
          <span
            className={cx(classes.dot, { [classes.error]: !isStatusOn })}
            data-testid={`${isStatusOn ? 'online' : 'offline'}-status`}
          />
          {lastReadingTimeValue && (
            <Typography data-testid="last-reading-time">
              {moment(lastReadingTimeValue).format('MM/DD/YY hh:mm A')}
            </Typography>
          )}
          <StatusIcon color={legendItem.color} icon={legendItem.icon} />
        </div>
        <div className={classes.tileBody}>
          <div className={classes.tileImage}>
            <img
              src={plantType === PLANT_TYPE.Boiler ? boilerImage : waterHeaterImage}
              alt={name}
              className={classes.image}
            />
          </div>
          <div className={classes.tileInfo}>
            <Tooltip placement="top" text={name}>
              <Link to={routes.equipmentDashboard.details.url(id)} className={classes.wrappedText} data-testid="name">
                {name}
              </Link>
            </Tooltip>
            <Tooltip placement="top" text={`${model} ${unitSize}`}>
              <Typography variant="body2" textTransform="uppercase" data-testid="model" className={classes.wrappedText}>
                {model} {unitSize}
              </Typography>
            </Tooltip>
            <div className={classes.box}>
              <Typography variant="caption" textTransform="uppercase">
                {content.PLANT_LIST.SETPOINT}
              </Typography>
              <Typography className={classes.value} data-testid="setpoint">
                {setpoint ? setpoint : content.PLANT_LIST.EMPTY_DATAPOINT_VALUE}
              </Typography>
            </div>
            <div className={classes.box}>
              <Typography variant="caption" textTransform="uppercase">
                {content.PLANT_LIST.OUTLET}
              </Typography>
              <Typography className={classes.value} data-testid="outlet">
                {outlet ? outlet : content.PLANT_LIST.EMPTY_DATAPOINT_VALUE}
              </Typography>
            </div>
            <div className={classes.box}>
              <Typography variant="caption" textTransform="uppercase">
                {content.PLANT_LIST.BURNER}
              </Typography>
              <Typography className={classes.value} data-testid="burner">
                {isStatusOn ? content.PLANT_LIST.ON : content.PLANT_LIST.OFF}
              </Typography>
            </div>
            <Typography variant="caption" textTransform="uppercase" data-testid="serialNumber">
              {serialNumber}
            </Typography>
          </div>
        </div>
      </div>
      {isManager && (
        <div className={classes.tileFooter} data-testid="manager">
          {content.PLANT_LIST.MANAGER}
        </div>
      )}
      {!isManager && backUpManagerAddress === commAddr && (
        <div className={classes.tileFooter} data-testid="manager">
          {content.PLANT_LIST.BACK_UP_MANAGER}
        </div>
      )}
    </div>
  );
};

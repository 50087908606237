import { zodResolver } from '@hookform/resolvers/zod';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { Grid, Link } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../../../content';
import { EQUIPMENT_ENUM, EQUIPMENT_STATUS, TEquipment, equipmentSchema } from '../../../onboarding.schema';
import { useOnboardingState } from '../../../use-onboarding-state.hook';
import { IEquipmentFormProps, defaultEquipmentValues, useStyles } from './common-parts';
import { FormButtons } from './form-buttons.component';

export const AercoInnovation = ({
  resetSwitcher,
  isEditForm,
  onCancel,
  equipment,
  openInstructionsModal,
  index,
  isAnotherEquipment,
}: IEquipmentFormProps) => {
  const { classes } = useStyles();
  const { addEquipment, updateEquipment } = useOnboardingState();

  const [equipmentName, setEquipmentName] = useState<string>(
    equipment && equipment.equipmentName ? equipment.equipmentName : defaultEquipmentValues.equipmentName || ''
  );
  const [registrationCode, setRegistrationCode] = useState<string>(
    equipment && equipment.registrationCode ? equipment.registrationCode : defaultEquipmentValues.registrationCode || ''
  );
  const [serialNumber, setSerialNumber] = useState<string>(
    equipment && equipment.serialNumber ? equipment.serialNumber : defaultEquipmentValues.serialNumber || ''
  );

  const form = useForm<TEquipment>({
    defaultValues: equipment
      ? {
          ...defaultEquipmentValues,
          ...equipment,
        }
      : defaultEquipmentValues,
    mode: 'onTouched',
    resolver: zodResolver(equipmentSchema),
  });

  const { isValid } = form.formState;

  const isDisabled = useMemo(() => {
    return !isValid || equipmentName.length < 4 || registrationCode.length < 4 || serialNumber.length < 4;
  }, [equipmentName.length, registrationCode.length, serialNumber.length, isValid]);

  const onSubmit = (data: TEquipment) => {
    addEquipment({
      data: {
        equipment: [
          {
            ...data,
            registrationFlow: 'AercoWaterHeater',
            equipmentType: EQUIPMENT_ENUM.AERCO_INNOVATION,
          },
        ],
      },
    });
    resetSwitcher();
  };

  const openInstructionsModalActivationCode = () => {
    openInstructionsModal(1);
  };

  const onEdit = (data: TEquipment) => {
    data.status = EQUIPMENT_STATUS.REFETCH;
    updateEquipment({
      data,
      index: index,
    });
    if (onCancel) onCancel();
    form.reset();
  };

  const onFormCancel = () => {
    form.reset();
    if (onCancel) onCancel();
  };

  return (
    <div className={classes.container}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={classes.formContainer}>
          <div className={classes.inputsContainer}>
            <FormField<TEquipment> fieldName={'equipmentName'}>
              {(field) => (
                <Input
                  {...field}
                  label={content.EQUPIMENT_NAME}
                  required
                  className={classes.input}
                  data-testid="register-equipment-equipmentName"
                  type={INPUT_TYPE.TEXT}
                  shrink={!!field.value?.length}
                  fullWidth
                  onChange={(value) => {
                    setEquipmentName(value.target.value);
                    form.setValue('equipmentName', value.target.value);
                    form.trigger(['equipmentName']);
                  }}
                />
              )}
            </FormField>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className={classes.inputsContainer}>
                <div>
                  <FormField<TEquipment> fieldName={'serialNumber'}>
                    {(field) => (
                      <Input
                        {...field}
                        label={content.SERIAL_NUMBER}
                        required
                        className={classes.input}
                        data-testid="register-equipment-registrationCode"
                        shrink={!!field.value?.length}
                        fullWidth
                        onChange={(value) => {
                          setSerialNumber(value.target.value);
                          form.setValue('serialNumber', value.target.value);
                          form.trigger(['serialNumber']);
                        }}
                      />
                    )}
                  </FormField>
                  <Link
                    onClick={() => openInstructionsModal()}
                    className={classes.link}
                    data-testid="serial-number-link"
                  >
                    {content.SERIAL_NUMBER_LINK}
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputsContainer}>
                <div>
                  <FormField<TEquipment> fieldName={'registrationCode'}>
                    {(field) => (
                      <Input
                        {...field}
                        label={content.CONTROLLER_FIXED_ID}
                        required
                        className={classes.input}
                        data-testid="register-equipment-registrationCode"
                        type={INPUT_TYPE.NUMBER}
                        shrink={!!field.value?.length}
                        fullWidth
                        onChange={(value) => {
                          setRegistrationCode(value.target.value);
                          form.setValue('registrationCode', value.target.value);
                          form.trigger(['registrationCode']);
                        }}
                      />
                    )}
                  </FormField>
                  <Link
                    onClick={openInstructionsModalActivationCode}
                    className={classes.link}
                    data-testid="controller-fixed-id-link"
                  >
                    {content.CONTROLLER_FIXED_ID_LINK}
                  </Link>
                </div>
              </Grid>
            </Grid>
            <div>
              <FormButtons
                isDisabled={isDisabled}
                onFormSubmit={form.handleSubmit(isEditForm ? onEdit : onSubmit)}
                isEditForm={isEditForm}
                isAnotherEquipment={isAnotherEquipment}
                onCancel={onFormCancel}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

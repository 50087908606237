import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { globalConfigAerco } from '@marlin/asset/shared/equipment-config';
import { aercoLogo, boilerImage, waterHeaterImage } from '@marlin/asset/shared/ui/assets';

import { content } from '../../content';
import { boilerSectionConfig } from './boiler/section-config-boiler';
import { detailsConfig } from './details-config';
import { waterHeaterSectionConfig } from './water-heater/section-config-water-heater';

export const aercoBoilerConfig: IConfig = {
  details: detailsConfig,
  headerBanner: null,
  tabs: {},
  dashboard: {
    ...globalConfigAerco.dashboard,
    productDetails: {
      datapoints: [
        { name: 'ActiveSetpt', label: content.SETPOINT },
        { name: 'OutletTemp', label: content.DATAPOINT_LABELS.OUTLET },
        { name: 'AirTemp', label: content.DATAPOINT_LABELS.AIR_INLET },
      ],
      imageSrc: boilerImage,
      logoSrc: aercoLogo,
      shouldDisplayRole: true,
      shouldDisplaySerialNumber: true,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: false,
    chart: {
      datapointNames: ['ActiveSetpt', 'OutletTemp', 'ValvePositnOut'],
      datapointGroupNames: ['temperature', 'other'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: true,
    sections: boilerSectionConfig,
  },
};

export const aercoWaterHeaterConfig: IConfig = {
  details: detailsConfig.filter((item) => item.name !== 'IGSTVersion'),
  headerBanner: null,
  tabs: {},
  dashboard: {
    ...globalConfigAerco.dashboard,
    productDetails: {
      datapoints: [
        { name: 'ActiveSetpointTemp', label: content.SETPOINT },
        { name: 'OutletTemp', label: content.DATAPOINT_LABELS.OUTLET },
        { name: 'AirTemp', label: content.DATAPOINT_LABELS.AIR_INLET },
      ],
      imageSrc: waterHeaterImage,
      logoSrc: aercoLogo,
      shouldDisplayRole: true,
      shouldDisplaySerialNumber: true,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: false,
    chart: {
      datapointNames: ['ActiveSetpointTemp', 'OutletTemp', 'ValvePositnOut'],
      datapointGroupNames: ['temperature', 'other'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: true,
    sections: waterHeaterSectionConfig,
  },
};

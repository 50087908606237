import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  collapsed: {
    backgroundColor: theme.palette.secondary.light,
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(36),
  },
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    color: theme.palette.background.primary,
  },
}));

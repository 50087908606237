import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginBottom: theme.typography.pxToRem(16),
  },
  collapse: {
    marginRight: theme.typography.pxToRem(-16),
    marginLeft: theme.typography.pxToRem(-16),
  },
}));

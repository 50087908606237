import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tabs: {
    backgroundColor: theme.palette.background.primary,
  },
  tab: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

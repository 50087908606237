import { TUserSchema } from '@marlin/account-data-access-user';
import { Paper } from '@marlin/shared/ui-page';

import { IUserContextMenuActionsProps, UserContextMenu } from '../user-context-menu.component';
import { UserStatus } from '../user-status.component';
import { ContactInfo } from './contact-info.component';
import { useStyles } from './person-card-styles';
import { PersonName } from './person-name.component';

interface IPersonCardProps extends IUserContextMenuActionsProps {
  person: TUserSchema;
}

export const PersonCard = ({ person, onResend, onEdit, onDelete, onDeleteInvitation }: IPersonCardProps) => {
  const { classes } = useStyles();
  const { firstName, lastName, title, phone, email, invitationStatus, role } = person;

  return (
    <Paper className={classes.wrapper} testId="person-card">
      <div className={classes.nameRow}>
        <PersonName firstName={firstName} lastName={lastName} title={title} />
        <div className={classes.contextMenuWrapper}>
          <UserContextMenu
            user={person}
            onResend={onResend}
            onDeleteInvitation={onDeleteInvitation}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </div>
      </div>

      <div className={classes.contactRow}>
        <div className={classes.wrapper}>
          {phone && <ContactInfo value={phone} type={'phone'} />}
          <ContactInfo value={email} type={'email'} />
        </div>
        {(invitationStatus || role) && <UserStatus invitationStatus={invitationStatus} role={role} />}
      </div>
    </Paper>
  );
};

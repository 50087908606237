import { StatusBar, StatusLabel } from '@marlin/asset/shared/ui/equipment-list';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { getUomFromSettings } from '@marlin/shared/utils-format-reading';
import { ReactNode } from 'react';

import { useRenderDatapoint } from '../../hooks/use-render-datapoint.hook';
import { ICardConfig, TCardRow } from '../../types';
import { content } from '../content';

export const useLdsValveCard = (): ICardConfig => {
  const { renderDatapoint } = useRenderDatapoint();
  const ldsValveColumns = [
    {
      field: 'WaterOn',
      headerName: content.COLUMNS.LDS_VALVE.VALVE_STATE,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { WaterOn } = row;

        return (
          <StatusBar
            isOperational={WaterOn === 'True'}
            labels={{
              operational: content.VALUES.VALVE_STATE.OPEN,
              notOperational: content.VALUES.VALVE_STATE.CLOSED,
            }}
            isValueStatus={true}
          />
        );
      },
    },
    {
      field: 'InFlowTrip',
      headerName: content.COLUMNS.LDS_VALVE.LEAK_STATUS,
      renderValue: (field: string, row: TCardRow) => {
        const { InFlowTrip } = row;

        return <StatusLabel isOperational={InFlowTrip === 'False'}>{renderDatapoint(field, row)}</StatusLabel>;
      },
    },
    {
      field: 'FlowRate',
      headerName: content.COLUMNS.LDS_VALVE.FLOW_RATE,
      renderValue: renderDatapoint,
    },
    {
      field: 'Temperature',
      headerName: content.COLUMNS.LDS_VALVE.TEMPERATURE,
      renderValue: (field: string, row: TCardRow) => renderDatapoint(field, row, getUomFromSettings('TEMPERATURE')),
    },
    {
      field: 'Pressure',
      headerName: content.COLUMNS.LDS_VALVE.PRESSURE,
      renderValue: (field: string, row: TCardRow) => renderDatapoint(field, row, getUomFromSettings('PRESSURE')),
    },
    {
      field: 'InAlert',
      headerName: content.COLUMNS.LDS_VALVE.IN_ALERT,
      renderValue: (field: string, row: TCardRow) => {
        const { InAlert } = row;
        const inAlert = InAlert === 'True';

        return (
          <StatusLabel isOperational={!inAlert}>
            {inAlert ? content.VALUES.IN_ALERT.TRUE : content.VALUES.IN_ALERT.FALSE}
          </StatusLabel>
        );
      },
    },

    {
      field: 'CommunicationStatusActive',
      headerName: content.COLUMNS.LDS_VALVE.STATUS,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { CommunicationStatusActive } = row;

        return <StatusBar isOperational={CommunicationStatusActive === 'True'} tooltip={true} />;
      },
    },
  ];

  const ldsValveFooter = [
    {
      field: 'LastReadingTime',
      headerName: content.COLUMNS.LDS_VALVE.LAST_READING_TIME,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { LastReadingTime } = row;

        return (
          <Tooltip
            placement="top"
            text={`${content.COLUMNS.LDS_VALVE.LAST_READING_TIME}: ${formatDate(
              LastReadingTime as unknown as null,
              defaultDateTime
            )}`}
          >
            <>{formatDate(LastReadingTime as unknown as null, defaultDateTime)}</>
          </Tooltip>
        );
      },
    },
  ];

  return {
    columns: ldsValveColumns,
    footer: ldsValveFooter,
  };
};

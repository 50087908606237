import { TAutomationBuilder, detailsSchema } from '@marlin/alert/data-access/automated-action';
import { SectionWrapper } from '@marlin/alert/ui/automation-builder-components';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useSectionsStore$ } from '../hooks/use-observable-sections-store';
import { useSectionValidation } from '../hooks/use-section-validation.hook';
import { content } from './content';
import { DetailsContentEdit } from './details-content-edit/details-content-edit.component';
import { DetailsContentView } from './details-content-view/details-content-view.component';

export const DetailsSection = () => {
  const { setSection, isSectionActive } = useSectionsStore$();
  const { isValid } = useSectionValidation(detailsSchema);
  const { trigger } = useFormContext<TAutomationBuilder>();

  const editModeToggle = () => {
    trigger().then((result) => {
      if (result) {
        setSection('details');
      }
    });
  };

  return (
    <SectionWrapper
      sectionTitle={content.DETAILS}
      sectionExpanded={true}
      editMode={isSectionActive('details')}
      buttonDisabled={!isValid}
      actions={
        <IconButton onClick={editModeToggle}>
          <EditRoundedIcon color={'primary'} />
        </IconButton>
      }
      viewModeChildren={<DetailsContentView editModeToggle={editModeToggle} />}
      testId="details"
    >
      <DetailsContentEdit />
    </SectionWrapper>
  );
};

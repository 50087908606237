import { SelectChangeEvent } from '@mui/material';
import { createContext, useContext } from 'react';

import { EXTENDED_RANGE_FILTER } from '../charts/collapsed-chart/chart-constants';
import { RANGE_FILTER } from '../constant';

export interface IRange {
  from: number;
  to: number;
}

export interface IChartRangeContext {
  savedRange: IRange | null;
  saveRange: (range: IRange | null) => void;
  restoreRange: (rangeFilter: EXTENDED_RANGE_FILTER) => IRange | null;
  rangeFilter: RANGE_FILTER;
  onRangeFilterChange: (event: SelectChangeEvent<RANGE_FILTER>) => void;
  range: IRange;
  extendedRangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER;
  setExtendedRangeFilter: (rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER) => void;
  refreshRange: () => void;
}

const defaultChartRangeContext: IChartRangeContext = {
  savedRange: null,
  saveRange: () => {},
  restoreRange: () => null,
  rangeFilter: RANGE_FILTER.HOURS_8,
  onRangeFilterChange: () => {},
  range: { from: 0, to: 0 },
  extendedRangeFilter: RANGE_FILTER.HOURS_8,
  setExtendedRangeFilter: () => {},
  refreshRange: () => {},
};

const ChartRangeContext = createContext<IChartRangeContext>(defaultChartRangeContext);

export const ChartRangeContextProvider = ChartRangeContext.Provider;

export const useChartRangeContext = (): IChartRangeContext => useContext(ChartRangeContext);

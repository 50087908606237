import { useQuery } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';
import z from 'zod';

import { getEquipmentSettingsGroup } from '../infrastructure/equipment';
import { queryKey } from './query-key.enum';

interface IUseEquipmentSettingGroupProps {
  equipmentId: string;
  settingGroupId: string | null;
  onError?: (error: AxiosError<{ errorMessage: string; status: string }>) => void;
}

export const useEquipmentSettingsGroup = ({ equipmentId, settingGroupId, onError }: IUseEquipmentSettingGroupProps) => {
  return useQuery({
    queryKey: queryKey.SETTINGS(settingGroupId, equipmentId),
    queryFn: () => getEquipmentSettingsGroup({ equipmentId, settingGroupId }),
    onError: (error: AxiosError<{ errorMessage: string; status: string }>) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError?.(error);
    },
    enabled: !!equipmentId && !!settingGroupId,
  });
};

import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../shared/content';
import { useLocationList } from '../shared/hooks/use-location-list.hook';
import { useUpdateHub } from './hooks/use-update-hub.hook';
import { HubFormComponent } from './hub-form.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonWrapper: {
    width: '100%',
    marginTop: theme.typography.pxToRem(8),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: theme.typography.pxToRem(324),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.typography.pxToRem(16),
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
    },
  },
  input: {
    marginBottom: theme.typography.pxToRem(32),
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(16),
    },
  },
  select: {
    width: '100%',
  },
  formSection: {
    margin: `${theme.typography.pxToRem(16)} 0`,
    fontSize: theme.typography.pxToRem(16),
    width: '100%',
  },
  crossIcon: {
    marginRight: theme.typography.pxToRem(5),
  },
  link: {
    color: theme.palette.info.light,
  },
  registerLink: {
    color: theme.palette.info.light,
    marginLeft: 'auto',
    textAlign: 'right',
    width: '100%',
    marginTop: theme.typography.pxToRem(32),
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(12),
  },
  registerCodeFields: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export const HubUpdateComponent = () => {
  const { isLoading, isError, hub, onSubmit } = useUpdateHub();
  const { classes } = useStyles();
  const { locationsList, isLoading: isLocationListLoading } = useLocationList();

  if (isLoading || isLocationListLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !hub) {
    return <SystemConstraintsPage />;
  }

  return (
    <PageContainer prefix="create-gateway">
      <PageHeader icon={null} title={hub.name} subtitle={content.SUBTITLE} prefix="gateway-header" />
      <Paper className={classes.formContainer}>
        <HubFormComponent locationsList={locationsList} onSubmit={onSubmit} hub={hub} />
      </Paper>
    </PageContainer>
  );
};

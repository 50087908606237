import { z } from 'zod';

const otaConfigSchema = z.object({
  apiUrl: z.string().url(),
});
export type TOtaConfig = z.infer<typeof otaConfigSchema>;

let otaConfig: TOtaConfig;

export async function initOtaConfig(): Promise<void> {
  try {
    const response = await fetch('/config.json');
    const configData = await response.json();
    otaConfig = otaConfigSchema.parse(configData);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to load config.json:', error);
    // eslint-disable-next-line no-console
    console.log('Using default config');
    otaConfig = {
      apiUrl: process.env.NX_OTA_API_URL || '',
    };
  }
}

export function getOtaConfig(): TOtaConfig {
  if (!otaConfig) {
    throw new Error(`OtaConfig isn't initialized`);
  }

  return otaConfig;
}

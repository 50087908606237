import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { useCallback, useEffect, useState } from 'react';
import { FieldPath, useFieldArray, useFormContext } from 'react-hook-form';

import { useSectionsStore$ } from '../hooks/use-observable-sections-store';

export const useDeleteTrigger = (triggerIndex: number) => {
  const { setSection, isSectionActive } = useSectionsStore$();
  const { remove, fields } = useFieldArray<TAutomationBuilder>({
    name: 'triggers',
  });
  const { watch } = useFormContext<TAutomationBuilder>();
  const [triggerExpanded, setTriggerExpanded] = useState(false);
  const trigger = watch(`triggers[${triggerIndex}]` as FieldPath<TAutomationBuilder>);

  const deleteTrigger = useCallback(() => {
    remove(triggerIndex);
    if (fields.length === 1) {
      setSection('details');
    } else if (isSectionActive(`trigger-${triggerIndex}`) && triggerIndex !== 0) {
      setSection(`trigger-${triggerIndex - 1}`);
    } else {
      setSection(`trigger-${triggerIndex}`);
    }
  }, [fields.length, isSectionActive, remove, setSection, triggerIndex]);

  const expandTrigger = () => {
    setTriggerExpanded(true);
  };

  useEffect(() => {
    if (trigger === undefined) {
      setTriggerExpanded(false);
    }
  }, [trigger]);

  return {
    triggerExpanded,
    deleteTrigger,
    expandTrigger,
  };
};

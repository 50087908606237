import { environment } from '@marlin/environment';

export const content = {
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  SAVE_SUCCESS_MSG: 'Changes have been saved',
  DASHBOARD_CONNECTION_ERROR: `There is no connection. Check your connection and try to refresh the page.`,
  SETTINGS_CONNECTION_ERROR_TITLE: 'There is no connection',
  SETTINGS_CONNECTION_ERROR_BODY: 'Check your connection and try to refresh the page.',
  REFRESH: 'Refresh',
  ID: 'ID',
  BUTTON_CANCEL: 'Back',
  SENSOR_NAME: 'Sensor Name',
  SENSOR_TYPE: 'Sensor Type',
  LAST_READING: 'Last Reading',
  LOCATION: 'Location',
  HIERARCHY_TITLE: 'Hierarchy',
  HIERARCHY_VIEW_TITLE: 'Hierarchy View',
  LINKED_SENSORS_TITLE: 'Linked Sensors',
  SERIAL_NUMBER_TITLE: 'Serial Number',
  MODEL_TITLE: 'Model',
  AUTOMATIONS_TITLE: 'Automations',
  ID_TITLE: 'ID',
  ROLE_TITLE: 'Role',
  SENSOR_TEMPERATURE: 'Temperature Sensor',
  SENSOR_PRESSURE: 'Pressure Sensor',
  SENSOR_LEAK: 'Leak Sensor',
  LABEL_LOCATION: 'Location',
  LABEL_SEARCH: 'Search',
  CLEAR_ALL: 'Clear All',
  DELETE_BUTTON: 'Delete',
  EDIT_BUTTON: 'Edit',
  LINKED_ITEMS: 'Linked items',
  DETAILS: 'Details',
  ALERTS: (val: number) => `Alerts (${val})`,
  SETTINGS: 'Settings',
  DASHBOARD: 'Dashboard',
  EVENT_LOG: 'Event Log',
  HIERARCHY_VIEW: 'Hierarchy view',
  EQUIPMENT_DETAILS: 'Equipment Details',
  ADDITIONAL_INFORMATION: 'Additional Information',
  EQUIPMENT_NAME: 'Equipment Name',
  ID_DESC: `Unique identifier assigned within the ${environment.app.name} platform`,
  MANUFACTURER_TITLE: 'Manufacturer',
  HIERARCHY_TAB_TITLE: 'Hierarchy',
  CHART_TITLE_MONITOR_INTELLISTATION: 'Monitor',
  CHART_TITLE_INTELLISTATION: 'Mixed Outlet Temperature',
  CHART_TITLE_BOILER_AERCO: 'Boiler Trends',
  SHOW_AUTOMATIONS: 'Show automations on chart',
  MANAGE_AUTOMATIONS: (count: number) => `Manage automations (${count})`,
  RESET_VIEW: 'Reset view',
  SYSTEM_STATUS: 'System Status',
  EQUIPMENT_READINGS: 'Current Equipment Readings',
  EQUIPMENT_METRICS: 'Equipment Metrics',
  MIXING_VALVE: 'Mixing Valve',
  TEMPERATURE: 'Temperature',
  SCHEDULE: 'Schedule',
  SYSTEM: 'System',
  ALERT_SETTINGS: 'Alerts',
  SYSTEM_INPUTS: 'System Inputs',
  FLOW_SENSORS: 'Flow Sensors',
  VALVES: 'Valves',
  PUMPS: 'Pumps',
  SYSTEM_OUTPUTS: 'System Outputs',
  CANCEL: 'Cancel',
  SET: 'Set',
  SAVE: 'Save',
  DISCARD: 'Discard',
  CURRENTLY_SET: 'Current Setting',
  NEW_VALUE: 'New value',
  MIN_VALUE: (value: number | string) => `Min ${value}`,
  MAX_VALUE: (value: number | string) => `Max ${value}`,
  UNSAVED_CHANGES_MODAL_TITLE: 'You have unsaved changes',
  UNSAVED_CHANGES_MODAL_BODY: 'You are about to leave this page. Do you want to save changes?',
  SANITIZATION_ERROR: (isStoppingSanitization: boolean) =>
    isStoppingSanitization ? 'Stopping Sanitization' : 'Sanitization in progress',
  STOPPING_SANITIZATION_ERROR_BODY: `Sanitization is being stopped. This process can take a few seconds.`,
  SANITIZATION_STOPPED: 'Sanitization mode has been stopped',
  START_UP_WARNING: 'Start up in progress',
  SETPOINT: 'Setpoint',
  ENERGY_USED: 'Energy used',
  LOAD_FLOW: 'Load flow highest',
  REMAINING_TIME: 'Time remaining',
  STOP_SANITIZATION: 'Stop Sanitization',
  STOPPING_SANITIZATION: 'Stopping Sanitization',
  BACK_BUTTON: 'Back',
  ADVANCED: 'Advanced',
  EQUIPMENT_OPERATIONAL: 'Equipment Operational',
  LOST_COMMUNICATION: 'Lost Communication',
  EMPTY_DATAPOINT_VALUE: '--',
  NOT_INSTALLED: 'Not Installed',
  EQUIPMENT_MODE_LABELS: {
    AUTO: 'Auto Mode',
    MANUAL: 'Manual Mode',
    IDLE: 'Idle Mode',
    SANITIZE: 'Sanitize Mode',
    START_UP: 'Start up Mode',
    COOL_DOWN: 'Cooldown Mode',
  },
  EQUIPMENT_MODE_SBS_LABELS: {
    AUTOMATIC: 'Automatic Mode',
    OVERRIDE: 'Override Mode',
  },
  EQUIPMENT_MODE_SSC_LABELS: {
    WWSD: 'Warm Weather Shutdown Mode',
    ESTABLISH_STEAM_MODE: 'Establishing Steam Mode',
    COOL_DOWN: 'Cool Down Mode',
    HEAT_OFF: 'Heat Off Mode',
    HEAT_ON: 'Heat On Mode',
    OVERRIDE_OFF: 'Override Mode: Off',
    OVERRIDE_HAND: 'Override Mode: Hand',
    OVERRIDE_MAX_HEAT: 'Override Mode: Max Heat',
    NO_HEAT_ABORT_CUTOFF: 'No Heat Abort Cutoff Mode',
    NO_HEAT_NORMAL: 'No Heat Normal Mode',
  },
  EQUIPMENT_MODE_LABELS_AERCO: {
    DISABLED: 'Disabled',
    STANDBY: 'Standby Mode',
    MANUAL: 'Manual Mode',
    REMOTE: 'Remote Mode',
    AUTO: 'Auto Mode',
    FAULT: 'Fault',
  },
  EQUIPMENT_MODE_LABELS_SENTINEL: {
    STANDBY: 'Standby',
    HOME: 'Home',
    AWAY: 'Away',
  },
  EQUIPMENT_STATUS_LABELS: {
    LOST_COMMUNICATION: 'Lost Communication',
    OFFLINE: 'Equipment Offline',
    OPERATIONAL: 'Equipment Operational',
    FAULT: 'Equipment Fault',
  },
  DATAPOINT_LABELS: {
    OUTLET_TEMP: 'Mixed Outlet',
    SETPOINT_TEMP: 'Setpoint',
    MIXING_TEMP: 'Mixed Outlet',
    RECIRC_TEMP: 'Recirc Return',
    HOT_TEMP: 'Hot Inlet',
    COLD_TEMP: 'Cold Inlet',
    OUTLET_PRESSURE: 'Mixed Outlet',
    RECIRC_PRESSURE: 'Recirc Return',
    HOT_PRESSURE: 'Hot Inlet',
    COLD_PRESSURE: 'Cold Inlet',
    MIXING_VALVE: 'Mixed%',
    ISOLATION_VALVE: 'Isolation Valve',
    SYSTEM_PUMP_1: 'Pump 1',
    SYSTEM_PUMP_2: 'Pump 2',
    OUTLET_FLOW: 'Mixed Outlet',
    RECIRC_FLOW: 'Recirc Return',
    OUTLET: 'Outlet',
    AIR_INLET: 'Air Inlet',
  },
  DATAPOINT_LABELS_AERCO: {
    UPPER_INLET: 'Upper Inlet',
    LOWER_INLET: 'Lower Inlet',
    EXHAUST_TEMP: 'Exhaust',
    EFFICIENCY: 'Efficiency',
    OXYGEN_LEVEL: 'Oxygen',
    FLAME_STRENGTH: 'Flame Strength',
    BLOWER_VOLTAGE: 'Blower Voltage',
    RUN_HOURS: 'Run Hours',
    CYCLE_COUNT: 'Cycle Count',
    CYCLES_PER_HOUR: 'Avg Cycles per Hour',
    PEAK_LOAD: 'Peak Load',
    START_LEVEL: 'Start Level',
    CONDENSING: 'Condensing',
    CALIBRATION: 'Calibration',
  },
  DATAPOINT_OPTIONS_LABELS: {
    ON: 'On',
    OFF: 'Off',
    OPEN: 'Open',
    CLOSED: 'Closed',
    OPENING: 'Opening',
    CLOSING: 'Closing',
    AUTO: 'Auto',
    OCCUPIED: 'Occupied',
    UNOCCUPIED: 'Unoccupied',
    NOT_USED: 'Not Used',
    INDOOR: 'Indoor',
    FLUE: 'Flue',
  },
  DATAPOINT_ERROR_LABEL: 'Error',
  STOP_SANITIZATION_MODAL_BODY_FIRST_LINE: 'Are you sure you want to Stop Sanitization?',
  STOP_SANITIZATION_MODAL_BODY_SECOND_LINE: 'This process can take a few seconds.',
  STOP: 'Stop',
  EQUIPMENT_TYPE: 'Equipment Type',
  EQUIPMENT_TYPES_OPTIONS: {
    NONE: '--',
    DIGITAL_MIXING_VALVE: 'Digital Mixing Valve',
  },
  AUTOMATIONS: 'Automations',
  FIRMWARE_VERSION: 'Firmware Version',
  MANUFACTURER: 'Manufacturer',
  EQUIPMENT_ID: 'Equipment ID',
  MODEL: 'Model',
  DESCRIPTION: 'Description',
  REGISTRATION_DATE: 'Registration Date',
  COMMISSION_DATE: 'Commission Date',
  EQUIPMENT_ID_TOOLTIP: 'This is the unique identifier assigned when you register your equipment',
  ON: 'On',
  OFF: 'Off',
  CONFIRMATION_MODAL_BODY_MESSAGE_ENABLE: 'Are you sure you want to enable this option?',
  CONFIRMATION_MODAL_BODY_MESSAGE_DISABLE: 'Are you sure you want to disable this option?',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  OCCUPIED_ONE: 'Occupied 1',
  OCCUPIED_TWO: 'Occupied 2',
  SKIP: 'Skip',
  SCHEDULE_TOOLTIP_TITLE: 'If the schedule is set to on:',
  TIMEZONE: 'Time zone',
  SCHEDULE_TOOLTIP_ROW_1: 'The Mixed Outlet Setpoint is the temperature during the occupied period.',
  SCHEDULE_TOOLTIP_ROW_2:
    'The temperature will be reduced by the Mixed Setback Differential during unoccupied periods.',
  OCCUPIED_DATAPOINT: (val: string) => `${val} (occupied)`,
  EQUIPMENT_DEREGISTERED_WARNING: 'Your equipment has been deregistered from the organization',
  SETTINGS_DISABLED_TOOLTIP:
    'System in sanitization mode, please stop sanitization from the equipment before changing any settings.',
  TIMEZONE_VALUE: (value: string) => `Timezone: ${value}`,
  EQUIPMENT_READINGS_AERCO: {
    TEMPERATURES: 'Temperatures',
    BOILER_PERFORMANCE: 'Boiler Performance',
    BOILER_METRICS: 'Boiler Metrics',
    ADDITIONAL_BOILER_INFO: 'Additional Boiler Info',
  },
  CONTROLLER_FIXED_ID: 'Controller Fixed ID',
  CONTROLLER_IGST_VERSION: 'Controller IGST Version',
  LAST_HEARTBEAT: 'Last Heartbeat',
  AGE_OF_LAST_HEARTBEAT: 'Age of Last Heartbeat',
  FAULTS_ENABLED_DISABLED: 'Faults Enabled/Disabled',
  EXTENDED_SHUTDOWN_PERIOD: 'Extended Shutdown Period',
  ROLE: {
    CLIENT: 'Client',
    MANAGER: 'Manager',
  },
  INTELLISTATION_MODEL_DISPLAY_NAME: 'IntelliStation',
  LDS_MODEL_DISPLAY_NAME: 'Leak Defense System',
  STATUS: {
    WWSD: 'Warm Weather Shutdown',
    COOL_DOWN: 'Cool Down',
    HEAT_OFF: 'Heat Off',
    HEAT_ON: 'Heat On',
    ESTABLISHING_STEAM_TIME: 'Establishing Steam Time',
    ESTABLISHING_STEAM_TEMP: 'Establishing Steam Temp',
    INVISIBLE: 'Invisible',
    ON: 'On',
    OFF: 'Off',
    OVERRIDE_OFF: 'Override Off',
    OVERRIDE_HAND: 'Override Hand',
    OVERRIDE_MAX_HEAT: 'Override Max Heat',
    NO_HEAT_ABORT_CUTOFF: 'No Heat Abort Cutoff',
    NO_HEAT_NORMAL: 'No Heat Normal',
  },
  NEXA_SENSOR_LABELS: {
    TEMPERATURE: 'Temperature',
    PRESSURE: 'Pressure',
    FLOW_RATE: 'Flow Rate',
    LEAK: 'Leak',
  },
};

export const getGatewayInfo = (
  devices: {
    gatewayId: string;
    gatewayName: string;
  }[]
) => {
  if (devices && devices.length > 0 && devices[0].gatewayName && devices[0].gatewayId) {
    return {
      gatewayName: devices[0].gatewayName,
      gatewayId: devices[0].gatewayId,
    };
  }

  return null;
};

import { MarlinTheme } from '@marlin/shared/theme';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { Chip, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    position: 'relative',
    paddingBottom: theme.typography.pxToRem(12),
    marginTop: theme.typography.pxToRem(12),
  },
  divider: {
    borderStyle: 'dashed',
    marginLeft: `-${theme.typography.pxToRem(16)}`,
    width: `calc(100% + ${theme.typography.pxToRem(32)})`,
  },
  chip: {
    position: 'absolute',
    top: `-${theme.typography.pxToRem(12)}`,
    backgroundColor: theme.palette.background.primary,
  },
}));

export const ConditionHeader = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.header}>
      <Divider className={classes.divider} />
      <div className={classes.chip}>
        <Chip size="small" label={content.CONDITIONS.HEADER} data-testid={createDataTestId('condition-header')} />
      </div>
    </div>
  );
};

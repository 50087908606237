import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    alignSelf: 'center',
  },
  buttonArray: {
    outline: `${theme.typography.pxToRem(1)} dashed ${theme.palette.divider}`,
    outlineColor: theme.palette.divider,
    border: 0,
    '&:hover': {
      outline: `${theme.typography.pxToRem(1)} dashed ${theme.palette.divider}`,
      outlineColor: theme.palette.divider,
      border: 0,
    },
    '&.Mui-disabled': {
      borderColor: 'transparent',
    },
  },
}));

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()((theme: MarlinTheme) => ({
  conditionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
}));

import { INVITATION_STATUS, useDeleteInvitationsByStatus } from '@marlin/account-data-access-invitation';
import { ActionDelete } from '@marlin/shared/ui-context-menu';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {
  Button,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';

import { content } from '../../shared/content/content';
import { useNavigate } from '../../shared/hooks/use-navigate/use-navigate.hook';

export const UserListMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { goToInvitationPage } = useNavigate();
  const { mutateAsync: deleteInvitationsByStatus } = useDeleteInvitationsByStatus();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const deleteExpiredInvitations = useCallback(async () => {
    return deleteInvitationsByStatus(INVITATION_STATUS.EXPIRED)
      .then(() => {
        enqueueSnackbar(content.DELETE_ALL_EXPIRED_INVITATIONS_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch(() => {
        enqueueSnackbar(content.DELETE_ALL_EXPIRED_INVITATIONS_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  }, [enqueueSnackbar, deleteInvitationsByStatus]);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isMobile ? (
        <IconButton onClick={handleClick}>
          <Icon baseClassName="material-symbols-outlined" data-testid="add_circle-circle" color="primary">
            add_circle
          </Icon>
        </IconButton>
      ) : (
        <Button
          variant="contained"
          id="user-list-menu-button"
          aria-controls={open ? 'user-list-menu-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={open ? <ExpandLess /> : <ExpandMore />}
        >
          {content.INVITE}
        </Button>
      )}
      <Menu
        id="user-list-menu-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            goToInvitationPage();
            handleClose();
          }}
          divider
          data-testid="invite-new-user"
        >
          <ListItemIcon>
            <SendRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{content.INVITE_NEW_USER_BTN}</ListItemText>
        </MenuItem>
        <ActionDelete
          onClick={() => {
            deleteExpiredInvitations();
            handleClose();
          }}
          customText={content.DELETE_EXPIRED_INVITATIONS_BTN}
          testId={'delete-expired-invitations'}
        />
      </Menu>
    </div>
  );
};

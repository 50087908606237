import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => {
  return {
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexWrap: 'nowrap',
    },

    redLabel: {
      color: theme.palette.error.main,
    },
  };
});

interface IStatusBarProps extends PropsWithChildren {
  isOperational: boolean;
}

export const StatusLabel = ({ isOperational, children }: IStatusBarProps) => {
  const { classes, cx } = useStyles();

  const labelClassesNames = cx(classes.text, {
    [classes.redLabel]: !isOperational,
  });

  return <div className={labelClassesNames}>{children}</div>;
};

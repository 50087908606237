import { TEquipmentSchema } from '@marlin/asset/data-access/equipment';
import {
  EquipmentCard,
  EquipmentListMobileUi,
  EquipmentListUi,
  useEquipmentHubContextMenu,
  usePagination,
} from '@marlin/asset/shared/ui/equipment-list';
import { ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { content } from '../../../../shared/content';
import { useEquipmentsList } from '../../../../shared/hooks/use-equipment-list.hook';
import { useListStyles } from '../../../hooks/use-list-styles.hook';
import { useNavigation } from '../../../hooks/use-navigation.hook';

interface IEquipmentsAndSensorsListProps {
  gatewayId: string;
}

export const EquipmentsAndSensorsList = ({ gatewayId }: IEquipmentsAndSensorsListProps) => {
  const { setPage, setPageSize, paginationModel, filterParams } = usePagination();
  const { editEquipment } = useEquipmentHubContextMenu();
  const { classes, cx, theme } = useListStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigation = useNavigation();
  const navigate = useNavigate();
  const { rows, totalItems, hasNextPage, fetchNextPage } = useEquipmentsList([gatewayId ?? ''], true);

  return (
    <Accordion
      className={cx(classes.accordion, classes.grid)}
      data-testid="gateway-table-section"
      defaultExpanded
      expanded={!isMobile ? true : undefined}
      elevation={0}
    >
      <AccordionSummary className={classes.header} expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
        <Typography variant="h5" className={classes.tableTitle}>
          {content.SENSORS_AND_EQUIPMENT.TABLE_TITLE}
        </Typography>
        {!isMobile && (
          <Button
            variant="outlined"
            onClick={() => navigate(navigation.getGatewayConfigurationPageLink(gatewayId))}
            data-testid="edit-gateway-button"
          >
            {content.SENSORS_AND_EQUIPMENT.CONFIGURE_CONNECTION}
          </Button>
        )}
      </AccordionSummary>
      <AccordionDetails className={cx(classes.paper, classes.dataGridWrapper, classes.mobilePaper)}>
        {isMobile && (
          <EquipmentListMobileUi
            rows={rows}
            fetchNextPage={fetchNextPage}
            totalItems={totalItems}
            hasNextPage={hasNextPage}
          >
            {(equipment: TEquipmentSchema) => (
              <EquipmentCard key={equipment.id} equipment={equipment} data-testid="equipment-card">
                <ContextMenu className={classes.mobileMenu} data-testid="equipment-card-menu">
                  <ActionEdit onClick={() => editEquipment(equipment.id)} />
                </ContextMenu>
              </EquipmentCard>
            )}
          </EquipmentListMobileUi>
        )}
        {!isMobile && (
          <EquipmentListUi
            paginationModel={paginationModel}
            setPageSize={setPageSize}
            setPage={setPage}
            allowDelete={false}
            allowEdit={true}
            hideGatewayColumn
            filterParams={{ ...filterParams, gatewayIds: [gatewayId] }}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

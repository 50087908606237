import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import {
  FilterButton,
  SectionWrapper,
  SensorTrigger,
  useSensorFilterStore,
} from '@marlin/alert/ui/automation-builder-components';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSectionsStore$ } from '../hooks/use-observable-sections-store';
import { useSectionValidation } from '../hooks/use-section-validation.hook';
import { mapSensorOption } from '../utils/map-sensor-option';
import { ConditionBuilder } from './conditions/condition-builder.component';
import { emptyTrigger } from './const';
import { content } from './content';
import { TriggersContentView } from './triggers-content-view/triggers-content-view.component';
import { useStyles } from './triggers-section.styles';
import { TDevice, triggersEnabledSchema } from './triggers.validation.schema';
import { useDeleteTrigger } from './use-delete-trigger.hook';

interface ITriggersSectionProps {
  triggerIndex: number;
  maxTriggers: number;
}

export const TriggersSection = ({ triggerIndex, maxTriggers }: ITriggersSectionProps) => {
  const { classes } = useStyles({ maxTriggers, triggerIndex });
  const { setSection, isSectionActive } = useSectionsStore$();
  const { isValid } = useSectionValidation(triggersEnabledSchema);
  const { getValues, setValue, trigger } = useFormContext<TAutomationBuilder>();
  const { filters, clear, dispatchOnChange, toggleFiltersOpen, selectedFiltersCount, areFiltersOpen } =
    useSensorFilterStore();

  const { triggerExpanded, deleteTrigger, expandTrigger } = useDeleteTrigger(triggerIndex);

  const addSection = useCallback(() => {
    setSection(`trigger-${triggerIndex}`);
    setValue('triggers', [...getValues().triggers, emptyTrigger]);
    expandTrigger();
  }, [expandTrigger, getValues, setSection, setValue, triggerIndex]);

  const editModeToggle = useCallback(() => {
    trigger().then((result) => {
      if (result) {
        setSection(`trigger-${triggerIndex}`);
      }
    });
  }, [setSection, trigger, triggerIndex]);

  return (
    <SectionWrapper
      className={classes.triggerSectionPaper}
      sectionExpanded={triggerExpanded}
      editMode={isSectionActive(`trigger-${triggerIndex}`)}
      buttonTitle={content.ADD_TRIGGER}
      sectionTitle={content.TRIGGER(triggerIndex + 1)}
      buttonDisabled={!isValid}
      onToggle={addSection}
      viewModeChildren={<TriggersContentView triggerIndex={triggerIndex} editModeToggle={editModeToggle} />}
      actions={
        <>
          <IconButton onClick={editModeToggle} data-testid={`edit-trigger-${triggerIndex}`}>
            <EditRoundedIcon color="primary" />
          </IconButton>
          <IconButton onClick={deleteTrigger} data-testid={`delete-trigger-${triggerIndex}`}>
            <DeleteRoundedIcon color="primary" />
          </IconButton>
        </>
      }
      buttonVariant={triggerIndex === 0 ? 'regular' : 'array'}
      editModeActions={
        <div>
          <FilterButton onToggle={toggleFiltersOpen} selectedFiltersCount={selectedFiltersCount} />
          <IconButton onClick={deleteTrigger} data-testid={`delete-trigger-${triggerIndex}`}>
            <DeleteRoundedIcon color="primary" />
          </IconButton>
        </div>
      }
      testId={`trigger-${triggerIndex}`}
    >
      <div className={classes.container}>
        <SensorTrigger<TAutomationBuilder, TDevice>
          fieldName={`triggers.${triggerIndex}.device`}
          optionMapper={mapSensorOption}
          filters={filters}
          clear={clear}
          dispatchOnChange={dispatchOnChange}
          showFilters={areFiltersOpen}
        />
        <ConditionBuilder triggerIndex={triggerIndex} />
      </div>
    </SectionWrapper>
  );
};

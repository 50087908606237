import { useEquipmentDetails, useMetadata } from '@marlin/asset/data-access/equipment';
import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import {
  IRangeFilter,
  RANGE_FILTER,
  getNumberOfBuckets,
  isAercoDevice,
  removeEmptyChartData,
  useDuration,
} from '@marlin/shared/utils-chart';
import { useMemo } from 'react';

interface IUseChartDataProps {
  manufacturerId: string | undefined;
  rangeFilter: IRangeFilter;
  datapointGroupNames: string[];
  datapointNames: Array<string>;
  useLastReadings?: boolean;
  equipmentId: string | undefined;
  shouldHaveSpecialCalculations?: boolean;
}

interface ITranslation {
  name: string;
  label?: string;
}

export const useChartData = ({
  manufacturerId = '',
  rangeFilter,
  datapointGroupNames,
  datapointNames,
  equipmentId = '',
  shouldHaveSpecialCalculations = false,
}: IUseChartDataProps) => {
  const { data: metadataResponse, isLoading } = useMetadata({ equipmentId });
  const datapointGroupNamesLower = useMemo(
    () => datapointGroupNames.map((name) => name.toLowerCase()),
    [datapointGroupNames]
  );
  const datapointsGroupMetadata = useMemo(
    () =>
      metadataResponse?.chartDatapoints.reduce<ITranslation[]>((acc, group) => {
        if (datapointGroupNamesLower.includes(group.groupName.toLowerCase())) {
          return [...acc, ...group.datapoints];
        }

        return acc;
      }, []),
    [datapointGroupNamesLower, metadataResponse?.chartDatapoints]
  );

  const {
    fromDate: from,
    toDate: to,
    maxSelection,
    minSelection,
  } = useDuration({ rangeFilter, fromDate: null, toDate: null });
  const { data: { model } = {} } = useEquipmentDetails({ equipmentId });

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations,
      }),
    [minSelection, maxSelection, rangeFilter.range]
  );

  const {
    data: telemetryData,
    isLoading: isDataLoading,
    refetch,
    isFetching,
  } = useAnalyticsTelemetry(
    {
      dateFrom: from,
      dateTo: to,
      requestedTelemetry: [{ manufacturerId, datapoints: datapointNames }],
      numberOfBuckets,
      withoutMargins: true,
    },
    {
      select: (data) =>
        rangeFilter.range === RANGE_FILTER.HOURS_8 && isAercoDevice(model) ? removeEmptyChartData({ data }) : data,
    }
  );

  return {
    datapointsGroupMetadata,
    telemetryData,
    isLoading: isDataLoading || isLoading || isFetching,
    refetch,
  };
};

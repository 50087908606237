import { LoadingSpinner } from '@marlin/shared/ui-loader';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Divider, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';

import { EmptyOrganizationItem } from '../../utils/empty-organization-item';
import { IEntity } from '../../utils/types';
import { OrganizationItem } from '../organization-item';
import { content } from './content';
import { useStyles } from './organizations-list.styles';

interface IOrganizationsListProps {
  entityId?: string;
  entities: IEntity[];
  open: boolean;
  anchor: null | HTMLElement;
  onClose: () => void;
  onChange: (organizationId: string) => void;
  handleOrgSearchValue: (value: string) => void;
  orgSearchValue: string;
  fetchNextPage?: () => void;
  isLoading: boolean;
  totalItems?: number;
  hasNextPage?: boolean;
  isPortfolio: boolean;
  leftAnchor?: boolean;
  width?: number;
  groupByCustomerOrg?: boolean;
}

const maxAllowedOrganizationsWithoutSearchInput = 9;
const heightOfListWithSearchInput = 340;
const listItemHeight = 37;
const virtualizedListOffset = 150;
const orgGroups = [content.CUSTOMERS, content.OTHER];

const renderRow = (
  row: IEntity,
  currentOrgId: string,
  onChange: (organizationId: string) => void,
  isPortfolio: boolean
) => {
  return (
    <OrganizationItem
      isCurrentOrg={currentOrgId === row.id}
      key={row.id}
      onChange={() => onChange(row.id)}
      orgName={row.name}
      tierName={row.tierName || ''}
      isPortfolio={isPortfolio}
    />
  );
};

export const OrganizationsList = ({
  entityId,
  open,
  anchor,
  onChange,
  onClose,
  handleOrgSearchValue,
  orgSearchValue,
  entities: entitiesProp = [],
  fetchNextPage,
  isLoading,
  totalItems,
  hasNextPage,
  isPortfolio,
  leftAnchor = false,
  width,
  groupByCustomerOrg,
}: IOrganizationsListProps) => {
  const { classes } = useStyles({ width });
  const [searchInputplaceholder, setSearchInputPlaceholder] = useState(content.SEARCH_PLACEHOLDER_BLURED);

  const entities = useMemo(() => {
    if (isPortfolio) {
      if (orgSearchValue) {
        return entitiesProp.filter((org) => org.name.toLowerCase().includes(orgSearchValue.toLowerCase()));
      }

      return entitiesProp;
    }

    return entitiesProp;
  }, [isPortfolio, entitiesProp, orgSearchValue]);

  const groupCount = useMemo(() => {
    if (!groupByCustomerOrg) {
      return [];
    }
    const customerOrgs = entities.filter((org) => org.isCustomerOrganization);

    return [customerOrgs.length, entities.length - customerOrgs.length];
  }, [entities, groupByCustomerOrg]);

  const clearSearchFilter = () => {
    handleOrgSearchValue('');
  };

  const handleClose = () => {
    clearSearchFilter();
    onClose();
  };

  const endAdornment = () => {
    if (orgSearchValue.length > 0) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={clearSearchFilter}>
            <CloseRoundedIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  const calculatedHeightOfOrgList = useCallback((loadedOrgs: number) => {
    if (loadedOrgs < maxAllowedOrganizationsWithoutSearchInput) {
      return loadedOrgs * listItemHeight;
    }
    return heightOfListWithSearchInput;
  }, []);

  return (
    <Menu
      anchorEl={anchor}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: leftAnchor ? 'left' : 'right',
      }}
      disableAutoFocusItem={true}
      className={classes.container}
    >
      <TextField
        variant="standard"
        onChange={(event) => handleOrgSearchValue(event.currentTarget.value)}
        placeholder={searchInputplaceholder}
        value={orgSearchValue}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
        }}
        onFocus={() => {
          setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_FOCUSED);
        }}
        onBlur={() => {
          setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_BLURED);
        }}
        autoFocus={false}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: endAdornment(),
        }}
      />

      <Divider />

      {totalItems === 0 ? (
        <EmptyOrganizationItem />
      ) : groupByCustomerOrg ? (
        <GroupedVirtuoso
          style={{ height: calculatedHeightOfOrgList(entities?.length + 2 ?? 2) }}
          itemSize={() => listItemHeight}
          groupCounts={groupCount}
          groupContent={(index) => <Group index={index} />}
          itemContent={(index) => renderRow(entities[index], entityId ?? '', onChange, isPortfolio)}
          increaseViewportBy={virtualizedListOffset}
          endReached={fetchNextPage}
          context={{ isLoading, hasNextPage: hasNextPage || false }}
          components={{ Footer }}
        />
      ) : (
        <Virtuoso
          style={{ height: calculatedHeightOfOrgList(entities?.length || 0) }}
          itemSize={() => listItemHeight}
          totalCount={entities?.length}
          itemContent={(index) => renderRow(entities[index], entityId ?? '', onChange, isPortfolio)}
          increaseViewportBy={virtualizedListOffset}
          endReached={fetchNextPage}
          context={{ isLoading, hasNextPage: hasNextPage || false }}
          components={{ Footer }}
        />
      )}
    </Menu>
  );
};

const Group = ({ index }: { index: number }) => {
  const { classes } = useStyles({});

  return (
    <MenuItem className={classes.groupContainer}>
      <Typography className={classes.groupName}>{orgGroups[index]}</Typography>
    </MenuItem>
  );
};

const Footer = ({ context }: { context?: { isLoading: boolean; hasNextPage: boolean } }) => {
  const { classes } = useStyles({});

  if (!context?.hasNextPage) return null;
  return <div className={classes.loadingContainer}>{context?.isLoading && <LoadingSpinner />}</div>;
};

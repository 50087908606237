import { TAutomationBuilder, TBooleanCondition } from '@marlin/alert/data-access/automated-action';
import { useEffect } from 'react';
import { FieldPath, useController } from 'react-hook-form';

import { content } from '../content';
import { useCommonStyles } from './common-condition.styles';
import { ConditionHeader } from './condition-header.component';

const waterDetectCondition: TBooleanCondition = {
  type: 'boolean',
  threshold: true,
  operator: 'eq' as const,
  uom: 'WaterDetect',
};

interface IWaterDetectConditionProps {
  triggerIndex: number;
}

export const WaterDetectCondition = ({ triggerIndex }: IWaterDetectConditionProps) => {
  const { classes: commonClasses } = useCommonStyles();

  const {
    field: { onChange },
  } = useController<TAutomationBuilder>({
    name: `triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>,
  });

  useEffect(() => {
    onChange(waterDetectCondition);
  }, [onChange]);

  return (
    <div className={commonClasses.conditionContainer}>
      <ConditionHeader />
      <div data-testid="condition-water-detect">{content.CONDITIONS.LEAK_DETECTED}</div>
    </div>
  );
};

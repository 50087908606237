import { SensorDetails } from '@marlin/asset/feature/common-asset-details';
import { DevicesHub } from '@marlin/asset/feature/device-list';
import { EditDevice, NewDevice } from '@marlin/asset/feature/device-upsert';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const SensorRoutes = () => {
  return (
    <Route path={routes.sensors.root}>
      <Route
        index
        element={
          <PermissionGuard redirectTo={routes.home.dashboard} permissions={[PERMISSIONS.SENSOR_DEVICES]}>
            <DevicesHub />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.sensors.create.path}
        element={
          <PermissionGuard
            redirectTo={routes.sensors.root}
            permissions={[PERMISSIONS.CREATE_NEW_DEVICE, PERMISSIONS.SENSOR_DEVICES]}
          >
            <NewDevice />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.sensors.update.path}
        element={
          <PermissionGuard
            redirectTo={routes.sensors.list.path}
            permissions={[PERMISSIONS.EDIT_DEVICE, PERMISSIONS.SENSOR_DEVICES]}
          >
            <EditDevice />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.sensors.details.path}
        element={
          <PermissionGuard redirectTo={routes.home.dashboard} permissions={[PERMISSIONS.SENSOR_DEVICES]}>
            <SensorDetails />
          </PermissionGuard>
        }
      />
    </Route>
  );
};

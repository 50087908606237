import { actionSchema } from '@marlin/alert/data-access/automated-action';
import { z } from 'zod';

import { detailsSchema } from '../details/details.validation.schema';
import { triggerSchema } from '../triggers/triggers.validation.schema';

export const createAutomationEnabledSchema = z.object({
  details: detailsSchema,
  triggers: z.array(triggerSchema).nonempty(),
  actions: actionSchema,
});

export type TCreateAutomationValidation = z.infer<typeof createAutomationEnabledSchema>;

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
  nameRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  contextMenuWrapper: {
    marginTop: theme.typography.pxToRem(-8),
  },
  contactRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
}));

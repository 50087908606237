import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

import { adminPermissions } from './admin.permission';
import { csmPermissions } from './csm.permission';

export const supportPermissions: TPermission[] = [
  ...csmPermissions.filter((permission) => permission !== PERMISSIONS.SHOW_SETTINGS_PROFILE_PAGE),
  ...adminPermissions.filter((permission) => permission !== PERMISSIONS.SHOW_SETTINGS_PROFILE_PAGE),
  PERMISSIONS.INVITE_SUPPORT_USERS,
  PERMISSIONS.SHOW_WATER_TRACEABILITY_SECTION,
];

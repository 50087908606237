import { MarlinTheme } from '@marlin/shared/theme';
import { ITooltipData, useChartTooltip } from '@marlin/shared/ui/chart-options';
import { IChartSeries, IChartSeries as IChartSwitcherSeries } from '@marlin/shared/utils-chart';
import { createDateString, formatDate } from '@marlin/shared/utils-common-date';
import { useTheme } from '@mui/material';
import Plotly, { Config, Datum, Layout, PlotDatum } from 'plotly.js';
import { useCallback, useMemo } from 'react';

interface ICreateRangeBarConfig {
  from: number;
  to: number;
  isMobile: boolean;
  height: number;
}

interface IPlotDatum extends PlotDatum {
  z: Datum;
  base?: string;
}

export const useRangeBarConfig = ({ from, to, isMobile, height }: ICreateRangeBarConfig) => {
  const theme = useTheme<MarlinTheme>();
  const layout: Partial<Layout> = useMemo(() => {
    return {
      paper_bgcolor: theme.palette.background.primary,
      plot_bgcolor: theme.palette.background.primary,
      barmode: 'stack',
      hovermode: 'closest',
      showlegend: false,
      xaxis: {
        type: 'date',
        anchor: 'y',
        fixedrange: true,
        autorange: false,
        showgrid: true,
        range: from && to ? [from, to] : undefined,
      },
      margin: {
        t: isMobile ? 40 : 50,
        b: isMobile ? 40 : 50,
        l: isMobile ? 0 : undefined,
        r: isMobile ? 50 : undefined,
      },
      legend: { orientation: 'h' },
      height,
      yaxis: {
        anchor: 'x',
        type: 'category',
        showgrid: true,
        visible: false,
        fixedrange: true,
      },
    };
  }, [theme.palette.background.primary, from, to, isMobile, height]);

  const config: Partial<Config> = useMemo(
    () => ({
      displaylogo: false,
      responsive: true,
      displayModeBar: false,
    }),
    []
  );

  const { setShowTooltip, tooltip, setTooltip, showTooltip, tooltipDirection } = useChartTooltip();

  const handleHover = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      const eventPoints = event.points as IPlotDatum[];

      const points: ITooltipData[] = eventPoints.map((point: IPlotDatum) => {
        const timeFormat = 'DD MMM HH:mm';
        return {
          color: point.data.marker.color?.toString() || '',
          name: point.y?.toString() || '',
          date: typeof point.x === 'string' ? point.x.toString() : '',
          formattedDate: `${formatDate(createDateString(new Date(point.base ?? '')), timeFormat)} - ${formatDate(
            createDateString(new Date(point.x ?? '')),
            timeFormat
          )}`,
          displayedValue: '',
        };
      });

      setTooltip({
        top: event.event.clientY,
        left: event.event.clientX,
        tooltipData: points,
      });

      setShowTooltip(true);
    },
    [setShowTooltip, setTooltip]
  );

  const handleUnhover = useCallback(() => {
    setShowTooltip(false);
    setTooltip(null);
  }, [setShowTooltip, setTooltip]);

  return useMemo(() => {
    return {
      layout,
      config,
      handleHover,
      handleUnhover,
      tooltip: { ...(tooltip ?? {}), direction: tooltipDirection, showTooltip },
    };
  }, [layout, config, handleHover, handleUnhover, tooltip, tooltipDirection, showTooltip]);
};

export interface IMapRangeDataPoints {
  data: IChartSeries[];
  invertBarDatapointNames?: string[];
  colors: string[];
  datapointLabels: Record<string, string>;
}

const mapData = (series: IChartSeries, invertBarDatapointNames?: string[]) => {
  if (Array.isArray(invertBarDatapointNames) && invertBarDatapointNames.includes(series.id)) {
    return series.data.map((point) => ({ ...point, y: !point.y ? 1 : 0 }));
  }

  return series.data;
};

export const mapDataPoints = ({
  data,
  invertBarDatapointNames,
  colors,
  datapointLabels,
}: IMapRangeDataPoints): IChartSwitcherSeries[] => {
  return data.map(
    (data, index): IChartSwitcherSeries => ({
      ...data,
      id: data.id,
      name: datapointLabels[data.id] ?? data.name,
      color: colors[index % colors.length],
      type: 'rangeBar',
      data: mapData(data, invertBarDatapointNames),
    })
  );
};

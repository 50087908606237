import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { IPaginationMobile, PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { PropsWithChildren, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { EmptyList } from './empty-list.component';
import { ErrorList } from './error-list.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    margin: theme.typography.pxToRem(16),
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
}));

interface ICardListProps extends PropsWithChildren, IPaginationMobile {
  isFetchingNextPage: boolean;
  isError: boolean;
}

export const CardInfiniteList = ({
  children,
  totalItems,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  isError,
  loadedItems,
}: ICardListProps) => {
  const { classes } = useStyles();

  const content = useMemo(() => {
    if (isError) {
      return <ErrorList />;
    }

    if (loadedItems === 0) {
      return <EmptyList />;
    }

    return children;
  }, [children, isError, loadedItems]);

  return (
    <>
      <div className={classes.wrapper}>{content}</div>
      {isFetchingNextPage && <LoadingSpinner />}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={loadedItems}
        data-testid="users-pagination"
      />
    </>
  );
};

import { DeviceSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { DeviceTypeOption, TDeviceType, deviceTypeOptions } from '@marlin/shared/ui-form';
import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import { InputAdornment } from '@mui/material';
import { FC } from 'react';
import { ControllerProps, FieldValues, useController, useFormContext } from 'react-hook-form';

import { content } from '../content';

export interface IOption {
  id: string;
  name: string;
  deviceType?: TDeviceType;
}

export interface ISensorTriggerSelectProps<TFormValues extends FieldValues, TFieldValue extends IOption> {
  fieldName: ControllerProps<TFormValues>['name'];
  optionMapper?: (option: TDeviceSchema) => TFieldValue;
  deviceType?: TDeviceType | null;
  locationId?: string | null;
}

export const SensorTriggerSelectField = <TFormValues extends FieldValues, TFieldValue extends IOption>({
  fieldName,
  optionMapper,
  deviceType,
  locationId,
}: ISensorTriggerSelectProps<TFormValues, TFieldValue>) => {
  const { watch } = useFormContext<TFormValues>();
  const value = watch(fieldName);
  const {
    fieldState: { error },
  } = useController<TFormValues>({ name: fieldName });

  const SelectedIcon = deviceTypeOptions[value?.deviceType]?.Icon as FC | null | undefined;

  return (
    <DeviceSearchWrapper<TFieldValue>
      optionMapper={optionMapper}
      deviceTypes={deviceType ? [deviceType] : undefined}
      locations={locationId ? [locationId] : undefined}
      enableSearch={false}
    >
      {(props) => {
        const showSensorIcon = SelectedIcon && value.name === props.term;

        return (
          <InfiniteAutocompleteControl<TFormValues, TFieldValue>
            fieldName={fieldName}
            testId="sensor-trigger-select"
            label={content.TRIGGER.LABEL}
            {...props}
            multiple={false}
            disableClearable={false}
            required
            error={error?.['id']}
            startAdornment={
              showSensorIcon ? (
                <InputAdornment position="start">
                  <SelectedIcon />
                </InputAdornment>
              ) : undefined
            }
            renderOption={(listItemProps, sensor: IOption) => {
              const Icon = sensor.deviceType ? deviceTypeOptions[sensor.deviceType].Icon : null;
              return (
                <li {...listItemProps} key={sensor.id}>
                  <DeviceTypeOption Icon={Icon} name={sensor.name} />
                </li>
              );
            }}
          />
        );
      }}
    </DeviceSearchWrapper>
  );
};

import { IConfig, globalConfigTekmarSsc } from '@marlin/asset/shared/equipment-config';
import { tekmarLogo, tekmarSSCImage } from '@marlin/asset/shared/ui/assets';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';

import { content } from '../../content';
import { boilersAndPumpsConfig } from './boilers-pumps-config';
import { detailsConfig } from './details-config';
import { sectionConfig } from './section-config';

export const tekmarSmartSteamConfig: IConfig = {
  headerBanner: null,
  tabs: {},
  settings: {
    settingGroups: [
      {
        id: SETTINGS_GROUP.CENTRAL_HEATING,
        title: content.CENTRAL_HEATING,
      },
      {
        id: SETTINGS_GROUP.DOMESTIC_HOT_WATER,
        title: content.DOMESTIC_HOT_WATER,
      },
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
      {
        id: SETTINGS_GROUP.ALERT,
        title: content.ALERT_SETTINGS,
      },
    ],
    checkAvailableBoilers: true,
    schedule: {
      scheduleTempGroup: SETTINGS_GROUP.SCHEDULE_TEMPERATURE,
    },
  },
  details: detailsConfig,
  dashboard: {
    ...globalConfigTekmarSsc.dashboard,
    productDetails: {
      datapoints: [
        // wwsd
        {
          name: 'modeVal',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['wwsd', true]]),
            },
          },
        },
        // Override Max Heat || Max Heat || Override hand
        {
          name: 'condensateTemp',
          label: content.DATAPOINT_SSC_LABELS.CONDENSATE,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['overrideMaxHeat', true],
                ['heatOn', true],
                ['overrideOff', true],
                ['overrideHand', true],
                ['heatOff', true],
              ]),
            },
          },
        },
        {
          name: 'outdoorTemp',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['overrideMaxHeat', true],
                ['heatOn', true],
                ['overrideOff', true],
                ['overrideHand', true],
                ['heatOff', true],
              ]),
            },
          },
        },
        // establishing steam, cooldown
        {
          name: 'condensateTarget',
          label: content.DATAPOINT_SSC_LABELS.TARGET,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['establishingSteamTemp', true],
                ['coolDown', true],
              ]),
            },
          },
        },
        {
          name: 'modeVal',
          label: content.DATAPOINT_SSC_LABELS.CONDENSATE,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['establishingSteamTemp', true],
                ['coolDown', true],
              ]),
            },
          },
        },
        {
          name: 'outdoorTemp',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([
                ['establishingSteamTemp', true],
                ['coolDown', true],
              ]),
            },
          },
        },

        // No Heat Abort Cutoff
        {
          name: 'modeVal',
          label: content.DATAPOINT_SSC_LABELS.INDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['noHeatAbortCutoff', true]]),
            },
          },
        },
        {
          name: 'condensateTarget',
          label: content.DATAPOINT_SSC_LABELS.CONDENSATE,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['noHeatAbortCutoff', true]]),
            },
          },
        },
        {
          name: 'outdoorTemp',
          label: content.DATAPOINT_SSC_LABELS.OUTDOOR,
          dependencies: {
            visibility: {
              datapointName: 'modeStatus',
              value: new Map([['noHeatAbortCutoff', true]]),
            },
          },
        },
      ],
      imageSrc: tekmarSSCImage,
      logoSrc: tekmarLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: true,
    sections: sectionConfig,
    chart: {
      datapointNames: ['condensateTemp', 'condensateTarget', 'outdoorTemp'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: false,
  },
  boilersAndPumps: boilersAndPumpsConfig,
};

import { IconEquipment, IconSensor, TIconEquipment } from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from './content';

export const deviceTypeSchema = z.union([
  z.literal('PRESSURE'),
  z.literal('TEMPERATURE'),
  z.literal('LEAK'),
  z.literal('PULSE_METER'),
  z.literal('EQUIPMENT'),
  z.literal('HUB'),
]);

export const meterTypeSchema = z.union([z.literal('Gas'), z.literal('Water')]).nullish();

export type TDeviceType = z.infer<typeof deviceTypeSchema>;
export type TMeterType = z.infer<typeof meterTypeSchema>;

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  deviceTypeIcon: {
    color: theme.palette.action.active,
  },
}));

type TOptionTypes = Exclude<TDeviceType, 'HUB'>;

export const deviceTypeOptions: Record<TOptionTypes, { name: string; Icon: TIconEquipment }> = {
  TEMPERATURE: {
    name: content.SENSOR_TYPE_TEMPERATURE_LABEL,
    Icon: IconSensor.Temperature,
  },
  PRESSURE: {
    name: content.SENSOR_TYPE_PRESSURE_LABEL,
    Icon: IconSensor.Pressure,
  },
  LEAK: {
    name: content.SENSOR_TYPE_LEAK_LABEL,
    Icon: IconSensor.LeakDetector,
  },
  PULSE_METER: {
    name: content.SENSOR_TYPE_FLOW_LABEL,
    Icon: IconSensor.FlowMeter,
  },
  EQUIPMENT: {
    name: content.EQUIPMENT_LABEL,
    Icon: IconEquipment.Other,
  },
};

interface ISensorTypeProps {
  deviceType?: TDeviceType;
  showName?: boolean;
  className?: string;
  meterType?: TMeterType;
}

export const SensorType = ({ deviceType, showName = true, className = '' }: ISensorTypeProps) => {
  const { classes, cx } = useStyles();

  if (!deviceType) {
    return null;
  }

  const { name, Icon } = deviceTypeOptions[deviceType];

  return (
    <div className={cx(classes.deviceTypeOption, className)}>
      <Icon className={classes.deviceTypeIcon} />
      {showName && name}
    </div>
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  listActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  filterChipWrapper: {
    display: 'flex',
  },
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
}));

import { AVERAGING_FUNCTION_FILTER, TChartDisplayType } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';
import {
  TDatapoint,
  TDatapointWithMetadata,
  TDatapointWithUiMetadata,
  TSettingsGroup,
} from '@marlin/shared/utils/datapoint-mappers';
import { TDevice, TEquipment } from '@marlin/shared/utils/zod';
import { ReactElement, ReactNode } from 'react';

export const chartIdList = [
  'temperature',
  'pressure',
  'flow',
  'pumpvalve',
  'other',
  'flamestrength',
  'pumpstatus',
  'boilerfiringrate',
] as const;

export type TChartId = (typeof chartIdList)[number];

export interface IConfig {
  headerBanner: ReactElement | null;
  tabs: {
    [key in EQUIPMENT_TAB_VALUES]?: ReactElement;
  };
  dashboard: IDashboardConfig;
  details: TEquipmentDetailsRow[];
  boilersAndPumps?: {
    rows: IBoilersAndPumpsRow[];
    columns: TBoilersAndPumpsColumnName[];
  };
  settings?: {
    functionsMapping?: {
      [key: string]: TFunctionsMappingCallback;
    };
    dynamicSettingsSections?: IDynamicSettingsSection[];
    settingGroups: ISettingsConfig[];
    checkAvailableBoilers?: boolean;
    schedule?: {
      scheduleTempGroup?: TSettingsGroup;
      scheduleDatapoints?: string[];
      headerTooltip?: NonNullable<ReactNode>;
      tempGroupReadonly?: boolean;
    };
  };
}

export type TDependencies = {
  override?: {
    datapointName: string;
    value: Map<string, string>;
  };
  visibility?: {
    datapointName: string;
    value: Map<string, boolean>;
  };
  statusMapping?: Map<string, string>;
  aggregation?: {
    datapointNames: string[];
    function: string; // TODO: define functions
  };
  additionalDatapointWhenActive?: {
    datapointName: string;
  };
};

export type TEquipmentDashboardSectionMetadataItem = {
  name: string;
  label: string;
  eventCodes?: string[];
  dependencies?: TDependencies;
  link?: { url: string; label: string } | null;
};

export type TEquipmentDashboardSectionMetadata = {
  title: string;
  sectionName: string;
  items: Array<TEquipmentDashboardSectionMetadataItem>;
  icon?: ReactElement | string;
};

export interface IDashboardSectionStyle {
  fullWidth?: boolean;
  rowMaxLength?: number;
}

export interface ISection {
  title: string;
  sectionDatapoints?: TEquipmentDashboardSectionMetadata[];
  dataTestId: string;
  style?: IDashboardSectionStyle;
  link?: { url: string; label: string };
}

export interface IChartConfigOptions {
  averagingFunctionFilter?: AVERAGING_FUNCTION_FILTER;
}

export type TFunctionsMappingCallback = (
  configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
  datapointsFromDeps: TDatapointWithUiMetadata[]
) => TDatapointWithUiMetadata;

export interface IDashboardConfig {
  productDetails:
    | {
        datapoints: { name: string; label: string; dependencies?: TDependencies; eventCodes?: string[] }[];
        imageSrc?: ((type: string) => string) | string;
        logoSrc?: string;
        shouldDisplayRole: boolean;
        shouldDisplaySerialNumber: boolean;
      }
    | undefined;
  topAlerts: ITopAlertsDefault | ITopAlertsDatapoint;
  commonSectionSettings?: Partial<ISection>;
  sections: ISection[];
  functionsMapping?: {
    [key: string]: TFunctionsMappingCallback;
  };
  liveDataExpirationSeconds: number;
  enableStartStopData: boolean;
  chart?: {
    datapointNames: string[];
    datapointGroupNames: string[];
    title: string;
    subTitle?: string;
    calculateValues?: boolean;
    chartType?: TChartDisplayType;
    invertBarDatapointNames?: string[];
    options?: IChartConfigOptions;
  };
  isLastReadingTimeCounter: boolean;
  dynamic?: boolean;
  datapointCallback?: (datapoint: TDatapointWithMetadata, allDatapoints: TDatapoint[]) => TDatapointWithMetadata;
}

export type TEquipmentValueSource = 'equipment' | 'datapoints' | 'mix' | 'device';

type TEquipmentDetailsRowCommon = {
  label: string;
  tooltip?: string;
  valueCallback?: (value: unknown) => string | undefined;
};

type TEquipmentDetailsDatapointSource = TEquipmentDetailsRowCommon & {
  valueSource: 'datapoints';
  name: string | string[];
};

type TEquipmentDetailsEquipmentSource = TEquipmentDetailsRowCommon & {
  valueSource: 'equipment';
  name: keyof TEquipment | Array<keyof TEquipment>;
};

type TEquipmentDetailsMixSource = TEquipmentDetailsRowCommon & {
  valueSource: 'mix';
  name: keyof TEquipment | Array<keyof TEquipment> | string[];
};

type TEquipmentDetailsDeviceSource = TEquipmentDetailsRowCommon & {
  valueSource: 'device';
  name: keyof TDevice | Array<keyof TDevice>;
};

type TEquipmentDetailsAdditionalPropertiesSource = TEquipmentDetailsRowCommon & {
  valueSource: 'additionalProperties';
  name: string;
};

export type TEquipmentDetailsRow =
  | TEquipmentDetailsDatapointSource
  | TEquipmentDetailsEquipmentSource
  | TEquipmentDetailsMixSource
  | TEquipmentDetailsDeviceSource
  | TEquipmentDetailsAdditionalPropertiesSource;

export interface IGlobalEquipmentConfig {
  dashboard: {
    liveDataExpirationSeconds: number;
  };
  calculationValues: boolean;
  chart: IChart[];
}

export interface IChart {
  name: TChartId;
  label: string;
  type: TChartDisplayType;
  hideUomOnAxis?: boolean;
  additionalAxisUom?: TUnitOfMeasure;
  mainDatapoint?: string;
  checkAvailableBoilers?: boolean;
}

interface ISettingsConfig {
  id: TSettingsGroup;
  title: string;
}

export interface IBoilersAndPumpsRow {
  id: string;
  name: string;
  type: 'pump' | 'boiler';
  hoursDatapoint?: string;
  cyclesDatapoint?: string;
  statusDatapoint?: string;
  pumpStatus?: string;
  pumpStatusControlType?: 'error' | 'highlight';
  valveStatus?: string;
  boilerStatus?: string;
  boilerStatusControlType?: 'error' | 'highlight';
  boilerFiringRateDatapoint?: string;
  boilerFiringTempDatapoint?: string;
  boilerEnabledStatus?: string;
  dependencies?: TDependencies;
}

interface ISettingsCommand {
  label: string;
  value: string;
  payload?: {
    [key: string]: string | { [key: string]: string };
  };
}

export interface ISettingsCommandGroup {
  label: string;
  commands: ISettingsCommand[];
}

export interface ISettingsPoint {
  name: string;
  label: string;
  match?: string;
  dependencies?: TDependencies;
  commandGroup?: ISettingsCommandGroup;
}

interface IDynamicSettingsSection {
  id: string;
  title: string;
  commandGroup?: ISettingsCommandGroup;
  settings: ISettingsPoint[];
}

type TMapperFunctions = Array<(datapoints: TDatapointWithUiMetadata[]) => TDatapointWithUiMetadata[]>;

interface ITopAlertsDefault {
  show: boolean;
  alertSource: 'default';
}

interface ITopAlertsDatapoint {
  show: boolean;
  alertSource: 'datapoint';
  datapointsMappers: TMapperFunctions;
}

export type TBoilersAndPumpsColumnName = 'name' | 'boiler' | 'hours' | 'cycles' | 'boilerStatus' | 'pumpStatus';

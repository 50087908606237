import { Typography } from '@mui/material';

interface IRecipientNameProps {
  firstName: string;
  lastName: string;
}

export const RecipientName = ({ firstName, lastName }: IRecipientNameProps) => {
  return (
    <div>
      <Typography variant="body2" fontWeight="bold">{`${firstName} ${lastName}`}</Typography>
    </div>
  );
};

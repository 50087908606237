import { TDataPointsSchema } from '@marlin/shared/utils-format-reading';

import { TDeviceChildrenDatapointsParams } from '../infrastructure/schema/device-children-datapoints.schema';
import { TSubtypesParams } from '../infrastructure/schema/subtypes.schema';
import { TWaterTrendsParams } from '../infrastructure/schema/water-trends.schema';
import { IFilterParams } from './filter-params.utils';

export enum QUERY_KEY {
  DEVICES = 'devices',
  DEVICE = 'device',
  DEVICE_DETAILS = 'device_details',
  PIN_DEVICES = 'pin-devices',
  HOME_LOCATIONS = 'home-locations',
  MANUFACTURER = 'manufacturer',
  SUBTYPES = 'subtypes',
  MODELS = 'models',
  CALIBRATIONS = 'calibrations',
  WATER_TRENDS = 'water-trends',
  DEFAULT_FLOW_METERS = 'default-flow-meters',
  DEVICE_CHILDREN_DATAPOINTS_FILTER = 'device-children-datapoints-filter',
  INFINITE_DEVICE_CHILDREN_DATAPOINTS_FILTER = 'infinite-device-children-datapoints-filter',
}

export const queryKey = {
  DEVICES: (filterParams?: IFilterParams) => (filterParams ? [QUERY_KEY.DEVICES, filterParams] : [QUERY_KEY.DEVICES]),
  DEVICE: (id: string) => [QUERY_KEY.DEVICE, id],
  DEVICE_DETAILS: (id: string, datapoints?: TDataPointsSchema[]) => [QUERY_KEY.DEVICE_DETAILS, { id, datapoints }],
  PIN_DEVICES: () => [QUERY_KEY.PIN_DEVICES],
  HOME_LOCATIONS: () => [QUERY_KEY.HOME_LOCATIONS],
  MANUFACTURER: (id: string) => [QUERY_KEY.MANUFACTURER, id],
  DEVICE_SUBTYPES: (subtypesParams: TSubtypesParams) => [QUERY_KEY.SUBTYPES, subtypesParams],
  DEVICE_MODELS: () => [QUERY_KEY.MODELS],
  WATER_TRENDS: (params: TWaterTrendsParams) => [QUERY_KEY.WATER_TRENDS, params],
  CALIBRATIONS: (filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.CALIBRATIONS, filterParams] : [QUERY_KEY.CALIBRATIONS],
  DEFAULT_FLOW_METERS: () => [QUERY_KEY.DEFAULT_FLOW_METERS],
  DEVICE_CHILDREN_DATAPOINTS_FILTER: (params: TDeviceChildrenDatapointsParams) => [
    QUERY_KEY.DEVICE_CHILDREN_DATAPOINTS_FILTER,
    params,
  ],
  INFINITE_DEVICE_CHILDREN_DATAPOINTS_FILTER: (params: Omit<TDeviceChildrenDatapointsParams, 'page'>) => [
    QUERY_KEY.DEVICE_CHILDREN_DATAPOINTS_FILTER,
    params,
  ],
};

import { TInfiniteUserParamsSchema, TUserParamsSchema } from './user.model';

export enum QUERY_KEY {
  USER = 'user',
  INFINITE_USER = 'infinite_user',
  USER_DATA = 'user_data',
}

export const queryKey = {
  USER: (userId: string) => [QUERY_KEY.USER, userId],
  USER_LIST: (params?: TUserParamsSchema) => [QUERY_KEY.USER, params || {}],
  USER_INFINITE_LIST: (params?: TInfiniteUserParamsSchema) => [QUERY_KEY.INFINITE_USER, params || {}],
  USER_DATA: (area: string) => [QUERY_KEY.USER_DATA, area],
};

import { IConfig, globalConfigTekmarSbs } from '@marlin/asset/shared/equipment-config';
import { sbcImage, tekmarLogo } from '@marlin/asset/shared/ui/assets';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';

import { content } from '../../content';
import { boilersAndPumpsConfig } from './boilers-pumps-config';
import { detailsConfig } from './details-config';
import { sectionConfig } from './section-config';

export const tekmarSmartBoilerConfig: IConfig = {
  headerBanner: null,
  tabs: {},
  settings: {
    settingGroups: [
      {
        id: SETTINGS_GROUP.CENTRAL_HEATING,
        title: content.CENTRAL_HEATING,
      },
      {
        id: SETTINGS_GROUP.DOMESTIC_HOT_WATER,
        title: content.DOMESTIC_HOT_WATER,
      },
      {
        id: SETTINGS_GROUP.CONSTANT_SETPOINT,
        title: content.CONSTANT_SETPOINT,
      },
      {
        id: SETTINGS_GROUP.REMOTE_SETPOINT,
        title: content.REMOTE_SETPOINT,
      },
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
      {
        id: SETTINGS_GROUP.ALERT,
        title: content.ALERT_SETTINGS,
      },
    ],
    checkAvailableBoilers: true,
    schedule: {
      scheduleTempGroup: SETTINGS_GROUP.SCHEDULE_TEMPERATURE,
    },
  },
  details: detailsConfig,
  dashboard: {
    ...globalConfigTekmarSbs.dashboard,
    productDetails: {
      datapoints: [
        {
          name: 'supplyTemp',
          label: content.DATAPOINT_SBS_LABELS.BOILER_SUPPLY_TEMP,
          dependencies: {
            statusMapping: new Map([['absent', content.EQUIPMENT_STATUS_LABELS.NOT_INSTALLED]]),
          },
        },
        { name: 'boilerTarget', label: content.DATAPOINT_SBS_LABELS.TARGET },
        { name: 'boilerTotalOutput', label: content.DATAPOINT_SBS_LABELS.PLANT_OUTPUT },
      ],
      imageSrc: sbcImage,
      logoSrc: tekmarLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: true,
    sections: sectionConfig,
    chart: {
      datapointNames: ['supplyTemp', 'boilerTarget'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: false,
  },
  boilersAndPumps: boilersAndPumpsConfig,
};

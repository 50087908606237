import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import {
  FilteredResultsChip,
  MobileFilterModal,
  MobileFilterToggleButton,
  useMobileFilterForm,
} from '@marlin/asset/shared/ui/mobile-filter-modal';
import { MarlinTheme } from '@marlin/shared/theme';
import { FieldFiltersChips, FormFilterChips } from '@marlin/shared/ui-form';
import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IOption } from '../shared/types';
import { FilteredLocationList } from './filtered-location-list.component';
import { LocationList } from './location-list.component';
import { TLocationSort, sortList } from './sorting';
import { ILocationsHubFilters, useLocationsHub } from './use-locations-hub.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  filteredResultsChip: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(8),
  },
}));

const defaultFilters: ILocationsHubFilters = {
  parentLocationIds: [],
};

export function RwdLocationHub() {
  const { classes } = useStyles();
  const mobileFilterForm = useMobileFilterForm<TLocationSort, ILocationsHubFilters>({ defaultFilters, sortList });
  const { rows, totalItems, hasNextPage, fetchNextPage, isLoading } = useLocationsHub(mobileFilterForm.filters);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageContainer prefix="location-hub-page" data-testid="location-hub-container">
      <PageHeader
        icon={<SensorsRoundedIcon />}
        title={content.LOCATIONS_HUB_TITLE}
        prefix="location-hub-header"
        data-testid="location-hub-header"
        actions={<MobileFilterToggleButton onToggle={mobileFilterForm.toggleOpen} isOpen={mobileFilterForm.open} />}
      />
      {mobileFilterForm.isAnyFilterSet ? (
        <div className={classes.filteredResultsChip}>
          <FilteredResultsChip onDelete={mobileFilterForm.clearFilters} />
        </div>
      ) : undefined}
      <LocationList
        rows={rows}
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isListLoading={isLoading}
      />
      <MobileFilterModal<TLocationSort, ILocationsHubFilters>
        {...mobileFilterForm}
        rows={<FilteredLocationList />}
        title={content.LOCATIONS_HUB_TITLE}
        chips={
          <FormFilterChips<ILocationsHubFilters> defaultFilters={defaultFilters}>
            <FieldFiltersChips<ILocationsHubFilters, 'parentLocationIds'>
              fieldName="parentLocationIds"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
          </FormFilterChips>
        }
      >
        <LocationSearchWrapper showUsedByLocations>
          {(props) => (
            <InfiniteAutocompleteControl<ILocationsHubFilters, IOption>
              fieldName="parentLocationIds"
              testId="location-search"
              label="Parent Location"
              {...props}
            />
          )}
        </LocationSearchWrapper>
      </MobileFilterModal>
    </PageContainer>
  );
}

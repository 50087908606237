import { ISection } from '@marlin/asset/shared/equipment-config';

import { content } from '../../content';

export const sectionConfig: ISection[] = [
  {
    title: content.SYSTEM_INPUTS,
    dataTestId: 'system-inputs-section',
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'callsAndTargets',
        title: content.HEATING_CALLS_AND_TARGET,
        items: [
          {
            name: 'hotTankCall',
            label: content.DATAPOINT_SHP_LABELS.HOT_TANK_TARGET,
            dependencies: {
              additionalDatapointWhenActive: {
                datapointName: 'hotTankTarget',
              },
            },
          },
          {
            name: 'dhwCall',
            label: content.DATAPOINT_SHP_LABELS.DHW,
            dependencies: {
              additionalDatapointWhenActive: {
                datapointName: 'dhwTarget',
              },
            },
          },
          {
            name: 'dualFuelStatus',
            label: content.DATAPOINT_SHP_LABELS.DUAL_FUEL_CALL,
          },
          {
            name: 'coldTankCall',
            label: content.DATAPOINT_SHP_LABELS.COLD_TANK_TARGET,
            dependencies: {
              additionalDatapointWhenActive: {
                datapointName: 'coldTankTarget',
              },
            },
          },
          {
            name: 'coldTankDewpoint',
            label: content.DATAPOINT_SHP_LABELS.COLD_TANK_DEWPOINT,
          },
        ],
      },
      {
        sectionName: 'mixCallsAndTargets',
        title: content.MIX_CALLS_AND_TARGETS,
        items: [
          {
            name: 'mix1Call',
            label: content.DATAPOINT_SHP_LABELS.MIX_1,
            dependencies: {
              additionalDatapointWhenActive: {
                datapointName: 'mix1Target',
              },
            },
          },
          {
            name: 'mix2Call',
            label: content.DATAPOINT_SHP_LABELS.MIX_2,
            dependencies: {
              additionalDatapointWhenActive: {
                datapointName: 'mix2Target',
              },
            },
          },
          {
            name: 'mix3Call',
            label: content.DATAPOINT_SHP_LABELS.MIX_3,
            dependencies: {
              additionalDatapointWhenActive: {
                datapointName: 'mix3Target',
              },
            },
          },
          {
            name: 'mix1Dewpoint',
            label: content.DATAPOINT_SHP_LABELS.MIX_1_DEWPOINT,
          },
          {
            name: 'mix2Dewpoint',
            label: content.DATAPOINT_SHP_LABELS.MIX_2_DEWPOINT,
          },
          {
            name: 'mix3Dewpoint',
            label: content.DATAPOINT_SHP_LABELS.MIX_3_DEWPOINT,
          },
        ],
      },
      {
        sectionName: 'sensors',
        title: content.SENSORS,
        items: [
          {
            name: 'tankTemp',
            label: content.DATAPOINT_SHP_LABELS.TANK,
          },
          {
            name: 'tankReturnTemp',
            label: content.DATAPOINT_SHP_LABELS.HP_RETURN,
          },
          {
            name: 'hotTankTemp',
            label: content.DATAPOINT_SHP_LABELS.HOT_TANK_SUPPLY,
          },
          {
            name: 'hotTankReturnTemp',
            label: content.DATAPOINT_SHP_LABELS.HOT_HP_RETURN,
          },
          {
            name: 'outdoorTemp',
            label: content.DATAPOINT_SHP_LABELS.OUTDOOR,
          },
          {
            name: 'coldTankTemp',
            label: content.DATAPOINT_SHP_LABELS.COLD_TANK_SUPPLY,
          },
          {
            name: 'coldTankReturnTemp',
            label: content.DATAPOINT_SHP_LABELS.COLD_HP_RETURN,
          },
          {
            name: 'dhwTemp',
            label: content.DATAPOINT_SHP_LABELS.DHW,
          },
          {
            name: 'transferTemp',
            label: content.DATAPOINT_SHP_LABELS.TRANSFER,
          },
        ],
      },
    ],
  },
  {
    title: content.SYSTEM_OUTPUTS_AND_CONDITION,
    dataTestId: 'system-outputs-and-special-conditions-section',
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'outputs',
        title: content.OUTPUTS,
        items: [
          {
            name: 'wwsdStatus',
            label: content.DATAPOINT_SHP_LABELS.WARM_WEATHER_SHUTDOWN,
          },
          {
            name: 'cwsdStatus',
            label: content.DATAPOINT_SHP_LABELS.COLD_WEATHER_SHUTDOWN,
          },
          {
            name: 'overrideMode',
            label: content.DATAPOINT_SHP_LABELS.OVERRIDES,
          },
          {
            name: 'dhwPriorityStatus',
            label: content.DATAPOINT_SHP_LABELS.DHW_PRIORITY,
          },
          {
            name: 'dhwPriorityOverStatus',
            label: content.DATAPOINT_SHP_LABELS.DHW_PRIORITY_OVERRIDE,
          },
          {
            name: 'dualFuelStatus',
            label: content.DATAPOINT_SHP_LABELS.DUAL_FUEL_PRIORITY,
          },
          {
            name: 'heatPumpPriority',
            label: content.DATAPOINT_SHP_LABELS.HEAT_COOL_PRIORITY,
          },
        ],
      },
      // TODO - Uncomment when the metadata is available PBI 56911
      // {
      //   sectionName: 'system-equipment',
      //   title: content.SYSTEM_EQUIPMENT,
      //   items: [
      //     {
      //       name: 'hpStatus',
      //       label: content.DATAPOINT_SHP_LABELS.HEAT_PUMPS,
      //       dependencies: {
      //         aggregation: {
      //           function: 'other',
      //           datapointNames: new Array(16).fill(null).map((_, i) => `hp${i + 1}Status`),
      //         },
      //       },
      //     },
      //     {
      //       name: 'mixingStatus',
      //       label: content.DATAPOINT_SHP_LABELS.MIXING,
      //       dependencies: {
      //         aggregation: {
      //           function: 'other',
      //           datapointNames: ['mix1PumpStatus', 'mix2PumpStatus', 'mix3PumpStatus'],
      //         },
      //       },
      //     },
      //     {
      //       name: 'pumpsStatus',
      //       label: content.DATAPOINT_SHP_LABELS.PUMPS,
      //       dependencies: {
      //         aggregation: {
      //           function: 'other',
      //           datapointNames: ['hotTankPumpStatus', 'coldTankPumpStatus', 'dhwPumpStatus', 'xferPumpStatus'],
      //         },
      //       },
      //     },
      //   ],
      // },
    ],
  },
];

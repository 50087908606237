import { IDashboardSectionStyle, TEquipmentDashboardSectionMetadata } from '@marlin/asset/shared/equipment-config';
import { MarlinTheme } from '@marlin/shared/theme';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useDatapointsContext } from '../../../../../shared/context/datapoints.context';
import { DatapointBox } from './datapoint-box.component';
import DatapointInfo from './datapoint-info.component';

interface IDatapointSectionProps {
  sectionDatapoints: TEquipmentDashboardSectionMetadata[];
  style?: IDashboardSectionStyle;
}

const useStyles = makeStyles<{ fullWidth?: boolean }>()((theme: MarlinTheme, { fullWidth }) => ({
  sensorsBox: {
    display: 'grid',
    gap: theme.typography.pxToRem(8),
    width: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  section: {
    display: 'grid',
    gridTemplateColumns: fullWidth ? '1fr' : '1fr 1fr',
    gap: theme.typography.pxToRem(16),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  skeleton: {
    transform: 'none',
    height: theme.typography.pxToRem(112),
  },
}));

const DatapointSection = ({ sectionDatapoints, style }: IDatapointSectionProps) => {
  const { classes } = useStyles({ fullWidth: style?.fullWidth });
  const { isLoading } = useDatapointsContext();

  return (
    <div className={classes.section}>
      {sectionDatapoints.map(({ items, title, icon, sectionName }) => {
        if (isLoading) {
          return <Skeleton className={classes.skeleton} key={sectionName} />;
        }

        return (
          <DatapointBox key={sectionName} title={title} icon={icon} id={sectionName}>
            <div className={classes.sensorsBox}>
              {items.map((item) => {
                return (
                  <DatapointInfo
                    key={`${item.label}-${item.name}`}
                    label={item.label}
                    name={item.name}
                    dependencies={item.dependencies}
                    link={item.link}
                  />
                );
              })}
            </div>
          </DatapointBox>
        );
      })}
    </div>
  );
};

export { DatapointSection };

import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { LogicOperator } from '@marlin/alert/ui/automation-builder-components';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TriggersSection } from './triggers-section.component';

interface ITriggersArrayProps {
  maxTriggers?: number;
}

export const TriggersArray = ({ maxTriggers = 3 }: ITriggersArrayProps) => {
  const { watch, setValue } = useFormContext<TAutomationBuilder>();
  const triggers = watch('triggers');
  const [selectedOperator, setSelectedOperator] = useState<'AND' | 'OR'>('AND');

  const toggleOperator = () => {
    setSelectedOperator(selectedOperator === 'AND' ? 'OR' : 'AND');
    setValue('logicOperator', selectedOperator === 'AND' ? 'OR' : 'AND');
  };

  const triggerArray = Array.from({ length: triggers.length + 1 <= maxTriggers ? triggers.length + 1 : maxTriggers });

  return triggerArray.map((_, index) => {
    return (
      <>
        <TriggersSection key={index} triggerIndex={index} maxTriggers={triggers.length} />
        {triggers[index + 1] !== undefined && index < triggerArray.length - 1 && (
          <LogicOperator selectedOperator={selectedOperator} onToggleOperator={toggleOperator} />
        )}
      </>
    );
  });
};

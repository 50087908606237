import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { ConnectionStatus } from '@marlin/asset/shared/ui/connection-status';
import { MarlinTheme } from '@marlin/shared/theme';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';
import { ProductDetails } from '@marlin/ui/product-details';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from '../../../shared/content';
import { useGatewayDatapointsContext } from '../../../shared/context/gateway-datapoints.context';
import { useGatewayDetailsConfigContext } from '../../../shared/context/gateway-details-config.context';
import { getDatapoint, getDatapointWithSchema } from '../../../shared/utils/get-datapoint.utils';
import { getIsOnline } from '../../../shared/utils/get-is-online.utils';
import { ConnectionType } from './connection-type/connection-type.component';
import { DashboardAlerts } from './gateway-alerts.component';
import { GatewayDetailsSection } from './gateway-details.component';
import { EquipmentsAndSensorsList } from './lists/equipments-and-sensors-list.component';
import { StatusBar } from './top-bar-status/top-bar-status.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.typography.pxToRem(24),
    rowGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('md')]: {
      rowGap: 0,
    },
  },
  alerts: {
    display: 'flex',
  },
  productDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(8),
    width: '100%',
    overflow: 'auto',
    margin: 0,
  },
  tileOverride: {
    // override default HeaderDatapoint styles
    '& > div, & > span': {
      minWidth: theme.typography.pxToRem(170),
    },
    '& > span': {
      padding: 0,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.typography.pxToRem(0),
    [theme.breakpoints.down('lg')]: {
      gap: 'unset',
      marginTop: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(74),
    maxWidth: theme.typography.pxToRem(170),
    flexDirection: 'column',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.primary,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  grid: {
    gridColumn: '1 / -1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface IDashboard {
  gateway: TGatewayInfoUpdateResponsePayload;
}

export const Dashboard = ({ gateway }: IDashboard) => {
  const { classes, cx } = useStyles();
  const { datapoints, timestamp } = useGatewayDatapointsContext();

  const isOnline = getIsOnline(timestamp, gateway.lastReadingTime, gateway.model);

  const {
    config: {
      dashboard: { displayEquipmentAndSensorsList, displayTopAlerts, displayGatewayMode, productDetails },
    },
  } = useGatewayDetailsConfigContext();

  const signalStrength = getDatapoint(
    productDetails?.datapoints.connectionStatus?.signalStrengthDatapointName ?? '',
    datapoints
  );
  const connectionType = getDatapoint(
    productDetails?.datapoints.connectionType?.connectionTypeDatapointName ?? '',
    datapoints
  );
  const powerSource = getDatapointWithSchema(
    productDetails?.datapoints.powerSource?.powerSourceDatapointName ?? '',
    datapoints,
    z.literal('LINE').or(z.literal('BATTERY'))
  );
  const batteryLevel = getDatapoint(
    productDetails?.datapoints.powerSource?.batteryLevelDatapointName ?? '',
    datapoints
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.column}>
        <ProductDetails
          statusPanel={
            <StatusBar
              mode={gateway.mode}
              displayMode={displayGatewayMode}
              fullWidth={false}
              time={timestamp ?? gateway.lastReadingTime}
              isOnline={isOnline}
            />
          }
          logoSrc={productDetails?.logoSrc}
          model={gateway.model}
          equipmentModel={productDetails?.modelName}
          imageSrc={productDetails?.imageSrc}
        >
          <div
            data-testid="equipment-details-datapoint-tiles"
            className={cx(classes.productDetailsWrapper, classes.tileOverride)}
          >
            {productDetails?.datapoints?.connectionStatus?.display && (
              <div className={classes.container} data-testid="reading-datapoint-connection-status">
                <div className={classes.titleWrapper}>
                  <Typography variant="overline" className={classes.title}>
                    {content.CONNECTION_STATUS}
                  </Typography>
                </div>
                <div data-testid="connection-status">
                  <ConnectionStatus
                    signalStrength={Number(signalStrength)}
                    connectionType={connectionType}
                    gatewayModel={gateway.model}
                    isOnline={isOnline}
                  />
                </div>
              </div>
            )}
            {productDetails?.datapoints?.connectionType?.display && (
              <div className={classes.container} data-testid="reading-datapoint-connection-type">
                <div className={classes.titleWrapper}>
                  <Typography variant="overline" className={classes.title}>
                    {content.CONNECTION_TYPE}
                  </Typography>
                </div>
                <div data-testid="connection-type">
                  <ConnectionType connectionType={connectionType} />
                </div>
              </div>
            )}
            {productDetails?.datapoints?.powerSource?.display && (
              <div className={classes.container} data-testid="reading-datapoint-power-source">
                <div className={classes.titleWrapper}>
                  <Typography variant="overline" className={classes.title}>
                    {content.POWER_SOURCE}
                  </Typography>
                </div>
                <div data-testid="power-source">
                  <PowerSource powerSource={powerSource || undefined} batteryLevel={parseInt(batteryLevel)} />
                </div>
              </div>
            )}
          </div>
        </ProductDetails>
      </div>
      {displayTopAlerts && (
        <div className={cx(classes.column, classes.alerts)}>
          <DashboardAlerts gateway={gateway} />
        </div>
      )}
      {displayEquipmentAndSensorsList && <EquipmentsAndSensorsList gatewayId={gateway.id ?? ''} />}

      <div className={classes.grid} data-testid="gateway-details">
        <GatewayDetailsSection gateway={gateway} datapoints={datapoints} />
      </div>
    </div>
  );
};

import { GATEWAY_TAB_VALUES } from '@marlin/shared/utils-routes';

import { GatewaySettings } from '../../../desktop/components/settings/gateway-settings.component';
import { IGatewayDetailsConfig } from '../model';
import { NexaGatewayConfig as NexaGatewayDetailsConfigBase } from './nexa-gateway.config.shared';

export const NexaGatewayConfig: IGatewayDetailsConfig = {
  ...NexaGatewayDetailsConfigBase,
  tabs: {
    [GATEWAY_TAB_VALUES.SETTINGS]: <GatewaySettings />,
  },
};

import { getHttpClient, queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { paths } from '../infrastructure/api';
import { QUERY_KEY } from '../query-key.enum';
import { TUserDataPayload } from '../schema/user-data.schema';

export const saveUserData = async ({ area, data }: TUserDataPayload) => {
  await getHttpClient().put(
    paths.USER_DATA,
    { data },
    {
      params: {
        area,
      },
    }
  );
};

export const useSaveUserData = () => {
  return useMutation({
    mutationFn: saveUserData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.INFINITE_USER] });
    },
  });
};

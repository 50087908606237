import { TUserSchema } from '@marlin/account-data-access-user';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';

import { IDeleteUser, IResendInvite } from '../types';
import { isInvitationActive, isInvitationActiveOrPending } from '../utils/invitations';
import { ActionResend } from './action-resend.component';

export interface IUserContextMenuActionsProps {
  onResend: (user: IResendInvite) => void;
  onEdit: (userId: string) => void;
  onDelete: (user: IDeleteUser) => void;
  onDeleteInvitation: (invitationId: string) => void;
}

interface IUserContextMenuProps extends IUserContextMenuActionsProps {
  user: TUserSchema;
}

export const UserContextMenu = ({ user, onDelete, onDeleteInvitation, onEdit, onResend }: IUserContextMenuProps) => {
  const deleteAction = isInvitationActiveOrPending(user.invitationStatus)
    ? () => onDeleteInvitation(user.id)
    : () => onDelete(user);

  return (
    <ContextMenu>
      {!isInvitationActive(user.invitationStatus) && <ActionResend onClick={() => onResend(user)} />}
      <ActionEdit disabled={isInvitationActiveOrPending(user.invitationStatus)} onClick={() => onEdit(user.id)} />
      {<ActionDelete onClick={deleteAction} />}
    </ContextMenu>
  );
};

import { IconEquipment, TIconEquipment } from '@marlin/asset/shared/ui/assets';
import { environment } from '@marlin/environment';
import { TAvailableWarrantyModel } from '@marlin/shared/utils/zod';

import { content } from '../../../content';
import { EQUIPMENT_ENUM } from '../../onboarding.schema';

export interface ITile {
  id: EQUIPMENT_ENUM;
  name: string;
  icon: TIconEquipment;
}

export const getEquipmentTilesConfig = (warrantyModel: TAvailableWarrantyModel | null): ITile[] => {
  if (warrantyModel === 'intellistation2') {
    return [intellistationTile];
  }

  if (warrantyModel === 'innovationedge') {
    return [aercoInnovationTile];
  }

  const config = [...equipmentTilesWithoutFeatureFlagsConfig];

  if (environment.module.features.tekmarSbs) {
    config.push(tekmarSbcTile);
  }

  if (environment.module.features.tekmarSsc) {
    config.push(tekmarSscTile);
  }

  if (environment.module.features.tekmarShp) {
    config.push(tekmarShpTile);
  }

  return config;
};

const intellistationTile = {
  id: EQUIPMENT_ENUM.INTELLISTATION,
  name: content.EQUIPMENT_INTELLISTATION,
  icon: IconEquipment['DigitalMixingValve'],
};

const aercoInnovationTile = {
  id: EQUIPMENT_ENUM.AERCO_INNOVATION,
  name: content.EQUIPMENT_AERCO_INNOVATION,
  icon: IconEquipment['HotWaterHeater'],
};

const tekmarSscTile = {
  id: EQUIPMENT_ENUM.TEKMAR_SSC,
  name: content.TEKMAR_SSC,
  icon: IconEquipment['SteamBoiler'],
};

const tekmarSbcTile = {
  id: EQUIPMENT_ENUM.TEKMAR_SBC,
  name: content.TEKMAR_SBC,
  icon: IconEquipment['Boiler'],
};

const tekmarShpTile = {
  id: EQUIPMENT_ENUM.TEKMAR_SHP,
  name: content.TEKMAR_SHP,
  icon: IconEquipment['HeatPump'],
};

const equipmentTilesWithoutFeatureFlagsConfig: ITile[] = [intellistationTile, aercoInnovationTile];

export const equipmentTilesConfig: ITile[] = [
  intellistationTile,
  aercoInnovationTile,
  tekmarSscTile,
  tekmarSbcTile,
  tekmarShpTile,
  // HIDE BENCHMARK TILE
  // {
  //   id: EQUIPMENT_ENUM.AERCO_BENCHMARK,
  //   name: content.EQUIPMENT_AERCO_BENCHMARK,
  //   icon: <AercoBenchmarkIcon />,
  // },
];

import { IconSensor } from '@marlin/asset/shared/ui/assets';
import { TAssetType, TDeviceType, TNodeType } from '@marlin/system-map/shared/data-access-schemas';
import CommitRoundedIcon from '@mui/icons-material/CommitRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';

import { content } from './content';
import { useStyles } from './flow-link-list.component.styles';

interface ITypeCell {
  nodeType: TNodeType;
  assetType: TAssetType;
  isAttachment: boolean;
  deviceType?: TDeviceType;
}

const DeviceTypeLabel = ({ deviceType }: { deviceType?: TDeviceType }) => {
  switch (deviceType) {
    case 'TEMPERATURE': {
      return (
        <>
          <IconSensor.Temperature />
          {content.TEMPERATURE_LABEL}
        </>
      );
    }
    case 'PRESSURE': {
      return (
        <>
          <IconSensor.Pressure />
          {content.PRESSURE_LABEL}
        </>
      );
    }
    case 'LEAK': {
      return (
        <>
          <IconSensor.LeakDetector />
          {content.LEAK_LABEL}
        </>
      );
    }
    case 'PULSE_METER': {
      return (
        <>
          <IconSensor.FlowMeter />
          {content.FLOW_LABEL}
        </>
      );
    }
    default: {
      return (
        <>
          <SensorsRoundedIcon />
          {content.SENSOR}
        </>
      );
    }
  }
};

export const CellType = ({ assetType, deviceType, isAttachment, nodeType }: ITypeCell) => {
  const { classes } = useStyles();

  if (nodeType === 'TEE') {
    return (
      <div className={classes.cellTypeWrapper}>
        <CommitRoundedIcon />
        {content.TEE}
      </div>
    );
  }

  switch (assetType) {
    case 'LOCATION': {
      return (
        <div className={classes.cellTypeWrapper}>
          <LocationOnRoundedIcon />
          {content.LOCATION}
        </div>
      );
    }
    case 'EQUIPMENT': {
      return (
        <div className={classes.cellTypeWrapper}>
          <DeviceHubRoundedIcon />
          {content.EQUIPMENT}
        </div>
      );
    }
    case 'DEVICE': {
      return (
        <div className={classes.cellTypeWrapper}>
          {isAttachment && <span className={classes.attachmentLabel}>{content.ATTACHMENT_LABEL}</span>}
          <DeviceTypeLabel deviceType={deviceType} />
        </div>
      );
    }

    default: {
      return <div>{assetType}</div>;
    }
  }
};

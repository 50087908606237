import { MarlinTheme } from '@marlin/shared/theme';
import { Box, Typography, alpha } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ImageModal } from './image-modal.component';
import { IProductDetails } from './product-details.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(12)}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)}`,
    borderTop: 'none',
    gap: theme.typography.pxToRem(16),
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    gap: theme.typography.pxToRem(32),
    width: '100%',
    height: '100%',
    padding: theme.typography.pxToRem(24),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.typography.pxToRem(4),
    borderBottomRightRadius: theme.typography.pxToRem(4),
    borderTop: 'none',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      columnGap: theme.typography.pxToRem(16),
    },
    '& > *': {
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
  },
  detailItem: {
    flex: 1,
    width: '50%',
    maxWidth: '50%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: theme.typography.pxToRem(16),
  },
  manufacturerLogo: {
    maxWidth: theme.typography.pxToRem(112),
    maxHeight: theme.typography.pxToRem(100),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  equipmentRow: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    gap: theme.typography.pxToRem(4),
    textTransform: 'uppercase',
  },
  modelVariant: {
    display: 'block',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(32),
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  valueText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: theme.typography.pxToRem(112),
    maxHeight: theme.typography.pxToRem(100),
    cursor: 'pointer',
  },
  image: { maxWidth: '100%', maxHeight: '100%' },
  logos: {
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(16)}`,
  },
}));

export function ProductDetailsUi({
  imageSrc,
  logoSrc,
  model,
  equipmentModel,
  serialNumber,
  role,
  children,
  statusPanel,
}: IProductDetails) {
  const { classes, cx } = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOnClose = () => setOpen(false);

  const getImage = () => {
    if (typeof imageSrc === 'function') {
      return imageSrc(model ?? '');
    }
    return imageSrc;
  };

  return (
    <>
      <div className={classes.wrapper}>
        {statusPanel}
        <div className={classes.container} data-testid="equipment-product-details">
          {equipmentModel && (
            <Box className={classes.equipmentRow}>
              <Typography variant="body2" data-testid="equipment-model-label">
                {content.MODEL}
              </Typography>
              <Typography variant="body2" data-testid="equipment-model" className={classes.valueText}>
                {equipmentModel}
              </Typography>
            </Box>
          )}
          {serialNumber && (
            <Box className={classes.equipmentRow}>
              <Typography variant="body1" data-testid="equipment-serial-number-label">
                {content.SERIAL_NUMBER_TITLE}
              </Typography>
              <Typography variant="body1" data-testid="equipment-serial-number" className={classes.valueText}>
                {serialNumber}
              </Typography>
            </Box>
          )}
          {!!role && (
            <Box className={classes.equipmentRow}>
              <Typography variant="body1" data-testid="equipment-role-label">
                {content.ROLE_TITLE}
              </Typography>
              <Typography variant="body1" data-testid="equipment-role" className={classes.valueText}>
                {role}
              </Typography>
            </Box>
          )}
          <Box className={classes.logos}>
            <Box className={classes.manufacturerLogo} data-testid="equipment-manufacturer-logo">
              {logoSrc && <img src={logoSrc} alt={`Logo ${model}`} className={classes.manufacturerLogo} />}
            </Box>
            <Box
              className={cx(classes.detailItem, classes.imageWrapper)}
              data-testid="equipment-product-image"
              onClick={handleOpen}
            >
              {imageSrc && <img className={classes.image} src={getImage()} alt={`Product ${model}`} />}
            </Box>
          </Box>
          <Box>{children}</Box>
        </div>
      </div>
      {open && <ImageModal handleOnClose={handleOnClose} imgSrc={getImage()} alt={`Product ${model}`} />}
    </>
  );
}

import { IconSensor } from '@marlin/asset/shared/ui/assets';

import { content } from './content';

export type TDeviceType = 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER' | 'UNKNOWN';
export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

export const deviceTypeOptions = [
  {
    id: DEVICE_TYPE.TEMPERATURE,
    name: content.TEMPERATURE_ITEM,
    Icon: IconSensor.Temperature,
  },
  {
    id: DEVICE_TYPE.PRESSURE,
    name: content.PRESSURE_ITEM,
    Icon: IconSensor.Pressure,
  },
  {
    id: DEVICE_TYPE.PULSE_METER,
    name: content.FLOW_METER,
    Icon: IconSensor.FlowMeter,
  },
  {
    id: DEVICE_TYPE.LEAK,
    name: content.LEAK_ITEM,
    Icon: IconSensor.LeakDetector,
  },
];

export enum LAST_READING_VALUE_NAME {
  BATTERY_LEVEL = 'BatteryLevel',
}

import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { useCallback, useMemo } from 'react';

import { useDatapointsContext } from '../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../context/equipment-details-config-context';
import { getDatapointWithDependencies } from '../utils/get-datapoint-with-dependencies.utils';

const getConfigDatapoints = (config: IConfig, datapointName: string) => {
  const productDetailsDatapoints = config.dashboard.productDetails?.datapoints;
  const sectionDatapoints = config.dashboard.sections.flatMap((section) =>
    section.sectionDatapoints?.flatMap((item) => item.items)
  );

  return [...(productDetailsDatapoints ?? []), ...sectionDatapoints].filter(Boolean);
};

export const useDynamicTitle = (title: string) => {
  const { getDatapoint } = useDatapointsContext();
  const { config } = useEquipmentDetailsConfigContext();

  const checkDatapointInDependencies = useCallback(
    (datapointName: string) => {
      const configDatapoints = getConfigDatapoints(config, datapointName);
      const configDatapoint = configDatapoints.find((item) => item?.name === datapointName);
      if (configDatapoint && configDatapoint.dependencies) {
        const datapoint = getDatapointWithDependencies({
          dependencies: configDatapoint.dependencies,
          datapoint: undefined,
          getDatapoint,
          configDatapoint: configDatapoint,
          functionsMapping: config.dashboard.functionsMapping,
        });
        if (!Array.isArray(datapoint)) {
          return datapoint?.displayedValue;
        }
      }
      return undefined;
    },
    [config, getDatapoint]
  );

  const matchedValue = useCallback(
    (datapointName: string) => {
      const datapoint = getDatapoint(datapointName);
      if (datapoint) {
        return datapoint.displayedValue;
      }
      return checkDatapointInDependencies(datapointName);
    },
    [checkDatapointInDependencies, getDatapoint]
  );

  const dynamicTitle = useMemo(() => {
    if (config.dashboard.dynamic) {
      const isDynamic = title.match(/\{(?<datapoint>[^{}]*)\}/);
      if (isDynamic) {
        const datapointName = isDynamic.groups?.['datapoint'];
        if (datapointName) {
          const matchedValueResult = matchedValue(datapointName);
          if (matchedValueResult) {
            return title.replace(`{${datapointName}}`, matchedValueResult);
          }
        }
      }
    }
    return title;
  }, [config.dashboard.dynamic, matchedValue, title]);

  return { dynamicTitle };
};

import { renderEllipsisTooltip } from '@marlin/shared/ui-common-tooltip';
import { TUnitOfMeasure, datapointToUom, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { ReactNode, useCallback } from 'react';

import { useMetadataContext } from '../metadata.context';

export const useRenderDatapoint = () => {
  const { getDatapointFromMetadata } = useMetadataContext();

  const renderDatapoint = useCallback(
    (field: string, row: Record<string, string | null>, uomFromProps?: TUnitOfMeasure | null): ReactNode => {
      const value = row[field];

      const datapoint = getDatapointFromMetadata(field);
      const uom = datapointToUom(field, uomFromProps ?? datapoint?.unitOfMeasure);
      return renderEllipsisTooltip(parseDisplayedValue(value || '', uom));
    },
    [getDatapointFromMetadata]
  );

  return { renderDatapoint };
};

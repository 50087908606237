import { useDeleteUser as useDeleteUserMutation } from '@marlin/account-data-access-user';
import { invalidateUserList } from '@marlin/account-data-access-user';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback } from 'react';

import { content } from '../content';

interface IUseDeleteUser {
  deleteUser: (userId: string) => Promise<void>;
}

export const useDeleteUser = (): IUseDeleteUser => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteUserMutation = useDeleteUserMutation();

  const deleteUser = useCallback(
    (userId: string): Promise<void> => {
      return deleteUserMutation
        .mutateAsync(userId)
        .then(() => {
          enqueueSnackbar(content.DELETE_USER_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
          invalidateUserList();
        })
        .catch(() => {
          enqueueSnackbar(content.DELETE_USER_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [deleteUserMutation, enqueueSnackbar]
  );

  return {
    deleteUser,
  };
};

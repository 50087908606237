import { INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { getUomSymbol } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';

import { useCommonStyles } from '../common-condition.styles';
import { ConditionHeader } from '../condition-header.component';
import { useStyles } from './generic-threshold-condition.styles';
import { useConditionLabels } from './use-condition-labels.hook';
import { useThresholdField } from './use-threshold-field.hook';

const conditionValueMask = /^\d{1,15}$/;

interface IGenericNumberConditionProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
  conditionIndex: number;
  operator: 'lt' | 'gt';
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

export const GenericThresholdCondition = ({
  datapoint,
  triggerIndex,
  conditionIndex,
  operator,
  enabled,
  setEnabled,
}: IGenericNumberConditionProps) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const field = useThresholdField({
    datapoint,
    triggerIndex,
    operator,
  });

  const { label, inputLabel } = useConditionLabels({ datapointLabel: datapoint.label, operator });

  const endAdornment = useMemo(
    () => <span className={classes.endAdornment}>{getUomSymbol(datapoint.unitOfMeasure) || ''}</span>,
    [classes.endAdornment, datapoint.unitOfMeasure]
  );

  const handleEnable = useCallback(() => {
    setEnabled(true);
  }, [setEnabled]);

  const handleDisable = useCallback(() => {
    setEnabled(false);
    field.handleClear();
  }, [field, setEnabled]);

  useEffect(() => {
    if (field.value !== '') {
      setEnabled(true);
    }
  }, [field.value, setEnabled]);

  if (!enabled) {
    return (
      <div className={commonClasses.conditionContainer} data-testid={`disabled-number-condition-${conditionIndex}`}>
        <FormControlLabel
          control={
            <Checkbox checked={enabled} onChange={handleEnable} data-testid={`enable-condition-${conditionIndex}`} />
          }
          label={label}
        />
      </div>
    );
  }

  return (
    <div className={commonClasses.conditionContainer} data-testid={`enabled-number-condition-${conditionIndex}`}>
      <ConditionHeader />
      <div className={classes.conditionRow}>
        <FormControlLabel
          className={classes.conditionElement}
          control={
            <Checkbox checked={enabled} onChange={handleDisable} data-testid={`disable-condition-${conditionIndex}`} />
          }
          label={label}
        />
        <Input
          className={classes.conditionElement}
          onBlur={field.handleBlur}
          label={inputLabel}
          type={INPUT_TYPE.NUMBER}
          onChange={field.handleChange}
          value={field.value}
          mask={conditionValueMask}
          externalEndAdornment={{
            endAdornment,
          }}
          testId={`condition-input-${datapoint.name}-${operator}`}
        />
      </div>
    </div>
  );
};

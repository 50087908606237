import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useListStyles = makeStyles()((theme: MarlinTheme) => ({
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  grid: {
    gridColumn: '1 / -1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  dataGridWrapper: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    width: '100%',
  },
  header: {
    '& > div': {
      padding: theme.typography.pxToRem(16),
      [theme.breakpoints.down('md')]: {
        padding: 0,
        fontSize: theme.typography.pxToRem(16),
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  mobilePaper: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  hubsAndSensorsPaper: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.secondary,
    },
  },
  mobileMenu: {
    padding: 0,
  },
  tableTitle: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      fontSize: theme.typography.pxToRem(16),
    },
  },
  hubsAndSensorsHeader: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
}));

export const useGridStyles = makeStyles()(() => ({
  dataGrid: {
    borderRadius: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  dataGridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 600,
    maxHeight: 600,
    width: '100%',
  },
}));

export const useMobileListStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100vw',
    gap: theme.typography.pxToRem(10),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },
}));

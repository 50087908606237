import { datapointToUom } from '@marlin/shared/utils-format-reading';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';

import { useDatapointsContext } from '../../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../../context/equipment-details-config-context';
import { getDatapointWithDependencies } from '../../utils/get-datapoint-with-dependencies.utils';
import type { TEquipmentProductDatapoint } from '../../utils/types';

export const useDatapointTiles = (productDatapoints?: Array<TEquipmentProductDatapoint>) => {
  const { datapoints, isLoading, getDatapoint } = useDatapointsContext();
  const { config } = useEquipmentDetailsConfigContext();
  const datapointTilesData: TDatapointWithUiMetadata[] = useMemo(() => {
    return (productDatapoints || [])
      .map((productDatapoint) => {
        const datapointValues: TDatapointWithUiMetadata | undefined = datapoints.find(
          (datapoint) => productDatapoint.name.toLowerCase() === datapoint.name.toLowerCase()
        );

        if (datapointValues) {
          return {
            ...datapointValues,
            ...productDatapoint,
            unitOfMeasure:
              productDatapoint.unitOfMeasure || datapointValues.unitOfMeasure || datapointToUom(datapointValues.name),
          };
        }
        if (productDatapoint.dependencies) {
          const datapoint = getDatapointWithDependencies({
            datapoint: datapointValues,
            dependencies: productDatapoint.dependencies,
            getDatapoint,
            configDatapoint: productDatapoint,
            functionsMapping: config.dashboard.functionsMapping,
          });
          if (!Array.isArray(datapoint) && datapoint?.value) {
            return {
              ...productDatapoint,
              ...datapoint,
            };
          }
        }

        return null;
      })
      .filter(Boolean) as TDatapointWithUiMetadata[];
  }, [datapoints, getDatapoint, productDatapoints, config.dashboard.functionsMapping]);

  return { datapointTilesData, isLoading };
};

import { TUserSchema, useUsers } from '@marlin/account-data-access-user';
import { IDataHookBaseParams, IHookBaseList, ISorting } from '@marlin/marlin-table';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { useNavigate } from '../use-navigate/use-navigate.hook';

type TUserList = IHookBaseList<TUserSchema>;

export const useUsersList = ({ debouncedTerm, page, pageSize }: IDataHookBaseParams): TUserList => {
  const { goToInvitationPage } = useNavigate();
  const [sortingParams, setSortingParams] = useState<ISorting | undefined>(undefined);
  const { data, isLoading, isError } = useUsers({
    search: debouncedTerm,
    page: page,
    pageSize: pageSize,
    sorting: sortingParams,
  });

  const rows = useMemo(
    () =>
      data?.data?.map((user) => ({
        ...user,
        FirstName: [user.firstName, user.lastName].join(' '),
        Email: user.email,
      })) || [],
    [data?.data]
  );

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel[0] || !sortModel[0].sort) {
      setSortingParams(undefined);
    } else {
      const sorting = {
        sortBy: sortModel[0]?.field,
        direction: mapSortDirection(sortModel[0]?.sort),
      };
      setSortingParams(sorting);
    }
  }, []);

  const userList: TUserList = useMemo(
    () => ({
      data: rows,
      pagination: data?.pagination || { page: page, pageSize: pageSize, totalItems: 0 },
      isLoading,
      isError,
      goToInvitationPage,
      onSortChange,
    }),
    [rows, data?.pagination, page, pageSize, isLoading, isError, goToInvitationPage, onSortChange]
  );

  return userList;
};

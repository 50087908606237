export const modelContent = {
  MODELS: new Map<string, string>([
    ['Partition', 'Partition'],
    ['Brigade', 'Brigade'],
    ['Conquest100_20L100A_GCL', 'Conquest 100 - 20 L 100A-GCL'],
    ['Conquest100_25L100A_GCL', 'Conquest 100 - 25 L 100A-GCL'],
    ['Conquest100_30L100A_GCL', 'Conquest 100 - 30 L 100A-GCL'],
    ['Conquest130_40L130A_GCML', 'Conquest 130 - 40 L 130A-GCML'],
    ['Conquest130_50L130A_GCML', 'Conquest 130 - 50 L 130A-GCML'],
    ['Conquest130_60L130A_GCML', 'Conquest 130 - 60 L 130A-GCML'],
    ['Conquest130_70L130A_GCML', 'Conquest 130 - 70 L 130A-GCML'],
    ['Conquest130_80L130A_GCML', 'Conquest 130 - 80 L 130A-GCML'],
    ['Conquest130_90L130A_GCML', 'Conquest 130 - 90 L 130A-GCML'],
    ['Conquest130_100L130A_GCML', 'Conquest 130 - 100 L 130A-GCML'],
    ['Conquest1100_CQT1100', 'Conquest 1100 - CQT1100'],
    ['Conquest1200_CQT1200', 'Conquest 1200 - CQT1200'],
    ['Conquest130_40L130A_GCMLW', 'Conquest 130 - 40 L 130A-GCMLW'],
    ['Conquest130_50L130A_GCMLW', 'Conquest 130 - 50 L 130A-GCMLW'],
    ['Conquest130_60L130A_GCMLW', 'Conquest 130 - 60 L 130A-GCMLW'],
    ['Conquest130_70L130A_GCMLW', 'Conquest 130 - 70 L 130A-GCMLW'],
    ['Conquest130_80L130A_GCMLW', 'Conquest 130 - 80 L 130A-GCMLW'],
    ['Conquest1100_CQTO1100', 'Conquest 1100 - CQTO1100'],
    ['Conquest1200_CQTO1200', 'Conquest 1200 - CQTO1200'],
    ['ValveControllerDT503', 'DT503'],
    ['ValveControllerDT553', 'DT553'],
    ['Intellistation', 'IntelliStation'],
    ['294', '294'],
    ['289', '289'],
    ['291', '291'],
    ['LDS', 'Leak Defense System'],
  ]),
};

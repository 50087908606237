import { IconEquipment } from '@marlin/asset/shared/ui/assets';
import { CardInfiniteList } from '@marlin/marlin-table';
import { MarlinTheme } from '@marlin/shared/theme';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { CardListAccordion } from '../../components/card-list-accordion.component';
import { SystemEquipmentCard } from '../../components/system-equipment-card.component';
import { useClearAlertsCommand } from '../../hooks/use-clear-alerts-command.hook';
import { useInfiniteSystemEquipmentList } from '../../hooks/use-infinite-system-equipment-data.hook';
import { usePoldFilter } from '../../hooks/use-pold-filter.hook';
import { TSentinelSystemEquipment } from '../../types';
import { sentinelAlertDatapointsFilter } from '../const';
import { content } from '../content';
import { PoldFilters } from './pold-filters.component';
import { usePoldCard } from './use-create-pold-cards';

const modelName: TSentinelSystemEquipment = 'POLD';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  inAlertFilter: {
    display: 'flex',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)} 0`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(8),
  },
}));

interface ILdsValveCardListProps {
  deviceId: string;
}

export const PoldCardList = ({ deviceId }: ILdsValveCardListProps) => {
  const { classes } = useStyles();
  const cardConfig = usePoldCard();
  const { showOnlyAlertsPolds, setShowOnlyAlertsPolds, inAlertPoldsCount } = usePoldFilter(deviceId || '');
  const { clearAllAlerts, isSendingDeviceCommand } = useClearAlertsCommand({
    onSuccess: () => {
      setShowOnlyAlertsPolds(false);
    },
  });
  const query = useInfiniteSystemEquipmentList({
    modelName,
    deviceId,
    pageSize: 5,
    ...(showOnlyAlertsPolds ? { datapoints: sentinelAlertDatapointsFilter } : {}),
  });

  const queryParams = useQueryParameters();
  const defaultExpanded = queryParams.get('model') === modelName;

  const title = useMemo(() => {
    return content.TABS.TAB_WITH_COUNT(content.TABS.POLD, query?.systemEquipmentTotal?.[modelName]);
  }, [query.systemEquipmentTotal]);

  return (
    <CardListAccordion title={title} defaultExpanded={defaultExpanded}>
      <div className={classes.inAlertFilter}>
        <PoldFilters
          setShowOnlyInAlerts={setShowOnlyAlertsPolds}
          showOnlyInAlerts={showOnlyAlertsPolds}
          inAlertPoldsCount={inAlertPoldsCount}
          clearAllAlerts={clearAllAlerts}
          isSendingDeviceCommand={isSendingDeviceCommand}
        />
      </div>
      <CardInfiniteList
        isFetchingNextPage={query.isFetchingNextPage}
        isError={query.isError}
        fetchNextPage={query.fetchNextPage}
        hasNextPage={query.hasNextPage}
        totalItems={query.totalItems}
        loadedItems={query.rows.length}
      >
        {query.rows.map((row) => (
          <SystemEquipmentCard row={row} cardConfig={cardConfig} icon={<IconEquipment.Other />} />
        ))}
      </CardInfiniteList>
    </CardListAccordion>
  );
};

import { Tab, Tabs } from '@mui/material';

import { content } from '../../shared/content/content';
import { useStyles } from './people-tab-switcher.styles';

export type TPeopleTab = 'users' | 'recipients';

export interface IPeopleTabSwitcherProps {
  activeTab: TPeopleTab;
  onTabChange: (tab: TPeopleTab) => void;
}

export const PeopleTabSwitcher = ({ activeTab, onTabChange }: IPeopleTabSwitcherProps) => {
  const { classes } = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: TPeopleTab) => {
    onTabChange(newValue);
  };

  return (
    <Tabs value={activeTab} onChange={handleChange} className={classes.tabs} variant="fullWidth">
      <Tab label={content.USER_TABLE_TITLE} value={'users'} data-testid={'settings-user-tab'} className={classes.tab} />
      <Tab
        label={content.RECIPIENT_TABLE_TITLE}
        value={'recipients'}
        data-testid={'settings-recipients-tab'}
        className={classes.tab}
      />
    </Tabs>
  );
};

import { realtimeCommunicationFacade } from '@marlin/shared/utils-realtime-communication';
import {
  DeviceMetadataResponse,
  ModelsMetadataResponse,
  SettingsGroupResponse,
  TDatapointsAPIResponse,
  TDatapointsResponse,
  TDeviceMetadataResponse,
  TDeviceMetadataResponseRaw,
  TModelsMetadataResponse,
} from '@marlin/shared/utils/datapoint-mappers';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import z from 'zod';

import { AvailableBoliersResponse, TAvailableBoliersResponse } from '../schema/available-boliers.schema';
import { CalculatedValuesList, TCalculatedValues, TCalculatedValuesList } from '../schema/calculated-values.schema';
import { DynamicMetadataDashboardConfig, TDynamicMetadataDashboardConfig } from '../schema/dynamic-metadata.schema';
import { EquipmentAlerts, TEquipmentAlerts } from '../schema/equipment-alerts.schema';
import { TEquipmentParamsSchema, getEquipmentParamsSchema } from '../schema/equipment.request.schema';
import { TEquipmentResponseSchema, getEquipmentResponseSchema } from '../schema/equipment.response.schema';
import { EquipmentSchedule, TEquipmentSchedule } from '../schema/equipment.schedule';
import { paths } from './api';

export const getFilteredEquipments = async (params: TEquipmentParamsSchema): Promise<TEquipmentResponseSchema> => {
  const body = safeParseData(params, getEquipmentParamsSchema);
  const res = await getHttpClient().post<unknown, TEquipmentResponseSchema>(paths.EQUIPMENTS_FILTER, body);

  return getEquipmentResponseSchema.parse(res);
};

export const getMetadata = async (equipmentId: string): Promise<TDeviceMetadataResponse> => {
  const data = await getHttpClient().get<TDeviceMetadataResponseRaw[]>(paths.EQUIPMENT_METADATA, {
    params: { equipmentId },
  });

  return DeviceMetadataResponse.parse(data);
};

export const getDynamicMetadata = async (
  equipmentId: string,
  metadataV2?: boolean
): Promise<TDynamicMetadataDashboardConfig> => {
  const optionalQueryParams = metadataV2 ? { metadataVersion: 'v2' } : {};
  const data = await getHttpClient().get<TDynamicMetadataDashboardConfig>(paths.DYNAMIC_EQUIPMENT_METADATA, {
    params: { equipmentId, ...optionalQueryParams },
  });

  return DynamicMetadataDashboardConfig.parse(data);
};

export const getMetadataByModels = async (models: string[]): Promise<TModelsMetadataResponse> => {
  const data = await getHttpClient().get<TDeviceMetadataResponseRaw[]>(paths.EQUIPMENT_METADATA_BY_MODELS, {
    params: { models },
    paramsSerializer: {
      indexes: null,
    },
  });

  return ModelsMetadataResponse.parse(data);
};

const subscribeToDeviceLiveDataTelemetry = 'SubscribeToDeviceLiveDataTelemetry';
const unsubscribeToDeviceLiveDataTelemetry = 'UnsubscribeFromDeviceLiveDataTelemetry';
const sendEquipmentDeregistered = 'SendEquipmentDeregistered';

export const startDatapointConnection = (manufacturerId: string): Observable<unknown> => {
  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) => {
      return connection.invoke(subscribeToDeviceLiveDataTelemetry, manufacturerId, null);
    })
  );
};

export const stopDatapointConnection = (manufacturerId: string): Observable<unknown> => {
  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) => {
      return connection.invoke(unsubscribeToDeviceLiveDataTelemetry, manufacturerId, null);
    })
  );
};

export const startSendData = async (equipmentId: string): Promise<void> => {
  return await getHttpClient().post<unknown, void>(paths.EQUIPMENTS_START_SEND_DATA, {}, { params: { equipmentId } });
};

export const stopSendData = async (equipmentId: string): Promise<void> => {
  return await getHttpClient().post<unknown, void>(paths.EQUIPMENTS_STOP_SEND_DATA, {}, { params: { equipmentId } });
};

export const getDatapoints = (callback?: (data: TDatapointsResponse) => void): Observable<TDatapointsResponse> => {
  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) => {
      return (connection.listenOn('SendDeviceTelemetry') as Observable<TDatapointsAPIResponse>).pipe(
        map((data) => {
          // TODO: Try use it inside zod transform
          const parsedData = {
            manufacturerId: data.manufacturerId,
            lastReadingTime: data.lastReadingTime.slice(-1) === 'Z' ? data.lastReadingTime : `${data.lastReadingTime}Z`,
            datapoints: data.datapoints,
          };

          if (callback) {
            callback(parsedData);
          }

          return parsedData;
        }),
        catchError(() => {
          return throwError(new TimeoutError());
        })
      );
    })
  );
};

export const getEquipmentDeregistered = () => {
  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) => {
      return connection.listenOn(sendEquipmentDeregistered).pipe(
        catchError((error) => {
          return throwError(new TimeoutError());
        })
      );
    })
  );
};

export const getEquipmentSettingsGroup = async ({
  equipmentId,
  settingGroupId,
}: {
  equipmentId: string;
  settingGroupId: string | null;
}) => {
  const response = await getHttpClient().get(paths.EQUIPMENT_SETTINGS_GROUP, {
    params: { equipmentId, settingGroupId },
  });

  return SettingsGroupResponse.parse(response);
};

export const getSchedule = async (equipmentId: string): Promise<TEquipmentSchedule> => {
  const data = await getHttpClient().get(paths.EQUIPMENT_SCHEDULE, { params: { equipmentId } });

  return EquipmentSchedule.parse(data);
};

export const getCalculatedValues = async ({
  equipmentId,
  from,
  calculatedValues,
  to,
}: {
  equipmentId: string;
  calculatedValues: TCalculatedValues[];
  from: string;
  to: string;
}): Promise<TCalculatedValuesList> => {
  const data = await getHttpClient().post(paths.EQUIPMENT_CALCULATION_VALUES, calculatedValues, {
    params: { equipmentId, from, to },
  });

  return CalculatedValuesList.parse(data);
};

export const getLiveAlerts = (): Observable<TEquipmentAlerts> => {
  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) => {
      return (connection.listenOn('SendDeviceDashboardUpdate') as Observable<TEquipmentAlerts>).pipe(
        map((data) => {
          return EquipmentAlerts.parse(data);
        }),
        catchError((error) => {
          // eslint-disable-next-line no-console
          if (error instanceof z.ZodError) {
            // eslint-disable-next-line no-console
            console.log(error.issues);
            return throwError(error);
          }

          return throwError(new TimeoutError());
        })
      );
    })
  );
};

export const getAvailableBoilers = async (equipmentId: string): Promise<TAvailableBoliersResponse> => {
  const data = await getHttpClient().get<TAvailableBoliersResponse[]>(paths.EQUIPMENT_AVAILABLE_BOILERS, {
    params: { equipmentId },
  });

  return AvailableBoliersResponse.parse(data);
};

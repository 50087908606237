export const content = {
  INSTRUCTIONS_MODAL: {
    STEP: (index: number) => `Step ${index}: `,
    INTELLISTATION: {
      TITLE: 'How do I find my registration code?',
      STEP_1: 'Go to Settings in the IntelliStation 2 Menu.',
      STEP_1_KEYWORD: 'Settings',
      STEP_2: 'Select Internet in the IntelliStation 2 Menu.',
      STEP_2_KEYWORD: 'Internet',
      STEP_3: 'Click on Register.',
      STEP_3_KEYWORD: 'Register',
      STEP_4: 'Enter the code when prompted in Nexa (either in the Wizard or from Create New Equipment).',
      STEP_4_KEYWORD: 'the code',
    },
    TEKMAR_SSC: {
      TITLE: 'How do I find my registration code?',
      STEP_1: 'Go to Settings in the Smart Steam Control Menu.',
      STEP_1_KEYWORD: 'Settings',
      STEP_2: 'Select Internet in the Smart Steam Control Menu.',
      STEP_2_KEYWORD: 'Internet',
      STEP_3: 'Select Register next to Nexa.',
      STEP_3_KEYWORD: 'Register',
      STEP_4: 'Enter Code. The code will remain for 30 minutes from the moment it was generated.',
      STEP_4_KEYWORD: 'Code',
    },
    TEKMAR_SBC: {
      TITLE: 'How do I find my registration code?',
      STEP_1: 'Go to Settings in the Smart Boiler Control Menu.',
      STEP_1_KEYWORD: 'Settings',
      STEP_2: 'Select Internet in the Smart Boiler Control Menu.',
      STEP_2_KEYWORD: 'Internet',
      STEP_3: 'Select Register next to Nexa.',
      STEP_3_KEYWORD: 'Register',
      STEP_4: 'Enter Code. The code will remain for 30 minutes from the moment it was generated.',
      STEP_4_KEYWORD: 'Code',
    },
    TEKMAR_SHP: {
      TITLE: 'How do I find my registration code?',
      STEP_1: 'Go to Settings in the Smart Heat Pump Menu.',
      STEP_1_KEYWORD: 'Settings',
      STEP_2: 'Select Internet in the Smart Heat Pump Menu.',
      STEP_2_KEYWORD: 'Internet',
      STEP_3: 'Select Register next to Nexa.',
      STEP_3_KEYWORD: 'Register',
      STEP_4: 'Enter Code. The code will remain for 30 minutes from the moment it was generated.',
      STEP_4_KEYWORD: 'Code',
    },
    AERCO: {
      TITLE_STEP_1: 'How do I find my serial number?',
      STEP_1:
        'This is the Unit Serial and can be found following the path Advanced setup > Unit > Unit Settings on Edge Controller.',
      STEP_1_KEYWORD: 'Unit Serial',
      TITLE_STEP_2: 'How do I find my Controller Fixed ID?',
      STEP_2: 'To find Controller Fixed ID follow the path: Diagnostics > Comm & Failsafe > onAER on Edge Controller.',
      STEP_2_KEYWORD: 'Fixed ID',
    },
  },
};

import { routes } from '@marlin/shared/utils-routes';

import { content } from './content';

type TOptionKey = 'people' | 'profile' | 'appearance' | 'orgSettings' | 'integration';

type TOption = {
  title: string;
  link: string;
};

export type TOptionPermisions = {
  [key in TOptionKey]: boolean;
};

export const options: { [key in TOptionKey]: TOption } = {
  people: {
    title: content.SETTINGS_TAB_PEOPLE,
    link: routes.settings.people.url(),
  },
  profile: {
    title: content.SETTINGS_TAB_MY_ACCOUNT,
    link: routes.settings.profile.url(),
  },
  appearance: {
    title: content.SETTINGS_TAB_MY_PREFERENCES,
    link: routes.settings.appearance.url(),
  },
  orgSettings: {
    title: content.SETTINGS_TAB_ORGANIZATION,
    link: routes.settings.orgSettings.url(),
  },
  integration: {
    title: content.SETTINGS_TAB_INTEGRATION,
    link: routes.settings.integration.url(),
  },
};

import { ISettingsPoint } from '@marlin/asset/shared/equipment-config';
import { useCallback, useMemo } from 'react';

import { useDatapointsContext } from '../../../../shared/context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../../../../shared/context/equipment-details-config-context';

const replaceDynamicValue = (value: string, id: string) => value.replace('{x}', id);

export const useDynamicSettingsConfig = () => {
  const { datapoints, getDatapoint } = useDatapointsContext();
  const {
    config: { settings },
  } = useEquipmentDetailsConfigContext();

  const datapointNames = useMemo(() => datapoints.map((datapoint) => datapoint.name), [datapoints]);

  const createNewSetting = useCallback(
    (setting: ISettingsPoint, id: string): ISettingsPoint => {
      const labelDatapoint = replaceDynamicValue(setting.label, id);
      const label = getDatapoint(labelDatapoint, { displayOutdatedValues: true })?.displayedValue ?? labelDatapoint;

      return {
        name: replaceDynamicValue(setting.name, id),
        label,
        commandGroup: setting.commandGroup,
        dependencies: {
          ...setting.dependencies,
          aggregation: setting.dependencies?.aggregation
            ? {
                ...setting.dependencies.aggregation,
                datapointNames: setting.dependencies.aggregation.datapointNames.map((name) =>
                  replaceDynamicValue(name, id)
                ),
              }
            : undefined,
        },
      } as ISettingsPoint;
    },
    [getDatapoint]
  );

  const mappedSettings = useMemo(
    () =>
      settings?.dynamicSettingsSections?.map((section) => {
        return {
          ...section,
          settings: section.settings.flatMap((setting) => {
            if (setting.match) {
              const re = new RegExp(setting.match, 'i');
              const matchedIds: string[] = datapointNames
                .filter((name) => re.test(name))
                .map((name) => name.match(re)?.[1] as string);
              return matchedIds.map((id: string) => createNewSetting(setting, id) as ISettingsPoint);
            }
            return setting;
          }),
        };
      }),
    [settings, datapointNames, createNewSetting]
  );

  const uniqueSettings = useMemo(() => {
    if (!mappedSettings) return [];
    const ids = new Set();
    return mappedSettings.filter(({ id }) => !ids.has(id) && ids.add(id));
  }, [mappedSettings]);

  return {
    settings: settings
      ? {
          ...settings,
          dynamicSettingsSections: uniqueSettings?.length ? uniqueSettings : (settings?.dynamicSettingsSections ?? []),
        }
      : undefined,
  };
};

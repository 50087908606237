import { zodResolver } from '@hookform/resolvers/zod';
import { CHANNEL_TYPE, CRITICALITY, TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { LineConnector } from '@marlin/alert/ui/automation-builder-components';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import '@xyflow/react/dist/base.css';
import '@xyflow/react/dist/style.css';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { automationBuilderValidationSchema } from './automation-builder.schema';
import { content } from './content';
import { ActionsSection } from './sections/actions/actions-section.component';
import { CreateAutomationBtn } from './sections/create-automation-btn/create-automation-btn';
import { DetailsSection } from './sections/details/details-section.component';
import { useSectionsStore$ } from './sections/hooks/use-observable-sections-store';
import { TriggersArray } from './sections/triggers/triggers-array.component';
import { useAutomationBuilder } from './use-automation-builder.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modal: {
    width: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formContainer: {
    width: '100%',
  },
  builderContainer: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(636),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(16),
    },
  },
  button: {
    alignSelf: 'center',
  },
}));

const defaultValues: TAutomationBuilder = {
  id: '',
  isEnabled: true,
  details: {
    name: '',
    criticality: CRITICALITY.LOW,
  },
  actions: {
    snoozeIntervalInMinutes: 0,
    channels: {
      [CHANNEL_TYPE.IN_APP]: {
        type: CHANNEL_TYPE.IN_APP,
      },
    },
  },
  triggers: [],
  logicOperator: 'AND',
};

export function AutomationBuilder() {
  const { cx, classes } = useStyles();
  const { clearStore } = useSectionsStore$();
  const { createAutomation } = useAutomationBuilder();
  const form = useForm<TAutomationBuilder>({
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver(automationBuilderValidationSchema),
  });

  const clearAutomationBuilder = useCallback(() => {
    // Clear RxJs store and reset form
    clearStore();
    form.reset(defaultValues);
  }, [clearStore, form]);

  useEffect(() => {
    clearAutomationBuilder();
  }, [clearAutomationBuilder]);

  const handleSubmit = useCallback(
    (values: TAutomationBuilder) => {
      createAutomation(values);
      clearAutomationBuilder();
    },
    [clearAutomationBuilder, createAutomation]
  );

  return (
    <PageContainer prefix="automation-builder" className={classes.container}>
      <PageHeader
        icon={<MiscellaneousServicesRoundedIcon />}
        title={content.TITLE}
        subtitle={content.SUBTITLE}
        prefix="automation-builder-header"
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className={cx(classes.container, classes.formContainer)}>
          <div className={classes.builderContainer}>
            <DetailsSection />
            <LineConnector />
            <TriggersArray />
            <LineConnector />
            <ActionsSection />
            <LineConnector />
            <CreateAutomationBtn />
          </div>
        </form>
      </FormProvider>
    </PageContainer>
  );
}

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    gap: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(8),
    alignItems: 'center',
  },

  detailsText: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  criticality: {
    textDecoration: 'underline',
    gap: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },

  iconColor: {
    color: theme.palette.action.active,
    fontSize: theme.typography.pxToRem(18),
  },

  textUnderline: {
    textDecoration: 'underline',
    marginLeft: theme.typography.pxToRem(4),
    marginRight: theme.typography.pxToRem(4),
    color: theme.palette.primary.main,
    fontWeight: 'normal',
    cursor: 'pointer',
  },
}));

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { CRITICALITY, TCriticality } from './models';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
  },

  criticalityButtonLabel: {
    color: theme.palette.primary.main,
  },

  dot: {
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    display: 'inline-block',
  },

  high: {
    backgroundColor: theme.palette.error.main,
  },

  low: {
    backgroundColor: theme.palette.warning.light,
  },

  underline: {
    textDecoration: 'underline',
  },
}));

interface ICriticalityControl {
  severity: TCriticality;
  isCriticalityButton?: boolean;
  className?: string;
}

export const CriticalityControl = ({ severity, isCriticalityButton = false, className }: ICriticalityControl) => {
  const { classes, cx } = useStyles();

  switch (severity) {
    case CRITICALITY.HIGH: {
      return (
        <div
          className={cx(classes.label, isCriticalityButton && classes.criticalityButtonLabel, className && className)}
          data-testid="criticality-control-high"
        >
          <div className={`${classes.dot} ${classes.high}`}></div>
          {content.CRITICALITY.HIGH}
        </div>
      );
    }
    case CRITICALITY.LOW: {
      return (
        <div
          className={cx(classes.label, isCriticalityButton && classes.criticalityButtonLabel, className && className)}
          data-testid="criticality-control-low"
        >
          <div className={`${classes.dot} ${classes.low}`}></div>
          {content.CRITICALITY.LOW}
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

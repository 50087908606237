import { EquipmentTypeMap } from '@marlin/shared/ui-device-type';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

export const useEquipmentTypeOptions = () =>
  useMemo(() => {
    const { tekmarShp } = useFeatureFlagsContext();

    const equipmentTypes = [
      { id: EQUIPMENT_TYPE.BOILER, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.BOILER) || '' },
      { id: EQUIPMENT_TYPE.HEAT_PUMP, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.HEAT_PUMP) || '' },
      { id: EQUIPMENT_TYPE.STEAM_BOILER, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.STEAM_BOILER) || '' },
      {
        id: EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE,
        name: EquipmentTypeMap.get(EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE) || '',
      },
      {
        id: EQUIPMENT_TYPE.PRESSURE_REDUCING_VALVE,
        name: EquipmentTypeMap.get(EQUIPMENT_TYPE.PRESSURE_REDUCING_VALVE) || '',
      },
      { id: EQUIPMENT_TYPE.PUMP, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.PUMP) || '' },
      { id: EQUIPMENT_TYPE.SHUT_OFF_VALVE, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.SHUT_OFF_VALVE) || '' },
      { id: EQUIPMENT_TYPE.HOT_WATER_HEATER, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.HOT_WATER_HEATER) || '' },
      { id: EQUIPMENT_TYPE.WATER_TANK, name: EquipmentTypeMap.get(EQUIPMENT_TYPE.WATER_TANK) || '' },
      {
        id: EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM,
        name: EquipmentTypeMap.get(EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM) || '',
        shouldBeHiddenInOther: true,
      },
      {
        id: EQUIPMENT_TYPE.PARTITION_CONTROLLER,
        name: EquipmentTypeMap.get(EQUIPMENT_TYPE.PARTITION_CONTROLLER) || '',
        shouldBeHiddenInOther: true,
      },
      {
        id: EQUIPMENT_TYPE.VALVE_CONTROLLER,
        name: EquipmentTypeMap.get(EQUIPMENT_TYPE.VALVE_CONTROLLER) || '',
        shouldBeHiddenInOther: true,
      },
    ];

    return equipmentTypes.filter(({ id }) => {
      return id !== EQUIPMENT_TYPE.HEAT_PUMP || tekmarShp;
    });
  }, []);

import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';

export interface IRecipientContextMenuActionsProps {
  onDelete: (recipientId: string) => void;
  onEdit: (recipientId: string) => void;
}

interface IRecipientContextMenuProps extends IRecipientContextMenuActionsProps {
  recipientId: string;
}

export const RecipientContextMenu = ({ onEdit, onDelete, recipientId }: IRecipientContextMenuProps) => {
  return (
    <ContextMenu>
      <ActionEdit onClick={() => onEdit(recipientId)} />
      <ActionDelete onClick={() => onDelete(recipientId)} />
    </ContextMenu>
  );
};

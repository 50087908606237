import { useGetOrganizationDetails } from '@marlin/account-data-access-organization';
import { ChartChip } from '@marlin/asset/shared/ui/chart-chips';
import { FullScreenChart, IRequestedTelemetry, TotalValue, useChartingColors } from '@marlin/asset/ui/charts';
import { SectionContainer } from '@marlin/shared/ui-page';
import { Period, useGetSustainabilityFormattedTimezone } from '@marlin/shared/ui/chart';
import { Button, Typography } from '@mui/material';

import { Options } from '../shared/options/options';
import { useFullScreen } from '../shared/use-full-screen.hook';
import { TDashboardSection } from '../types';
import { useStyles } from './accumulated-volumes.styles';
import { content } from './content';
import { useAccumulatedVolumes } from './use-accumulated-volumes';
import { useAccumulatedVolumesDownload } from './use-accumulated-volumes-download.hook';

interface IAccumulatedVolumesProps {
  requestedTelemetry: IRequestedTelemetry[];
  fullScreenChart: TDashboardSection | null;
  handleFullScreenClick: (section: TDashboardSection | null) => void;
}

export const AccumulatedVolumes = ({
  requestedTelemetry,
  fullScreenChart,
  handleFullScreenClick,
}: IAccumulatedVolumesProps) => {
  const { classes } = useStyles();
  const { openFullScreen, closeFullScreen, isFullScreen } = useFullScreen(
    handleFullScreenClick,
    fullScreenChart,
    'individualAccumulatedVolumes'
  );
  const {
    layout,
    config,
    handleRelayout,
    isEmpty,
    handleCustomRangeSet,
    isFetching,
    isLoading,
    onActiveDatapointsChange,
    clearAllActiveDatapoints,
    isDatapointActive,
    selectedPeriod,
    handleChangePeriod,
    totalVolume,
    from,
    range,
    to,
    chartData,
    uoms,
  } = useAccumulatedVolumes({ requestedTelemetry, isFullScreen });

  const { handleDownloadClick } = useAccumulatedVolumesDownload(chartData);

  const { data: orgDetails } = useGetOrganizationDetails();

  const { timezone } = useGetSustainabilityFormattedTimezone(orgDetails?.timeZone);

  const colors = useChartingColors();

  return (
    <>
      <SectionContainer
        title={content.ACCUMULATED_VOLUMES}
        isFullScreen={isFullScreen}
        options={
          <Options
            onDownloadClick={handleDownloadClick}
            onFullScreenOpen={openFullScreen}
            onFullScreenClose={closeFullScreen}
            isFullScreenOpen={isFullScreen}
          />
        }
      >
        <div className={classes.content}>
          <div className={classes.topWrapper}>
            <Period
              timezone={timezone}
              onChange={handleChangePeriod}
              handleCustomRangeSet={handleCustomRangeSet}
              selectedPeriod={selectedPeriod}
              currentPeriod={range}
            />
            <div data-testid="chart-chips" className={classes.chips}>
              <Typography className={classes.text}>{content.SHOWING}:</Typography>
              {requestedTelemetry.map(({ name, manufacturerId }, index) => {
                return (
                  <ChartChip
                    key={manufacturerId}
                    label={name}
                    color={colors[index % colors.length]}
                    isActive={isDatapointActive(manufacturerId)}
                    toggleChip={() => onActiveDatapointsChange(manufacturerId)}
                    name={name}
                    dataTestId={`chart-chip-${manufacturerId}`}
                  />
                );
              })}
              {!!requestedTelemetry.length && (
                <Button variant="text" onClick={clearAllActiveDatapoints} data-testid="clear-all-button">
                  {content.CLEAR_ALL}
                </Button>
              )}
            </div>
          </div>
          {totalVolume && (
            <div>
              <TotalValue value={totalVolume} chartType={'bar'} />
            </div>
          )}
          <FullScreenChart
            chartData={chartData}
            from={from}
            to={to}
            isFetching={isFetching}
            isLoading={isLoading}
            layout={layout}
            config={config}
            handleRelayout={handleRelayout}
            isEmpty={isEmpty}
            isFullScreen={isFullScreen}
            uoms={uoms}
          />
        </div>
      </SectionContainer>
    </>
  );
};

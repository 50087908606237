import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getEditGatewayPageLink: (id: string) => string;
  getEditHubPageLink: (parentId: string, id: string) => string;
  getEditSensorPageLink: (id: string) => string;
  getDetailsHubPageLink: (parentId: string, id: string) => string;
  getDetailsSensorPageLink: (id: string) => string;
  getGatewayDetailsPageLink: (gatewayId: string) => string;
  getGatewayConfigurationPageLink: (id: string) => string;
  getGatewayListPageLink: () => string;
  getEditEquipmentPageLink: (id: string) => string;
  getEquipmentDetailsPageLink: (id: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getEditGatewayPageLink: (gatewayId: string) => routes.gateway.edit.url(gatewayId),
    getGatewayConfigurationPageLink: (gatewayId: string) => routes.gateway.configuration.url(gatewayId),
    getGatewayDetailsPageLink: (gatewayId: string) => routes.gateway.details.url(gatewayId),
    getDetailsSensorPageLink: (gatewayId: string) => routes.sensors.details.url(gatewayId),
    getEditHubPageLink: (gatewayId: string, hubId: string) => routes.gateway.hub.update.url(gatewayId, hubId),
    getEditSensorPageLink: (sensorId: string) => routes.sensors.update.url(sensorId),
    getEditEquipmentPageLink: (equipmentId: string) => routes.equipments.update.url(equipmentId),
    getDetailsHubPageLink: (gatewayId: string, hubId: string) => routes.gateway.hub.details.url(gatewayId, hubId),
    getGatewayListPageLink: () => routes.gateway.list.url(),
    getEquipmentDetailsPageLink: (equipmentId: string) => routes.equipments.details.url(equipmentId),
  };
};

import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { PropsWithChildren } from 'react';

import { ICommonAssetDetailsConfig } from '../model';

interface ICommonAssetDetailsConfigContext {
  config: ICommonAssetDetailsConfig;
}

interface ICommonAssetDetailsConfigContextProviderProps extends PropsWithChildren<ICommonAssetDetailsConfigContext> {}

export const CommonAssetDetailsConfigContext = createContext<ICommonAssetDetailsConfigContext>();

export const CommonAssetDetailsConfigContextProvider = ({
  config,
  children,
}: ICommonAssetDetailsConfigContextProviderProps) => {
  return (
    <CommonAssetDetailsConfigContext.Provider value={{ config }}>{children}</CommonAssetDetailsConfigContext.Provider>
  );
};

export const useCommonAssetDetailsConfigContext = () => useContext(CommonAssetDetailsConfigContext);

import { Collapse } from '@mui/material';
import { FieldValues } from 'react-hook-form';

import { ISensorFilterStore } from './hooks/use-sensor-filters.store';
import { SensorTriggerFilters } from './sensor-trigger-filters.component';
import { IOption, ISensorTriggerSelectProps, SensorTriggerSelectField } from './sensor-trigger-select.component';
import { useStyles } from './sensor-trigger.styles';

interface ISensorTriggerProps<TFormValues extends FieldValues, TFieldValue extends IOption>
  extends ISensorTriggerSelectProps<TFormValues, TFieldValue>,
    ISensorFilterStore {
  showFilters?: boolean;
}

export const SensorTrigger = <TFormValues extends FieldValues, TFieldValue extends IOption>({
  fieldName,
  optionMapper,
  showFilters,
  filters,
  dispatchOnChange,
  clear,
}: ISensorTriggerProps<TFormValues, TFieldValue>) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Collapse in={showFilters} className={classes.collapse}>
        <SensorTriggerFilters filters={filters} dispatchOnChange={dispatchOnChange} clear={clear} />
      </Collapse>
      <SensorTriggerSelectField<TFormValues, TFieldValue>
        fieldName={fieldName}
        optionMapper={optionMapper}
        deviceType={filters.deviceType}
        locationId={filters.location?.id}
      />
    </div>
  );
};

import { hubImage, theDetectionGroupLogo } from '@marlin/asset/shared/ui/assets';
import { TDateString, formatDate } from '@marlin/shared/utils-common-date';

import { content } from '../../../content';
import { ICommonAssetDetailsConfig } from '../../model';

const capitalizeText = (value: string) => value.toLowerCase().replace(/^(.)/, (char) => char.toUpperCase());

export const sensorConfig: ICommonAssetDetailsConfig = {
  productDetails: {
    logoSrc: theDetectionGroupLogo,
    imageSrc: hubImage,
  },
  displayTopOpenAlerts: false,
  details: [
    {
      name: 'name',
      label: content.SENSOR_NAME,
      valueSource: 'asset',
    },
    {
      name: 'deviceType',
      label: content.SENSOR_TYPE,
      valueSource: 'asset',
      callback: (value: unknown) => (typeof value === 'string' ? capitalizeText(value) : ''),
    },
    {
      name: 'manufacturerId',
      label: content.SERIAL_NUMBER,
      callback: (value: unknown) => (typeof value === 'string' ? value.split('-').pop() : undefined),
      valueSource: 'asset',
    },
    {
      name: 'brand',
      label: content.BRAND,
      valueSource: 'asset',
    },
    {
      name: 'firmwareVersion',
      label: content.FIRMWARE_VERSION,
      valueSource: 'datapoint',
    },
    {
      name: 'commissionDate',
      label: content.COMMISSION_DATE,
      valueSource: 'asset',
      callback: (value) => formatDate(value as unknown as TDateString, 'MM/DD/YY'),
    },
    {
      name: 'description',
      label: content.DESCRIPTION,
      valueSource: 'asset',
    },
  ],
};

import { refetchDeviceChildrenDatapoints, useDeviceChildrenDatapoints } from '@marlin/asset/data-access/device';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import { useObservable, useSubscription } from 'observable-hooks';
import { useMemo, useState } from 'react';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { useDatapointsContext } from '../../../../../shared/context/datapoints.context';
import { sentinelAlertDatapointsFilter } from '../sentinel/const';

export interface IPoldFiltersProps {
  setShowOnlyAlertsPolds: (value: boolean) => void;
  showOnlyAlertsPolds: boolean;
  inAlertPoldsCount?: number;
}

export const usePoldFilter = (deviceId: string): IPoldFiltersProps => {
  const queryParams = useQueryParameters();
  const showAlerts = queryParams.get('showAlerts') === 'true';
  const isPold = queryParams.get('model') === 'POLD';
  const [showOnlyAlertsPolds, setShowOnlyAlertsPolds] = useState(showAlerts && isPold);
  const { datapoints } = useDatapointsContext();
  const datapoints$ = useObservable(() => of(datapoints), [datapoints]);

  const query = useDeviceChildrenDatapoints({
    page: 1,
    pageSize: 5,
    modelName: 'POLD',
    deviceId,
    datapoints: sentinelAlertDatapointsFilter,
    isEnabled: isPold,
  });

  useSubscription(datapoints$.pipe(distinctUntilChanged(), debounceTime(200)), refetchDeviceChildrenDatapoints);

  const inAlertPoldsCount = useMemo(() => query?.data?.pagination.totalItems, [query?.data?.pagination.totalItems]);

  return useMemo(
    () => ({
      showOnlyAlertsPolds,
      setShowOnlyAlertsPolds,
      inAlertPoldsCount,
    }),
    [showOnlyAlertsPolds, inAlertPoldsCount]
  );
};

import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { StatusBar, StatusLabel } from '@marlin/asset/shared/ui/equipment-list';
import { renderEllipsisCell } from '@marlin/data-grid';
import { Tooltip, renderEllipsisTooltip } from '@marlin/shared/ui-common-tooltip';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { getUomFromSettings } from '@marlin/shared/utils-format-reading';

import { useDatapointCell } from '../../hooks/use-datapoint-cell.hook';
import { TCreateColumns } from '../../types';
import { content } from '../content';

export const useCreatePoldColumns = () => {
  const { renderDatapointCell } = useDatapointCell();
  const createColumns: TCreateColumns = (columnProps) => [
    {
      field: 'DeviceName',
      headerName: content.COLUMNS.POLD.NAME,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { InPoldAlert } = cellValues.row;
        return <StatusLabel isOperational={InPoldAlert === 'False'}>{renderEllipsisCell(cellValues)}</StatusLabel>;
      },
    },
    {
      field: 'InPoldAlert',
      headerName: content.COLUMNS.POLD.LEAK_STATUS,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: renderDatapointCell,
    },
    {
      field: 'Temperature',
      headerName: content.COLUMNS.POLD.AIR_TEMPERATURE,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => renderDatapointCell(cellValues, getUomFromSettings('TEMPERATURE')),
    },
    {
      field: 'RelativeHumidity',
      headerName: content.COLUMNS.POLD.AIR_HUMIDITY,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: renderDatapointCell,
    },
    {
      field: 'PoldInAlert',
      headerName: content.COLUMNS.POLD.IN_ALERT,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { InPoldAlert, InRelativeHumidityAlert, InTemperatureAlert } = cellValues.row;
        const inAlert = [InPoldAlert, InRelativeHumidityAlert, InTemperatureAlert].some((alert) => alert === 'True');
        return (
          <StatusLabel isOperational={!inAlert}>
            {renderEllipsisCell({
              ...cellValues,
              value: inAlert ? content.VALUES.IN_ALERT.TRUE : content.VALUES.IN_ALERT.FALSE,
            })}
          </StatusLabel>
        );
      },
    },
    {
      field: 'LastReadingTime',
      headerName: content.COLUMNS.POLD.LAST_READING_TIME,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const lastReadingTime = cellValues.value;
        return (
          <Tooltip
            placement="top"
            text={`${content.COLUMNS.POLD.LAST_READING_TIME}: ${formatDate(lastReadingTime, defaultDateTime)}`}
          >
            <>{renderEllipsisTooltip(formatDate(lastReadingTime, defaultDateTime))}</>
          </Tooltip>
        );
      },
    },
    {
      field: 'CommunicationStatusActive',
      headerName: content.COLUMNS.POLD.STATUS,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { CommunicationStatusActive } = cellValues.row;
        return <StatusBar isOperational={CommunicationStatusActive === 'True'} tooltip={true} />;
      },
    },
    {
      field: 'HasLowBatteries',
      headerName: content.COLUMNS.POLD.POWER_SOURCE,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { IsWired, HasLowBatteries } = cellValues.row;
        return (
          <PowerSource
            powerSource={IsWired === 'True' ? 'LINE' : 'BATTERY'}
            batteryLevel={HasLowBatteries === 'True' ? 'LOW' : 'HIGH'}
            variant={'text'}
          />
        );
      },
    },
  ];

  return createColumns;
};

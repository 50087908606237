import { AERCO_UNIT_STATUS } from '@marlin/shared/utils/zod';

import { onStatuses } from '../types';

export const getShouldDisplayDeviceTile = (
  status: AERCO_UNIT_STATUS,
  activeStatusChips: { operational: boolean; offline: boolean }
) => {
  if (activeStatusChips.offline === activeStatusChips.operational) return true;

  return onStatuses.includes(status) ? activeStatusChips.operational : activeStatusChips.offline;
};

import moment from 'moment/moment';
import { z } from 'zod';

import { content } from './content';

export const EquipmentFormTypes = z.enum([
  'intellistation',
  'tekmarSmartBoiler',
  'tekmarSmartSteam',
  'tekmarSmartHeatPump',
  'aercoBenchmark',
  'aercoInnovation',
  'valveController',
  'sentinel',
  'other',
] as const);

const basicSchema = z.object({
  name: z
    .string({ required_error: content.REQUIRED })
    .trim()
    .min(1, content.REQUIRED)
    .min(3, content.MIN_MAX)
    .max(200, content.MIN_MAX),
  location: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional()
    .refine((value) => !!value?.name, { message: content.REQUIRED }),
  commissionDate: z
    .string()
    .refine(
      (dateString: string) => {
        if (!dateString) return true;
        const inputDate = moment(dateString);
        const today = moment().endOf('day');
        return inputDate.isValid() && inputDate.isBefore(today);
      },
      { message: content.DATE_VALIDATION_FUTURE }
    )
    .optional()
    .nullable(),
  description: z.string().optional(),
  formType: z.literal(undefined),
});

const intellistationSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.intellistation),
    registrationCode: z.string({ required_error: content.REQUIRED }).min(8, content.REGISTER_CODE_MIN_MAX),
    serialNumber: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
  })
);

const tekmarSmartBoilerSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.tekmarSmartBoiler),
    registrationCode: z.string({ required_error: content.REQUIRED }).min(8, content.REGISTER_CODE_MIN_MAX),
    serialNumber: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
  })
);

const tekmarSmartSteamSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.tekmarSmartSteam),
    registrationCode: z.string({ required_error: content.REQUIRED }).min(8, content.REGISTER_CODE_MIN_MAX),
    serialNumber: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
  })
);

const tekmarHeatPumpSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.tekmarSmartHeatPump),
    registrationCode: z.string({ required_error: content.REQUIRED }).min(8, content.REGISTER_CODE_MIN_MAX),
    serialNumber: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
  })
);

const sentinelSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.sentinel),
    registrationCode: z.string({ required_error: content.REQUIRED }).min(9, content.REGISTER_CODE_NUMBERS_ONLY),
  })
);

const serialNumberRegex = /^[a-zA-Z]-\d{2}-\d{4,15}$|^[a-zA-Z]{2}-\d{2}-\d{4,14}$/;

const aercoSchemaBase = basicSchema.merge(
  z.object({
    serialNumber: z
      .string({ required_error: content.REQUIRED })
      .regex(serialNumberRegex, content.SERIAL_NUMBER_INVALID),
    registrationCode: z
      .string({ required_error: content.REQUIRED })
      .min(4, content.FIXED_ID_INVALID)
      .max(10, content.FIXED_ID_INVALID),
  })
);

const aercoBenchmarkSchema = aercoSchemaBase.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.aercoBenchmark),
  })
);

const aercoInnovationSchema = aercoSchemaBase.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.aercoInnovation),
  })
);

const valveControllerSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.valveController),
  })
);

const otherSchema = basicSchema.merge(
  z.object({
    formType: z.literal(EquipmentFormTypes.enum.other),
    brand: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
    model: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
    serialNumber: z.string().max(50, content.COMMON_MAX_LENGTH).optional(),
    type: z.string().optional(),
  })
);

export const equipmentFormDataSchema = z.discriminatedUnion('formType', [
  basicSchema,
  intellistationSchema,
  tekmarSmartBoilerSchema,
  tekmarSmartSteamSchema,
  tekmarHeatPumpSchema,
  aercoBenchmarkSchema,
  aercoInnovationSchema,
  sentinelSchema,
  otherSchema,
]);

export const editEquipmentFormDataSchema = z.discriminatedUnion('formType', [
  intellistationSchema.omit({ registrationCode: true }),
  tekmarSmartBoilerSchema.omit({ registrationCode: true }),
  tekmarSmartSteamSchema.omit({ registrationCode: true }),
  tekmarHeatPumpSchema.omit({ registrationCode: true }),
  aercoBenchmarkSchema.omit({ registrationCode: true }),
  valveControllerSchema,
  aercoInnovationSchema,
  sentinelSchema.omit({ registrationCode: true }),
  otherSchema,
]);

import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { globalConfigIntellistation } from '@marlin/asset/shared/equipment-config';
import { intellistationImage, intellistationLogo } from '@marlin/asset/shared/ui/assets';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';

import { content } from '../../content';
import { detailsConfig } from './details-config';
import { intellistationEventCodes } from './event-codes';
import { sectionConfig } from './section-config';

export const intellistationConfig: IConfig = {
  headerBanner: null,
  tabs: {},
  details: detailsConfig,
  settings: {
    settingGroups: [
      {
        id: SETTINGS_GROUP.TEMPERATURE,
        title: content.TEMPERATURE,
      },
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
      {
        id: SETTINGS_GROUP.ALERT,
        title: content.ALERT_SETTINGS,
      },
    ],
  },
  dashboard: {
    ...globalConfigIntellistation.dashboard,
    productDetails: {
      datapoints: [
        { name: 'setpointTarget', label: content.DATAPOINT_LABELS.SETPOINT_TEMP },
        {
          name: 'mixingTemp',
          label: content.DATAPOINT_LABELS.OUTLET_TEMP,
          eventCodes: intellistationEventCodes.readings.mixedOutlet,
        },
      ],
      imageSrc: intellistationImage,
      logoSrc: intellistationLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: true,
    sections: sectionConfig,
    chart: {
      datapointNames: ['mixingTemp', 'setpointTarget'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: false,
  },
};

import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { AlertCriticality } from '@marlin/shared/utils/zod';
import { z } from 'zod';

export const EquipmentAlert = z.object({
  deviceEventCode: z.string().nullable(),
  startTime: z.string(),
  title: z.string().nullable(),
  criticality: AlertCriticality,
  uoM: UnitOfMeasureType,
  value: z.string().optional(),
});
export type TEquipmentAlert = z.infer<typeof EquipmentAlert>;

export const EquipmentAlerts = z.object({
  deviceId: z.string(),
  currentAlerts: EquipmentAlert.array(),
});
export type TEquipmentAlerts = z.infer<typeof EquipmentAlerts>;

interface IConditionValue {
  enabled: boolean;
  value: number;
}

export interface ICondition {
  thresholdMin?: IConditionValue;
  thresholdMax?: IConditionValue;
  deadbandMin?: IConditionValue;
  deadbandMax?: IConditionValue;
}

export enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  IN_APP = 'IN_APP',
  HOT_SOS = 'HOT_SOS',
}

export enum CHANNEL_RESPONSE_TYPE {
  EMAIL = 'Email',
  SMS = 'SMS',
  IN_APP = 'InApp',
  HOT_SOS = 'HotSOS',
}

export interface IChannelRecipient {
  recipientId: string;
  recipientType: TRecipientType;
}

interface IChannel {
  recipients: IChannelRecipient[];
}

export interface IEmailChannel extends IChannel {
  type: CHANNEL_TYPE.EMAIL;
}

export interface ISmsChannel extends IChannel {
  type: CHANNEL_TYPE.SMS;
}

export interface IInAppChannel {
  type: CHANNEL_TYPE.IN_APP;
}

export interface IHotSosChannel {
  type: CHANNEL_TYPE.HOT_SOS;
}

export interface IChannels {
  [CHANNEL_TYPE.SMS]?: ISmsChannel;
  [CHANNEL_TYPE.EMAIL]?: IEmailChannel;
  [CHANNEL_TYPE.IN_APP]?: IInAppChannel;
  [CHANNEL_TYPE.HOT_SOS]?: IHotSosChannel;
}

export interface IPagination {
  totalItems: number;
  page: number;
  pageSize: number;
}

export type TRecipientType = 'Recipient' | 'User';

export enum RULE_CATEGORY {
  HEALTH = 'Health',
  PERFORMANCE = 'Performance',
  INFORMATION = 'Information',
}

export enum DEVICE_RESPONSE_TYPE {
  TEMPERATURE = 'Temperature',
  PRESSURE = 'Pressure',
  LEAK = 'Leak',
  FLOW_METER = 'FlowMeter',
  PULSE_METER = 'PulseMeter',
  GATEWAY = 'Gateway',
  EQUIPMENT = 'Equipment',
}

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  GATEWAY = 'GATEWAY',
  UNKNOWN = 'UNKNOWN',
  EQUIPMENT = 'EQUIPMENT',
}

export enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export interface IFilterParams {
  locationId: string[];
  equipmentId: string[];
  deviceId?: string[];
  term?: string;
  isEnabled: boolean | null;
  pageSize: number;
  page: number;
  ruleCategory?: RULE_CATEGORY;
}

interface ICriticality {
  low: boolean;
  high: boolean;
}

enum ALERT_STATUS_FILTER {
  CURRENT = 'CURRENT',
  SNOOZED = 'SNOOZED',
  RESOLVED = 'RESOLVED',
  ALL = 'ALL',
}

export interface IAlertFilterParams {
  status: ALERT_STATUS_FILTER;
  locationIds: string[];
  equipmentIds: string[];
  deviceIds: string[];
  deviceTypes: string[];
  search?: string;
  criticality?: ICriticality;
}

import { ISettingsPoint } from '@marlin/asset/shared/equipment-config';
import { StatusBar } from '@marlin/asset/shared/ui/equipment-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { VALVE_STATUS } from '../../../../shared/config/sentinel/valve-status';
import { content } from '../../../../shared/content';
import { useDatapointsContext } from '../../../../shared/context/datapoints.context';
import { getDatapointWithDependencies } from '../../../../shared/utils/get-datapoint-with-dependencies.utils';
import { useObservableLastCommand } from '../commands/hooks/use-observable-last-command.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  settingsRow: {
    display: 'flex',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    gap: theme.typography.pxToRem(16),
    justifyContent: 'space-between',
  },
  settingsRowLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  settingsRowStatus: {
    width: 'auto',
  },
}));

interface ISettingsRowProps {
  setting: ISettingsPoint;
  functionsMapping?: {
    [key: string]: (
      configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
      datapointsFromDeps: TDatapointWithUiMetadata[]
    ) => TDatapointWithUiMetadata;
  };
}

export const SettingsRow = ({ setting, functionsMapping }: ISettingsRowProps) => {
  const { classes } = useStyles();
  const { getDatapoint } = useDatapointsContext();
  const { lastCommand } = useObservableLastCommand();

  const mappedSetting = getDatapointWithDependencies({
    getDatapoint,
    dependencies: setting.dependencies,
    datapoint: undefined,
    configDatapoint: setting,
    functionsMapping,
  });

  const valveStatus = useMemo(() => {
    if (Array.isArray(mappedSetting)) {
      return null;
    }
    const isOperational = mappedSetting?.value === VALVE_STATUS.OPEN;
    const isValueStatus =
      mappedSetting?.value === VALVE_STATUS.OPENING || mappedSetting?.value === VALVE_STATUS.CLOSING || !!lastCommand;

    const tempLabel = lastCommand === 'Open' ? content.LDS_VALVE_STATUS.OPENING : content.LDS_VALVE_STATUS.CLOSING;

    const labels = {
      operational: lastCommand ? tempLabel : mappedSetting?.displayedValue,
      notOperational: lastCommand ? tempLabel : mappedSetting?.displayedValue,
    };

    return { isValueStatus, isOperational, labels };
  }, [lastCommand, mappedSetting]);

  if (!mappedSetting || Array.isArray(mappedSetting)) {
    return null;
  }

  return (
    <div className={classes.settingsRow}>
      <div className={classes.settingsRowLabel}>{getDatapoint(setting.label)?.value ?? setting.label}</div>
      <div className={classes.settingsRowStatus}>
        <StatusBar
          isOperational={valveStatus?.isOperational ?? false}
          labels={valveStatus?.labels}
          isValueStatus={valveStatus?.isValueStatus ?? false}
        />
      </div>
    </div>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { ILocation, LOCATION_TYPE } from '@marlin/asset/data-access/location';
import { useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useLocation as useRouterLocation } from 'react-router-dom';

import { emptyDefaultValues } from '../../shared/constants';
import { LocationFormDataSchema } from './location-form-data-schema';

export interface IUpsertLocation {
  form: UseFormReturn<ILocation>;
}

const transformLocationType = (locationType: string | undefined, isMechanicalRoom?: boolean) => {
  if (isMechanicalRoom) {
    return LOCATION_TYPE.MECHANICAL_ROOM as string;
  }

  if (locationType === LOCATION_TYPE.NOT_CLASSIFIED) {
    return '';
  }

  return locationType;
};

export const useUpsertLocation = (defaultValues: ILocation = emptyDefaultValues): IUpsertLocation => {
  const { state } = useRouterLocation();

  const defaultFormValues = useMemo(() => {
    const isCreatingMechanicalRoom = Boolean(state) && 'isMechanicalRoom' in state ? state.isMechanicalRoom : false;

    return {
      ...defaultValues,
      locationType: transformLocationType(defaultValues.locationType, isCreatingMechanicalRoom),
      parentLocation: defaultValues.parentLocation?.id ? defaultValues.parentLocation : undefined,
    };
  }, [defaultValues, state]);

  const form = useForm<ILocation>({
    mode: 'onTouched',
    resolver: zodResolver(LocationFormDataSchema),
    defaultValues: defaultFormValues,
  });

  return {
    form,
  };
};

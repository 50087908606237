import {
  CHANNEL_TYPE,
  TAutomationBuilder,
  TCondition,
  TCreateAutomation,
  useCreateAutomation,
} from '@marlin/alert/data-access/automated-action';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback, useMemo } from 'react';

import { content } from './content';

const createCondition = (condition?: TCondition) => {
  switch (condition?.type) {
    case 'boolean':
      return {
        thresholdMin: {
          value: 0,
          enabled: true,
        },
        thresholdMax: {
          value: 0.5,
          enabled: true,
        },
      };
    case 'number':
      return {
        thresholdMin: condition?.rules?.lt
          ? {
              value: condition?.rules?.lt?.threshold,
              enabled: true,
            }
          : undefined,
        thresholdMax: condition.rules?.gt
          ? {
              value: condition?.rules?.gt?.threshold,
              enabled: true,
            }
          : undefined,
      };
    default:
      return {
        thresholdMin: undefined,
        thresholdMax: undefined,
      };
  }
};

export const mapSourceToTargetAutomationBuilder = (data: TAutomationBuilder): TCreateAutomation => {
  return {
    name: data.details.name,
    snoozeIntervalInMinutes: data.actions.snoozeIntervalInMinutes,
    criticality: data.details.criticality,
    manufacturerId: data.triggers[0].device.manufacturerId,
    device: data.triggers[0].device,
    conditions: createCondition(data?.triggers[0]?.conditions ?? undefined),
    duration: {
      value: null,
      unit: null,
    },
    channels: {
      [CHANNEL_TYPE.IN_APP]: {
        type: CHANNEL_TYPE.IN_APP,
      },
    },
  };
};

export const useAutomationBuilder = () => {
  const { enqueueSnackbar } = useSnackbar();

  const createAutomationMutation = useCreateAutomation();

  const isLoading = useMemo(() => createAutomationMutation.isPending, [createAutomationMutation.isPending]);

  const isSuccess = useMemo(() => createAutomationMutation.isSuccess, [createAutomationMutation.isSuccess]);

  const createAutomation = useCallback(
    async (data: TAutomationBuilder) => {
      try {
        const payload = mapSourceToTargetAutomationBuilder(data);
        await createAutomationMutation.mutateAsync(payload);
        enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_AUTOMATION_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (e) {
        enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_AUTOMATION_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [createAutomationMutation, enqueueSnackbar]
  );

  return {
    createAutomation,
    isLoading,
    isSuccess,
  };
};

import { z } from 'zod';

const content = {
  MIN_MAX: 'Must be between 3 and 200 characters',
};

export const registrationFlowValues = z.enum([
  'Tekmar',
  'AercoBoiler',
  'AercoWaterHeater',
  'tekmarSmartHeatPump',
  'tekmarSmartSteam',
  'tekmarSmartBoiler',
]);

export enum EQUIPMENT_ENUM {
  INTELLISTATION = 'intellistation',
  AERCO_INNOVATION = 'aercoInnovation',
  AERCO_BENCHMARK = 'aercoBenchmark',
  SMART_STEAM = 'tekmarSmartSteam',
  SMART_BOILER = 'tekmarSmartBoiler',
  HEAT_PUMP = 'tekmarSmartHeatPump',
}

export enum EQUIPMENT_STATUS {
  NEW = 'NEW',
  VALIDATED = 'VALIDATED',
  CONNECTING = 'CONNECTING',
  OK = 'OK',
  ERROR = 'ERROR',
  REFETCH = 'REFETCH',
}
const equipmentBase = z.object({
  indexId: z.string().nullish().optional(),
  name: z.string().min(3, content.MIN_MAX).max(200, content.MIN_MAX).nullish(),
  status: z.nativeEnum(EQUIPMENT_STATUS),
  equipmentType: z.nativeEnum(EQUIPMENT_ENUM),
  registrationCode: z.string().min(4).nullish(),
  registrationFlow: registrationFlowValues,
});

const equipmentIntellistationSchema = equipmentBase.extend({
  registrationFlow: z.literal('Tekmar'),
});

const tekmarSmartSteamSchema = equipmentBase.extend({
  registrationFlow: z.literal('tekmarSmartSteam'),
});

const tekmarSmartBoilerSchema = equipmentBase.extend({
  registrationFlow: z.literal('tekmarSmartBoiler'),
});

const tekmarSmartHeatPumpSchema = equipmentBase.extend({
  registrationFlow: z.literal('tekmarSmartHeatPump'),
});

const equipmentAercoSchema = equipmentBase.extend({
  registrationFlow: z.literal('AercoBoiler'),
  serialNumber: z.string().min(4).nullish(),
});

const equipmentAercoWaterHeaterSchema = equipmentBase.extend({
  registrationFlow: z.literal('AercoWaterHeater'),
  serialNumber: z.string().min(4).nullish(),
});

export const equipmentSchema = z.discriminatedUnion('registrationFlow', [
  equipmentAercoSchema,
  equipmentAercoWaterHeaterSchema,
  equipmentIntellistationSchema,
  tekmarSmartSteamSchema,
  tekmarSmartBoilerSchema,
  tekmarSmartHeatPumpSchema,
]);

export const registerEquipmentSchema = z.object({
  equipment: z.array(equipmentSchema),
});

export type TOnboardingEquipment = z.infer<typeof equipmentSchema>;
export type TOnboardingAercoEquipment =
  | z.infer<typeof equipmentAercoSchema>
  | z.infer<typeof equipmentAercoWaterHeaterSchema>;
export type TOnboardingIntellistationEquipment = z.infer<typeof equipmentIntellistationSchema>;
export type TRegisterEquipment = z.infer<typeof registerEquipmentSchema>;
export type TRegistrationFlow = z.infer<typeof registrationFlowValues>;

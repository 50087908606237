import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';

import { content } from '../../content';
import { useStyles } from './filter-container.styles';

interface IFilterContainerProps extends PropsWithChildren {
  handleClearAll?: () => void;
}

export const FilterContainer = ({ children, handleClearAll }: IFilterContainerProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.filters}>
        {children}
        {handleClearAll && (
          <div className={classes.clearButton}>
            <Button onClick={handleClearAll}>{content.TRIGGER.CLEAR_ALL}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

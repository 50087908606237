import { CRITICALITY } from '@marlin/alert/data-access/automated-action';
import { z } from 'zod';

import { content } from './content';

export const detailsSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: content.MIN_1_CHARACTER_REQUIRED })
    .max(200, { message: content.MAX_200_CHARTACTERS_ALLOWED }),
  criticality: z.nativeEnum(CRITICALITY),
});

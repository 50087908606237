import { content } from '../content';
import { IPlantConfig } from '../context/plant-config.context';

const activeSetpointDatapointName = 'ActiveSetpt';

export const boilerConfig: IPlantConfig = {
  plantListTitle: content.PLANT_LIST.HEADER_BOILER,
  title: content.BOILER_TITLE,
  overviewTitle: content.OVERVIEW_TITLE,
  managerDatapointName: 'DeviceGroupManagerId',
  backupManagerDatapointName: 'AutoManagerAddr',
  performance: [
    { name: activeSetpointDatapointName, label: content.DATAPOINT_LABELS.SETPOINT_TEMP },
    {
      name: 'OutletTemp',
      label: content.DATAPOINT_LABELS.OUTLET_TEMP,
    },
    {
      name: 'Inlet2Temp',
      label: content.DATAPOINT_LABELS.RETURN_WTR,
    },
  ],
  calculatedValue: true,
  chartData: {
    datapointNames: [activeSetpointDatapointName, 'OutletTemp'],
    datapointGroupNames: ['temperature'],
  },
  activeSetpointDatapointName,
};

import { MarlinTheme } from '@marlin/shared/theme';
import { IFormInputProps } from '@marlin/shared/ui-form-common';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Paper } from '@mui/material';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { InviteNewUserForm } from './form/invite-new-user.form';
import { useInviteUserForm } from './hooks/use-invite-user-form.hook';
import { useInviteUserMutation } from './hooks/use-invite-user-mutation.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
  },
}));

export const SettingsInviteNewUser = () => {
  const { classes } = useStyles();
  const [errorMessage, setErrorMessage] = useState('');

  const { goTo } = useRouter();

  const form = useInviteUserForm();

  const onCancel = () => {
    form.reset();
    goTo(routes.settings.people.url());
  };

  const resetError = () => {
    setErrorMessage('');
  };

  const { inviteUser } = useInviteUserMutation({
    onSuccess: () => {
      goTo(routes.settings.people.url());
    },
    onError: (message: string) => {
      setErrorMessage(message);
    },
  });

  const onSubmit: SubmitHandler<IFormInputProps> = (data) => {
    if (form.formState.isValid) {
      return inviteUser(data);
    }

    return;
  };

  return (
    <div data-testid="invite-user-container">
      <Paper elevation={1} className={classes.formContainer} data-testid="card">
        <InviteNewUserForm
          form={form}
          onSubmit={onSubmit}
          onCancel={onCancel}
          resetError={resetError}
          errorMessage={errorMessage}
        />
      </Paper>
    </div>
  );
};

import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  error: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },

  errorText: {
    marginLeft: theme.typography.pxToRem(10),
  },
}));

interface IConditionValidationErrorProps {
  triggerIndex: number;
}

export function ConditionValidationError({ triggerIndex }: IConditionValidationErrorProps): JSX.Element | null {
  const { classes } = useStyles();

  const { formState } = useFormContext<TAutomationBuilder>();
  const errorMessage = formState.errors.triggers?.[triggerIndex]?.conditions?.['rules']?.message;

  if (!errorMessage) {
    return null;
  }

  return (
    <div className={classes.error}>
      <WarningRoundedIcon />
      <span className={classes.errorText} data-testid="condition-error">
        {errorMessage}
      </span>
    </div>
  );
}

import { MarlinTheme } from '@marlin/shared/theme';
import { Tab as MuiTab, Tabs as MuiTabs, useMediaQuery, useTheme } from '@mui/material';
import { ComponentProps } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    padding: 0,
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.typography.pxToRem(10),
      borderTop: 'none',
    },
  },
  container: {
    height: theme.typography.pxToRem(42),
  },
  tab: {
    padding: `${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(16)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(16)}`,
    },
  },
}));

type TTabProps = ComponentProps<typeof MuiTab>;

export const Tab = ({ ...props }: TTabProps) => {
  const { classes } = useStyles();

  return <MuiTab classes={{ root: classes.tab }} {...props} />;
};

type TTabsProps = ComponentProps<typeof MuiTabs>;

export const Tabs = ({ children, ...props }: TTabsProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MuiTabs
      className={classes.wrapper}
      variant={isMobile ? 'fullWidth' : 'standard'}
      classes={{ flexContainer: classes.container }}
      {...props}
    >
      {children}
    </MuiTabs>
  );
};

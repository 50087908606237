import { zodResolver } from '@hookform/resolvers/zod';
import { firstNameZodSchema, jobTitleZodSchema, lastNameZodSchema, roleZodSchema } from '@marlin/shared/ui-form';
import { IFormInputProps } from '@marlin/shared/ui-form-common';
import { emailZodSchema } from '@marlin/shared/utils/zod';
import { ValidationMode, useForm } from 'react-hook-form';
import * as z from 'zod';

export const InviteUserValidationSchema = z.object({
  firstName: firstNameZodSchema,
  lastName: lastNameZodSchema,
  email: emailZodSchema,
  title: jobTitleZodSchema,
  role: roleZodSchema,
});

export const useInviteUserForm = (mode: keyof ValidationMode = 'onTouched') => {
  const form = useForm<IFormInputProps>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      title: '',
    },
    mode,
    resolver: zodResolver(InviteUserValidationSchema),
  });

  return form;
};

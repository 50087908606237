import { DeleteUserModal as DeleteUserModalUI, IDeleteUser } from '@marlin/account-ui-user-list';
import { useCallback } from 'react';

import { useDeleteUser } from '../../shared/hooks/use-delete-user.hook';

interface IDeleteUserModalProps {
  user: IDeleteUser | null;
  onClose: () => void;
}

const DeleteUserModal = ({ user, onClose }: IDeleteUserModalProps) => {
  const { deleteUser } = useDeleteUser();

  const handleConfirm = useCallback(async (): Promise<void> => {
    if (!user) {
      return;
    }

    await deleteUser(user.id);
    onClose();
  }, [deleteUser, onClose, user]);
  if (!user) {
    return null;
  }

  return <DeleteUserModalUI onCancel={onClose} onSubmit={handleConfirm} isOpen={!!user} user={user} />;
};

export { DeleteUserModal };

import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

import { useConditionField } from './use-condition-field.hook';

interface IConditionFieldProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
  operator: 'lt' | 'gt';
}

export const useThresholdField = ({ datapoint, triggerIndex, operator }: IConditionFieldProps) => {
  const { trigger } = useFormContext<TAutomationBuilder>();
  const { threshold, rules, onChange } = useConditionField({ triggerIndex, operator, datapoint });

  const handleBlur = useCallback(() => {
    return trigger(`triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>);
  }, [trigger, triggerIndex]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        type: 'number',
        rules: {
          ...rules,
          [operator]: {
            operator,
            threshold: event.target.value === '' ? null : event.target.value,
            deadband: rules[operator]?.deadband || null,
            uom: datapoint.unitOfMeasure,
          },
        },
      });
      trigger(`triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>);
    },
    [datapoint.unitOfMeasure, onChange, operator, rules, trigger, triggerIndex]
  );

  const handleClear = useCallback(() => {
    const { [operator]: currentCondition, ...otherConditions } = rules;
    onChange({
      type: 'number',
      rules: otherConditions,
    });
  }, [onChange, operator, rules]);

  useEffect(() => {
    if (rules[operator]?.threshold && rules[operator]?.uom !== datapoint.unitOfMeasure) {
      onChange({
        type: 'number',
        rules: {
          ...rules,
          [operator]: {
            ...rules[operator],
            uom: datapoint.unitOfMeasure,
          },
        },
      });
    }
  }, [datapoint.unitOfMeasure, onChange, handleClear, operator, rules]);

  return {
    value: threshold,
    handleChange,
    handleBlur,
    handleClear,
  };
};

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  conditionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    minHeight: theme.typography.pxToRem(56),
  },
  conditionRow: {
    display: 'flex',
    minHeight: theme.typography.pxToRem(56),
    alignItems: 'center',
  },
  conditionElement: {
    flexGrow: 1,
    flexBasis: 0,
  },
  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
}));

import {
  ControlSwitcher,
  SettingsErrorPage,
  SettingsLeaveGuard,
  SettingsSectionAccordion,
  SettingsSectionSkeleton,
} from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useSearchParams } from '@marlin/shared/utils-router';
import { SETTINGS_GROUP, TEquipmentSetting } from '@marlin/shared/utils/datapoint-mappers';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Backdrop } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../shared/content';
import { useEditSettingContext } from '../../../shared/context/edit-settings.context';
import { useGatewaySettingsDatapoints } from '../../../shared/hooks/use-gateway-settings.hook';
import { useUpdateSettings } from '../../../shared/hooks/use-update-settings.hook';
import { SettingsChangeInfo } from './settings-change-info.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(746),
  },
  loadingSpinnerWrapper: {
    minHeight: theme.typography.pxToRem(350),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.indexPosition.foreground,
  },
}));

export const GatewaySettings = () => {
  const { classes } = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const settingGroupId = searchParams.get('settingGroupId');
  const settingsGroups = useMemo(
    () => [
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
    ],
    []
  );
  const editSettingContext = useEditSettingContext();

  const gatewayId = useIdFromPathname();

  const onDatapointsError = () => {
    setSearchParams((currentParams) => {
      if (currentParams.has('settingGroupId')) {
        currentParams.delete('settingGroupId');
      }
      return currentParams;
    });
  };

  const {
    data: settings = [],
    isGatewaySettingsLoading,
    isError,
    isMetadataLoading,
    refetch,
    isCurrent,
  } = useGatewaySettingsDatapoints({
    gatewayId,
    onError: onDatapointsError,
  });

  useEffect(() => {
    setSearchParams((currentParams) => {
      if (!currentParams.has('settingGroupId')) {
        currentParams.set('settingGroupId', SETTINGS_GROUP.SYSTEM);
      }

      if (currentParams.has('settingId')) {
        currentParams.delete('settingId');
      }

      return currentParams;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { updateSettings, isLoading: isMutationLoading } = useUpdateSettings();

  if (isError) {
    return <SettingsErrorPage onRefreshClick={refetch} />;
  }

  return (
    <div className={classes.container}>
      {!isCurrent && <SettingsChangeInfo />}
      {settingsGroups.map(({ title, id }) => {
        return (
          <SettingsSectionAccordion title={title} id={id} key={id}>
            {id === settingGroupId ? (
              <SectionBody
                updateSettings={updateSettings}
                isSectionLoading={isGatewaySettingsLoading}
                datapoints={settings}
              />
            ) : null}
          </SettingsSectionAccordion>
        );
      })}
      <SettingsLeaveGuard saveSetting={updateSettings} {...editSettingContext} />
      <Backdrop className={classes.backdrop} open={isMetadataLoading || isMutationLoading}>
        <LoadingSpinner />
      </Backdrop>
    </div>
  );
};

const SectionBody = ({
  updateSettings,
  isSectionLoading,
  datapoints,
}: {
  updateSettings: (name: string, value: string, prevValue: string) => Promise<void>;
  isSectionLoading: boolean;
  datapoints: TEquipmentSetting[];
}) => {
  const editSettingContext = useEditSettingContext();
  const isEditable = usePermission(PERMISSIONS.EDIT_SETTINGS_GATEWAY);

  if (isSectionLoading) {
    return <SettingsSectionSkeleton />;
  }

  return (
    <ControlSwitcher
      datapoints={datapoints}
      updateSettings={updateSettings}
      {...editSettingContext}
      disabled={!isEditable}
    />
  );
};

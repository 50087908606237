import { TAutomationBuilder, TCondition } from '@marlin/alert/data-access/automated-action';
import { ConditionValidationError, GenericNumberCondition } from '@marlin/alert/ui/automation-builder-components';
import { MarlinTheme } from '@marlin/shared/theme';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useEffect } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(8),
  },
}));

interface INumberConditionProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
}

export const NumberCondition = ({ datapoint, triggerIndex }: INumberConditionProps) => {
  const { classes } = useStyles();
  const { setValue, watch } = useFormContext<TAutomationBuilder>();

  const conditions = watch(`triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>) as
    | TCondition
    | null
    | undefined;
  const device = watch(`triggers[${triggerIndex}].device` as FieldPath<TAutomationBuilder>);

  useEffect(() => {
    if ((!conditions && !!device) || conditions?.type !== 'number') {
      setValue(
        `triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>,
        {
          type: 'number',
          rules: {},
        },
        { shouldTouch: true }
      );
    }
  }, [conditions, device, setValue, triggerIndex]);

  return (
    <div className={classes.container}>
      <GenericNumberCondition datapoint={datapoint} triggerIndex={triggerIndex} operator="lt" conditionIndex={1} />
      <GenericNumberCondition datapoint={datapoint} triggerIndex={triggerIndex} operator="gt" conditionIndex={2} />
      <ConditionValidationError triggerIndex={triggerIndex} />
    </div>
  );
};

import { useCurrentUser } from '@marlin/account-data-access-organization';
import { invalidateUserList } from '@marlin/account-data-access-user';
import { useInviteUser } from '@marlin/account-data-access-user-invite';
import { IFormInputProps } from '@marlin/shared/ui-form-common';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { getLogger } from '@marlin/shared/utils/logger';
import { AxiosError } from 'axios';

import { content } from '../utils/content';
import { IInvitationError, INVITATION_ERROR, errorHandler, isSupportUser } from '../utils/error-handler';

export const useInviteUserMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (message: string) => void;
}) => {
  const { mutateAsync, isPending, isError } = useInviteUser();
  const { data: userData } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const inviteUser = async (data: IFormInputProps) => {
    try {
      await mutateAsync(data);
      await invalidateUserList();

      enqueueSnackbar(content.SUCCESS_MESSAGE, {
        variant: 'success',
        preventDuplicate: true,
      });
      onSuccess();
      getLogger()?.track('UserInvited');
    } catch (error: unknown) {
      const axiosError = error as AxiosError<IInvitationError>;
      if (
        axiosError.response?.data.errorCode === INVITATION_ERROR.USER_ALREADY_EXISTS &&
        isSupportUser(data.email, userData)
      ) {
        enqueueSnackbar(content.ERROR_SUPPORT_USER_ALREADY_EXISTS, {
          variant: 'error',
          preventDuplicate: true,
        });
      } else {
        errorHandler({ error: axiosError, onError });
      }
    }
  };

  return {
    inviteUser,
    isLoading: isPending,
    isError: isError,
  };
};

import { TDeviceChildrenDatapointsParams } from '@marlin/asset/data-access/device';
import { useMetadataByModels } from '@marlin/asset/data-access/equipment';
import { TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { sentinelAlertDatapointsFilter } from '../sentinel/const';
import { TSentinelSystemEquipment } from '../types';
import { useClearAlertsCommand } from './use-clear-alerts-command.hook';
import { IPoldFiltersProps, usePoldFilter } from './use-pold-filter.hook';
import { useSystemEquipmentTabs } from './use-system-equipment-tabs.hook';

export interface ISystemEquipmentHook extends IPoldFiltersProps {
  tab: TSentinelSystemEquipment;
  changeTab: (tab: TSentinelSystemEquipment) => void;
  dataHookFilters: Partial<TDeviceChildrenDatapointsParams>;
  metadata?: TDeviceMetadataResponse;
  clearAllAlerts: () => void;
  isSendingDeviceCommand: boolean;
}

export const useSystemEquipment = (): ISystemEquipmentHook => {
  const { tab, changeTab } = useSystemEquipmentTabs();
  const { equipmentId } = useParams();
  const { data } = useMetadataByModels([tab]);
  const { showOnlyAlertsPolds, setShowOnlyAlertsPolds, inAlertPoldsCount } = usePoldFilter(equipmentId || '');
  const { clearAllAlerts, isSendingDeviceCommand } = useClearAlertsCommand({
    onSuccess: () => {
      setShowOnlyAlertsPolds(false);
    },
  });

  const dataHookFilters: Partial<TDeviceChildrenDatapointsParams> = useMemo(
    () => ({
      modelName: tab,
      deviceId: equipmentId,
      ...(tab === 'POLD' && showOnlyAlertsPolds ? { datapoints: sentinelAlertDatapointsFilter } : {}),
    }),
    [tab, equipmentId, showOnlyAlertsPolds]
  );

  return {
    tab,
    changeTab,
    dataHookFilters,
    metadata: data?.[0].metadata,
    showOnlyAlertsPolds,
    setShowOnlyAlertsPolds,
    inAlertPoldsCount,
    clearAllAlerts,
    isSendingDeviceCommand,
  };
};

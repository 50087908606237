import { content } from '../../content';
import { ICommonAsset, IDetailsRow } from '../model';

interface IDetailsRows {
  asset: ICommonAsset;
  detailsRows: IDetailsRow[];
}

export const useDetailsRows = ({
  asset,
  detailsRows,
}: IDetailsRows): Array<{
  label: string;
  value: string;
}> => {
  const valueOrEmpty = (value: string | undefined) => value ?? content.EMPTY_DATAPOINT_VALUE;

  return detailsRows.map((row) => {
    if (row.valueSource === 'datapoint') {
      return {
        label: row.label,
        value: valueOrEmpty(
          asset.lastReadingValues?.find((datapoint) => datapoint.name.toLowerCase() === row.name.toLowerCase())?.value
        ),
      };
    }

    return {
      label: row.label,
      value: valueOrEmpty(row.callback ? row.callback(asset[row.name]) : String(asset[row.name] ?? '')),
    };
  });
};

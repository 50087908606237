import { TValveState } from '@marlin/asset/data-access/equipment';
import { ISettingsPoint } from '@marlin/asset/shared/equipment-config';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { useCallback, useContext, useMemo } from 'react';

import { useDatapointsContext } from '../../../../../shared/context/datapoints.context';
import { useDynamicSettingsConfig } from '../../dynamic-settings/use-dynamic-settings-config.hook';
import { ValveCommandModalBody } from '../valve-command-modal/modal-body.component';
import { ValveCommandModalFooter } from '../valve-command-modal/modal-footer.component';
import { ValveCommandModalTitle } from '../valve-command-modal/modal-title.component';

interface IHandleOpenModalProps {
  valveState: TValveState;
  handleSendCommand: () => Promise<void>;
  valveNames: string[];
}

interface IUseValveCommandModal {
  handleCloseModal: () => void;
  handleOpenModal: (props: IHandleOpenModalProps) => void;
}

const waterOnDatapoints = (datapoints: TDatapointWithUiMetadata[], settings: ISettingsPoint[]) => {
  const ldsIds: string[] = settings
    .map((setting) => setting.name.match(/^LDS_(?<id>\d+)_\w*$/i))
    .map((match) => match?.groups?.id)
    .filter((match) => match !== undefined) as string[];

  return datapoints.filter(
    (datapoint) => !!ldsIds.find((id) => datapoint.name.includes(id)) && datapoint.name.includes('WaterOn')
  );
};

export const useValveCommandModal = (): IUseValveCommandModal => {
  const { modalDispatch } = useContext(ModalContext);
  const { datapoints } = useDatapointsContext();
  const { settings } = useDynamicSettingsConfig();

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleOpenModal = useCallback(
    ({ valveState, valveNames, handleSendCommand }: IHandleOpenModalProps) => {
      const confirmCommand =
        waterOnDatapoints(
          datapoints,
          settings?.dynamicSettingsSections.flatMap((section) => section.settings) ?? []
        )?.some(
          (d) => (valveState === 'Open' && d.value === 'True') || (valveState === 'Closed' && d.value === 'False')
        ) ?? undefined;
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <ValveCommandModalTitle valveState={valveState} />,
          body: <ValveCommandModalBody valveState={valveState} valveNames={valveNames} confirmation={confirmCommand} />,
          footer: <ValveCommandModalFooter handleCloseModal={handleCloseModal} handleSendCommand={handleSendCommand} />,
        },
      });
      return;
    },
    [datapoints, settings?.dynamicSettingsSections, modalDispatch, handleCloseModal]
  );

  return useMemo(
    (): IUseValveCommandModal => ({ handleCloseModal, handleOpenModal }),
    [handleCloseModal, handleOpenModal]
  );
};

import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useCallback } from 'react';

import { content } from './content';

interface IDatapointsProps {
  data: TDatapointsResponse;
}

interface IHandleErrorDatapointsProps {
  data: TDatapointsResponse;
  valveName: string;
  valveState: 'Open' | 'Closed';
}

const valveMovingDatapoints = ({ data }: IDatapointsProps) => {
  return data.datapoints.filter((datapoint) => datapoint.name.includes('ValveMoving'));
};

const waterOnDatapoints = ({ data }: IDatapointsProps) => {
  return data.datapoints.filter((datapoint) => datapoint.name.includes('WaterOn'));
};

export const useErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleValveOpenError = useCallback(
    ({ valveState, valveName, data }: IHandleErrorDatapointsProps) => {
      if (
        waterOnDatapoints({ data })?.some((d) => d.value === 'False') &&
        valveMovingDatapoints({ data })?.some((d) => d.value === 'False') &&
        valveState === 'Open'
      ) {
        enqueueSnackbar(content.FAILED_TO_OPEN_VALVE(valveName), {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [enqueueSnackbar]
  );

  const handleValveCloseError = useCallback(
    ({ valveState, valveName, data }: IHandleErrorDatapointsProps) => {
      if (
        waterOnDatapoints({ data })?.some((d) => d.value === 'True') &&
        valveMovingDatapoints({ data })?.some((d) => d.value === 'False') &&
        valveState === 'Closed'
      ) {
        enqueueSnackbar(content.FAILED_TO_CLOSE_VALVE(valveName), {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [enqueueSnackbar]
  );

  return {
    handleValveCloseError,
    handleValveOpenError,
  };
};

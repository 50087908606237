import { TCriticality } from '@marlin/shared/ui-criticality';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import {
  ALERT_CAUSE,
  DEVICE_TYPE,
  ERROR_TYPES,
  getDeviceTypeText,
  getTitleErrorText,
} from '@marlin/shared/utils/format-alert';

import { content } from './content';

export type TTopOpenAlert = {
  alertCause?: ALERT_CAUSE;
  criticality: TCriticality;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  startTime: string;
  thresholdMax?: number | null;
  thresholdMin?: number | null;
  title?: string | null;
  uoM: TUnitOfMeasure | null;
  value?: string;
};

export const getTopAlertTitle = (
  alert: TTopOpenAlert,
  alertErrorType: ERROR_TYPES,
  alertDeviceType: DEVICE_TYPE,
  isEquipment = false,
  dashboard = false
): string => {
  if (alert.title && isEquipment) {
    return dashboard ? `${alert.deviceName} - ${alert.title}` : alert.title;
  }

  if (alertErrorType === ERROR_TYPES.LOW_BATTERY) {
    return `${alert.deviceName} ${content.LOW_BATTERY}`;
  }
  if (alertErrorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return `${alert.deviceName} ${content.LOST_COMMUNICATION}`;
  }

  if (alertDeviceType === DEVICE_TYPE.LEAK) {
    return `${alert.deviceName} ${content.LEAK}`;
  }

  const errorText = getTitleErrorText(
    alertErrorType,
    alert.uoM,
    alert.thresholdMin ?? undefined,
    alert.thresholdMax ?? undefined
  );
  const deviceTypeText = getDeviceTypeText(alertDeviceType);

  return `${alert.deviceName} ${deviceTypeText} ${errorText}`;
};

const extractDatapointName = (input: string, prefix: string): string | null => {
  const regex = new RegExp(`(${prefix})_(\\d+)_`);
  const match = input.match(regex);
  if (match) {
    return `${match[1]} ${match[2]}`;
  }
  return null;
};

export const getTopAlertDatapointTitle = (alert: TTopOpenAlert): string => {
  const alertTitle = alert.title ?? '';

  if (alertTitle.includes('CommunicationStatusActive') && alertTitle.includes('POLD_')) {
    return `${extractDatapointName(alertTitle, 'POLD')} ${content.LOST_COMMUNICATION}`;
  }

  if (alertTitle.includes('CommunicationStatusActive') && alertTitle.includes('LDS_')) {
    return `${extractDatapointName(alertTitle, 'LDS')} ${content.LOST_COMMUNICATION}`;
  }

  const alertMappings: { [key: string]: string | ((title: string) => string) } = {
    InCommunicationAlert: `${alert.deviceName} ${content.LOST_COMMUNICATION}`,
    InPoldAlert: `${alert.deviceName} ${content.LEAK}`,
    InLdsAlert: `${alert.deviceName} ${content.LEAK}`,
    InTemperatureAlert: `${alert.deviceName} ${content.AIR_TEMPERATURE}`,
    InRelativeHumidityAlert: `${alert.deviceName} ${content.AIR_HUMIDITY}`,
    InPoldAlarm: `${alert.deviceName} ${content.DETECTED_LEAK_CLOSING_VALVE}`,
    InLdsAlarm: `${alert.deviceName} ${content.DETECTED_LEAK_CLOSING_VALVE}`,
    InTemperatureAlarm: `${alert.deviceName} ${content.AIR_TEMPERATURE_CLOSING_VALVE}`,
    InRelativeHumidityAlarm: `${alert.deviceName} ${content.AIR_HUMIDITY_CLOSING_VALVE}`,
  };

  for (const key in alertMappings) {
    if (alertTitle.includes(key)) {
      const value = alertMappings[key];
      return typeof value === 'function' ? value(alertTitle) : value;
    }
  }

  return `${content.NOT_SUPPORTED_DATAPOINT_ALERT} ${alert.deviceName}`;
};

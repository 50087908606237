import {
  IconEquipment as SharedIconEquipment,
  IconEquipmentMode as SharedIconEquipmentMode,
  IconSensor as SharedIconSensor,
  IconUoM as SharedIconUoM,
} from './icons';
import { IIconEquipment, IIconEquipmentMode, IIconSensor, IIconUoM, TIconEquipment, TNativeIconProps } from './types';

export const IconEquipment = SharedIconEquipment as IIconEquipment;
export const IconSensor = SharedIconSensor as IIconSensor;
export const IconEquipmentMode = SharedIconEquipmentMode as IIconEquipmentMode;
export const IconUoM = SharedIconUoM as IIconUoM;
export type { TIconEquipment, TNativeIconProps };

import {
  TDeviceChildrenDatapoint,
  TDeviceChildrenDatapointsParams,
  TTotalItems,
} from '@marlin/asset/data-access/device';
import { IHookBaseList } from '@marlin/marlin-table';
import { GridColDef } from '@mui/x-data-grid';
import { ReactNode } from 'react';

export const sentinelSystemEquipment = ['LDSValve', 'POLD'] as const;

export type TSentinelSystemEquipment = (typeof sentinelSystemEquipment)[number];

export const isSentinelSystemEquipmentType = (value: unknown): value is TSentinelSystemEquipment => {
  return sentinelSystemEquipment.includes(value as TSentinelSystemEquipment);
};

export type TSystemEquipmentData = TDeviceChildrenDatapoint;

export type TUseSystemEquipmentData = IHookBaseList<TSystemEquipmentData, TTotalItems>;
export type TUserSystemEquipmentHook = (params: TDeviceChildrenDatapointsParams) => TUseSystemEquipmentData;

interface IBaseColumnsProps {
  columnProps: Partial<GridColDef>;
  rows: TSystemEquipmentData[];
}
export type TCreateColumns = (params?: IBaseColumnsProps) => GridColDef[];

export interface ISystemEquipmentTab {
  name: string;
  id: TSentinelSystemEquipment;
  useDataHook: TUserSystemEquipmentHook;
  useColumns: () => TCreateColumns;
}

export type TCardRow = Record<string, string | null>;

export interface ICardDatapointConfig {
  field: string;
  headerName: string;
  renderValue?: (field: string, row: TCardRow) => ReactNode;
}

export interface ICardConfig {
  columns: ICardDatapointConfig[];
  footer: ICardDatapointConfig[];
}

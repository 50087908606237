import { TUnitOfMeasure } from '../zod/uom.schema';

export const datapointToUom = (
  datapointName?: string | null,
  fallbackUoM?: TUnitOfMeasure | null
): TUnitOfMeasure | null => {
  if (!datapointName) {
    return fallbackUoM ?? null;
  }

  const datapoint = datapointName.toLowerCase();

  switch (datapoint) {
    case 'leak':
    case 'inldsalarm':
    case 'inpoldalert':
    case 'inflowtrip':
    case datapoint.match(/^LDS_(?<id>\d+)_InAlarm$/i)?.input:
    case datapoint.match(/^LDS_(?<id>\d+)_InFlowTrip$/i)?.input:
    case datapoint.match(/^API_(?<id>\d+)_InPoldAlert$/i)?.input:
    case datapoint.match(/^POLD_(?<id>\d+)_InPoldAlert$/i)?.input: {
      return 'WaterDetect';
    }
    case 'wateron':
    case datapoint.match(/^LDS_(?<id>\d+)_WaterOn$/i)?.input: {
      return 'ValvePosition';
    }
    default: {
      return fallbackUoM ?? null;
    }
  }
};

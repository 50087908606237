import { TBaseCondition, TBooleanCondition, TNumberCondition } from '@marlin/alert/data-access/automated-action';
import { parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { datapointsLabelsMap } from '@marlin/shared/utils/datapoint-mappers';
import { Link } from '@mui/material';

import { content } from '../content';

export const formatBooleanCondition = (condition: TBooleanCondition, editModeToggle: () => void) => {
  switch (condition.uom) {
    case 'WaterDetect': {
      return (
        <>
          {content.VIEW_MODE.HAS}
          <Link onClick={editModeToggle}>{content.VIEW_MODE.LEAK_DETECTED}</Link>
        </>
      );
    }
    default: {
      return '';
    }
  }
};

const formatDeadband = (condition: TBaseCondition, editModeToggle: () => void) => {
  if (!condition.deadband) {
    return '';
  }

  return (
    <>
      {content.VIEW_MODE.DEADBAND}
      <Link onClick={editModeToggle}>
        {parseDisplayedValue(condition.deadband.toString(), condition.uom ?? '', undefined, 'decimal')}
      </Link>
    </>
  );
};

const formatNumberCondition = (
  condition: TBaseCondition,
  operator: 'lt' | 'gt',
  formattedDatapoint: string,
  editModeToggle: () => void
) => {
  const formattedOperator = operator === 'lt' ? content.VIEW_MODE.LOWER : content.VIEW_MODE.HIGHER;

  return (
    <>
      {content.VIEW_MODE.HAS} {formattedDatapoint} {formattedOperator}{' '}
      <Link onClick={editModeToggle}>
        {parseDisplayedValue(condition.threshold.toString(), condition.uom ?? '', undefined, 'decimal')}
      </Link>
      {formatDeadband(condition, editModeToggle)}
    </>
  );
};

export const formatNumberConditions = (condition: TNumberCondition, datapoint: string, editModeToggle: () => void) => {
  const formattedDatapoint = datapointsLabelsMap.get(datapoint.toLowerCase())?.toLowerCase() ?? '';
  const lowerCondition = condition.rules.lt
    ? formatNumberCondition(condition.rules.lt, 'lt', formattedDatapoint, editModeToggle)
    : null;
  const higherCondition = condition.rules.gt
    ? formatNumberCondition(condition.rules.gt, 'gt', formattedDatapoint, editModeToggle)
    : null;
  if ([lowerCondition, higherCondition].filter(Boolean).length === 2) {
    return (
      <>
        {lowerCondition} {content.VIEW_MODE.OR} {higherCondition}
      </>
    );
  }
  return lowerCondition ?? higherCondition ?? '';
};

import { IChartThresholdSeries, plotlyThresholdAdapterSchema } from '@marlin/shared/utils-chart';
import { parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { Annotations, Axis, Datum, PlotData } from 'plotly.js';
import { useMemo } from 'react';

export const useThresholdData = ({
  thresholdData,
  yAxisRange,
}: {
  thresholdData?: IChartThresholdSeries[];
  yAxisRange?: Axis['range'];
}): {
  data: Partial<PlotData>[];
  annotations: Partial<Annotations>[];
} => {
  const thresholdDataParsed = useMemo(() => plotlyThresholdAdapterSchema.parse(thresholdData ?? []), [thresholdData]);

  const mappedData = useMemo<{ data: Partial<PlotData>[]; annotations: Partial<Annotations>[] }[]>(() => {
    const min = yAxisRange?.[0] ?? 0;
    const max = yAxisRange?.[1] ?? 0;

    return thresholdDataParsed
      .map((initialSeries) => {
        const { splittedSeries, markerSeries } = splitSeries(initialSeries);

        const threshold = thresholdData?.find((s) => s.name === initialSeries?.name);
        const annotationsForSeries = getAnnotations(markerSeries, threshold);

        if (splittedSeries?.length && markerSeries) {
          return {
            data: [...getMergedData(splittedSeries, thresholdData, min, max), markerSeries],
            annotations: annotationsForSeries,
          };
        }

        return {
          data: [initialSeries],
          annotations: annotationsForSeries,
        };
      })
      .flat();
  }, [thresholdData, thresholdDataParsed, yAxisRange]);

  const data = useMemo(() => mappedData.flatMap((item) => item.data), [mappedData]);
  const annotations = useMemo(() => mappedData.flatMap((item) => item.annotations), [mappedData]);

  return {
    data,
    annotations,
  };
};

const splitSeries = (
  series: Partial<PlotData>
): {
  splittedSeries: Array<Partial<PlotData>>;
  markerSeries: Partial<PlotData> | undefined;
} => {
  const yValues = series.y;
  const xValues = series.x;
  let currentyValues: PlotData['y'] = [];
  let currentxValues: PlotData['x'] = [];
  const markerSeriesX: PlotData['x'] = [];
  const markerSeriesY: PlotData['y'] = [];
  let shouldAddMarker = true;
  const result: Array<Partial<PlotData>> = [];

  if (!yValues?.length || !xValues?.length) {
    return { splittedSeries: [], markerSeries: undefined };
  }

  for (let i = 0; i < yValues.length; i++) {
    const yValue = yValues[i] as Datum & Datum[];
    const xValue = xValues[i] as Datum & Datum[];
    if (yValue !== null && xValue !== null) {
      currentyValues.push(yValue);
      currentxValues.push(xValue);
      const isNextValueEmpty = yValues[i + 1] === null;
      if (shouldAddMarker || isNextValueEmpty) {
        markerSeriesX.push(xValue);
        markerSeriesY.push(yValue);
        shouldAddMarker = false;
      }
    } else if (currentyValues.length) {
      result.push({ ...series, y: currentyValues, x: currentxValues });
      shouldAddMarker = true;
      currentyValues = [];
      currentxValues = [];
    }

    if (i === yValues.length - 1 && yValue !== null) {
      markerSeriesX.push(xValue);
      markerSeriesY.push(yValue);
    }
  }

  if (currentyValues.length) {
    result.push({ ...series, y: currentyValues, x: currentxValues });
  }

  return {
    splittedSeries: result.filter(isPlotData),
    markerSeries: getMarkerSeries(markerSeriesX, markerSeriesY, series),
  };
};

const cloneSeries = (
  thresholdData: IChartThresholdSeries[] | undefined,
  series: Partial<PlotData> | Datum | Datum[],
  min: number,
  max: number
): Partial<PlotData> | undefined => {
  if (Array.isArray(series) || series instanceof Date || typeof series !== 'object') return undefined;

  const position = thresholdData?.find((s) => s.name === series?.name)?.position ?? 'top';
  const clonedY = (Array.isArray(series?.y) ? series?.y?.map((y) => getClonedY(y, position, min, max)) : []) as
    | Datum[]
    | Datum[][];
  return {
    ...series,
    y: clonedY,
    line: { color: 'transparent' },
    marker: { ...series?.marker, color: 'transparent' },
    fill: undefined,
  };
};

const getClonedY = (y: Datum | Datum[], position: 'top' | 'bottom', min: number, max: number): Datum | Datum[] => {
  if (Array.isArray(y)) return [];
  if (y !== 0 && !y) return null;
  return position === 'top' ? max : min;
};

const getMarkerSeries = (
  seriesX: PlotData['x'],
  seriesY: PlotData['y'],
  series: Partial<PlotData>
): Partial<PlotData> => {
  const color = (Array.isArray(series?.marker?.color) ? series?.marker?.color[0] : series?.marker?.color) ?? undefined;
  return {
    x: seriesX,
    y: seriesY,
    customdata: series.customdata,
    type: 'scatter',
    line: { color: 'transparent' },
    hoverinfo: 'none',
    marker: {
      size: 20,
      color,
      line: {
        color: '#FFF',
        width: 2,
      },
    },
  };
};

const getAnnotations = (
  series: Partial<PlotData> | undefined,
  threshold: IChartThresholdSeries | undefined
): Array<Partial<Annotations>> => {
  if (!series) return [];
  const yValues = Array.isArray(series.y) ? series.y : [];
  const xValues = Array.isArray(series.x) ? series.x : [];

  return yValues.map((yValue, index) => ({
    x: String(xValues.find((_, i) => i === index)),
    y: String(yValue),

    xref: 'x' as const,
    yref: 'y' as const,
    text: threshold?.alias ?? '',
    showarrow: false,
    font: {
      color: '#FFF',
    },
    captureevents: true,
    hovertext: `${threshold?.label ?? ''} ${parseDisplayedValue(String(yValue), threshold?.uom ?? null)}`,
  }));
};

const getMergedData = (
  splittedSeries: Partial<PlotData>[],
  thresholdData: IChartThresholdSeries[] | undefined,
  min: number,
  max: number
): Partial<PlotData>[] => {
  return splittedSeries
    .map((series) => {
      const clonedSeries = cloneSeries(thresholdData, series, min, max);
      if (clonedSeries && isPlotData(series)) {
        return [clonedSeries, series];
      }

      return [series];
    })
    .flat();
};

const isPlotData = (series: Partial<PlotData> | Datum | Datum[]): series is Partial<PlotData> => {
  return Boolean(series && typeof series === 'object' && !(series instanceof Date));
};

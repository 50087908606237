export const content = {
  USER_TABLE_NO_DATA: 'No results found.',
  USER_TABLE_ERROR: 'Something went wrong. Please try again later.',

  USER_TABLE_HEADER_NAME: 'Name',
  USER_TABLE_HEADER_EMAIL: 'Email Address',
  USER_TABLE_HEADER_PHONE: 'Phone Number',

  CONFIRM_DELETE_RECIPIENT_TITLE: 'Delete recipient?',
  CONFIRM_DELETE_RECIPIENT_TEXT:
    'Are you sure you want to remove this Alert Recipient? They will be removed from all automations as a recipient.',
  CONFIRM_DELETE_RECIPIENT_CANCEL_BUTTON: 'Cancel',
  CONFIRM_DELETE_RECIPIENT_DELETE_BUTTON: 'Delete',
  EMPTY_LIST: 'No results found.',
};

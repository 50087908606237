import { IconSensor } from '@marlin/asset/shared/ui/assets';

import { content } from '../content';
import { DEVICE_TYPE } from '../types';
import { IFilterDevice } from './types';

export const deviceTypeOptions: IFilterDevice[] = [
  {
    id: DEVICE_TYPE.TEMPERATURE,
    name: content.FILTERS.SENSOR_TYPES.TEMPERATURE,
    Icon: IconSensor.Temperature,
  },
  {
    id: DEVICE_TYPE.PRESSURE,
    name: content.FILTERS.SENSOR_TYPES.PRESSURE,
    Icon: IconSensor.Pressure,
  },
  {
    id: DEVICE_TYPE.LEAK,
    name: content.FILTERS.SENSOR_TYPES.LEAK,
    Icon: IconSensor.LeakDetector,
  },
  {
    id: DEVICE_TYPE.PULSE_METER,
    name: content.FILTERS.SENSOR_TYPES.FLOW_METER,
    Icon: IconSensor.FlowMeter,
  },
];

export const equipmentTypeOptions: IFilterDevice[] = [
  {
    id: DEVICE_TYPE.EQUIPMENT,
    name: content.FILTERS.EQUIPMENT,
  },
];

export const gatewayTypeOptions: IFilterDevice[] = [
  {
    id: DEVICE_TYPE.GATEWAY,
    name: content.FILTERS.GATEWAY,
  },
];

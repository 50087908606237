import { TEquipmentSchema, queryKey, useFilteredEquipmentsWithLoadMore } from '@marlin/asset/data-access/equipment';
import { statusDatapoints } from '@marlin/asset/shared/ui/equipment-list';
import { IBaseFilters } from '@marlin/asset/shared/ui/mobile-filter-modal';
import { IEquipmentTypeOption } from '@marlin/shared/ui-device-type';
import { queryClient } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { TEquipmentSort } from '../../rwd/sorting';

const defaultParams = {
  pageSize: 10,
  datapoints: statusDatapoints,
};

export interface IOption {
  id: string;
  name: string;
}

export interface IEquipmentsHubFilters extends IBaseFilters<TEquipmentSort> {
  locations: IOption[];
  equipmentType: IEquipmentTypeOption[];
}

export const useEquipmentsHub = (filters: IEquipmentsHubFilters, enabled?: boolean) => {
  const params = useMemo(
    () => ({
      params: {
        ...defaultParams,
        ...filters,
        locations: filters.locations.map(({ id }) => id),
        equipmentType: filters.equipmentType.map(({ id }) => id),
      },
      enabled,
    }),
    [enabled, filters]
  );
  const equipmentsQuery = useFilteredEquipmentsWithLoadMore(params);

  const rows: TEquipmentSchema[] = useMemo(() => {
    const unfilteredRows: (TEquipmentSchema | null)[] = equipmentsQuery.data?.pages?.flatMap((i) => i.data) || [];
    return unfilteredRows.filter((i: TEquipmentSchema | null): i is TEquipmentSchema => i !== null);
  }, [equipmentsQuery.data?.pages]);

  return {
    rows,
    totalItems: equipmentsQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: equipmentsQuery.hasNextPage,
    fetchNextPage: equipmentsQuery.fetchNextPage,
    refetch: equipmentsQuery.refetch,
    isLoading: equipmentsQuery.isLoading,
    isError: equipmentsQuery.isError,
    error: equipmentsQuery.error,
  };
};

export const invalidateEquipmentsQuery = () => {
  queryClient.resetQueries({ queryKey: queryKey.EQUIPMENTS() });
};

import { TFunctionsMappingCallback } from '@marlin/asset/shared/equipment-config';

export const aggregationStatusFunction: TFunctionsMappingCallback = (configDatapoint, datapointsFromDeps) => {
  const activeCount = datapointsFromDeps.filter((dp) => !!parseInt(dp?.value)).length;
  return {
    ...configDatapoint,
    value: activeCount.toString(),
    displayedValue: activeCount.toString(),
    controlState: activeCount > 0 ? 'active' : 'inactive',
    unitOfMeasure: null,
    controlType: 'value',
    lastReadingTime: configDatapoint.lastReadingTime ?? new Date().toISOString(),
    label: configDatapoint.label ?? '',
    name: configDatapoint.name,
  };
};

import { TUserProfileForm, useGetProfile, useUpdateProfile } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { PageContainer, Paper, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button } from '@mui/material';
import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../shared/translation-keys';
import { UserProfileUpdateForm } from './user-profile-update-form/user-profile-update-form.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(712),
    margin: theme.typography.pxToRem(0),
    marginTop: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      margin: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(8),
    },
  },
}));

export function UpdateProfile() {
  const { classes } = useStyles();
  const { data, isLoading, isError } = useGetProfile();
  const { status, mutateAsync } = useUpdateProfile();
  const { enqueueSnackbar } = useSnackbar();
  const { modalDispatch } = useContext(ModalContext);

  const handleCancel = () => {
    enqueueSnackbar(content.DISCARD_MESSAGE, {
      variant: 'success',
      preventDuplicate: true,
    });
  };

  const displaySuccessNotification = () =>
    enqueueSnackbar(content.SUCCESS_MESSAGE, {
      variant: 'success',
      preventDuplicate: true,
    });

  const handleSubmit = async (formData: TUserProfileForm) => {
    const hasCodeBeenChanged = formData.deviceAccessCode !== data?.deviceAccessCode;

    try {
      await mutateAsync(formData);

      if (hasCodeBeenChanged) {
        modalDispatch({
          type: MODAL_ACTION_TYPE.SHOW,
          payload: {
            title: <>{content.ACCESS_CODE_MODAL_TITLE}</>,
            body: <>{content.ACCESS_CODE_MODAL_BODY}</>,
            footer: (
              <Button
                variant="contained"
                onClick={() => {
                  modalDispatch({
                    type: MODAL_ACTION_TYPE.DISMISS,
                  });
                  displaySuccessNotification();
                }}
              >
                {content.BUTTONS.CLOSE}
              </Button>
            ),
          },
        });
      } else {
        displaySuccessNotification();
      }
    } catch (error) {
      enqueueSnackbar(content.ERROR_DEFAULT, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  if (isError) {
    return <SystemConstraintsPage onlyContent />;
  }

  if (isLoading) {
    return <DataLoader />;
  }

  switch (status) {
    case 'pending': {
      return <DataLoader />;
    }
  }

  return (
    <PageContainer prefix="update-user-profile" className={classes.container}>
      <Paper data-testid="update-user-profile">
        <UserProfileUpdateForm user={data} onSubmit={handleSubmit} onCancel={handleCancel} />
      </Paper>
    </PageContainer>
  );
}

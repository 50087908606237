import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  collapse: {
    marginTop: theme.typography.pxToRem(-34),
  },

  deadbandRow: {
    paddingLeft: theme.typography.pxToRem(20),
    marginLeft: theme.typography.pxToRem(10),
    borderLeft: `1px solid ${theme.palette.divider}`,
    marginTop: theme.typography.pxToRem(-34),
    paddingTop: theme.typography.pxToRem(34),
  },
}));

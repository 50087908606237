import { TUserSchema, TUsersSchema, useUsersWithLoadMore } from '@marlin/account-data-access-user';
import { useMemo } from 'react';

interface IInfiniteUserListProps {
  search: string;
}

export const useInfiniteUserList = ({ search }: IInfiniteUserListProps) => {
  const usersQuery = useUsersWithLoadMore({ params: { search, pageSize: 5 } });

  const rows: TUserSchema[] = useMemo(() => {
    const unfilteredRows: (TUserSchema | null)[] =
      usersQuery.data?.pages?.flatMap((i: TUsersSchema) => i.data || []) || [];
    return unfilteredRows.filter((i: TUserSchema | null): i is TUserSchema => i !== null);
  }, [usersQuery.data?.pages]);

  return {
    rows,
    totalItems: usersQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: usersQuery.hasNextPage,
    fetchNextPage: usersQuery.fetchNextPage,
    refetch: usersQuery.refetch,
    isLoading: usersQuery.isLoading,
    isFetching: usersQuery.isFetching,
    isFetchingNextPage: usersQuery.isFetchingNextPage,
    isError: usersQuery.isError,
    isFetched: usersQuery.isFetched,
    error: usersQuery.error,
  };
};

import { MarlinTheme } from '@marlin/shared/theme';
import { Message } from '@marlin/shared/ui-page';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const InAppNotificationMessage = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Message>{content.IN_APP_NOTIFICATION_MESSAGE}</Message>
    </div>
  );
};

import { useCallback, useState } from 'react';

export const usePagination = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const setPageSize = (size: number) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: size }));
  };

  const setPage = useCallback(
    (page: number) => {
      setPaginationModel((prev) => ({ ...prev, page }));
    },
    [setPaginationModel]
  );
  return { setPageSize, setPage, paginationModel };
};

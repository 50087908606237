import { useCurrentUser } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsIcon from '@mui/icons-material/Settings';
import { Accordion, AccordionSummary, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { TOptionPermisions, options } from './options';
import { SettingsPageContainer } from './settings-page-container';
import { SettingsPageHeader } from './settings-page-header';
import { useSettingsPermissions } from './use-settings-permissions.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  optionText: {
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(25.6),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
}));

export const SettingsOptions = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const { goTo } = useRouter();
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { data } = useCurrentUser();
  const isSupport = data?.currentOrganization.roles.find((role) => ['Support', 'BUCS', 'CSM'].includes(role));

  const { allowedToSettings, allowedToOrgPage, allowedToIntegrations } = useSettingsPermissions();

  const permissionsForTabs: TOptionPermisions = useMemo(() => {
    return {
      people: allowedToSettings,
      profile: !isSupport,
      appearance: true,
      orgSettings: allowedToOrgPage,
      integration: allowedToIntegrations,
    };
  }, [allowedToIntegrations, allowedToOrgPage, allowedToSettings, isSupport]);

  useEffect(() => {
    if (pathname === routes.settings.root && !isTablet) {
      goTo(routes.settings.people.url());
    }
  }, [pathname, isTablet, goTo]);

  return (
    <SettingsPageContainer>
      <SettingsPageHeader icon={<SettingsIcon />} title={content.SETTINGS_TITLE} prefix="settings-mobile-hub-header" />
      {Object.entries(options).map(([key, option]) =>
        permissionsForTabs[key] ? (
          <Accordion expanded={false} elevation={0}>
            <AccordionSummary expandIcon={<ArrowForwardIosIcon />} onClick={() => navigate(option.link)}>
              <Typography className={classes.optionText}>{option.title}</Typography>
            </AccordionSummary>
          </Accordion>
        ) : null
      )}
      <Divider />
    </SettingsPageContainer>
  );
};

import { TExtractFnReturnType, queryClient, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

import { getUsers } from '../infrastructure/users';
import { QUERY_KEY, queryKey } from '../query-key.enum';
import { InfiniteUserParamsSchema, TInfiniteUserParamsSchema, TUserParamsSchema } from '../user.model';

export const mapGetUserParams = (params: TUserParamsSchema): TUserParamsSchema => ({
  search: params.search?.trim(),
  page: params.page,
  pageSize: params.pageSize,
  sorting: params.sorting,
});

type TQueryFnType = typeof getUsers;

export const useUsers = (params: TUserParamsSchema) => {
  const queryParams = useMemo(() => mapGetUserParams(params), [params]);

  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.USER_LIST(queryParams),
    queryFn: () => getUsers(queryParams),
  });
};

type TUseInfiniteUsersProps = {
  params: TInfiniteUserParamsSchema;
  enabled?: boolean;
};

export const useUsersWithLoadMore = ({ params, enabled = true }: TUseInfiniteUsersProps) => {
  const queryParams = useMemo(() => safeParseData(params, InfiniteUserParamsSchema), [params]);

  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.USER_INFINITE_LIST(queryParams),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getUsers({ ...queryParams, page: (pageParam as number) || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};

export const invalidateUserList = async () => {
  await queryClient.invalidateQueries({
    queryKey: [QUERY_KEY.USER, queryKey.USER_LIST(), queryKey.USER_INFINITE_LIST()],
  });
};

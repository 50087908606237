import { TDeviceChildrenDatapointsParams, useDeviceChildrenDatapoints } from '@marlin/asset/data-access/device';
import { ISorting } from '@marlin/marlin-table';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { TUserSystemEquipmentHook } from '../types';

export const useSystemEquipmentDataHook: TUserSystemEquipmentHook = (filters) => {
  const [sortingParams, setSortingParams] = useState<ISorting | undefined>(undefined);

  const params: TDeviceChildrenDatapointsParams = useMemo(
    (): TDeviceChildrenDatapointsParams => ({
      ...filters,
      ...sortingParams,
    }),
    [filters, sortingParams]
  );

  const query = useDeviceChildrenDatapoints(params);

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel[0] || !sortModel[0].sort) {
      setSortingParams(undefined);
    } else {
      const sorting = {
        sortBy: sortModel[0]?.field === 'PoldInAlert' ? 'InPoldAlert' : sortModel[0]?.field,
        direction: mapSortDirection(sortModel[0]?.sort),
      };
      setSortingParams(sorting);
    }
  }, []);

  return {
    data: query.data?.data || [],
    pagination: query.data?.pagination || { page: filters.page, pageSize: filters.pageSize, totalItems: 0 },
    isLoading: query.isLoading,
    isError: query.isError,
    metadata: query.data?.totalItems,
    onSortChange,
  };
};

import { TDeviceSchema, TGetDeviceSchema, useFilteredDevicesWithLoadMore } from '@marlin/asset/data-access/device';
import { TEquipmentResponseSchema, useFilteredEquipmentsWithLoadMore } from '@marlin/asset/data-access/equipment';
import { EQUIPMENT_TYPE, TEquipment } from '@marlin/shared/utils/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useMemo } from 'react';

import { content } from '../../../../../../shared/content';
import { useListStyles } from '../../../../../hooks/use-list-styles.hook';
import { AssetListMobile } from './asset-list.mobile.component';

export const ConnectedDevicesMobile = ({
  manufacturerId,
  gatewayId,
}: {
  manufacturerId: string;
  gatewayId: string;
}) => {
  const { classes, cx } = useListStyles();

  const { data, hasNextPage, fetchNextPage, isLoading, isError } = useFilteredDevicesWithLoadMore({
    params: {
      parentManufacturerId: manufacturerId,
      deviceTypes: ['HUB'],
      pageSize: 5,
    },
  });
  const {
    data: leakData,
    hasNextPage: leakHasNextPage,
    fetchNextPage: leakFetchNextPage,
    isLoading: leakIsLoading,
    isError: leakIsError,
  } = useFilteredDevicesWithLoadMore({
    params: {
      parentManufacturerId: manufacturerId,
      deviceTypes: ['LEAK'],
      pageSize: 5,
    },
  });
  const {
    data: valveControllerData,
    hasNextPage: valveControllerHasNextPage,
    fetchNextPage: valveControllerFetchNextPage,
    isLoading: valveControllerIsLoading,
    isError: valveControllerIsError,
  } = useFilteredEquipmentsWithLoadMore({
    params: {
      gatewayIds: [gatewayId],
      equipmentType: [EQUIPMENT_TYPE.VALVE_CONTROLLER],
      pageSize: 5,
    },
  });

  const hubRows: TDeviceSchema[] = useMemo(() => {
    const unfilteredRows: (TDeviceSchema | null)[] = data?.pages?.flatMap((i: TGetDeviceSchema) => i.data) || [];
    return unfilteredRows.filter((i: TDeviceSchema | null): i is TDeviceSchema => i !== null);
  }, [data?.pages]);

  const sensorRows: TDeviceSchema[] = useMemo(() => {
    const unfilteredRows: (TDeviceSchema | null)[] = leakData?.pages?.flatMap((i: TGetDeviceSchema) => i.data) || [];
    return unfilteredRows.filter((i: TDeviceSchema | null): i is TDeviceSchema => i !== null);
  }, [leakData?.pages]);

  const valveControllerRows: TEquipment[] = useMemo(() => {
    const unfilteredRows: (TEquipment | null)[] =
      valveControllerData?.pages?.flatMap((i: TEquipmentResponseSchema) => i.data) || [];
    return unfilteredRows.filter((i: TEquipment | null): i is TEquipment => i !== null);
  }, [valveControllerData?.pages]);

  return (
    <>
      <Accordion className={cx(classes.accordion, classes.grid)} data-testid="gateway-hubs" elevation={0}>
        <AccordionSummary className={cx(classes.header)} expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" className={classes.tableTitle}>
            {content.HUBS_AND_SENSORS.CONNECTED_HUBS(data?.pages[0].pagination.totalItems ?? 0)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={cx(classes.paper, classes.dataGridWrapper, classes.hubsAndSensorsPaper)}>
          <AssetListMobile
            variant="hub"
            key="hub"
            assets={hubRows ?? []}
            isError={isError}
            gatewayId={gatewayId}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
            totalItems={data?.pages[0].pagination.totalItems}
            hasNextPage={hasNextPage}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion className={cx(classes.accordion, classes.grid)} data-testid="gateway-sensors" elevation={0}>
        <AccordionSummary className={cx(classes.header)} expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" className={classes.tableTitle}>
            {content.HUBS_AND_SENSORS.CONNECTED_SENSORS(leakData?.pages[0].pagination.totalItems ?? 0)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={cx(classes.paper, classes.dataGridWrapper, classes.hubsAndSensorsPaper)}>
          <AssetListMobile
            variant="sensor"
            key="sensor"
            isLoading={leakIsLoading}
            isError={leakIsError}
            gatewayId={gatewayId}
            assets={sensorRows}
            fetchNextPage={leakFetchNextPage}
            hasNextPage={leakHasNextPage}
            totalItems={leakData?.pages[0].pagination.totalItems ?? 0}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion className={cx(classes.accordion, classes.grid)} data-testid="gateway-valve-controllers" elevation={0}>
        <AccordionSummary className={cx(classes.header)} expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" className={classes.tableTitle}>
            {content.HUBS_AND_SENSORS.VALVE_CONTROLLERS(valveControllerData?.pages[0].pagination.totalItems ?? 0)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={cx(classes.paper, classes.dataGridWrapper, classes.hubsAndSensorsPaper)}>
          <AssetListMobile
            variant="valveController"
            key="valveController"
            isLoading={valveControllerIsLoading}
            isError={valveControllerIsError}
            gatewayId={gatewayId}
            assets={valveControllerRows}
            fetchNextPage={valveControllerFetchNextPage}
            hasNextPage={valveControllerHasNextPage}
            totalItems={valveControllerData?.pages[0].pagination.totalItems ?? 0}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

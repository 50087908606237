import { globalConfigAerco } from '@marlin/asset/shared/equipment-config';
import { MarlinTheme } from '@marlin/shared/theme';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { AERCO_UNIT_STATUS, TEquipment } from '@marlin/shared/utils/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../shared/content';
import { useEquipmentsTelemetryContext } from '../../../shared/context/equipments-telemetry.context';
import { usePlantConfigContext } from '../../../shared/context/plant-config.context';
import { PLANT_TYPE } from '../../../shared/types';
import { getManagerAddresses } from '../../../shared/utils/get-addresses';
import { getDatapoint } from '../../../shared/utils/get-datapoint';
import { getShouldDisplayDeviceTile } from '../../../shared/utils/get-should-display-device-tile';
import { getStatus } from '../../../shared/utils/get-status';
import { sortPlantEquipment } from '../../../shared/utils/sort-plant-equipment';
import { DeviceTile } from './device-tile.component';
import { PlantListHeader } from './plant-list-header.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  accordion: {
    boxShadow: 'none',
    '&:before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.primary,
    },
  },
  accordionDetails: {
    padding: 0,
  },
  accordionSummary: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  container: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.primary,
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      border: 'none',
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(16),
  },
  emptyContent: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    padding: theme.typography.pxToRem(24),
  },
}));

export const PlantList = ({ equipmentList, plantType }: { equipmentList: TEquipment[]; plantType: PLANT_TYPE }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStatusChips, setActiveStatusChips] = useState({ operational: false, offline: false });
  const interval = useRef<NodeJS.Timer | null>(null);
  const [currentTime, setCurrentTime] = useState(dateAdapter.date());
  const {
    config: { plantListTitle, backupManagerDatapointName, managerDatapointName },
  } = usePlantConfigContext();

  const toggleStatusChip = (status: 'operational' | 'offline') => {
    setActiveStatusChips((prev) => ({ ...prev, [status]: !prev[status] }));
  };

  const { equipmentsTelemetry } = useEquipmentsTelemetryContext();

  const { managerAddress, backupManagerAddress } = useMemo(() => {
    if (Object.keys(equipmentsTelemetry).length > 0) {
      const datapoints = Object.values(equipmentsTelemetry)
        .map((equipment) => equipment.datapoints)
        .flat();
      return getManagerAddresses(datapoints, managerDatapointName, backupManagerDatapointName);
    }

    if (equipmentList.length) {
      const datapoints = equipmentList.map((equipment) => equipment.devices?.[0]?.lastReadingValues).flat();
      return getManagerAddresses(datapoints, managerDatapointName, backupManagerDatapointName);
    }

    return { managerAddress: undefined, backupManagerAddress: undefined };
  }, [backupManagerDatapointName, equipmentList, equipmentsTelemetry, managerDatapointName]);

  const filteredList = useMemo(
    () =>
      equipmentList
        .reduce<(TEquipment & { status: AERCO_UNIT_STATUS })[]>((acc, equipment) => {
          const [{ manufacturerId = '', lastReadingValues = [], lastReadingTime = '' }] = equipment.devices || [];
          const data = equipmentsTelemetry[manufacturerId] ?? undefined;
          const unitStatus = getDatapoint('UnitStatusIdx', lastReadingValues, data);

          const status = getStatus(currentTime, unitStatus, lastReadingTime, data?.lastReadingTime);

          return getShouldDisplayDeviceTile(status, activeStatusChips) ? [...acc, { ...equipment, status }] : acc;
        }, [])
        .sort((a, b) => sortPlantEquipment(a, b, managerAddress, backupManagerAddress)),
    [equipmentList, equipmentsTelemetry, currentTime, activeStatusChips, managerAddress, backupManagerAddress]
  );

  useEffect(() => {
    interval.current = setInterval(() => {
      setCurrentTime(dateAdapter.date());
      // trigger checking status every 30sek
    }, globalConfigAerco.dashboard.liveDataExpirationSeconds * 250);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  return (
    <>
      <Accordion
        className={classes.accordion}
        defaultExpanded
        expanded={!isMobile ? true : undefined}
        data-testid="plant-equipment"
      >
        {isMobile && (
          <AccordionSummary className={classes.accordionSummary} expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
            {plantListTitle}
          </AccordionSummary>
        )}
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.container}>
            <PlantListHeader toggleStatusChip={toggleStatusChip} activeStatusChips={activeStatusChips} />
            <div className={classes.list}>
              {filteredList.length ? (
                filteredList.map((equipment) => (
                  <DeviceTile
                    key={equipment.id}
                    plantType={plantType}
                    backUpManagerAddress={backupManagerAddress}
                    managerAddress={managerAddress}
                    {...equipment}
                  />
                ))
              ) : (
                <Typography variant="body2" className={classes.emptyContent}>
                  {content.PLANT_LIST.EMPTY_LIST}
                </Typography>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {isMobile && <Divider />}
    </>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { EQUIPMENT_UPSERT_ERROR } from '@marlin/asset/data-access/equipment';
import {
  aercoDescriptionSteps,
  intellistationDescriptionSteps,
  tekmarSbcDescriptionSteps,
  tekmarShpDescriptionSteps,
  tekmarSscDescriptionSteps,
} from '@marlin/asset/shared/ui/assets';
import {
  AttachmentUpdateValidation,
  AttachmentValidation,
  getAttachments,
  useHasAttachments,
} from '@marlin/asset/shared/ui/attachment-validation';
import {
  EquipmentFormTypes,
  editEquipmentFormDataSchema,
  equipmentFormDataSchema,
} from '@marlin/asset/shared/utils/equipment-upsert';
import { EquipmentModelControl, TEquipmentFormTypes } from '@marlin/shared/ui-form';
import { FormField, InfiniteAutocompleteControl, Input, Select } from '@marlin/shared/ui-form-common';
import { StepperModal } from '@marlin/shared/ui-modal';
import { GridLayout, Paper } from '@marlin/shared/ui-page';
import { EquipmentTypeEnum, TEquipment, TUpsertEquipmentForm, UpsertEquipmentForm } from '@marlin/shared/utils/zod';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getEquipmentTypeByModel } from '../utils';
import { AercoForm } from './components/aerco-form.component';
import { BradleyPartitionForm } from './components/bradley-partition-form.component';
import { TekmarForm } from './components/intellistation-form.component';
import { OtherForm } from './components/other-form.component';
import { PviForm } from './components/pvi-form.component';
import { SentinelForm } from './components/sentinel-form.component';
import { ValveControllerForm } from './components/valve-controller-form.component';
import { content } from './content';
import { useStyles } from './upsert-equipment.styles';
import { useEquipmentTypeOptions } from './use-equipment-type-options.hook';
import { useUpsertEquipment } from './use-upsert.equipment-form.hook';

interface IUpsertEquipmentProps {
  defaultValues?: TEquipment;
  equipmentId?: string;
  disableSubmit: boolean;
  onSubmit: (value: TUpsertEquipmentForm) => void;
  onCancel: () => void;
  upsertEquipmentErrorCode?: EQUIPMENT_UPSERT_ERROR | undefined;
}

export const UpsertEquipment = ({
  defaultValues,
  equipmentId,
  disableSubmit,
  onSubmit,
  onCancel,
  upsertEquipmentErrorCode,
}: IUpsertEquipmentProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isEdit = useMemo(() => !!equipmentId, [equipmentId]);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState<{ isOpen: boolean; defaultStep?: number }>({
    isOpen: false,
  });

  const form = useForm<TUpsertEquipmentForm>({
    mode: 'onTouched',
    resolver: zodResolver(isEdit ? editEquipmentFormDataSchema : equipmentFormDataSchema),
    defaultValues: defaultValues
      ? UpsertEquipmentForm.transform((data) => {
          return {
            ...data,
            formType: getEquipmentTypeByModel(data.model),
          };
        }).parse(defaultValues)
      : undefined,
  });

  const {
    handleSubmit,
    watch,
    setError,
    reset,
    clearErrors,
    formState: { isDirty, isValid, errors },
  } = form;

  const formLocation = watch('location');
  const equipmentType = watch('formType');
  const type = watch('type');

  const locationChanged = useMemo(() => {
    return defaultValues?.location.id !== formLocation?.id;
  }, [formLocation?.id, defaultValues?.locationId]);

  const assetId = defaultValues?.devices?.[0]?.id ?? equipmentId;

  const { dateValidationErrorMessage, setDateValidationErrorMessage } = useUpsertEquipment({
    setError,
    upsertEquipmentErrorCode,
    equipmentType,
  });
  const openInstructionsModal = useCallback((defaultStep?: number) => {
    setIsInstructionsModalOpen({ isOpen: true, defaultStep });
  }, []);

  const closeInstructionsModal = useCallback(() => {
    setIsInstructionsModalOpen({ isOpen: false });
  }, []);

  const { data } = useAssetLinks({ assetId: assetId ?? '', enabled: locationChanged && isEdit });

  const shouldDisplayAttachmentValidation = useMemo(() => {
    return isEdit && data && locationChanged && !!formLocation?.id;
  }, [isEdit, data, locationChanged, formLocation?.id]);

  const attachmentsFound = useHasAttachments(data);
  const equipmentTypesOptions = useEquipmentTypeOptions();

  const isGateway = defaultValues?.parentDeviceType === 'Gateway';
  const shouldLocationBeDisabled =
    isEdit &&
    (defaultValues?.locationType === 'Plant' ||
      (isGateway && equipmentType !== EquipmentFormTypes.enum.valveController));

  const instructionsModalContent = useMemo(() => {
    switch (equipmentType) {
      case EquipmentFormTypes.enum.intellistation: {
        return {
          withStepCount: true,
          steps: intellistationDescriptionSteps,
        };
      }
      case EquipmentFormTypes.enum.aercoInnovation:
      case EquipmentFormTypes.enum.aercoBenchmark: {
        return {
          withStepCount: false,
          steps: aercoDescriptionSteps,
        };
      }
      case EquipmentFormTypes.enum.tekmarSmartSteam: {
        return {
          withStepCount: true,
          steps: tekmarSscDescriptionSteps,
        };
      }
      case EquipmentFormTypes.enum.tekmarSmartBoiler: {
        return {
          withStepCount: true,
          steps: tekmarSbcDescriptionSteps,
        };
      }
      case EquipmentFormTypes.enum.tekmarSmartHeatPump: {
        return {
          withStepCount: true,
          steps: tekmarShpDescriptionSteps,
        };
      }
      default: {
        return {
          withStepCount: undefined,
          steps: [],
        };
      }
    }
  }, [equipmentType]);

  const disableSubmitButton =
    disableSubmit ||
    !isDirty ||
    !isValid ||
    !!dateValidationErrorMessage ||
    !!Object.keys(errors).length ||
    !equipmentType;

  const getFormFields = () => {
    if (isGateway && type === EquipmentTypeEnum.enum.PARTITION_CONTROLLER) {
      return (
        <BradleyPartitionForm
          dateValidationErrorMessage={dateValidationErrorMessage}
          setDateValidationErrorMessage={setDateValidationErrorMessage}
        />
      );
    }

    if (isGateway && type === EquipmentTypeEnum.enum.HOT_WATER_HEATER) {
      return (
        <PviForm
          dateValidationErrorMessage={dateValidationErrorMessage}
          setDateValidationErrorMessage={setDateValidationErrorMessage}
        />
      );
    }

    switch (equipmentType) {
      case EquipmentFormTypes.enum.intellistation: {
        return (
          <TekmarForm
            isEdit={isEdit}
            modelVariant={'2'}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
            openInstructionsModal={openInstructionsModal}
          />
        );
      }
      case EquipmentFormTypes.enum.tekmarSmartSteam:
      case EquipmentFormTypes.enum.tekmarSmartBoiler:
      case EquipmentFormTypes.enum.tekmarSmartHeatPump: {
        return (
          <TekmarForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
            openInstructionsModal={openInstructionsModal}
          />
        );
      }
      case EquipmentFormTypes.enum.valveController: {
        return (
          <ValveControllerForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
            openInstructionsModal={openInstructionsModal}
          />
        );
      }

      case EquipmentFormTypes.enum.sentinel: {
        return (
          <SentinelForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
          />
        );
      }

      case EquipmentFormTypes.enum.other: {
        return (
          <OtherForm
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
          />
        );
      }

      case EquipmentFormTypes.enum.aercoBenchmark:
      case EquipmentFormTypes.enum.aercoInnovation: {
        return (
          <AercoForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
            key={equipmentType}
            openInstructionsModal={openInstructionsModal}
            upsertEquipmentErrorCode={upsertEquipmentErrorCode}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <GridLayout
      form={
        <Paper className={classes.formContainer}>
          <div className={classes.formSection}>{content.EQUIPMENT_LABEL}</div>
          <FormProvider {...form}>
            <FormField<TUpsertEquipmentForm> fieldName="name">
              {(props) => (
                <Input
                  {...props}
                  className={classes.input}
                  label={content.EQUIPMENT_NAME}
                  required
                  disabled={isGateway && EquipmentFormTypes.enum.valveController !== equipmentType}
                  testId="equipment-name-field"
                  error={
                    errors.name && {
                      type: 'custom',
                      message: errors.name.message,
                    }
                  }
                />
              )}
            </FormField>
            <div className={classes.fieldWrapper}>
              <LocationSearchWrapper enableSearch={false}>
                {(props) => (
                  <>
                    <InfiniteAutocompleteControl<TEquipment>
                      {...props}
                      fieldName="location"
                      testId="select-filters-location"
                      label={content.LOCATION_NAME}
                      className={classes.selectMargins}
                      disabled={shouldLocationBeDisabled}
                      multiple={false}
                      required
                    />
                    {shouldDisplayAttachmentValidation && (
                      <AttachmentValidation
                        assetId={assetId}
                        data={getAttachments(data)}
                        variant={attachmentsFound ? 'warning' : 'success'}
                      />
                    )}
                  </>
                )}
              </LocationSearchWrapper>
            </div>
            {!isEdit && (
              <div className={classes.equipmentTypeWrapper}>
                <EquipmentModelControl<TUpsertEquipmentForm>
                  fieldName="formType"
                  required
                  requiredErrorMessageOnBlur={content.REQUIRED}
                  onChange={(e) => {
                    reset(
                      {
                        name: watch('name'),
                        location: watch('location'),
                        formType: e.target.value as TEquipmentFormTypes,
                      },
                      { keepDirty: true, keepErrors: true }
                    );
                    clearErrors('registrationCode');
                    clearErrors('serialNumber');
                  }}
                />
              </div>
            )}
            {isEdit && (isGateway || defaultValues?.isRegistered) && (
              <FormField<TEquipment> fieldName="type">
                {(props) => (
                  <Select
                    {...props}
                    disabled
                    prefix="type"
                    emptyOption=" "
                    value={props.value}
                    label={content.EQUIPMENT_TYPE}
                    data={equipmentTypesOptions}
                    className={classes.input}
                  />
                )}
              </FormField>
            )}
            {getFormFields()}
            {isEdit && locationChanged && attachmentsFound && (
              <div className={classes.attachmentUpdateValidation}>
                <AttachmentUpdateValidation />
              </div>
            )}
            <StepperModal
              handleClose={closeInstructionsModal}
              {...isInstructionsModalOpen}
              {...instructionsModalContent}
            />
          </FormProvider>
        </Paper>
      }
      tags={null}
      buttons={
        <div className={classes.buttonsWrapper}>
          {!isMobile && (
            <Button
              data-testid="equipment-cancel-button"
              className={classes.button}
              variant="outlined"
              onClick={onCancel}
            >
              {content.CANCEL}
            </Button>
          )}
          <Button
            data-testid="equipment-submit-button"
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={disableSubmitButton}
          >
            {isEdit ? content.UPDATE : content.CREATE}
          </Button>
        </div>
      }
    />
  );
};

import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { PowerSource } from '@marlin/asset/shared/ui/battery';
import {
  DeleteDeviceModalBody,
  DeleteDeviceModalFooter,
  DeleteDeviceModalTitle,
} from '@marlin/asset/ui/delete-device-modal';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { ActionCopy, ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { SensorType } from '@marlin/shared/ui-device-type';
import { Link, PageContainer, Paper } from '@marlin/shared/ui-page';
import { defaultDateTime, defaultTime, formatDate } from '@marlin/shared/utils-common-date';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { DEVICE_DRAWER_ACTION_TYPE, useDeviceDrawerContext } from '@marlin/shared/utils/sensor-provider';
import { sensorModelsWithDetailsPage } from '@marlin/shared/utils/zod';
import { useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCallback, useContext, useMemo } from 'react';

import { content } from '../content';
import { LAST_READING_VALUE_NAME } from '../shared/constants';
import { useDeviceHubContextMenu } from '../shared/use-devices-hub-context-menu.hook';
import { Filters } from './components/filters.component';
import { DevicesHubHeader } from './components/header.component';
import { LastReadingCell } from './components/last-reading-cell.component';
import { useStyles } from './devices-hub.component.styles';
import { useDevicesHub } from './use-devices-hub.hook';

interface ICreateColumns {
  deleteDevice: (device: TDeviceSchema) => void;
  editDevice: (deviceId: string) => void;
  allowDelete: boolean;
  allowEdit: boolean;
  currentUrl: string;
  isTablet: boolean;
  cellClassName?: string;
  addSensorsFromContext: () => void;
}

const createColumns = ({
  allowDelete,
  deleteDevice,
  allowEdit,
  editDevice,
  currentUrl,
  isTablet,
  cellClassName,
  addSensorsFromContext,
}: ICreateColumns): GridColDef<TDeviceSchema>[] => [
  {
    field: 'name',
    headerName: content.SENSOR_HEADER_NAME,
    type: 'string',
    flex: 2,
    headerAlign: 'left',
    align: 'left',
    renderCell: (cellValues) => {
      const model = cellValues.row.model;

      if (sensorModelsWithDetailsPage.includes(model)) {
        return (
          <Tooltip text={cellValues.value} placement="top">
            <Link className={cellClassName} to={routes.sensors.details.url(cellValues.row.id)}>
              {cellValues.value}
            </Link>
          </Tooltip>
        );
      }

      return (
        <Tooltip text={cellValues.value} placement="top">
          <Link
            onClick={addSensorsFromContext}
            className={cellClassName}
            to={routes.deviceDrawer.open(currentUrl, cellValues.row.id)}
          >
            {cellValues.value}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'locationName',
    headerName: content.LOCATION_HEADER_NAME,
    type: 'string',
    flex: 2,
    headerAlign: 'left',
    align: 'left',
    renderCell: (cellValues) => {
      return (
        <Tooltip text={cellValues.value} placement="top">
          <Link className={cellClassName} to={routes.locations.details.url(cellValues.row.locationId || '')}>
            {cellValues.value}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'deviceType',
    headerName: content.TYPE_HEADER_NAME,
    type: 'string',
    flex: isTablet ? 1 : 2.3,
    headerAlign: 'left',
    align: 'left',
    renderCell: (cellValues) => {
      return (
        <SensorType
          className={cellClassName}
          deviceType={cellValues.value}
          meterType={cellValues.row.meterType}
          showName={!isTablet}
        />
      );
    },
  },
  {
    field: 'lastReading',
    headerName: content.LAST_READING_HEADER_NAME,
    type: 'string',
    flex: 1.5,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    renderCell: (cellValues) => {
      return <LastReadingCell row={cellValues.row} cellClassName={cellClassName} />;
    },
  },
  {
    field: 'lastReadingTime',
    headerName: content.LAST_READING_TIME_HEADER_NAME,
    type: 'string',
    flex: 1.5,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    renderCell: (cellValues) => {
      const defaultDatapoint = cellValues.row.lastReadingValues?.find((reading) => reading.isDefault);
      if (!defaultDatapoint) {
        return content.EMPTY_CELL;
      }
      const date = formatDate(defaultDatapoint.lastReadingTime, defaultDateTime);
      const time = formatDate(defaultDatapoint.lastReadingTime, defaultTime);

      return (
        <Tooltip text={date} placement="top">
          <span className={cellClassName}>{isTablet ? time : date}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'powerSource',
    headerName: content.POWER_SOURCE_HEADER_NAME,
    type: 'string',
    flex: 1.5,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    renderCell: (cellValues) => {
      //TODO: Adjust when battery level is available for TDG
      if (cellValues.row.brand === 'TheDetectionGroup') {
        return content.EMPTY_CELL;
      }

      const batteryReading = cellValues.row.lastReadingValues.find(
        (reading) => reading.name === LAST_READING_VALUE_NAME.BATTERY_LEVEL
      );

      if (batteryReading) {
        const batteryReadingValue = Number(batteryReading?.value);
        return <PowerSource batteryLevel={batteryReadingValue} />;
      }

      return content.EMPTY_CELL;
    },
  },
  {
    field: 'displayManufacturerId',
    headerName: content.MANUFACTURER_ID_HEADER_NAME,
    type: 'string',
    flex: 1.5,
    sortable: false,
    renderCell: (cellValues) => {
      if (cellValues.row.brand === 'TheDetectionGroup') {
        return cellValues.row.manufacturerId.split('-').pop();
      }
      return cellValues.row.manufacturerId;
    },
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    flex: 0.5,
    align: 'center',
    renderCell: (cellValues) => {
      return (
        <ContextMenu>
          {allowEdit && <ActionEdit onClick={() => editDevice(cellValues.row.id)} />}
          {allowDelete && <ActionDelete onClick={() => deleteDevice(cellValues.row as TDeviceSchema)} />}
          <ActionCopy textToCopy={cellValues.row.id} contentCopy={content.ACTION_COPY} />
        </ContextMenu>
      );
    },
  },
];

export const DesktopDevicesHub = () => {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => `${router.getUrl()}?${router.getParams().toString()}`, [router]);
  const {
    rows,
    isLoading,
    isError,
    pagination,
    changePage,
    changePageSize,
    refetch,
    onFiltersChange,
    onSortChange,
    clearAdditionalFilters,
  } = useDevicesHub();
  const { modalDispatch } = useContext(ModalContext);
  const { allowDelete, allowEdit, editDevice } = useDeviceHubContextMenu();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { deviceDrawerDispatch } = useDeviceDrawerContext();

  const deleteDevice = useCallback(
    (device: TDeviceSchema) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <DeleteDeviceModalTitle />,
          body: <DeleteDeviceModalBody deviceName={device.name} deviceId={device.id} />,
          footer: <DeleteDeviceModalFooter deviceId={device.id} refetch={refetch} />,
        },
      });
    },
    [modalDispatch, refetch]
  );

  const addSensorsFromContext = useCallback(() => {
    deviceDrawerDispatch({
      type: DEVICE_DRAWER_ACTION_TYPE.SET_SENSORS,
      payload: {
        rows,
      },
    });
  }, [rows, deviceDrawerDispatch]);

  const columns = useMemo(
    () =>
      createColumns({
        allowDelete,
        allowEdit,
        editDevice,
        currentUrl,
        deleteDevice,
        isTablet,
        addSensorsFromContext,
        cellClassName: classes.cellStyle,
      }),
    [allowDelete, addSensorsFromContext, allowEdit, editDevice, currentUrl, deleteDevice, isTablet, classes.cellStyle]
  );

  return (
    <PageContainer prefix="device-hub-page">
      <DevicesHubHeader />
      <div className={classes.wrapper}>
        <Paper className={classes.filterWrapper}>
          <Filters onFiltersChange={onFiltersChange} clearAdditionalFilters={clearAdditionalFilters} />
          <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
            <DataGrid
              localeText={{
                noRowsLabel: isError ? content.ERROR : content.NO_DATA,
              }}
              loading={isLoading}
              className={classes.dataGrid}
              rows={rows}
              columns={columns}
              rowsPerPageOptions={[5, 10, 50, 100]}
              columnVisibilityModel={{
                action: allowEdit || allowDelete,
                manufacturerId: !isTablet,
              }}
              page={pagination.page}
              pageSize={pagination.pageSize}
              rowCount={pagination.rowCount}
              onPageChange={changePage}
              onPageSizeChange={changePageSize}
              paginationMode="server"
              onSortModelChange={onSortChange}
              disableColumnMenu
            />
          </div>
        </Paper>
      </div>
    </PageContainer>
  );
};

import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';

const getPoldLostCommAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints
    .filter((item) => item.name.includes('CommunicationStatusActive') && item.value.toLowerCase() === 'false')
    .filter((item) => item.name.includes('POLD_'));
};

const getValveLostCommAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints
    .filter((item) => item.name.includes('CommunicationStatusActive') && item.value.toLowerCase() === 'false')
    .filter((item) => item.name.includes('LDS_'));
};

const getControllerLostCommAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InCommunicationAlert') && item.value.toLowerCase() === 'true');
};

const getPoldLeakAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InPoldAlert') && item.value.toLowerCase() === 'on');
};

const getLeakOnValveAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InLdsAlert') && item.value.toLowerCase() === 'on');
};

const getAirTemperaturePoldAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InTemperatureAlert') && item.value.toLowerCase() === 'on');
};

const getAirHumidityPoldAlerts = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter(
    (item) => item.name.includes('InRelativeHumidityAlert') && item.value.toLowerCase() === 'on'
  );
};

const getLeakOnPoldClosedValveAlarm = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InPoldAlarm') && item.value.toLowerCase() === 'on');
};

const getLeakOnValveClosedValveAlarm = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InLdsAlarm') && item.value.toLowerCase() === 'on');
};

const getAirTemperaturePoldClosedValveAlarm = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter((item) => item.name.includes('InTemperatureAlarm') && item.value.toLowerCase() === 'on');
};

const getAirHumidityPoldClosedValveAlarm = (datapoints: TDatapointWithUiMetadata[]) => {
  return datapoints.filter(
    (item) => item.name.includes('InRelativeHumidityAlarm') && item.value.toLowerCase() === 'on'
  );
};

export const arrayOfMappers = [
  getPoldLostCommAlerts,
  getValveLostCommAlerts,
  getControllerLostCommAlerts,
  getPoldLeakAlerts,
  getLeakOnValveAlerts,
  getAirTemperaturePoldAlerts,
  getAirHumidityPoldAlerts,
  getLeakOnPoldClosedValveAlarm,
  getLeakOnValveClosedValveAlarm,
  getAirTemperaturePoldClosedValveAlarm,
  getAirHumidityPoldClosedValveAlarm,
];

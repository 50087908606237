import { StatusBar, StatusLabel } from '@marlin/asset/shared/ui/equipment-list';
import { renderEllipsisCell } from '@marlin/data-grid';
import { Tooltip, renderEllipsisTooltip } from '@marlin/shared/ui-common-tooltip';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { getUomFromSettings } from '@marlin/shared/utils-format-reading';

import { useDatapointCell } from '../../hooks/use-datapoint-cell.hook';
import { TCreateColumns } from '../../types';
import { content } from '../content';

export const useCreateLdsValveColumns = () => {
  const { renderDatapointCell } = useDatapointCell();
  const createColumns: TCreateColumns = (columnProps?) => [
    {
      field: 'DeviceName',
      headerName: content.COLUMNS.LDS_VALVE.NAME,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: renderEllipsisCell,
    },
    {
      field: 'WaterOn',
      headerName: content.COLUMNS.LDS_VALVE.VALVE_STATE,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { WaterOn } = cellValues.row;
        return (
          <StatusBar
            isOperational={WaterOn === 'True'}
            labels={{
              operational: content.VALUES.VALVE_STATE.OPEN,
              notOperational: content.VALUES.VALVE_STATE.CLOSED,
            }}
            isValueStatus={true}
          />
        );
      },
    },
    {
      field: 'InFlowTrip',
      headerName: content.COLUMNS.LDS_VALVE.LEAK_STATUS,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { InFlowTrip } = cellValues.row;

        return <StatusLabel isOperational={InFlowTrip === 'False'}>{renderDatapointCell(cellValues)}</StatusLabel>;
      },
    },
    {
      field: 'FlowRate',
      headerName: content.COLUMNS.LDS_VALVE.FLOW_RATE,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: renderDatapointCell,
    },
    {
      field: 'Temperature',
      headerName: content.COLUMNS.LDS_VALVE.TEMPERATURE,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => renderDatapointCell(cellValues, getUomFromSettings('TEMPERATURE')),
    },
    {
      field: 'Pressure',
      headerName: content.COLUMNS.LDS_VALVE.PRESSURE,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => renderDatapointCell(cellValues, getUomFromSettings('PRESSURE')),
    },
    {
      field: 'InAlert',
      headerName: content.COLUMNS.LDS_VALVE.IN_ALERT,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { InAlert } = cellValues.row;
        const inAlert = InAlert === 'True';

        return (
          <StatusLabel isOperational={!inAlert}>
            {renderEllipsisCell({
              ...cellValues,
              value: inAlert ? content.VALUES.IN_ALERT.TRUE : content.VALUES.IN_ALERT.FALSE,
            })}
          </StatusLabel>
        );
      },
    },
    {
      field: 'LastReadingTime',
      headerName: content.COLUMNS.LDS_VALVE.LAST_READING_TIME,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const lastReadingTime = cellValues.value;
        return (
          <Tooltip
            placement="top"
            text={`${content.COLUMNS.LDS_VALVE.LAST_READING_TIME}: ${formatDate(lastReadingTime, defaultDateTime)}`}
          >
            <>{renderEllipsisTooltip(formatDate(lastReadingTime, defaultDateTime))}</>
          </Tooltip>
        );
      },
    },
    {
      field: 'CommunicationStatusActive',
      headerName: content.COLUMNS.LDS_VALVE.STATUS,
      flex: 2,
      disableColumnMenu: true,
      ...columnProps,
      renderCell: (cellValues) => {
        const { CommunicationStatusActive } = cellValues.row;
        return <StatusBar isOperational={CommunicationStatusActive === 'True'} tooltip={true} />;
      },
    },
  ];

  return createColumns;
};

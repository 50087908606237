export const emptyTrigger = {
  device: {
    id: '',
    name: '',
    manufacturerId: null,
    lastReadingValues: [],
  },
  datapoint: '',
  conditions: null,
};

import { PropsWithChildren } from 'react';

import { content } from '../../content/content';
import { useStyles } from './person-list-component.styles';

interface IPersonListProps extends PropsWithChildren {
  rowsCount: number;
}

export const PersonList = ({ children, rowsCount }: IPersonListProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      {!rowsCount ? <div className={classes.emptyList}>{content.EMPTY_LIST}</div> : children}
    </div>
  );
};

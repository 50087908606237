import { PropsWithChildren } from 'react';

import { content } from '../../content';
import { useStyles } from './recipient-list-component.styles';

interface IRecipientCardListProps extends PropsWithChildren {
  rowsCount: number;
}

export const RecipientListWrapper = ({ children, rowsCount }: IRecipientCardListProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      {!rowsCount ? <div className={classes.emptyList}>{content.EMPTY_LIST}</div> : children}
    </div>
  );
};

export const firmwareVersionCallback = (value: unknown) => {
  if (typeof value === 'string') {
    try {
      const configObject = JSON.parse(value);
      return configObject?.Firmware?.Version;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

import { TAutomationBuilder, TNumberCondition } from '@marlin/alert/data-access/automated-action';
import { roundValue } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { FieldPath, useController } from 'react-hook-form';

type TRules = NonNullable<TNumberCondition['rules']>;

interface IConditionFieldProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
  operator: 'lt' | 'gt';
}

export const useConditionField = ({ datapoint, triggerIndex, operator }: IConditionFieldProps) => {
  const { field, fieldState } = useController<TAutomationBuilder>({
    name: `triggers[${triggerIndex}].conditions` as FieldPath<TAutomationBuilder>,
  });

  const rules = useMemo(() => {
    return (field.value as TNumberCondition)?.rules ?? ({} as TRules);
  }, [field.value]);

  const threshold = useMemo(() => {
    const currentCondition = rules[operator];

    return isNil(currentCondition) || isNil(currentCondition.threshold)
      ? ''
      : roundValue(currentCondition.threshold.toString(), 'decimal');
  }, [operator, rules]);

  const deadband = useMemo(() => {
    const currentCondition = rules[operator];
    return isNil(currentCondition) || isNil(currentCondition.deadband)
      ? ''
      : roundValue(currentCondition?.deadband.toString(), 'decimal');
  }, [operator, rules]);

  return {
    rules,
    threshold,
    deadband,
    onChange: field.onChange,
    error: fieldState.error,
  };
};

import { HubDetails } from '@marlin/asset/feature/common-asset-details';
import { ConfigurationFlow } from '@marlin/asset/feature/gateway-configuration';
import { GatewayDetails } from '@marlin/asset/feature/gateway-dashboard';
import { GatewayHub } from '@marlin/asset/feature/gateway-list';
import { GatewayEdit, GatewayRegistration } from '@marlin/asset/feature/gateway-upsert';
import { HubUpdateComponent } from '@marlin/asset/feature/hub-update';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const GatewayRoutes = (): JSX.Element => {
  return (
    <Route path={routes.gateway.root}>
      <Route path={routes.gateway.list.path} element={<GatewayHub />} />
      <Route path={routes.gateway.configuration.path} element={<ConfigurationFlow />} />
      <Route path={routes.gateway.registration.path} element={<GatewayRegistration />} />
      <Route path={routes.gateway.edit.path} element={<GatewayEdit />} />
      <Route path={routes.gateway.hub.update.path} element={<HubUpdateComponent />} />
      <Route path={routes.gateway.hub.details.path} element={<HubDetails />} />
      <Route path={routes.gateway.details.path} element={<GatewayDetails />} />
    </Route>
  );
};

import { useDevice, useUpdateDevice } from '@marlin/asset/data-access/device';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback, useMemo } from 'react';

import { content } from '../content';
import { TUpsertHubUpdateForm } from '../utils/hub-form.utils';

type TProps = {
  hubId: string;
  onSuccess: () => void;
};

export const useUpdateHub = ({ hubId, onSuccess }: TProps) => {
  const hubQuery = useDevice({ deviceId: hubId, enabled: !!hubId });
  const hub = useMemo(() => hubQuery?.data, [hubQuery?.data]);

  const isLoading = useMemo(() => hubQuery.isFetching, [hubQuery.isFetching]);
  const isError = useMemo(() => hubQuery.isError, [hubQuery.isError]);
  const onError = () =>
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_HUB_ERROR, { variant: 'error', preventDuplicate: true });

  const updateHubMutation = useUpdateDevice({ onSuccess, onError });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formValues: TUpsertHubUpdateForm) => {
      try {
        await updateHubMutation.mutateAsync({
          data: {
            ...hub!,
            name: formValues.name,
            locationId: formValues.locationId,
            description: formValues.description,
          },
          deviceId: hubId,
        });

        enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_HUB_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });

        onSuccess();
      } catch (error) {
        enqueueSnackbar(content.CONFIRMATION_MESSAGE_UPDATE_HUB_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [enqueueSnackbar, hub, hubId, onSuccess, updateHubMutation]
  );

  return {
    hub,
    isLoading,
    isPendingMutation: updateHubMutation.isPending,
    onSubmit,
    hubId,
    isError,
  };
};

export const content = {
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  ERROR_OFFLINE: 'Equipment is offline. Check your connection and try again.',
  SAVE_SUCCESS_MSG: 'Changes have been saved',
  CLEAR_ALL: 'Clear All',
  EDIT_BUTTON: 'Edit',
  EQUIPMENT_DETAILS: 'Equipment Details',
  TEMPERATURE: 'Temperature',
  SCHEDULE: 'Schedule',
  SYSTEM: 'System',
  BOILER_SETTING: (boilerNumber: number) => `Boiler ${boilerNumber}`,
  CANCEL: 'Cancel',
  SET: 'Set',
  DISABLED_SETTINGS: 'Disabled due to the current configuration',
  SANITIZATION_ERROR: (isStoppingSanitization: boolean) =>
    isStoppingSanitization ? 'Stopping Sanitization' : 'Sanitization in progress',
  STOPPING_SANITIZATION_ERROR_BODY: `Sanitization is being stopped. This process can take a few seconds.`,
  SANITIZATION_STOPPED: 'Sanitization mode has been stopped',
  START_UP_WARNING: 'Start up in progress',
  ESTABLISHING_STEAM_TIME: 'Establishing Steam',
  HEAT_ON: 'Heat On',
  HEAT_OFF: 'Heat Off',
  SETPOINT: 'Setpoint',
  REMAINING_TIME: 'Time remaining',
  STOP_SANITIZATION: 'Stop Sanitization',
  STOPPING_SANITIZATION: 'Stopping Sanitization',
  EMPTY_DATAPOINT_VALUE: '--',
  EQUIPMENT_MODE_LABELS: {
    AUTO: 'Auto Mode',
    MANUAL: 'Manual Mode',
    IDLE: 'Idle Mode',
    SANITIZE: 'Sanitize Mode',
    START_UP: 'Start up Mode',
    COOL_DOWN: 'Cooldown Mode',
  },
  EQUIPMENT_MODE_LABELS_AERCO: {
    DISABLED: 'Disabled',
    STANDBY: 'Standby Mode',
    MANUAL: 'Manual Mode',
    REMOTE: 'Remote Mode',
    AUTO: 'Auto Mode',
    FAULT: 'Fault',
  },
  EQUIPMENT_MODE_LABELS_SENTINEL: {
    SCENE: (scene: string) => `${scene} Scene`,
  },
  EQUIPMENT_STATUS_LABELS: {
    LOST_COMMUNICATION: 'Lost Communication',
    OFFLINE: 'Equipment Offline',
    OPERATIONAL: 'Equipment Operational',
    FAULT: 'Equipment Fault',
    NOT_INSTALLED: 'Not Installed',
    ERROR: 'Error',
    AUTOMATIC: 'Automatic',
    OVERRIDE: 'Override',
  },
  LDS_BUTTON_LABELS: {
    VIEW_VALVES: 'VIEW VALVES',
    VIEW_POLDS: 'VIEW POLDs',
    VIEW_APIS: 'VIEW APIs',
  },
  STOP_SANITIZATION_MODAL_BODY_FIRST_LINE: 'Are you sure you want to Stop Sanitization?',
  STOP_SANITIZATION_MODAL_BODY_SECOND_LINE: 'This process can take a few seconds.',
  STOP: 'Stop',
  ON: 'On',
  OFF: 'Off',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  OCCUPIED_ONE: 'Occupied 1',
  OCCUPIED_TWO: 'Occupied 2',
  SKIP: 'Skip',
  SCHEDULE_TOOLTIP_TITLE: 'If the schedule is set to on:',
  TIMEZONE: 'Time zone',
  SCHEDULE_TOOLTIP_ROW_1: 'The Mixed Outlet Setpoint is the temperature during the occupied period.',
  SCHEDULE_TOOLTIP_ROW_2:
    'The temperature will be reduced by the Mixed Setback Differential during unoccupied periods.',
  OCCUPIED_DATAPOINT: (val: string) => `${val} (occupied)`,
  EQUIPMENT_DEREGISTERED_WARNING: 'Your equipment has been deregistered from the organization',
  SETTINGS_DISABLED_TOOLTIP:
    'System in sanitization mode, please stop sanitization from the equipment before changing any settings.',
  START_TIME: 'Start Time',
  STATUS: 'Status',
  NEW: 'New',
  RESOLVED: 'Resolved',
  CRITICALITY: 'Criticality',
  EVENT_TYPE: 'Event Type',
  TITLE: 'Title',
  RESOLVED_TIME: 'Resolved Time',
  VALUE_CHANGED: 'Value Changed',
  NEW_VALUE_LABEL: 'New Value',
  ORIGINAL_VALUE: 'Original Value',
  GENERATED_BY: 'Generated By',
  FILTERS: {
    LABEL_SEARCH: 'Text Search',
  },
  EVENT_LOG_DETAILS: {
    MANUAL_LINK: 'Go to Help Manual',
    EMAIL_MSG: 'Email',
    TEXT_MSG: 'Text',
    HIGH: 'High',
    LOW: 'Low',
    RESOLVED: 'Resolved',
    NEW: 'New',
    HEALTH: 'Health',
    PERFORMANCE: 'Performance',
    START_TIME: 'Start Time',
    RESOLVE_TIME: 'Resolved Time',
    CLOSE: 'Close',
    SETTING_CHANGE: 'Setting Change',
    ACTIONS: 'Actions',
    HIERARCHY: 'Hierarchy',
    CHANGELOG: 'Changelog',
    DESCRIPTION: 'Description',
  },
  THRESHOLD_ERROR: (val: string, isMaxExceeded: boolean) =>
    `You cannot set the temperature ${
      isMaxExceeded ? 'above' : 'below'
    } ${val} remotely. You must change it on the equipment locally to set it ${
      isMaxExceeded ? 'above' : 'below'
    } this threshold.`,
  LOCAL_ADMIN: 'Local Admin',
  MOBILE_FILTERS: {
    HIDE_FILTERS: 'Hide Filters',
  },
  OCCUPANCY_COUNT_AND_TIME: 'Sensor Occupancy Count and Occupancy Time',
  RESET: 'Reset',
  RESET_MODAL: {
    TITLE: 'Reset Occupancy',
    BODY_TEXT: 'Are you sure you want to reset all door counts and occupancy times?',
    BODY_CONFIRMATION: 'This action cannot be undone.',
    CANCEL: 'Cancel',
    SUBMIT: 'Reset',
  },
  RESET_MESSAGE: 'The occupancy count and times have been reset.',
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
  SORT_BY: 'Sort By',
  BOILERS_AND_PUMPS: {
    COLUMNS: {
      NAME: 'Name',
      HOURS: 'Hours',
      CYCLES: 'Cycles',
      PUMP_STATUS: 'Pump Status',
      BOILER_STATUS: 'Boiler Status',
      BOILER: 'Boiler',
    },
    STATUS: {
      NOT_INSTALLED: 'Not Installed',
      NOT_USED: 'Not Used',
      FIRING: 'Firing',
      MIN_OFF: 'Min Off',
      OFF: 'Off',
      ON: 'On',
      DISABLED: '0%',
    },
  },
  ACTUATE_VALVES: {
    BUTTON_LABEL: 'Actuate Valves',
    CLOSE_VALVES: 'Close Valves',
    OPEN_VALVES: 'Open Valves',
    MODAL: {
      TITLE: {
        OPEN: 'Open Valves?',
        CLOSE: 'Close Valves?',
      },
      COMMAND: {
        OPEN: 'Send open command to:',
        CLOSE: 'Send close command to:',
      },
      CONFIRMATION: {
        OPEN: 'Valves are already open. Send open command anyway to:',
        CLOSE: 'Valves are already closed. Send close command anyway to:',
      },
      BUTTON: {
        CANCEL_LABEL: 'Cancel',
        SEND_LABEL: 'Send',
      },
    },
  },
  COMMANDS: {
    SNACKBAR: {
      SUCCESS: {
        GENERIC: 'Your command has been sent',
        OPEN: (valveName: string) => `Command sent to open ${valveName}`,
        CLOSE: (valveName: string) => `Command sent to close ${valveName}`,
      },
      ERROR: 'Something went wrong. Your command has not been sent.',
    },
  },
};

import { DEVICE_TYPE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, useMediaQuery } from '@mui/material';

import { content } from '../../shared/content';
import { TTopOpenAlert } from '../../shared/utils';
import { useRouter } from '../hooks/use-router.hook';
import { EmptyContent } from './empty-content';
import { TopAlertsCardItem } from './top-alerts-card-item.component';
import { useStyles } from './top-alerts-card.component.style';

type TTopAlertsCardProps = {
  topOpenAlerts:
    | Array<{
        alert: TTopOpenAlert;
        alertErrorType: ERROR_TYPES;
        alertDeviceType: DEVICE_TYPE;
        equipmentId?: string;
      }>
    | undefined;
  devicesCount: number | undefined;
  automationsCount?: number;
  equipmentId?: string;
  isAssetDashboardSection?: boolean;
  isDatapointAlerts?: boolean;
};

const TopAlertsCard = ({
  topOpenAlerts,
  devicesCount,
  automationsCount,
  equipmentId,
  isAssetDashboardSection,
  isDatapointAlerts,
}: TTopAlertsCardProps) => {
  const { classes } = useStyles();
  const { goToAlertsPage } = useRouter();

  const isAddDeviceMessage = automationsCount === 0 && devicesCount === 0;
  const isCreateAutomationMessage = automationsCount === 0;

  if (isAssetDashboardSection && !isDatapointAlerts) {
    return (
      <AssetDashboardTopAlertsCard
        topOpenAlerts={topOpenAlerts}
        devicesCount={devicesCount}
        automationsCount={automationsCount}
        equipmentId={equipmentId}
      />
    );
  }

  return (
    <div className={classes.container} data-testid="alerts-container">
      <div className={classes.header}>
        <div className={classes.textContainer}>
          <div className={classes.text} data-testid="alerts-card-subtitle">
            {content.ALERTS}
          </div>
          <div className={classes.title} data-testid="alerts-card-title">
            {content.TOP_OPEN_ALERTS}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {!isDatapointAlerts && (
            <Button
              className={classes.button}
              variant="outlined"
              data-testid="view-alerts-button"
              onClick={goToAlertsPage}
            >
              {content.VIEW_ALERTS}
            </Button>
          )}
        </div>
      </div>
      {topOpenAlerts && topOpenAlerts?.length > 0 ? (
        <div className={classes.list}>
          {topOpenAlerts?.map(
            ({ alert, alertErrorType, alertDeviceType, equipmentId: alertEquipmentId }, index: number) => (
              <TopAlertsCardItem
                alert={alert}
                key={`${index}-${alert.deviceId}`}
                alertDeviceType={alertDeviceType}
                alertErrorType={alertErrorType}
                equipmentId={equipmentId ?? alertEquipmentId}
                isDatapointAlerts={isDatapointAlerts}
              />
            )
          )}
        </div>
      ) : (
        <div className={classes.listEmpty}>
          <EmptyContent isAddDeviceMessage={isAddDeviceMessage} isCreateAutomationMessage={isCreateAutomationMessage} />
        </div>
      )}
    </div>
  );
};

export { TopAlertsCard };

const AssetDashboardTopAlertsCard = ({
  topOpenAlerts,
  devicesCount,
  automationsCount,
  equipmentId,
}: Omit<TTopAlertsCardProps, 'isAssetDashboardSection'>) => {
  const isAddDeviceMessage = automationsCount === 0 && devicesCount === 0;
  const isCreateAutomationMessage = automationsCount === 0;
  const { classes, theme, cx } = useStyles();

  const { goToAlertsPage } = useRouter();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Accordion
      defaultExpanded
      expanded={!isMobile ? true : undefined}
      elevation={0}
      slotProps={{ transition: { unmountOnExit: true } }}
      className={classes.accordion}
    >
      <AccordionSummary expandIcon={isMobile ? <ExpandMoreIcon /> : undefined} data-testid="alerts-card-title">
        <div className={classes.titleWrapper}>
          <div className={classes.title}>{content.TOP_OPEN_ALERTS}</div>
          {!isMobile && (
            <Button
              className={classes.equipmentDashboardButton}
              variant="outlined"
              data-testid="view-alerts-button"
              onClick={goToAlertsPage}
            >
              {content.VIEW_ALERTS}
            </Button>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails data-testid="alerts-container">
        <div className={cx(classes.container, classes.equipmentDashboardContainer)}>
          {isMobile && (
            <Button
              className={classes.equipmentDashboardButton}
              variant="outlined"
              data-testid="view-alerts-button"
              onClick={goToAlertsPage}
            >
              {content.VIEW_ALERTS}
            </Button>
          )}
          {topOpenAlerts && topOpenAlerts?.length > 0 ? (
            <div className={classes.list}>
              {topOpenAlerts?.map(
                ({ alert, alertErrorType, alertDeviceType, equipmentId: alertEquipmentId }, index: number) => (
                  <TopAlertsCardItem
                    alert={alert}
                    key={`${index}-${alert.deviceId}`}
                    alertDeviceType={alertDeviceType}
                    alertErrorType={alertErrorType}
                    equipmentId={equipmentId ?? alertEquipmentId}
                  />
                )
              )}
            </div>
          ) : (
            <div className={cx(classes.listEmpty, classes.listEmptyEquipmentDashboard)}>
              <EmptyContent
                isAddDeviceMessage={isAddDeviceMessage}
                isCreateAutomationMessage={isCreateAutomationMessage}
              />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

// TODO: create new action schema, refactor the old one
import { actionSchema, automationRuleTypeSchema } from '@marlin/alert/data-access/automated-action';
import { z } from 'zod';

import { detailsSchema } from './sections/details/details.validation.schema';
import { triggerSchema } from './sections/triggers/triggers.validation.schema';

export const automationBuilderValidationSchema = z.object({
  id: z.string().nullish(),
  isEnabled: z.boolean(),
  isSystemRule: z.boolean().optional(),
  ruleType: automationRuleTypeSchema.optional(),
  details: detailsSchema,
  triggers: z.array(triggerSchema),
  actions: actionSchema,
  logicOperator: z.union([z.literal('AND'), z.literal('OR')]),
});

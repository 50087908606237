import groupBy from 'lodash/groupBy';

import { IMeasured, INode } from './types';

export const hasWidthAndHeight = <T extends INode>(node: T): node is T & { measured: IMeasured } => {
  return node.measured?.width !== null && node.measured?.height !== null;
};

export const groupNodesByParent = <T extends { parentId?: string }>(nodes: T[]) => {
  return groupBy(nodes, (node) => node.parentId || 'root');
};

export const groupByParentId = <T extends { parentId?: string }>(nodes?: T[], parentId?: string) => {
  return nodes?.filter((node) => node.parentId === parentId);
};

import { TDeviceType } from './model';
import { uomSettingsService } from './settings/uom-settings.service';
import { TUnitOfMeasure } from './zod/uom.schema';

export const getUomFromSettings = (deviceType?: TDeviceType): TUnitOfMeasure | null => {
  const uomSettings = uomSettingsService.getUomSettings();

  if (!uomSettings) {
    return null;
  }

  switch (deviceType) {
    case 'TEMPERATURE': {
      return uomSettings.temperatureUnit || null;
    }
    case 'PRESSURE': {
      return uomSettings.pressureUnit || null;
    }
    case 'PULSE_METER': {
      return uomSettings.waterFlowRateUnit || null;
    }

    default: {
      return null;
    }
  }
};

import { UnitOfMeasureType, lastReadingValuesOutputSchema } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { CRITICALITY } from '../../automation.model';
import { automationRuleTypeSchema } from '../automation.schema';
import { channelsSchema } from '../channel.schema';
import { deviceTypeSchema } from '../device-type.schema';
import { durationSchema } from '../duration.schema';

const booleanCondition = z.object({
  type: z.literal('boolean'),
  threshold: z.boolean(),
  operator: z.union([z.literal('eq'), z.literal('neq')]),
  uom: UnitOfMeasureType.nullish(),
});

const baseCondition = z.object({
  threshold: z.coerce.number(),
  deadband: z.coerce.number().nullable(),
  uom: UnitOfMeasureType.nullish(),
});

const higherCondition = baseCondition.extend({
  operator: z.literal('gt'),
});

const lowerCondition = baseCondition.extend({
  operator: z.literal('lt'),
});

const numberCondition = z.object({
  type: z.literal('number'),
  rules: z.object({
    lt: lowerCondition.nullish(),
    gt: higherCondition.nullish(),
  }),
});

const deviceSchema = z.object({
  id: z.string(),
  name: z.string(),
  manufacturerId: z.string().nullable(),
  deviceType: deviceTypeSchema.optional(),
  model: z.string().nullish(),
  lastReadingValues: z.array(lastReadingValuesOutputSchema),
});

export const conditionSchema = z.discriminatedUnion('type', [booleanCondition, numberCondition]);

export const triggerSchema = z.object({
  device: deviceSchema,
  datapoint: z.string(),
  conditions: conditionSchema.nullish(),
});

export const detailsSchema = z.object({
  name: z.string(),
  criticality: z.nativeEnum(CRITICALITY),
});

export const actionSchema = z.object({
  snoozeIntervalInMinutes: z.number(),
  channels: channelsSchema,
  duration: durationSchema.nullish(),
});

export const automationBuilderSchema = z.object({
  id: z.string().nullish(),
  isEnabled: z.boolean(),
  isSystemRule: z.boolean().optional(),
  ruleType: automationRuleTypeSchema.optional(),
  details: detailsSchema,
  triggers: z.array(triggerSchema),
  actions: actionSchema,
  logicOperator: z.union([z.literal('AND'), z.literal('OR')]),
});

export type TDevice = z.infer<typeof deviceSchema>;
export type TTrigger = z.infer<typeof triggerSchema>;
export type TBooleanCondition = z.infer<typeof booleanCondition>;
export type TBaseCondition = z.infer<typeof baseCondition>;
export type THigherCondition = z.infer<typeof higherCondition>;
export type TLowerCondition = z.infer<typeof lowerCondition>;
export type TNumberCondition = z.infer<typeof numberCondition>;
export type TCondition = z.infer<typeof conditionSchema>;
export type TAutomationBuilder = z.infer<typeof automationBuilderSchema>;

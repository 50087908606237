import { MarlinTheme } from '@marlin/shared/theme';
import { TUnitOfMeasure, getUomSymbol } from '@marlin/shared/utils-format-reading';
import AddRounded from '@mui/icons-material/AddRounded';
import RemoveRounded from '@mui/icons-material/RemoveRounded';
import { IconButton, TextField } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(12)}`,
    width: 'auto',
    maxWidth: theme.typography.pxToRem(60),
    minWidth: theme.typography.pxToRem(40),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.typography.pxToRem(140),
    },
  },
  textFieldRoot: {
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(32),
      marginBottom: theme.typography.pxToRem(48),
    },
  },
  endAdornment: {
    color: theme.palette.text.secondary,
  },
  iconButton: {
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48),
  },
}));

interface IValueInputProps {
  value: string;
  minValue: number;
  maxValue: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onRemoveClick: () => void;
  onAddClick: () => void;
  unitOfMeasure: TUnitOfMeasure | null;
}

export const ValueInput = ({
  onBlur,
  onChange,
  value,
  minValue,
  maxValue,
  onRemoveClick,
  onAddClick,
  unitOfMeasure,
}: IValueInputProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.iconButton}
        onClick={onRemoveClick}
        data-testid="minus-icon-button"
        disabled={value === minValue.toString()}
      >
        <RemoveRounded />
      </IconButton>
      <TextField
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        classes={{
          root: classes.textFieldRoot,
        }}
        inputProps={{
          min: minValue,
          max: maxValue,
          className: classes.input,
          'data-testid': 'value-input',
        }}
        InputProps={{
          endAdornment: <span className={classes.endAdornment}>{getUomSymbol(unitOfMeasure)}</span>,
        }}
        label={content.NEW_VALUE}
      />
      <IconButton
        className={classes.iconButton}
        onClick={onAddClick}
        data-testid="plus-icon-button"
        disabled={value === maxValue.toString()}
      >
        <AddRounded />
      </IconButton>
    </div>
  );
};

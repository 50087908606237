import { refetchDeviceChildrenDatapoints } from '@marlin/asset/data-access/device';
import { useSendDeviceCommand } from '@marlin/asset/data-access/equipment';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback, useMemo } from 'react';

import { useEquipmentContext } from '../../../../../shared/context/equipment.context';
import { content } from '../sentinel/content';

interface IUseClearAlertsCommand {
  clearAllAlerts: () => Promise<void>;
  isSendingDeviceCommand: boolean;
}

interface IUseClearAlertsCommandProps {
  onSuccess: () => void;
}

export const useClearAlertsCommand = ({ onSuccess }: IUseClearAlertsCommandProps): IUseClearAlertsCommand => {
  const { equipment } = useEquipmentContext();
  const { mutateAsync: sendDeviceCommand, isPending: isSendingDeviceCommand } = useSendDeviceCommand();
  const { enqueueSnackbar } = useSnackbar();

  const clearAllAlerts = useCallback(async () => {
    try {
      await sendDeviceCommand({
        model: equipment?.devices?.[0]?.model || '',
        manufacturer: equipment?.devices?.[0]?.manufacturer || '',
        manufacturerId: equipment?.devices?.[0]?.manufacturerId?.split('-')[1] || '',
        command: 'clear-all-alerts',
      });
      onSuccess();
      enqueueSnackbar(content.COMMANDS.CLEAR_ALL_ALERTS_SUCCESS, {
        variant: 'success',
        preventDuplicate: true,
      });
      await refetchDeviceChildrenDatapoints();
    } catch (error) {
      enqueueSnackbar(content.COMMANDS.CLEAR_ALL_ALERTS_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, equipment?.devices, onSuccess, sendDeviceCommand]);

  return useMemo(
    (): IUseClearAlertsCommand => ({ clearAllAlerts, isSendingDeviceCommand }),
    [clearAllAlerts, isSendingDeviceCommand]
  );
};

import { TDeviceSchema, TGetDeviceSchema } from '@marlin/asset/data-access/device';
import { TEquipmentResponseSchema } from '@marlin/asset/data-access/equipment';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { TEquipment } from '@marlin/shared/utils/zod';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { useMemo } from 'react';

import { content } from '../../../../../../shared/content';
import {
  IConfig,
  useCreateConnectedDevicesColumns,
} from '../../../../../hooks/use-create-connected-devices-columns.hook';
import { useGridStyles } from '../../../../../hooks/use-list-styles.hook';
import { usePagination } from '../../../../../hooks/use-pagination.hook';
import { SUB_TABS } from './connected-devices-desktop.component';

interface IConnectedDevicesListProps {
  parentManufacturerId: string;
  gatewayId: string;
  asset: {
    onSortChange: (sortModel: GridSortModel) => void;
    isError: boolean;
    pagination: ReturnType<typeof usePagination>;
    data: TGetDeviceSchema | TEquipmentResponseSchema | undefined;
    isLoading: boolean;
    config: Partial<IConfig>;
  };
  tab: SUB_TABS;
}

export const ConnectedDevicesList = ({
  asset: {
    pagination: { paginationModel, setPageSize, setPage },
    data,
    isError,
    isLoading,
    config,
    onSortChange,
  },
  gatewayId,
}: IConnectedDevicesListProps) => {
  const { classes } = useGridStyles();
  const columns = useCreateConnectedDevicesColumns(gatewayId, config);

  const rows = useMemo<TDeviceSchema[] | TEquipment[]>(() => data?.data ?? [], [data]);
  const rowCount = useMemo(() => data?.pagination.totalItems ?? 0, [data]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={classes.dataGridWrapper}>
      <DataGrid
        localeText={{
          noRowsLabel: isError ? content.HUBS_AND_SENSORS.ERROR : content.HUBS_AND_SENSORS.NO_DATA,
        }}
        className={classes.dataGrid}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        disableColumnFilter
        disableColumnMenu
        rowsPerPageOptions={[10, 50, 100]}
        paginationMode="server"
        filterMode="server"
        onSortModelChange={onSortChange}
        initialState={{ pagination: paginationModel }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(size) => setPageSize(size)}
      />
    </div>
  );
};

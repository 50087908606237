import { useRouter } from '@marlin/shared/utils-router';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useUpdateHub as useUpdateHubShared } from '../../shared/hooks/use-update-hub.hook';
import { useNavigation } from './use-navigation.hook';

export const useUpdateHub = () => {
  const hubId = useIdFromPathname();
  const { goTo } = useRouter();
  const navigation = useNavigation();
  const { gatewayId } = useParams();
  const nextRoute = navigation.getGatewayDetailsPageLink(gatewayId ?? '');

  const onSubmitSuccessCallback = useCallback(() => {
    goTo(nextRoute, { state: { shouldIgnoreGuard: true } });
  }, [goTo, nextRoute]);

  return useUpdateHubShared({ hubId, onSuccess: onSubmitSuccessCallback });
};

import { TEquipment } from '@marlin/shared/utils/zod';

export const sortPlantEquipment = (
  a: TEquipment,
  b: TEquipment,
  managerAddress: string | undefined,
  backupManagerAddress: string | undefined
) => {
  const aCommAddr = getCommAddr(a);
  const bCommAddr = getCommAddr(b);

  const aRole = getEquipmentRole(aCommAddr, a.serialNumber, managerAddress, backupManagerAddress);
  const bRole = getEquipmentRole(bCommAddr, b.serialNumber, managerAddress, backupManagerAddress);

  const rolePriority = { Manager: 0, BackupManager: 1, Regular: 2 };

  return rolePriority[aRole] - rolePriority[bRole] || a.name.localeCompare(b.name);
};

const getCommAddr = (equipment: TEquipment) => {
  const values = equipment.devices?.[0]?.lastReadingValues;

  return values?.find((value) => value.name.toLowerCase() === 'CommAddr'.toLowerCase())?.value;
};

const getEquipmentRole = (
  commAddr: string | undefined,
  serialNumber: string | undefined,
  managerAddress: string | undefined,
  backupManagerAddress: string | undefined
) => {
  if (commAddr === managerAddress || serialNumber === managerAddress) {
    return 'Manager';
  }

  if (commAddr === backupManagerAddress || serialNumber === backupManagerAddress) {
    return 'BackupManager';
  }

  return 'Regular';
};

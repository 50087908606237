import { MarlinTheme } from '@marlin/shared/theme';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';

interface IValveCommandModalFooterProps {
  handleCloseModal: () => void;
  handleSendCommand: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(8),
  },
}));

export const ValveCommandModalFooter = ({ handleCloseModal, handleSendCommand }: IValveCommandModalFooterProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer} data-testid="valve-command-modal-footer">
      <Button variant="text" onClick={handleCloseModal}>
        {content.ACTUATE_VALVES.MODAL.BUTTON.CANCEL_LABEL}
      </Button>
      <Button variant={'contained'} onClick={handleSendCommand}>
        {content.ACTUATE_VALVES.MODAL.BUTTON.SEND_LABEL}
      </Button>
    </div>
  );
};

import { z } from 'zod';

import { detailsSchema } from '../details/details.validation.schema';
import { triggerSchema } from '../triggers/triggers.validation.schema';

export const actionsEnabledSchema = z.object({
  details: detailsSchema,
  triggers: z.array(triggerSchema).nonempty(),
});

export type TActionValidation = z.infer<typeof actionsEnabledSchema>;

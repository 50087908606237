import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

export const useSectionValidation = <T>(schema: z.ZodType<T>) => {
  const { watch } = useFormContext<TAutomationBuilder>();

  const formData = watch();

  const isValid = useMemo(() => {
    return schema.safeParse(formData).success;
  }, [formData, schema]);

  return { isValid };
};

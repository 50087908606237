import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useMemo } from 'react';

import { useSensorId } from '../../../hooks/use-sensor-id.hook';

export const useAlertTooltip = () => {
  const deviceId = useSensorId();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const alertTabLink = useMemo(() => routes.deviceDrawer.open(currentUrl, deviceId, 'alerts'), [deviceId, currentUrl]);

  return {
    alertTabLink,
  };
};

import { SectionHeader } from '@marlin/shared/ui-page';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { Chip, Paper } from '@mui/material';
import { ReactNode } from 'react';

import { useStyles } from './block-container.styles';

interface IBlockContainerProps {
  editMode: boolean;
  title: string;
  children: ReactNode;
  actions?: ReactNode;
  editModeActions?: ReactNode;
  testId: string;
  className?: string;
}

export const BlockContainer = ({
  editMode,
  title,
  children,
  actions,
  editModeActions,
  testId,
  className,
}: IBlockContainerProps) => {
  const { classes, cx } = useStyles();

  if (editMode) {
    return (
      <Paper
        variant="outlined"
        className={cx(classes.container, className)}
        data-testid={`section-expanded-edit-${testId}`}
      >
        <SectionHeader title={title} testId={createDataTestId('block-container', title)} options={editModeActions} />
        {children}
      </Paper>
    );
  }

  return (
    <Paper
      variant="outlined"
      className={cx(classes.container, classes.containerClosed, className)}
      data-testid={`section-expanded-view-${testId}`}
    >
      <div className={classes.headerClosed}>
        <Chip size="small" label={title} data-testid={createDataTestId('block-container', title)} />
      </div>
      <div className={classes.contentClosed}>
        {children}
        <div className={classes.actionsClosed}>{actions}</div>
      </div>
    </Paper>
  );
};

import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { TTopOpenAlert, TopAlertsCard } from '@marlin/shared/ui/top-open-alerts';
import { DEVICE_TYPE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';

interface ITopAlerts {
  isLoading: boolean;
  topOpenAlerts:
    | Array<{
        alert: TTopOpenAlert;
        alertErrorType: ERROR_TYPES;
        alertDeviceType: DEVICE_TYPE;
        equipmentId?: string;
      }>
    | undefined;
  classes?: string;
  isAssetDashboardSection?: boolean;
  isDatapointAlerts?: boolean;
}

export const TopAlerts = ({
  isLoading,
  topOpenAlerts,
  classes,
  isAssetDashboardSection,
  isDatapointAlerts,
}: ITopAlerts) => {
  return (
    <div className={classes}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TopAlertsCard
          topOpenAlerts={topOpenAlerts}
          devicesCount={undefined}
          isAssetDashboardSection={isAssetDashboardSection}
          isDatapointAlerts={isDatapointAlerts}
        />
      )}
    </div>
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import { ProductDetails } from '@marlin/ui/product-details';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useCommonAssetDetailsConfigContext } from '../../../../shared/context/common-asset-details-config.context';
import { ICommonAsset } from '../../../../shared/model';
import { CommonAssetDetailsSection } from './common-asset-details.component';
import { DashboardAlerts } from './dashboard-alerts.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.typography.pxToRem(24),
    rowGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('md')]: {
      rowGap: 0,
    },
  },
  status: {
    height: theme.typography.pxToRem(24),
    padding: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.primary,
    flexWrap: 'wrap',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)} 0 0`,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      gap: theme.typography.pxToRem(8),
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    },
  },
  alerts: {
    display: 'flex',
    width: '100%',
  },
  productDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(8),
    width: '100%',
    overflow: 'auto',
    margin: 0,
  },
  tileOverride: {
    // override default HeaderDatapoint styles
    '& > div, & > span': {
      minWidth: theme.typography.pxToRem(170),
    },
    '& > span': {
      padding: 0,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.typography.pxToRem(0),
    gap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      gap: 'unset',
      marginTop: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(74),
    maxWidth: theme.typography.pxToRem(170),
    flexDirection: 'column',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.primary,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  grid: {
    gridColumn: '1 / -1',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface ICommonAssetDashboardProps {
  asset: ICommonAsset;
}

export const CommonAssetDashboard = ({ asset }: ICommonAssetDashboardProps) => {
  const { classes, cx } = useStyles();
  const { config } = useCommonAssetDetailsConfigContext();

  const emptyStatus = useMemo(() => <div className={classes.status}></div>, [classes.status]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.column}>
        <ProductDetails
          logoSrc={config.productDetails.logoSrc}
          // TODO: update status in the future
          statusPanel={config.productDetails.statusPanel ?? emptyStatus}
          model={asset.model}
          equipmentModel={asset.model}
          imageSrc={config.productDetails.imageSrc}
        >
          <div
            data-testid="equipment-details-datapoint-tiles"
            className={cx(classes.productDetailsWrapper, classes.tileOverride)}
          />
        </ProductDetails>
      </div>
      <div className={classes.column}>
        {config.displayTopOpenAlerts && <DashboardAlerts asset={asset} />}
        <div className={classes.grid} data-testid="gateway-details">
          <CommonAssetDetailsSection asset={asset} />
        </div>
      </div>
    </div>
  );
};

import { IconSensor, TIconEquipment } from '@marlin/asset/shared/ui/assets';

import { content } from '../content';
import { TDeviceType } from './device-type.schema';

export interface IDeviceTypeOption {
  id: TDeviceType;
  value: TDeviceType;
  name: string;
  Icon: TIconEquipment;
}

export const deviceTypeOptions: Record<Exclude<TDeviceType, 'EQUIPMENT'>, IDeviceTypeOption> = {
  TEMPERATURE: {
    id: 'TEMPERATURE',
    value: 'TEMPERATURE',
    name: content.SENSOR_TYPE_TEMPERATURE_LABEL,
    Icon: IconSensor.Temperature,
  },
  PRESSURE: {
    id: 'PRESSURE',
    value: 'PRESSURE',
    name: content.SENSOR_TYPE_PRESSURE_LABEL,
    Icon: IconSensor.Pressure,
  },

  LEAK: {
    id: 'LEAK',
    value: 'LEAK',
    name: content.SENSOR_TYPE_LEAK_LABEL,
    Icon: IconSensor.LeakDetector,
  },

  PULSE_METER: {
    id: 'PULSE_METER',
    value: 'PULSE_METER',
    name: content.SENSOR_TYPE_FLOW_LABEL,
    Icon: IconSensor.FlowMeter,
  },
};

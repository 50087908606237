import { z } from 'zod';

export const AssetTypeResponseSchema = z.union([z.literal('Location'), z.literal('Equipment'), z.literal('Device')]);
export const AssetTypeSchema = z.union([z.literal('LOCATION'), z.literal('EQUIPMENT'), z.literal('DEVICE')]);
export type TAssetTypeResponse = z.infer<typeof AssetTypeResponseSchema>;
export type TAssetType = z.infer<typeof AssetTypeSchema>;

export const NodeTypeResponseSchema = z.union([z.literal('Attachment'), z.literal('Asset'), z.literal('Tee')]);
export const NodeTypeSchema = z.union([z.literal('ASSET'), z.literal('ATTACHMENT'), z.literal('TEE')]);
export type TNodeTypeResponse = z.infer<typeof NodeTypeResponseSchema>;
export type TNodeType = z.infer<typeof NodeTypeSchema>;

export const AssetTypeSchemaParser = AssetTypeResponseSchema.nullish().transform(
  (assetType: TAssetTypeResponse | null | undefined): TAssetType | undefined => {
    switch (assetType) {
      case 'Location': {
        return 'LOCATION';
      }
      case 'Equipment': {
        return 'EQUIPMENT';
      }
      case 'Device': {
        return 'DEVICE';
      }
      default: {
        return undefined;
      }
    }
  }
);

export const NodeTypeSchemaParser = NodeTypeResponseSchema.nullish().transform(
  (nodeType: TNodeTypeResponse | null | undefined): TNodeType | undefined => {
    switch (nodeType) {
      case 'Asset': {
        return 'ASSET';
      }
      case 'Attachment': {
        return 'ATTACHMENT';
      }
      case 'Tee': {
        return 'TEE';
      }
      default: {
        return undefined;
      }
    }
  }
);

export const UnitOfMeasureSchema = z.union([
  z.literal('Fahrenheit'),
  z.literal('PSI'),
  z.literal('WaterDetect'),
  z.literal('GPM'),
  z.literal(''),
]);

export const DeviceTypeResponseSchema = z.union([
  z.literal('Pressure'),
  z.literal('Temperature'),
  z.literal('Leak'),
  z.literal('FlowMeter'),
  z.literal('PulseMeter'),
  z.literal('Equipment'),
  z.literal('Hub'),
]);
export const DeviceTypeSchema = z.union([
  z.literal('PRESSURE'),
  z.literal('TEMPERATURE'),
  z.literal('LEAK'),
  z.literal('PULSE_METER'),
  z.literal('EQUIPMENT'),
  z.literal('HUB'),
]);
export type TDeviceTypeResponse = z.infer<typeof DeviceTypeResponseSchema>;
export type TDeviceType = z.infer<typeof DeviceTypeSchema>;

export const DeviceTypeSchemaParser = DeviceTypeResponseSchema.nullish().transform(
  (assetType: TDeviceTypeResponse | null | undefined): TDeviceType | undefined => {
    switch (assetType) {
      case 'Temperature': {
        return 'TEMPERATURE';
      }
      case 'Pressure': {
        return 'PRESSURE';
      }
      case 'Leak': {
        return 'LEAK';
      }
      case 'FlowMeter':
      case 'PulseMeter': {
        return 'PULSE_METER';
      }
      case 'Equipment': {
        return 'EQUIPMENT';
      }
      case 'Hub': {
        return 'HUB';
      }
      default: {
        return undefined;
      }
    }
  }
);

export const UpdateDeviceTypeSchema = DeviceTypeSchema.nullish().transform(
  (deviceType: TDeviceType | null | undefined): TDeviceTypeResponse | null => {
    if (!deviceType) {
      return null;
    }

    switch (deviceType) {
      case 'TEMPERATURE': {
        return 'Temperature';
      }
      case 'PRESSURE': {
        return 'Pressure';
      }
      case 'LEAK': {
        return 'Leak';
      }
      case 'PULSE_METER': {
        return 'FlowMeter';
      }
      case 'EQUIPMENT': {
        return 'Equipment';
      }
      case 'HUB': {
        return 'Hub';
      }
    }
  }
);

export const UpdateAssetTypeSchema = AssetTypeSchema.nullish().transform(
  (assetType: TAssetType | null | undefined): TAssetTypeResponse | null => {
    if (!assetType) {
      return null;
    }

    switch (assetType) {
      case 'LOCATION': {
        return 'Location';
      }
      case 'EQUIPMENT': {
        return 'Equipment';
      }
      case 'DEVICE': {
        return 'Device';
      }
    }
  }
);

export const UpdateNodeTypeSchema = NodeTypeSchema.nullish().transform(
  (assetType: TNodeType | null | undefined): TNodeTypeResponse | null => {
    if (!assetType) {
      return null;
    }

    switch (assetType) {
      case 'ASSET': {
        return 'Asset';
      }
      case 'ATTACHMENT': {
        return 'Attachment';
      }
      case 'TEE': {
        return 'Tee';
      }
    }
  }
);

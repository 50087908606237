import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const Device = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string().optional(),
  createdByUserId: z.string().optional(),
  createdDateTime: z.string().optional(),
  modifiedByUserId: z.string().optional(),
  modifiedDateTime: z.string().optional(),
  manufacturerId: z.string().optional(),
  model: z.string().optional(),
  description: z.string().optional(),
  manufacturer: z.string().optional(),
  deviceType: z.string().optional(),
  locationId: z.string().optional(),
});
export type TDevice = z.infer<typeof Device>;

export enum EQUIPMENT_TYPE {
  BOILER = 'Boiler',
  STEAM_BOILER = 'SteamBoiler',
  VALVE_CONTROLLER = 'ValveController',
  HEAT_PUMP = 'HeatPump',
  DIGITAL_MIXING_VALVE = 'DigitalMixingValve',
  LEAK_DEFENSE_SYSTEM = 'LeakPrevention',
  PRESSURE_REDUCING_VALVE = 'PressureReducingValve',
  PUMP = 'Pump',
  SHUT_OFF_VALVE = 'ShutOffValve',
  HOT_WATER_HEATER = 'HotWaterHeater',
  WATER_TANK = 'WaterTank',
  NONE = 'None',
  PARTITION_CONTROLLER = 'PartitionController',
}

export enum MODEL {
  SENTINEL = 'LDS',
  SENTINEL_POLD = 'POLD',
  SENTINEL_API = 'API',
  SENTINEL_VALVE = 'LDSValve',
  INTELLISTATION = 'Intellistation',
  TEKMAR_SMART_BOILER = '294',
  TEKMAR_SMART_STEAM = '289',
  TEKMAR_HEAT_PUMP = '291',
  INTELLISTATION2 = 'IntelliStation2',
  AERCO_BOILER_LN = 'BMKBoilerLN',
  AERCO_BOILER_DUAL = 'BMKBlrLNDual',
  AERCO_WATER_HEATER_INNOVATION = 'Innovation',
  AERCO_WATER_HEATER_N = 'InnovationN',
  PVI_BRIGADE = 'Brigade',
  PARTITION = 'Partition',
  CONQUEST_100_20L100A_GCL = 'Conquest100_20L100A_GCL',
  CONQUEST_100_25L100A_GCL = 'Conquest100_25L100A_GCL',
  CONQUEST_100_30L100A_GCL = 'Conquest100_30L100A_GCL',
  CONQUEST_130_40L130A_GCML = 'Conquest130_40L130A_GCML',
  CONQUEST_130_50L130A_GCML = 'Conquest130_50L130A_GCML',
  CONQUEST_130_60L130A_GCML = 'Conquest130_60L130A_GCML',
  CONQUEST_130_70L130A_GCML = 'Conquest130_70L130A_GCML',
  CONQUEST_130_80L130A_GCML = 'Conquest130_80L130A_GCML',
  CONQUEST_130_90L130A_GCML = 'Conquest130_90L130A_GCML',
  CONQUEST_130_100L130A_GCML = 'Conquest130_100L130A_GCML',
  CONQUEST_1100_CQT1100 = 'Conquest1100_CQT1100',
  CONQUEST_1200_CQT1200 = 'Conquest1200_CQT1200',
  CONQUEST_130_40L130A_GCMLW = 'Conquest130_40L130A_GCMLW',
  CONQUEST_130_50L130A_GCMLW = 'Conquest130_50L130A_GCMLW',
  CONQUEST_130_60L130A_GCMLW = 'Conquest130_60L130A_GCMLW',
  CONQUEST_130_70L130A_GCMLW = 'Conquest130_70L130A_GCMLW',
  CONQUEST_130_80L130A_GCMLW = 'Conquest130_80L130A_GCMLW',
  CONQUEST_1100_CQTO1100 = 'Conquest1100_CQTO1100',
  CONQUEST_1200_CQTO1200 = 'Conquest1200_CQTO1200',
  VALVE_CONTROLLER_DT503 = 'ValveControllerDT503',
  VALVE_CONTROLLER_DT553 = 'ValveControllerDT553',
}

export enum BRAND {
  BRADLEY = 'Bradley',
  PVI = 'PVI',
  MONNIT = 'Monnit',
  AERCO = 'Aerco',
  WATTS = 'Watts',
  POWERS = 'Powers',
  SENTINEL = 'Sentinel',
  NEXA = 'Nexa',
}

export const EquipmentTypeEnum = z.nativeEnum(EQUIPMENT_TYPE);
export type TEquipmentType = z.infer<typeof EquipmentTypeEnum>;
export const ModelEnum = z.nativeEnum(MODEL);
export type TModel = z.infer<typeof ModelEnum>;
export const BrandEnum = z.nativeEnum(BRAND);
export type TBrand = z.infer<typeof BrandEnum>;

// TODO during refactoring TEquipment & TDevice update types - probably nullish is redundant and can be replaced with nullable
export const Equipment = z
  .object({
    id: z.string(),
    name: z.string(),
    registrationStatusChangeDate: z.string().nullish(),
    locationId: z.string().nullable(),
    locationName: z.string().nullish(),
    serialNumber: z.string().optional().nullable(), // TODO: remove optional after switch to endpoint with filtering
    brand: z.string().optional().nullable(), // TODO: remove optional after switch to endpoint with filtering
    model: z.string().nullable(),
    description: z.string().optional().nullable(), // TODO: remove optional after switch to endpoint with filtering
    deviceCount: z.number().nullish(),
    commissionDate: z.string().nullish(),
    organizationId: z.string().nullish(),
    registrationCode: z.string().nullish(),
    isRegistered: z.boolean().optional(),
    type: EquipmentTypeEnum.optional(),
    devices: z.any().array().optional(),
    automationCount: z.number().optional(),
    fixedId: z.string().nullish(),
    additionalProperties: z.record(z.string().nullable()).nullish(),
    // TODO: Remove after mobile adjustment to brand
    manufacturer: z.string().nullable().optional(),
    locationType: z.string().optional().nullable(),
    parentDeviceId: z.string().optional().nullable(),
    parentDeviceType: z.string().optional().nullable(),
  })
  .transform((data) => ({
    ...data,
    brand: data.brand || undefined,
    model: data.model || undefined,
    description: data.description || undefined,
    serialNumber: data.serialNumber || undefined,
    commissionDate: data.commissionDate || undefined,
    locationId: data.locationId || undefined,
    locationName: data.locationName || undefined,
    fixedId: data.fixedId || undefined,
    deviceCount: data.deviceCount || data.devices?.length || 0,
    location: { id: data.locationId, name: data.locationName },
    devices:
      data.devices?.map((device) => ({
        ...device,
        lastReadingValues: device.lastReadingValues?.map((lastReadingValue: TLastReadingValue) => ({
          ...lastReadingValue,
          name: lastReadingValue.name,
        })),
      })) || [],
    // TODO: Remove after mobile adjustment to brand
    manufacturer: data.manufacturer || undefined,
  }));
//TODO: for now we just change Equipment a little bit - in the future we should use TDevice from @marlin/shared/utils/zod
type TEquipmentBase = z.infer<typeof Equipment>;
export type TEquipment = Omit<TEquipmentBase, 'devices'> & { devices?: IDevice[] };

export const LastReadingValue = z
  .object({
    name: z.string(),
    value: z.string(),
    isDefault: z.boolean(),
    lastReadingTime: z.string(),
    unitOfMeasure: UnitOfMeasureType,
  })
  .transform((data) => ({
    ...data,
    name: data.name,
  }));

export type TLastReadingValue = z.infer<typeof LastReadingValue>;

// todo [check types] those types was moved from apps dir without checking it
interface IDevice {
  id: string;
  deviceId?: string;
  createdAt?: number;
  createdByUserId: string;
  createdDateTime: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  manufacturerId?: string;
  deviceType: string;
  locationId?: string;
  equipmentId?: string;
  manufacturer?: string;
  model?: string;
  description?: string;
  lastReadingValues?: Array<TLastReadingValue>;
  lastReadingTime?: string;
}

export enum AERCO_UNIT_STATUS {
  Disabled = '0',
  Standby = '1',
  Manual = '2',
  Remote = '3',
  Auto = '4',
  Fault = '5',
}

export enum AERCO_USER_ROLES {
  Client = '1',
  Manager = '2',
}

export const registerableEquipmentModels: string[] = [
  ModelEnum.enum.INTELLISTATION,
  ModelEnum.enum.TEKMAR_SMART_BOILER,
  ModelEnum.enum.TEKMAR_SMART_STEAM,
  ModelEnum.enum.TEKMAR_HEAT_PUMP,
  ModelEnum.enum.AERCO_BOILER_DUAL,
  ModelEnum.enum.AERCO_BOILER_LN,
  ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION,
  ModelEnum.enum.AERCO_WATER_HEATER_N,
  ModelEnum.enum.SENTINEL,
];

export const gatewayEquipmentModels: string[] = [
  ModelEnum.enum.PARTITION,
  ModelEnum.enum.PVI_BRIGADE,
  ModelEnum.enum.CONQUEST_100_20L100A_GCL,
  ModelEnum.enum.CONQUEST_100_25L100A_GCL,
  ModelEnum.enum.CONQUEST_100_30L100A_GCL,
  ModelEnum.enum.CONQUEST_1100_CQT1100,
  ModelEnum.enum.CONQUEST_1100_CQTO1100,
  ModelEnum.enum.CONQUEST_1200_CQT1200,
  ModelEnum.enum.CONQUEST_1200_CQTO1200,
  ModelEnum.enum.CONQUEST_130_100L130A_GCML,
  ModelEnum.enum.CONQUEST_130_40L130A_GCML,
  ModelEnum.enum.CONQUEST_130_40L130A_GCMLW,
  ModelEnum.enum.CONQUEST_130_50L130A_GCML,
  ModelEnum.enum.CONQUEST_130_50L130A_GCMLW,
  ModelEnum.enum.CONQUEST_130_60L130A_GCML,
  ModelEnum.enum.CONQUEST_130_60L130A_GCMLW,
  ModelEnum.enum.CONQUEST_130_70L130A_GCMLW,
  ModelEnum.enum.CONQUEST_130_70L130A_GCML,
  ModelEnum.enum.CONQUEST_130_80L130A_GCML,
  ModelEnum.enum.CONQUEST_130_80L130A_GCMLW,
  ModelEnum.enum.CONQUEST_130_90L130A_GCML,
];

import { TEquipmentAlert } from '@marlin/asset/data-access/equipment';
import { createContext, useContext } from '@marlin/shared/utils-component-context';

import { useAlerts } from './use-alerts';

interface IEquipmentAlertsContext {
  alerts: TEquipmentAlert[];
  getAlertsForDatapoint: (datapointName: string) => TEquipmentAlert[];
  isLoading: boolean;
}

export const EquipmentAlertsContext = createContext<IEquipmentAlertsContext>();

export const EquipmentAlertsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { alerts, getAlertsForDatapoint, isLoading } = useAlerts();

  return (
    <EquipmentAlertsContext.Provider value={{ alerts, getAlertsForDatapoint, isLoading }}>
      {children}
    </EquipmentAlertsContext.Provider>
  );
};

export const useEquipmentAlertsContext = () => useContext(EquipmentAlertsContext);

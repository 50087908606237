import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Paper } from '@mui/material';
import React from 'react';

import { useDatapointsContext } from '../../../../shared/context/datapoints.context';
import { useEquipmentContext } from '../../../../shared/context/equipment.context';
import { CommandsMenu } from '../commands/commands-menu.component';
import { useStyles } from './dynamic-settings.styles';
import { SettingsRow } from './settings-row.component';
import { useDynamicSettingsConfig } from './use-dynamic-settings-config.hook';

export const DynamicSettings = () => {
  const { classes } = useStyles();
  const { settings } = useDynamicSettingsConfig();
  const { equipment } = useEquipmentContext();
  const { getDatapoint } = useDatapointsContext();

  if (!settings?.dynamicSettingsSections) {
    return null;
  }

  return (
    <div>
      {settings.dynamicSettingsSections?.map((section, index) => (
        <Paper key={section.id} variant="outlined" className={classes.settingsSection}>
          <Accordion
            className={classes.accordion}
            data-testid={`dynamic-settings-section-${section.id}`}
            defaultExpanded
            elevation={0}
            slotProps={{ transition: { unmountOnExit: true } }}
          >
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
              {section.title}
              {section.commandGroup && (
                <div className={classes.commandsMenu}>
                  <CommandsMenu
                    manufacturer={equipment?.devices?.[0]?.manufacturer || ''}
                    manufacturerId={equipment?.devices?.[0]?.manufacturerId?.split('-')[1] || ''}
                    model={equipment?.devices?.[0]?.model || ''}
                    valveNames={section.settings.map((setting) => getDatapoint(setting.label)?.value ?? setting?.label)}
                    commandGroup={section.commandGroup}
                  />
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.paper}>
              {section.settings.map((setting) => (
                <SettingsRow setting={setting} key={setting.name} functionsMapping={settings.functionsMapping} />
              ))}
            </AccordionDetails>
          </Accordion>
        </Paper>
      ))}
    </div>
  );
};

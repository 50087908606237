import { TMultiOrgRole } from '@marlin/shared/utils-role';

export enum INVITATION_STATUS {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface IResendInvite {
  firstName: string;
  lastName: string;
  title?: string | null;
  email: string;
  role: TMultiOrgRole;
}

export interface IDeleteUser {
  id: string;
  firstName: string;
  lastName: string;
}

export interface IUserStatusProps {
  role?: TMultiOrgRole | null;
  invitationStatus?: INVITATION_STATUS | null;
}

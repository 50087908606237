import { Tab, Tabs } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { PropsWithChildren, useMemo } from 'react';

import { MarlinTable } from './marlin-table.component';
import { useStyles } from './tab-table.styles';
import { IDataHookBaseParams, IMarlinTableProps } from './types';

interface ITab<
  TabType extends string,
  TableType extends object,
  TDataHookParams extends IDataHookBaseParams = IDataHookBaseParams,
  TMetadata extends object = object,
> extends Omit<IMarlinTableProps<TableType, TDataHookParams, TMetadata>, 'createColumns'> {
  id: TabType;
  name: string;
  useColumns: () => (params: { columnProps: Partial<GridColDef>; rows: TableType[] }) => GridColDef[];
}

interface ITabTableProps<
  TabType extends string,
  TableType extends object,
  TDataHookParams extends IDataHookBaseParams = IDataHookBaseParams,
  TMetadata extends object = object,
> extends PropsWithChildren {
  selectedTab: TabType;
  tabs: ITab<TabType, TableType, TDataHookParams, TMetadata>[];
  changeTab: (tab: TabType) => void;
  dataHookFilters?: Partial<TDataHookParams>;
  renderTabLabel?: (tab: ITab<TabType, TableType, TDataHookParams, TMetadata>, metadata?: TMetadata) => React.ReactNode;
}

export const TabTable = <
  TabType extends string,
  TableType extends object,
  TDataHookParams extends IDataHookBaseParams = IDataHookBaseParams,
  TMetadata extends object = object,
>({
  selectedTab,
  tabs,
  changeTab,
  dataHookFilters,
  renderTabLabel,
  children,
}: ITabTableProps<TabType, TableType, TDataHookParams, TMetadata>) => {
  const { classes } = useStyles();
  const { useDataHook, useColumns, texts, actions, buttons, options } = useMemo(
    (): ITab<TabType, TableType, TDataHookParams, TMetadata> => tabs.find((tab) => tab.id === selectedTab) ?? tabs[0],
    [tabs, selectedTab]
  );
  const createColumns = useColumns();

  return (
    <MarlinTable<TableType, TDataHookParams, TMetadata>
      useDataHook={useDataHook}
      createColumns={createColumns}
      options={options}
      actions={actions}
      texts={texts}
      buttons={buttons}
      dataHookFilters={dataHookFilters}
      header={(metadata) => {
        return (
          <>
            <Tabs value={selectedTab} onChange={(_event, value) => changeTab(value)} className={classes.tabs}>
              {tabs.map((tab) => {
                const label = renderTabLabel ? renderTabLabel(tab, metadata) : tab.name;
                return <Tab value={tab.id} label={label} className={classes.tab} />;
              })}
            </Tabs>
            {children && <div className={classes.header}>{children}</div>}
          </>
        );
      }}
    />
  );
};

import { z } from 'zod';

export const deviceModel = z.union([z.literal('LDSValve'), z.literal('POLD'), z.string()]);

export const deviceChildrenDatapointsParamsSchema = z.object({
  deviceId: z.string(),
  modelName: z.string().optional(),
  page: z.number(),
  pageSize: z.number(),
  sortBy: z.string().optional(),
  direction: z.union([z.literal('Ascending'), z.literal('Descending')]).optional(),
  datapoints: z.record(z.string(), z.string()).optional(),
});

export type TDeviceChildrenDatapointsParams = z.infer<typeof deviceChildrenDatapointsParamsSchema>;

const deviceChildrenDatapointResponseSchema = z
  .object({
    DeviceId: z.string(),
    DeviceName: z.string(),
    DeviceType: z.string(),
    Model: deviceModel,
    Brand: z.string(),
    LastReadingTime: z.string(),
  })
  .catchall(z.string())
  .transform((data) => ({
    id: data.DeviceId,
    ...data,
  }));

const paginationSchema = z.object({
  page: z.number(),
  pageSize: z.number(),
  totalItems: z.number(),
});

const totalItemsSchema = z.record(deviceModel, z.number());

export const deviceChildrenDatapointsResponseSchema = z.object({
  data: z.array(deviceChildrenDatapointResponseSchema),
  pagination: paginationSchema,
  totalItems: totalItemsSchema,
});

export type TDeviceChildrenDatapoint = z.infer<typeof deviceChildrenDatapointResponseSchema>;
export type TDeviceChildrenDatapointsResponse = z.infer<typeof deviceChildrenDatapointsResponseSchema>;
export type TTotalItems = z.infer<typeof totalItemsSchema>;

import { TValveState } from '@marlin/asset/data-access/equipment';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback, useMemo } from 'react';

import { content } from '../../../../content';

interface IEnqueueSuccessSnackbarsProps {
  valveNames: string[];
  valveState: TValveState;
}

interface IUseValveCommandSnackbar {
  enqueueSuccessSnackbars: (props: IEnqueueSuccessSnackbarsProps) => void;
  enqueueErrorSnackbar: () => void;
  enqueueSuccessSnackbar: () => void;
}

export const useCommandSnackbar = (): IUseValveCommandSnackbar => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSuccessSnackbars = useCallback(
    ({ valveNames, valveState }) => {
      valveNames.toReversed().forEach((valveName: string) => {
        const message =
          valveState === 'Open'
            ? content.COMMANDS.SNACKBAR.SUCCESS.OPEN(valveName)
            : content.COMMANDS.SNACKBAR.SUCCESS.CLOSE(valveName);

        enqueueSnackbar(message, {
          variant: 'success',
          preventDuplicate: true,
        });
      });
    },
    [enqueueSnackbar]
  );

  const enqueueErrorSnackbar = useCallback(
    () =>
      enqueueSnackbar(content.COMMANDS.SNACKBAR.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      }),
    [enqueueSnackbar]
  );

  const enqueueSuccessSnackbar = useCallback(
    () =>
      enqueueSnackbar(content.COMMANDS.SNACKBAR.SUCCESS.GENERIC, {
        variant: 'success',
        preventDuplicate: true,
      }),
    [enqueueSnackbar]
  );

  return useMemo(
    (): IUseValveCommandSnackbar => ({ enqueueSuccessSnackbars, enqueueErrorSnackbar, enqueueSuccessSnackbar }),
    [enqueueSuccessSnackbars, enqueueErrorSnackbar, enqueueSuccessSnackbar]
  );
};

import { TValveState } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';

interface IValveCommandModalBodyProps {
  valveState: TValveState;
  valveNames: string[];
  confirmation?: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  body: {
    lineHeight: 1.6,
  },
  list: {
    margin: 0,
    paddingInlineStart: theme.typography.pxToRem(28),
  },
}));

export const ValveCommandModalBody = ({ valveState, valveNames, confirmation }: IValveCommandModalBodyProps) => {
  const { classes } = useStyles();

  const label = useMemo(() => {
    if (confirmation) {
      return valveState === 'Open'
        ? content.ACTUATE_VALVES.MODAL.CONFIRMATION.OPEN
        : content.ACTUATE_VALVES.MODAL.CONFIRMATION.CLOSE;
    }
    return valveState === 'Open'
      ? content.ACTUATE_VALVES.MODAL.COMMAND.OPEN
      : content.ACTUATE_VALVES.MODAL.COMMAND.CLOSE;
  }, [confirmation, valveState]);

  return (
    <div data-testid="valve-command-modal-body" className={classes.body}>
      {label}
      <b>
        <ul data-testid="valve-command-modal-valve-list" className={classes.list}>
          {valveNames.map((valveName) => (
            <li data-testid="valve-command-modal-valve-name" key={valveName}>
              {valveName}
            </li>
          ))}
        </ul>
      </b>
    </div>
  );
};

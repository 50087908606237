import { IDevice } from '@marlin/asset/data-access/device';
import { CommissionDateField } from '@marlin/shared/ui-form';
import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
import { TUpsertGatewayForm } from '@marlin/shared/utils/gateway-utils';
import { ASSET_TYPE } from '@marlin/system-map/ui/flow-link-upsert-form';
import { Button, SelectChangeEvent } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { content } from '../shared/content';
import { useHubUpsertForm } from '../shared/hooks/use-hub-update-form.hook';
import { TUpsertHubUpdateForm } from '../shared/utils/hub-form.utils';
import { useNavigation } from './hooks/use-navigation.hook';
import { useStyles } from './hub-update.component';
import { UpdateHubLeaveGuard } from './update-hub-leave-guard.component';

interface IHubFormComponent {
  locationsList: { name: string; type: ASSET_TYPE; id: string }[] | undefined;
  onSubmit: (formValues: TUpsertHubUpdateForm) => Promise<void>;
  hub: IDevice;
}

export const HubFormComponent = ({ locationsList, onSubmit, hub }: IHubFormComponent) => {
  const { classes } = useStyles();
  const { form, errors, dateValidationErrorMessage, setDateValidationErrorMessage, disableSubmitButton } =
    useHubUpsertForm({ defaultValues: hub });
  const navigation = useNavigation();
  const navigate = useNavigate();
  const { gatewayId } = useParams();
  const gatewayDashboard = navigation.getGatewayDetailsPageLink(gatewayId ?? '');

  const handleOnCancel = () => {
    navigate(gatewayDashboard);
  };

  return (
    <>
      <FormProvider {...form}>
        <div className={classes.formSection}>{content.GATEWAY_LABEL}</div>
        <FormField<TUpsertGatewayForm> fieldName="name">
          {(props) => (
            <Input
              {...props}
              className={classes.input}
              label={content.GATEWAY_NAME}
              required
              testId="gateway-name-field"
              error={
                errors.name && {
                  type: 'custom',
                  message: errors.name.message,
                }
              }
            />
          )}
        </FormField>
        <FormField<TUpsertGatewayForm> fieldName="locationId">
          {(props) => (
            <Select
              {...props}
              required
              className={classes.select}
              prefix="locationId"
              label={content.LOCATION_NAME}
              data={locationsList || []}
              onChange={(e: SelectChangeEvent) => {
                form.clearErrors('locationId');
                props.onChange && props.onChange(e);
              }}
              error={errors.locationId}
              requiredErrorMessageOnBlur={content.REQUIRED}
            />
          )}
        </FormField>

        <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION_LABEL}</div>
        <CommissionDateField
          disabled
          setDateValidationErrorMessage={setDateValidationErrorMessage}
          dateValidationErrorMessage={dateValidationErrorMessage}
        />
        <FormField<TUpsertGatewayForm> fieldName="description">
          {(props) => (
            <Input
              {...props}
              className={classes.input}
              label={content.DESCRIPTION}
              testId="description-field"
              maxLength={500}
              multiline
            />
          )}
        </FormField>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant="outlined"
            data-testid="gateway-cancel-button"
            onClick={handleOnCancel}
          >
            {content.CANCEL}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            data-testid="gateway-submit-button"
            onClick={form.handleSubmit(onSubmit)}
            disabled={disableSubmitButton}
          >
            {content.UPDATE}
          </Button>
        </div>
      </FormProvider>
      <UpdateHubLeaveGuard
        shouldDisplayGuard={
          form.formState.isDirty && (!form.formState.isSubmitSuccessful || !form.formState.isSubmitting)
        }
      />
    </>
  );
};

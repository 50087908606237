export const content = {
  EMPTY_DATAPOINT_VALUE: '--',
  ERROR: 'Something went wrong. Please try again later.',
  DASHBOARD_CONNECTION_ERROR: `There is no connection. Check your connection and try to refresh the page.`,
  DASHBOARD_CONNECTION_ERROR_NATIVE: `There is no connection. Check your connection and try again.`,
  ROLE: {
    CLIENT: 'Client',
    MANAGER: 'Manager',
  },
  MODEL: 'Model',
  SANITIZATION_STOPPED: 'Sanitization mode has been stopped.',
  DATAPOINT_ERROR: 'ERROR',
  SYSTEM_STATUS: 'System Status',
  EQUIPMENT_METRICS: 'Equipment Metrics',
  AUTOMATIONS: 'Automations',
  FIRMWARE_VERSION: 'Firmware Version',
  EQUIPMENT_ID: 'Equipment ID',
  GATEWAY_ID: 'Gateway ID',
  TEMPERATURE: 'Temperature',
  SCHEDULE: 'Schedule',
  SYSTEM: 'System',
  ALERT_SETTINGS: 'Alerts',
  CENTRAL_HEATING: 'Central Heating',
  DOMESTIC_HOT_WATER: 'Domestic Hot Water',
  CONSTANT_SETPOINT: 'Constant Setpoint',
  REMOTE_SETPOINT: 'Remote Setpoint',
  EQUIPMENT_NAME: 'Equipment Name',
  SENSOR_TEMPERATURE: 'Temperature Sensors',
  SENSOR_PRESSURE: 'Pressure Sensors',
  SENSOR_LEAK: 'Leak Sensor',
  LABEL_LOCATION: 'Location',
  LABEL_SEARCH: 'Search',
  CLEAR_ALL: 'Clear All',
  DELETE_BUTTON: 'Delete',
  EDIT_BUTTON: 'Edit',
  SYSTEM_INPUTS: 'System Inputs',
  FLOW_SENSORS: 'Flow Sensors',
  VALVES: 'Valves',
  PUMPS: 'Pumps',
  SYSTEM_OUTPUTS: 'System Outputs',
  SYSTEM_OUTPUTS_AND_CONDITION: 'System Outputs and Special Conditions',
  OUTPUTS: 'Outputs',
  GATEWAY_NAME: 'Gateway Name',
  MODBUS_ADDRESS: 'Modbus Address',
  DESCRIPTION: 'Description',
  REGISTRATION_DATE: 'Registration Date',
  COMMISSION_DATE: 'Commission Date',
  SIGNAL_STRENGTH: 'Signal Strength',
  EQUIPMENT_ID_TOOLTIP: 'This is the unique identifier assigned to your equipment controller.',
  SENSORS: 'Sensors',
  ON: 'On',
  OFF: 'Off',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  OCCUPIED_ONE: 'Occupied 1',
  OCCUPIED_TWO: 'Occupied 2',
  SKIP: 'Skip',
  SCHEDULE_TOOLTIP_TITLE: 'If the schedule is set to on:',
  TIMEZONE: 'Time zone',
  SCHEDULE_TOOLTIP_ROW_1: 'The Mixed Outlet Setpoint is the temperature during the occupied period.',
  SCHEDULE_TOOLTIP_ROW_2:
    'The temperature will be reduced by the Mixed Setback Differential during unoccupied periods.',
  OCCUPIED_DATAPOINT: (val: string) => `${val} (occupied)`,
  EQUIPMENT_DEREGISTERED_WARNING: 'Your equipment has been deregistered from the organization',
  SETTINGS_DISABLED_TOOLTIP:
    'System in sanitization mode, please stop sanitization from the equipment before changing any settings.',
  TIMEZONE_VALUE: (value: string) => `Timezone: ${value}`,
  EQUIPMENT_READINGS_AERCO: {
    TEMPERATURES: 'Temperatures',
    BOILER_PERFORMANCE: 'Boiler Performance',
    WATER_HEATER_PERFORMANCE: 'Water Heater Performance',
    BOILER_METRICS: 'Boiler Metrics',
    WATER_HEATER_METRICS: 'Water Heater Metrics',
    ADDITIONAL_BOILER_INFO: 'Additional Boiler Info',
    ADDITIONAL_WATER_HEATER_INFO: 'Additional Water Heater Info',
  },
  EQUIPMENT_READINGS_PVI: {
    TEMPERATURES: 'Temperatures',
    WATER_HEATER_PERFORMANCE: 'Water Heater performance',
    EQUIPMENT_METRICS: 'Equipment Metrics',
    WATER_HEATER_METRICS: 'Water Heater Metrics',
    ADDITIONAL_WATER_HEATER_INFO: 'Additional Water Heater Info',
    RUN_HOURS: 'Run Hours',
    CYCLE_COUNT: 'Cycle Count',
    HEATER_ENABLE: 'Heater Enable',
    PUMP_1_STATUS: 'Pump 1 Status',
  },
  SETPOINT: 'Setpoint',
  CHART_TITLE_MONITOR_INTELLISTATION: 'Trends',
  CONTROLLER_FIXED_ID: 'Controller Fixed ID',
  CONTROLLER_IGST_VERSION: 'Controller IGST Version',
  LAST_HEARTBEAT: 'Last Heartbeat',
  AGE_OF_LAST_HEARTBEAT: 'Age of Last Heartbeat',
  FAULTS_ENABLED_DISABLED: 'Faults Enabled/Disabled',
  EXTENDED_SHUTDOWN_PERIOD: 'Extended Shutdown Period',
  START_TIME: 'Start Time',
  STATUS: 'Status',
  NEW: 'New',
  RESOLVED: 'Resolved',
  CRITICALITY: 'Criticality',
  EVENT_TYPE: 'Event Type',
  TITLE: 'Title',
  RESOLVED_TIME: 'Resolved Time',
  VALUE_CHANGED: 'Value Changed',
  NEW_VALUE_LABEL: 'New Value',
  ORIGINAL_VALUE: 'Original Value',
  GENERATED_BY: 'Generated By',
  HEATING_CALLS_AND_TARGET: 'Heating Calls & Targets',
  MIX_CALLS_AND_TARGETS: 'Mix Calls & Targets',
  CALLS_AND_TARGET: 'Calls & Target',
  HEATING_CALLS: 'Heating Calls',
  SYSTEM_EQUIPMENT: 'System Equipment',
  FILTERS: {
    LABEL_SEARCH: 'Text Search',
  },
  EVENT_LOG_DETAILS: {
    MANUAL_LINK: 'Go to Help Manual',
    EMAIL_MSG: 'Email',
    TEXT_MSG: 'Text',
    HIGH: 'High',
    LOW: 'Low',
    RESOLVED: 'Resolved',
    NEW: 'New',
    HEALTH: 'Health',
    PERFORMANCE: 'Performance',
    START_TIME: 'Start Time',
    RESOLVE_TIME: 'Resolved Time',
    CLOSE: 'Close',
    SETTING_CHANGE: 'Setting Change',
    ACTIONS: 'Actions',
    HIERARCHY: 'Hierarchy',
    CHANGELOG: 'Changelog',
    DESCRIPTION: 'Description',
  },
  THRESHOLD_ERROR: (val: string, isMaxExceeded: boolean) =>
    `You cannot set the temperature ${
      isMaxExceeded ? 'above' : 'below'
    } ${val} remotely. You must change it on the equipment locally to set it ${
      isMaxExceeded ? 'above' : 'below'
    } this threshold.`,
  LOCAL_ADMIN: 'Local Admin',
  MOBILE_FILTERS: {
    HIDE_FILTERS: 'Hide Filters',
  },
  OCCUPANCY_COUNT_AND_TIME: 'Sensor Occupancy Count and Occupancy Time',
  RESET: 'Reset',
  RESET_MODAL: {
    TITLE: 'Reset Occupancy',
    BODY_TEXT: 'Are you sure you want to reset all door counts and occupancy times?',
    BODY_CONFIRMATION: 'This action cannot be undone.',
    CANCEL: 'Cancel',
    SUBMIT: 'Reset',
  },
  RESET_MESSAGE: 'The occupancy count and times have been reset.',
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
  SORT_BY: 'Sort By',
  BACK: 'Back',
  APP_FIRMWARE_VERSION: 'Application Firmware Version',
  BOOTLOADER_FIRMWARE_VERSION: 'Bootloader Firmware Version',
  BRAND: 'Brand',
  CONTROLLER_ID: 'Controller ID',
  SERIAL_NUMBER: 'Serial Number',
  RUNTIME: 'Run Time',

  BOILERS_AND_PUMPS: {
    COLUMNS: {
      NAME: 'Name',
      HOURS: 'Hours',
      CYCLES: 'Cycles',
      PUMP_STATUS: 'Pump Status',
      BOILER_STATUS: 'Boiler Status',
      BOILER: 'Boiler',
    },
    STATUS: {
      NOT_INSTALLED: 'Not Installed',
      NOT_USED: 'Not Used',
      FIRING: 'Firing',
      MIN_OFF: 'Min Off',
      OFF: 'Off',
      ON: 'On',
      DISABLED: '0%',
    },
  },
  DATAPOINT_LABELS: {
    OUTLET_TEMP: 'Mixed Outlet',
    SETPOINT_TEMP: 'Setpoint',
    MIXING_TEMP: 'Mixed Outlet',
    RECIRC_TEMP: 'Recirc Return',
    HOT_TEMP: 'Hot Inlet',
    COLD_TEMP: 'Cold Inlet',
    OUTLET_PRESSURE: 'Mixed Outlet',
    RECIRC_PRESSURE: 'Recirc Return',
    HOT_PRESSURE: 'Hot Inlet',
    COLD_PRESSURE: 'Cold Inlet',
    MIXING_VALVE: 'Mixed%',
    ISOLATION_VALVE: 'Isolation Valve',
    SYSTEM_PUMP_1: 'Pump 1',
    SYSTEM_PUMP_2: 'Pump 2',
    OUTLET_FLOW: 'Mixed Outlet',
    RECIRC_FLOW: 'Recirc Return',
    OUTLET: 'Outlet',
    AIR_INLET: 'Air Inlet',
  },
  DATAPOINT_SSC_LABELS: {
    ROOM_TARGET: 'Room Target',
    SYSTEM_AUXILIARY: 'Aux. Input',
    FLUE_TEMP: 'Flue Temperature',
    OVERRIDE_MODE: 'Override Mode',
    CONDENSATE: 'Condensate',
    TARGET: 'Target',
    OUTDOOR: 'Outdoor',
    INDOOR: 'Indoor',
    EARLY_START: 'Early Start',
    BOOST: 'Boost',
    WARM_WEATHER_SHUTDOWN: 'Warm Weather Shutdown',
    SYSTEM_SMART_SETBACK: 'Smart Setback',
    DHW_ENABLE: 'DHW Enable',
    OUTDOOR_TEMP: 'Outdoor Temperature',
    INDOOR_TEMP: 'Indoor Temperature',
    CONDENSATE_TEMP: 'Condensate Temperature',
    PRESSURE: 'Pressure',
    COMBUSTION_AIR: 'Combustion Air',
    BOILER: 'Boiler',
  },
  DATAPOINT_SBS_LABELS: {
    BOILER_SUPPLY_TEMP: 'Boiler Supply',
    TARGET: 'Target',
    PLANT_OUTPUT: 'Plant Output',
    COMBUSTION_AIR: 'Combustion Air',
    SYSTEM_PUMP: 'System Pump',
    DHW_PUMP: 'DHW Pump',
    DHW_PRIORITY: 'DHW Priority',
    WARM_WEATHER_SHUTDOWN: 'Warm Weather Shutdown',
    CENTRAL_HEATING: 'Central Heating',
    DHW_TARGER: 'DHW',
    SETPOINT_TARGET: 'Setpoint',
    MODE: 'Mode',
    OUTDOOR_TEMP: 'Outdoor Temperture',
    DHW_TANK: 'DHW Tank',
    PRESSURE: 'Pressure',
    BOILER: 'Boiler',
  },
  DATAPOINT_LABELS_AERCO: {
    UPPER_INLET: 'Upper Inlet',
    INLET: 'Inlet',
    LOWER_INLET: 'Lower Inlet',
    OUTDOOR: 'Outdoor',
    OUTDOOR_TEMPERATURE: 'Outdoor Temperature',
    EXHAUST_TEMP: 'Exhaust',
    EFFICIENCY: 'Efficiency',
    OXYGEN_LEVEL: 'Oxygen',
    FLAME_STRENGTH: 'Flame Strength',
    BLOWER_VOLTAGE: 'Blower Voltage',
    RUN_HOURS: 'Run Hours',
    CYCLE_COUNT: 'Cycle Count',
    CYCLES_PER_HOUR: 'Avg Cycles per Hour',
    PEAK_LOAD: 'Peak Load',
    START_LEVEL: 'Start Level',
    CONDENSING: 'Condensing',
    CALIBRATION: 'Calibration',
  },
  DATAPOINT_LABELS_PVI: {
    MID_TANK: 'Mid Tank',
    EXHAUST_TEMP: 'Exhaust',
    FLAME_STRENGTH: 'Flame Strength',
  },
  DATAPOINT_SHP_LABELS: {
    HOT_TANK_TARGET: 'Hot Tank Target',
    HOT_TANK_SUPPLY: 'Hot Tank Supply',
    COLD_TANK_TARGET: 'Cold Tank Target',
    COLD_TANK_SUPPLY: 'Cold Tank Supply',
    HOT_TANK: 'Hot Tank',
    DHW: 'DHW',
    DUAL_FUEL_CALL: 'Dual Fuel Call',
    COLD_TANK: 'Cold Tank',
    COLD_TANK_DEWPOINT: 'Cold Tank Dewpoint',
    MIX_1: 'Mix 1',
    MIX_2: 'Mix 2',
    MIX_3: 'Mix 3',
    MIX_1_DEWPOINT: 'Mix 1 Dewpoint',
    MIX_2_DEWPOINT: 'Mix 2 Dewpoint',
    MIX_3_DEWPOINT: 'Mix 3 Dewpoint',
    HOT_HP_RETURN: 'Hot HP Return',
    OUTDOOR: 'Outdoor',
    COLD_HP_RETURN: 'Cold HP Return',
    TRANSFER: 'Transfer',
    TANK: 'Tank',
    HP_RETURN: 'HP Return',
    WARM_WEATHER_SHUTDOWN: 'Warm Weather Shutdown',
    COLD_WEATHER_SHUTDOWN: 'Cold Weather Shutdown',
    OVERRIDES: 'Overrides',
    DHW_PRIORITY: 'DHW Priority',
    DHW_PRIORITY_OVERRIDE: 'DHW Priority Override',
    DUAL_FUEL_PRIORITY: 'Dual Fuel Priority',
    HEAT_COOL_PRIORITY: 'Heat/Cool Priority',
    HEAT_PUMPS: 'Heat Pumps',
    MIXING: 'Mixing',
    PUMPS: 'Pumps',
  },
  DATAPOINT_OPTIONS_LABELS: {
    ON: 'On',
    OFF: 'Off',
    OPEN: 'Open',
    CLOSED: 'Closed',
    OPENING: 'Opening',
    CLOSING: 'Closing',
    AUTO: 'Auto',
    OCCUPIED: 'Occupied',
    UNOCCUPIED: 'Unoccupied',
    NOT_USED: 'Not Used',
  },
  EQUIPMENT_STATUS_LABELS: {
    LOST_COMMUNICATION: 'Lost Communication',
    OFFLINE: 'Equipment Offline',
    OPERATIONAL: 'Equipment Operational',
    FAULT: 'Equipment Fault',
    NOT_INSTALLED: 'Not Installed',
    ERROR: 'Error',
    AUTOMATIC: 'Automatic',
    OVERRIDE: 'Override',
  },
  EQUIPMENT_TYPE: 'Equipment Type',
  EQUIPMENT_TYPES_OPTIONS: {
    NONE: '--',
    DIGITAL_MIXING_VALVE: 'Digital Mixing Valve',
    LEAK_DEFENSE_SYSTEM: 'Leak Defense System',
  },
  CHART_TITLE_SENTINEL: 'Trends',
  ALL_OPERATIONAL: 'All Operational',
  LOST_COMM: (lostComm: number) => `${lostComm} Lost Comm`,
  LOW_BATTERY: (lowBattery: number) => `${lowBattery} Low Battery`,
  NO_ALERT: 'No Alert',
  ALERT: 'Yes',
  LEAK: (leakCount: number) => `${leakCount} Leak Detected`,
  NO_LEAK: 'No Leak',
  LDS_SYSTEM_STATUS: {
    SYSTEM_ACTIVE: 'System Active',
    CHECK_POLDS: 'Check POLDs',
    CHECK_LDS: 'Check Valves',
    LEAK: 'Leak Detected',
    WATER_OFF: 'Water is Off',
    NO_LEAK: 'No Leak',
  },
  LDS_VALVE_STATUS: {
    OPEN: 'Open',
    CLOSED: 'Closed',
    OPENING: 'Valve Opening',
    CLOSING: 'Valve Closing',
  },
};

import { TopAlerts } from '@marlin/asset/shared/ui/top-alerts';
import { TEquipment } from '@marlin/shared/utils/zod';

import { usePlantAlerts } from '../../shared/hooks/use-plant-alerts.hook';

interface ITopAlerts {
  equipmentIds: string[];
  classes?: string;
  equipmentList: TEquipment[];
}

export const TopAlertsWrapper = ({ equipmentList, equipmentIds, classes }: ITopAlerts) => {
  const { topAlerts, isLoading } = usePlantAlerts({ equipmentIds, equipmentList });

  return <TopAlerts topOpenAlerts={topAlerts} isLoading={isLoading} classes={classes} isAssetDashboardSection />;
};

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tabs: {
    paddingTop: theme.typography.pxToRem(16),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    textTransform: 'none',
  },
  header: {
    padding: theme.typography.pxToRem(16),
  },
}));

import { useCreateRecipient } from '@marlin/account-data-access-recipient';
import { IUpsertRecipientProfile, RecipientUpsertForm } from '@marlin/account-ui-recipient-upsert-form';
import { MarlinTheme } from '@marlin/shared/theme';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ICreateRecipientErrorProps, errorHandler } from './error-handler';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
  },
}));

export const RecipientCreateForm = () => {
  const { classes } = useStyles();
  const { goTo } = useRouter();
  const { mutateAsync: createRecipient } = useCreateRecipient();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');

  const resetError = () => {
    setErrorMessage('');
  };

  const onError = (message: string) => {
    setErrorMessage(message);
  };

  const handleSubmit = (data: IUpsertRecipientProfile) => {
    return createRecipient({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phone,
    })
      .then(() => {
        enqueueSnackbar(content.SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
        goTo(routes.settings.people.url());
      })
      .catch((error: AxiosError<ICreateRecipientErrorProps>) => {
        errorHandler({ error, onError });
      });
  };

  const handleCancel = () => {
    goTo(routes.settings.people.url());
  };

  return (
    <Paper className={classes.formContainer} data-testid="card">
      <RecipientUpsertForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        resetError={resetError}
        errorMessage={errorMessage}
      />
    </Paper>
  );
};

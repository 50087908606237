import { TDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useRef } from 'react';
import { Subscription } from 'rxjs';
import { last, take } from 'rxjs/operators';

import { getDatapoints } from '../infrastructure/equipment';

export const useDatapointHeartbeatCounter = () => {
  const subscription = useRef<Subscription | null>(null);

  const reset = (count: number, callback?: (data: TDatapointsResponse) => void) => {
    if (!subscription.current) {
      subscription.current = getDatapoints()
        .pipe(take(count), last())
        .subscribe({
          next: (data) => {
            callback?.(data);
          },
          complete: () => {
            subscription.current = null;
          },
        });
    }
  };

  return { reset };
};

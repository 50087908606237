import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 0,
    borderBottom: 0,
    padding: `${theme.typography.pxToRem(8)} 0`,
    backgroundColor: theme.palette.background.primary,
  },
  btnGroup: {
    alignSelf: 'center',
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  button: {
    '&.MuiButton-outlined': {
      color: theme.palette.primary.main,
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&.MuiButtonGroup-firstButton': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&.MuiButtonGroup-firstButton:hover': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
}));

import { TDeviceType } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { deviceTypeOptions } from '@marlin/shared/ui-form';
import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(4),
  },

  active: {
    color: theme.palette.action.active,
    fontSize: theme.typography.pxToRem(18),
  },

  emptyOption: {
    height: '36px',
  },
}));

interface IDeviceName {
  name: string;
  deviceType?: TDeviceType;
  editModeToggle: () => void;
}

export const DeviceName = ({ name, deviceType, editModeToggle }: IDeviceName) => {
  const { classes } = useStyles();

  const Icon = deviceType ? deviceTypeOptions[deviceType].Icon : null;

  return (
    <span className={classes.deviceTypeOption}>
      {Icon && <Icon className={classes.active} />}
      <Link onClick={editModeToggle}>{name}</Link>
    </span>
  );
};

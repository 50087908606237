import { TEquipment, TLastReadingValue } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

import { useEquipmentsTelemetryContext } from '../shared/context/equipments-telemetry.context';
import { getDatapoint } from './utils/get-datapoint';

export interface IPerformance {
  equipmentList: TEquipment[];
}

export interface IPerformanceValue {
  performance: { name: string; label: string }[];
  lastReadingValues: TLastReadingValue[];
  manufacturerId: string | undefined;
}

export const usePerformanceValue = ({ performance, lastReadingValues, manufacturerId = '' }: IPerformanceValue) => {
  const { equipmentsTelemetry } = useEquipmentsTelemetryContext();
  const data = equipmentsTelemetry[manufacturerId] ?? undefined;

  const performanceTilesData = useMemo(() => {
    return (performance || []).map((performanceDatapoint) => {
      const datapointValues = getDatapoint(performanceDatapoint.name, lastReadingValues, data);

      return {
        value: datapointValues,
        ...performanceDatapoint,
      };
    });
  }, [performance, lastReadingValues, data]);

  return { performanceTilesData };
};

export const getManufacturerId = (manager: TEquipment | undefined) => {
  return manager?.devices?.[0]?.manufacturerId ?? '';
};

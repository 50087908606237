import { IRecipient, IRecipientList, useRecipientsWithLoadMore } from '@marlin/account-data-access-recipient';
import { useMemo } from 'react';

interface IInfiniteRecipientListProps {
  search: string;
}

export const useInfiniteRecipientList = ({ search }: IInfiniteRecipientListProps) => {
  const usersQuery = useRecipientsWithLoadMore({ search, pageSize: 5 });

  const rows: IRecipient[] = useMemo(() => {
    const unfilteredRows: (IRecipient | null)[] =
      usersQuery.data?.pages?.flatMap((i: IRecipientList) => i.data || []) || [];
    return unfilteredRows.filter((i: IRecipient | null): i is IRecipient => i !== null);
  }, [usersQuery.data?.pages]);

  return {
    rows,
    totalItems: usersQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: usersQuery.hasNextPage,
    fetchNextPage: usersQuery.fetchNextPage,
    refetch: usersQuery.refetch,
    isLoading: usersQuery.isLoading,
    isFetching: usersQuery.isFetching,
    isFetchingNextPage: usersQuery.isFetchingNextPage,
    isFetched: usersQuery.isFetched,
    isError: usersQuery.isError,
    error: usersQuery.error,
  };
};

export const content = {
  DETAILS: 'Details',
  EMPTY_DATAPOINT_VALUE: '--',
  EDIT: 'Edit',
  HUB_NAME: 'Hub Name',
  HUB_DETAILS: 'Hub Details',
  SERIAL_NUMBER: 'Serial Number',
  BRAND: 'Brand',
  FIRMWARE_VERSION: 'Firmware Version',
  COMMISSION_DATE: 'Commission Date',
  DESCRIPTION: 'Description',
  SENSOR_NAME: 'Sensor Name',
  SENSOR_TYPE: 'Sensor Type',
};

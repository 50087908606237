import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Button } from '@mui/material';

import { content } from '../../content';

interface IFilterButtonProps {
  onToggle: () => void;
  selectedFiltersCount?: number;
}

export const FilterButton = ({ onToggle, selectedFiltersCount }: IFilterButtonProps) => {
  return (
    <Button onClick={onToggle} startIcon={<FilterAltRoundedIcon />}>
      {content.TRIGGER.FILTERS(selectedFiltersCount ?? 0)}
    </Button>
  );
};

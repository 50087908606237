import { TUnitOfMeasure, getUomDescription, getUomSymbol } from '@marlin/shared/utils-format-reading';

import { TEnergy, TGas, TPressure, TTemperature, TWater } from '../../../../desktop/uom-settings/schema';

export interface ISettingsRadioOption<TValue> {
  id?: TValue;
  value: TValue;
  label: string;
}
const mapUomToOptions = <T extends TUnitOfMeasure>(uoms: T[]): ISettingsRadioOption<T>[] => {
  return uoms.map((uom) => ({
    id: uom,
    value: uom,
    label: getUomSymbol(uom) || '',
    labelDescription: getUomDescription(uom) || '',
  }));
};

export const gasOptions: ISettingsRadioOption<TGas>[] = mapUomToOptions(['scf', 'sm3']);

export const temperatureOptions: ISettingsRadioOption<TTemperature>[] = mapUomToOptions(['f', 'c']);

export const pressureOptions: ISettingsRadioOption<TPressure>[] = mapUomToOptions(['psi', 'kpa']);

export const energyOptions: ISettingsRadioOption<TEnergy>[] = mapUomToOptions(['mbtu', 'kwh', 'gj', 'thm']);

export const waterOptions: ISettingsRadioOption<TWater>[] = mapUomToOptions(['gal', 'l', 'm3']);

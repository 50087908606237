import { TAutomationBuilder, TDevice } from '@marlin/alert/data-access/automated-action';
import { useMetadata } from '@marlin/asset/data-access/equipment';
import { TLastReadingValuesOutputSchema } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse, datapointsLabelsMap } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

// TODO: This condition can be changed later
const filterMetadata = (metadata: TDeviceMetadataDatapointsResponse): boolean => {
  return !!metadata.isDefault;
};

const mapMetadata =
  (lastReadingValues?: TLastReadingValuesOutputSchema[]) =>
  (metadata: TDeviceMetadataDatapointsResponse): TDeviceMetadataDatapointsResponse => {
    const lastReadingValue = lastReadingValues?.find((value) => value.name === metadata.name);
    const uom = metadata.name === 'Leak' ? 'WaterDetect' : lastReadingValue?.unitOfMeasure ?? metadata.unitOfMeasure;

    return {
      ...metadata,
      label: datapointsLabelsMap.get(metadata.name.toLowerCase()) ?? metadata.name,
      unitOfMeasure: uom,
    };
  };

export const useConditionDatapoints = (triggerIndex: number) => {
  const { watch } = useFormContext<TAutomationBuilder>();
  const device = watch(`triggers[${triggerIndex}].device` as FieldPath<TAutomationBuilder>) as TDevice | undefined;

  const { data: metadata, isLoading } = useMetadata({ equipmentId: device?.id ?? '', enabled: !!device });

  const filteredMetadata = useMemo(
    () => metadata?.datapoints.filter(filterMetadata).map(mapMetadata(device?.lastReadingValues)),
    [device?.lastReadingValues, metadata?.datapoints]
  );

  return {
    datapoints: filteredMetadata,
    isLoading,
  };
};

import { TEquipmentDashboardSectionMetadataItem } from '@marlin/asset/shared/equipment-config';
import { DatapointDisplayValue } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useDatapointsContext } from '../../../../../shared/context/datapoints.context';
import { useEquipmentAlertsContext } from '../../../../../shared/context/equipment-alerts.context';
import { useEquipmentDetailsConfigContext } from '../../../../../shared/context/equipment-details-config-context';
import { getDatapointWithDependencies } from '../../../../../shared/utils/get-datapoint-with-dependencies.utils';
import { content } from '../../../../content';

type TDatapointBoxProps = {
  label: string;
  name: string;
  dependencies?: TEquipmentDashboardSectionMetadataItem['dependencies'];
  link?: {
    url: string;
    label: string;
  } | null;
};

type TStyles = {
  emptyValue: boolean;
};

const useStyles = makeStyles<TStyles>()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(12),
    borderRadius: theme.typography.pxToRem(4),
  },
  value: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  link: {
    textDecoration: 'none',
  },
}));

const DatapointInfo = ({ label, name, dependencies, link }: TDatapointBoxProps) => {
  const { getDatapoint } = useDatapointsContext();
  const { pathname } = useLocation();
  const { getAlertsForDatapoint } = useEquipmentAlertsContext();
  const { config } = useEquipmentDetailsConfigContext();
  const datapoint = getDatapoint(name);
  const datapointAlerts = getAlertsForDatapoint(name);
  const { classes } = useStyles({ emptyValue: !datapoint?.value });
  const datapointWithDependencies = getDatapointWithDependencies({
    dependencies,
    datapoint,
    getDatapoint,
    configDatapoint: { name: name ?? datapoint?.name, label: label ?? datapoint?.label },
    functionsMapping: config.dashboard.functionsMapping,
  });

  if (dependencies?.visibility && !datapointWithDependencies) {
    return null;
  }

  if (Array.isArray(datapointWithDependencies)) {
    const primaryDatapoint = datapointWithDependencies.find((dp) => dp.name.toLowerCase() === name.toLowerCase());
    const secondaryDatapoint = datapointWithDependencies.find((dp) => dp.name.toLowerCase() !== name.toLowerCase());
    const controlState = datapointAlerts?.length ? 'error' : primaryDatapoint?.controlState;

    if (link && controlState === 'error') {
      return (
        <Link to={link?.url.replace('{pathname}', pathname)} color={'inherit'} className={classes.link}>
          <div className={classes.container} data-testid={label}>
            <Typography variant="subtitle2" className={classes.label}>
              {label}
            </Typography>
            <DatapointDisplayValue
              value={primaryDatapoint?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
              controlState={controlState}
              alerts={datapointAlerts}
            />
            {secondaryDatapoint && (
              <div className={classes.value}>
                <DatapointDisplayValue
                  value={secondaryDatapoint.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
                  controlState={controlState}
                  alerts={datapointAlerts}
                />
                {link && controlState === 'error' && <ChevronRightIcon color={'primary'} />}
              </div>
            )}
          </div>
        </Link>
      );
    }

    return (
      <div className={classes.container} data-testid={label}>
        <Typography variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
        <DatapointDisplayValue
          value={primaryDatapoint?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
          controlState={controlState}
          alerts={datapointAlerts}
        />

        {secondaryDatapoint && (
          <DatapointDisplayValue
            value={secondaryDatapoint.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
            controlState={controlState}
            alerts={datapointAlerts}
          />
        )}
      </div>
    );
  }

  const controlState = datapointAlerts?.length ? 'error' : datapointWithDependencies?.controlState;

  if (link && controlState === 'error') {
    return (
      <Link to={link?.url.replace('{pathname}', pathname)} color={'inherit'} className={classes.link}>
        <div className={classes.container} data-testid={label}>
          <Typography variant="subtitle2" className={classes.label}>
            {label}
          </Typography>
          <div className={classes.value}>
            <DatapointDisplayValue
              value={datapointWithDependencies?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
              controlState={controlState}
              alerts={datapointAlerts}
            />
            {link && controlState === 'error' && <ChevronRightIcon color={'primary'} />}
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className={classes.container} data-testid={label}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <DatapointDisplayValue
        value={datapointWithDependencies?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
        controlState={controlState}
        alerts={datapointAlerts}
      />
    </div>
  );
};

export default DatapointInfo;

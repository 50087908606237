import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
  criticalityWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(16),
    alignContent: 'center',
  },
  criticalityChipsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
    alignContent: 'center',
  },
  tooltip: {
    marginLeft: theme.typography.pxToRem(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(22.4),
    letterSpacing: theme.typography.pxToRem(0.17),
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
  criticalityButton: {
    height: theme.typography.pxToRem(32),
  },
}));

import { ALERT_STATUS_FILTER, useFilteredAlertsWithPaging } from '@marlin/alert/data-access/alert-action';
import { TEquipmentAlert, useLiveAlerts } from '@marlin/asset/data-access/equipment';
import { useCallback, useMemo } from 'react';

import { useDatapointsContext } from './datapoints.context';
import { useEquipmentDetailsConfigContext } from './equipment-details-config-context';
import { useEquipmentContext } from './equipment.context';
import { mapToEquipmentAlert } from './utils';

const maxAlertCount = 5;

export const useAlerts = () => {
  const {
    config: {
      dashboard: {
        sections,
        productDetails,
        topAlerts,
        topAlerts: { alertSource },
      },
    },
  } = useEquipmentDetailsConfigContext();
  const { equipment } = useEquipmentContext();

  const { datapoints } = useDatapointsContext();
  const datapointsAlerts = useMemo(() => {
    if (topAlerts.alertSource === 'datapoint') {
      return topAlerts.datapointsMappers.flatMap((mapper) => {
        return mapToEquipmentAlert(mapper(datapoints));
      });
    }
    return [];
  }, [datapoints, topAlerts]);

  const sectionDatapoints = useMemo(() => {
    return sections.flatMap((section) =>
      section.sectionDatapoints?.flatMap((sectionDatapoint) => sectionDatapoint.items)
    );
  }, [sections]);

  const { data, isLoading } = useFilteredAlertsWithPaging({
    enabled: alertSource === 'default',
    params: {
      equipmentIds: [equipment?.id ?? ''],
      locationIds: [],
      status: ALERT_STATUS_FILTER.CURRENT,
      deviceIds: [],
      pageSize: maxAlertCount,
      page: 1,
      deviceTypes: [],
      ruleCategories: [],
      eventCodes: [],
    },
  });

  const { currentAlerts: liveAlerts } = useLiveAlerts();

  const alerts = useMemo((): TEquipmentAlert[] => {
    if (!liveAlerts) {
      return (data?.data || []).map<TEquipmentAlert>((alert) => ({
        deviceEventCode: alert.deviceEventCode || '',
        startTime: alert.startTime || '',
        title: alert.title || '',
        criticality: alert.criticality,
        uoM: alert.uoM,
      }));
    }

    return liveAlerts.slice(0, maxAlertCount);
  }, [data?.data, liveAlerts]);

  const getAlertsForDatapoint = useCallback(
    (datapointName: string) => {
      const sectionDatapoint = sectionDatapoints?.find(
        (sectionDatapoint) => sectionDatapoint?.name.toLowerCase() === datapointName.toLowerCase()
      );
      const eventCodes = sectionDatapoint?.eventCodes?.map((eventCode) => eventCode.toLowerCase());

      if (eventCodes) {
        return alerts.filter(
          (alert) => alert.deviceEventCode && eventCodes?.includes(alert.deviceEventCode.toLowerCase())
        );
      }

      const readingsDatapointNames = productDetails?.datapoints.map((datapoint) => datapoint.name.toLowerCase()) || [];

      if (readingsDatapointNames.includes(datapointName.toLowerCase())) {
        const eventCodes = productDetails?.datapoints
          .find((reading) => reading.name.toLowerCase() === datapointName.toLowerCase())
          ?.eventCodes?.map((eventCode) => eventCode.toLowerCase());

        return alerts.filter((alert) => {
          return alert.deviceEventCode && eventCodes?.includes(alert.deviceEventCode.toLowerCase());
        });
      }

      return [];
    },
    [alerts, sectionDatapoints, productDetails?.datapoints]
  );

  return {
    alerts: alertSource === 'default' ? alerts : datapointsAlerts,
    getAlertsForDatapoint,
    isLoading,
  };
};

// eslint-disable-next-line @nx/enforce-module-boundaries
import { TDeviceParamsSchema, TDeviceSchema, useFilteredDevicesWithLoadMore } from '@marlin/asset/data-access/device';
import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { TAsset } from '../schemas/assets/assets.schema';
import { useEnableOption } from './use-enable-option';

interface IDeviceSearchWrapper<TOption extends TAsset = TAsset> {
  options: TOption[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  loadedItems?: number;
  isLoading?: boolean;
  isError?: boolean;
  search: (value: string) => void;
  term: string;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  enableOptions: () => void;
}

interface IDeviceSearchWrapperProps<TOption extends TAsset = TAsset>
  extends Omit<TDeviceParamsSchema, 'page' | 'pageSize' | 'sorting' | 'search'> {
  enableSearch?: boolean;
  children: (props: IDeviceSearchWrapper<TOption>) => JSX.Element;
  optionMapper?: (option: TDeviceSchema) => TOption;
}

export function DeviceSearchWrapper<TOption extends TAsset = TAsset>({
  children,
  enableSearch = true,
  locations,
  deviceTypes,
  meterTypes,
  optionMapper,
}: IDeviceSearchWrapperProps<TOption>) {
  const { term, debouncedTerm, search } = useSearchFilter();
  const { enabled, enableOptions } = useEnableOption();

  const deviceQuery = useFilteredDevicesWithLoadMore({
    params: {
      search: enableSearch ? debouncedTerm : undefined,
      pageSize: 10,
      locations,
      deviceTypes,
      meterTypes,
    },
    enabled,
  });

  const options: TOption[] = useMemo(() => {
    const unfilteredRows: TDeviceSchema[] = deviceQuery.data?.pages.map((page) => page.data || []).flat() || [];

    if (optionMapper) {
      return unfilteredRows.map(optionMapper);
    }

    return unfilteredRows as unknown as TOption[];
  }, [deviceQuery.data?.pages, optionMapper]);

  return (
    <Box onClick={enableOptions}>
      {children({
        options,
        hasNextPage: deviceQuery.hasNextPage,
        totalItems: deviceQuery.data?.pages[0].pagination.totalItems || 0,
        fetchNextPage: deviceQuery.fetchNextPage,
        isLoading: deviceQuery.isLoading,
        isError: deviceQuery.isError,
        isFetching: deviceQuery.isFetching,
        isFetchingNextPage: deviceQuery.isFetchingNextPage,
        enableOptions,
        search,
        term,
      })}
    </Box>
  );
}

import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';

interface IDatapointCreatorUtil {
  configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string };
  value: string;
  displayedValue: string;
  controlState: 'error' | 'active' | 'inactive' | 'success' | 'outdated';
}

export const datapointCreatorUtil = ({
  configDatapoint,
  value,
  displayedValue,
  controlState,
}: IDatapointCreatorUtil) => ({
  ...configDatapoint,
  value,
  displayedValue,
  controlState,
  unitOfMeasure: configDatapoint.unitOfMeasure ?? '',
  controlType: configDatapoint.controlType ?? 'notSpecified',
  lastReadingTime: configDatapoint.lastReadingTime ?? new Date().toISOString(),
  label: configDatapoint.label ?? '',
});

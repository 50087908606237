import { TDeviceParamsSchema, TDeviceSchema, useFilteredDevicesWithPaging } from '@marlin/asset/data-access/device';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useSearchParamsRepository } from '@marlin/shared/utils-router';
import { ISensorHubParams } from '@marlin/shared/utils-routes';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { usePagination } from '@marlin/shared/utils/react-query';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { IDeviceHubFilters } from './components/types';

interface IPagination {
  page: number;
  pageSize: number;
  rowCount: number;
}

export interface IDevicesHub {
  rows: TDeviceSchema[];
  isLoading: boolean;
  isError: boolean;
  pagination: IPagination;
  changePage: (newPage: number) => void;
  changePageSize: (newPageSize: number) => void;
  refetch: () => void;
  onFiltersChange: (filters: IDeviceHubFilters) => void;
  onSortChange: (sortModel: GridSortModel) => void;
  clearAdditionalFilters: () => void;
}

const defaultParams = {
  initialPage: 1,
  initialPageSize: 10,
};

const dataGridPageCounterOffset = 1;

export const useDevicesHub = (): IDevicesHub => {
  const { getSearchParam, removeSearchParam } = useSearchParamsRepository<ISensorHubParams>();

  const { page, pageSize, changePage: paginationChangePage, changePageSize } = usePagination(defaultParams);
  const [requestParams, setRequestParams] = useState<Omit<TDeviceParamsSchema, 'page' | 'pageSize'>>({});

  const batteryLevelStatus = getSearchParam('batteryLevelStatus');
  const filterByLostCommunication = !!getSearchParam('lostCommunication');

  const lastReadingTo = useMemo(() => {
    if (!filterByLostCommunication) {
      return undefined;
    }
    return dateAdapter?.date()?.subtract(8, 'hours').toISOString();
  }, [filterByLostCommunication]);

  const params = useMemo(() => {
    return {
      params: {
        ...requestParams,
        batteryLevelStatus,
        page,
        pageSize,
        lastReadingTo,
      },
    };
  }, [page, pageSize, requestParams, batteryLevelStatus, lastReadingTo]);

  const devicesQuery = useFilteredDevicesWithPaging(params);

  const onFiltersChange = useCallback(
    (data: IDeviceHubFilters) => {
      paginationChangePage(1);
      setRequestParams({
        search: data.search?.trim() || undefined,
        locations: data.locations?.map((location) => location.id),
        deviceTypes: data.deviceType,
      });
    },
    [paginationChangePage]
  );

  const onSortChange = useCallback(
    (sortModel: GridSortModel) => {
      if (!sortModel[0] || !sortModel[0].sort) {
        setRequestParams({ ...requestParams, sorting: undefined });
      } else {
        const sorting = {
          sortBy: sortModel[0]?.field,
          direction: mapSortDirection(sortModel[0]?.sort),
        };
        setRequestParams({
          ...requestParams,
          sorting,
        });
      }
    },
    [requestParams]
  );

  const clearAdditionalFilters = useCallback(() => {
    if (batteryLevelStatus) {
      removeSearchParam('batteryLevelStatus');
    }

    if (filterByLostCommunication) {
      removeSearchParam('lostCommunication');
    }
  }, [batteryLevelStatus, filterByLostCommunication, removeSearchParam]);

  const rows = useMemo(() => devicesQuery.data?.data || [], [devicesQuery.data]);
  const dataGridPage = useMemo(() => page - dataGridPageCounterOffset, [page]);
  const changeDataGridPage = (newPage: number) => paginationChangePage(newPage + dataGridPageCounterOffset);

  const isLoading = useMemo(
    () => devicesQuery.isLoading || devicesQuery.isInitialLoading || devicesQuery.isFetching,
    [devicesQuery.isFetching, devicesQuery.isInitialLoading, devicesQuery.isLoading]
  );

  const isError = useMemo(() => devicesQuery.isError, [devicesQuery.isError]);

  return {
    rows,
    isLoading,
    isError,
    pagination: {
      page: dataGridPage,
      pageSize,
      rowCount: devicesQuery.data?.pagination.totalItems || 0,
    },
    changePage: changeDataGridPage,
    changePageSize,
    refetch: devicesQuery.refetch,
    onFiltersChange,
    onSortChange,
    clearAdditionalFilters,
  };
};

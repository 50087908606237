import { TChartId } from '@marlin/asset/shared/equipment-config';
import { ChartSwitcherWrapper } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { useChartOptionsV2 } from '@marlin/shared/ui/chart-options';
import { TChartDisplayType } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { useDurationContext } from '../../context/context-duration.context';
import { useSelectedDatapointsContext } from '../../context/selected-datapoints.context';
import { useChartData } from '../../hooks/use-chart-data.hook';
import { useChartMetadata } from '../../hooks/use-chart-metadata.hook';
import { ChartChips } from '../chips/chart-chips';
import { TotalValue } from '../total-volume/total-value.component';

interface IChartProps {
  chartId: TChartId;
  additionalAxisUom: TUnitOfMeasure | undefined;
  mainDatapoint: string;
  hideUomOnAxis: boolean;
  chartType: TChartDisplayType;
}

interface IChartProps {
  chartId: TChartId;
  additionalAxisUom: TUnitOfMeasure | undefined;
  mainDatapoint: string;
  hideUomOnAxis: boolean;
  chartType: TChartDisplayType;
}

export const Chart = ({
  chartId,
  additionalAxisUom,
  mainDatapoint,
  hideUomOnAxis,
  chartType: chartDisplayType,
}: IChartProps) => {
  const { rangeFilter } = useDurationContext();
  const {
    datapointGroups,
    activeDatapoints,
    activeThresholds,
    toggleDatapointVisibility,
    toggleThresholdVisibility,
    clearGroup,
  } = useSelectedDatapointsContext();
  const datapointsGroup = useMemo(() => datapointGroups[chartId.toLowerCase()], [datapointGroups, chartId]);

  const datapointsWithMetadata = useChartMetadata(datapointsGroup);
  const {
    chartData,
    chartDataWithoutMargins,
    thresholdsData,
    from,
    to,
    max,
    min,
    isLoading,
    handleZoomChange,
    isZoomed,
    totalVolume,
    isFetching,
    bucketOption,
  } = useChartData({
    datapointsWithMetadata,
    activeDatapoints: activeDatapoints[chartId],
    activeThresholds: activeThresholds[chartId],
    chartId,
    chartDisplayType,
  });

  const maxForBarChart = useMemo(() => {
    const sumDictionary: Map<number, number> = new Map<number, number>();

    chartDataWithoutMargins.map((datapoint) => {
      datapoint.data.map(({ x, y }) => {
        const currentSum = sumDictionary.get(x) || 0;
        sumDictionary.set(x, currentSum + (y ?? 0));
      });
    });

    return Math.max(...Array.from(sumDictionary.values()));
  }, [chartDataWithoutMargins]);

  const rangeBarDatapoints = useMemo<string[] | undefined>(() => {
    if (chartDisplayType === 'rangeBar') {
      return datapointsWithMetadata.datapointsMetadata.map((datapoint) => datapoint.label);
    }

    return undefined;
  }, [chartDisplayType, datapointsWithMetadata.datapointsMetadata]);

  const { layout, config, handleRelayout, isEmpty, uoms } = useChartOptionsV2({
    chartData,
    chartDataWithoutMargins,
    from,
    to,
    rangeFilter: rangeFilter.range,
    max: chartDisplayType === 'bar' ? maxForBarChart : max,
    min,
    handleZoomChange,
    hideUomOnAxis,
    isZoomed,
    additionalAxisUom,
    mainDatapoint,
    chartDisplayType,
    chartId,
    thresholdsData,
    bucketOption,
    rangeBarDatapoints,
    disableFullScreen: true,
  });

  return (
    <div data-testid={`${chartId}-historical-chart`}>
      <ChartChips
        datapointsGroup={datapointsGroup}
        toggleDatapointVisibility={(name: string) => toggleDatapointVisibility(chartId, name)}
        activeDatapoints={activeDatapoints[chartId]}
        clearActiveDatapoints={() => clearGroup(chartId)}
        toggleThresholdVisibility={(name: string) => toggleThresholdVisibility(chartId, name)}
        activeThresholds={activeThresholds[chartId]}
      />
      {chartId === 'flow' && totalVolume && <TotalValue value={totalVolume} chartType={chartDisplayType} />}
      <ChartSwitcherWrapper
        from={from}
        to={to}
        isFetching={isFetching}
        isLoading={isLoading}
        chartDisplayType={chartDisplayType}
        chartData={chartData}
        currentAnnotationTooltip={null}
        chartId={chartId}
        rangeBarDatapoints={rangeBarDatapoints}
        chartOptions={undefined}
        layout={layout}
        config={config}
        handleRelayout={handleRelayout}
        isEmpty={isEmpty}
        thresholdData={thresholdsData}
        hideUomOnAxis={hideUomOnAxis}
        uoms={uoms}
      />
    </div>
  );
};

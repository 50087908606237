import { TExtractFnReturnType, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { getRecipients } from '../infrastructure/recipient';
import { queryKey } from '../query-key.enum';
import { IListFilters, TInfiniteRecipientParams } from '../recipient.model';

interface IGetRecipientsParamsRequest {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

type TQueryFnType = typeof getRecipients;

export const mapGetRecipientsParams = (params: IListFilters): IGetRecipientsParamsRequest => ({
  search: params.search?.trim(),
  page: params.page,
  pageSize: params.pageSize,
  sorting: params.sorting,
});

export const useRecipientsWithFilters = (params: IListFilters) => {
  const queryParams = useMemo(() => mapGetRecipientsParams(params), [params]);

  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.RECIPIENTS_FILTER(queryParams),
    queryFn: () => getRecipients(queryParams),
  });
};

export const useRecipientsWithLoadMore = (queryParams: TInfiniteRecipientParams) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.INFINITE_RECIPIENTS(queryParams),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getRecipients({ ...queryParams, page: (pageParam as number) || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
  });
};

import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { CHANNEL_TYPE } from '@marlin/alert/ui/automated-action-list';
import { FormField } from '@marlin/shared/ui-form-common';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useStyles } from './actions-content-edit.styles';

export const ActionsContentEdit = () => {
  const { classes } = useStyles();
  const { watch } = useFormContext<TAutomationBuilder>();

  const actionChannelType = watch('actions.channels.IN_APP.type');

  return (
    <div className={classes.container}>
      <FormField<TAutomationBuilder> fieldName={`actions.channels.IN_APP.type`}>
        {(props) => (
          <FormControlLabel
            control={<Checkbox {...props} checked={actionChannelType === CHANNEL_TYPE.IN_APP} disabled={true} />}
            label="In App Notification" //TODO: remove it
          />
        )}
      </FormField>
    </div>
  );
};

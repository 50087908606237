import { TEquipmentDashboardSectionMetadataItem } from '@marlin/asset/shared/equipment-config';
import { getControlState } from '@marlin/asset/shared/utils/datapoint-mappers';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';

interface IDatapointWithDependencies {
  dependencies: TEquipmentDashboardSectionMetadataItem['dependencies'];
  getDatapoint: (name: string) => TDatapointWithUiMetadata | undefined;
  datapoint?: TDatapointWithUiMetadata;
  functionsMapping?: {
    [key: string]: (
      configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
      datapointsFromDeps: TDatapointWithUiMetadata[]
    ) => TDatapointWithUiMetadata;
  };
  configDatapoint?: Partial<TDatapointWithUiMetadata>;
}

export const getDatapointWithDependencies = ({
  dependencies,
  datapoint,
  getDatapoint,
  configDatapoint,
  functionsMapping,
}: IDatapointWithDependencies):
  | undefined
  | TDatapointWithUiMetadata
  | [TDatapointWithUiMetadata, TDatapointWithUiMetadata] => {
  if (!dependencies) {
    return datapoint;
  }

  if (dependencies.aggregation) {
    const { datapointNames, function: aggregationFunction } = dependencies.aggregation;
    if (aggregationFunction === 'count') {
      return {
        ...datapoint,
        ...configDatapoint,
        name: configDatapoint?.name ?? '',
        value: datapointNames.length.toString(),
        displayedValue: datapointNames.length.toString(),
        unitOfMeasure: null,
        controlType: datapoint?.controlType ?? 'notSpecified',
        controlState: datapoint ? datapoint.controlState : ('active' as const),
        lastReadingTime: datapoint?.lastReadingTime ?? new Date().toISOString(),
        label: configDatapoint?.label ?? datapoint?.label ?? '',
      } satisfies TDatapointWithUiMetadata;
    } else if (aggregationFunction === 'other') {
      const datapointsFromDeps: TDatapointWithUiMetadata[] = dependencies.aggregation.datapointNames.map((name) =>
        getDatapoint(name)
      ) as TDatapointWithUiMetadata[];
      if (functionsMapping && datapoint) {
        return functionsMapping[datapoint.name]?.(datapoint, datapointsFromDeps);
      } else if (functionsMapping && configDatapoint?.name) {
        if (functionsMapping[configDatapoint?.name]) {
          return functionsMapping[configDatapoint?.name]({ name: configDatapoint?.name }, datapointsFromDeps);
        }
        const matchingMappingFunction = Object.keys(functionsMapping).find((key) => {
          const regexpKey = new RegExp(key, 'i');
          return regexpKey.test(configDatapoint?.name ?? '');
        });
        if (matchingMappingFunction) {
          return functionsMapping[matchingMappingFunction]({ name: configDatapoint?.name }, datapointsFromDeps);
        }
      }

      return datapoint;
    }
  }

  if (!datapoint) {
    return undefined;
  }

  if (dependencies.visibility) {
    const dependency = getDatapoint(dependencies.visibility.datapointName);
    const isVisible = dependencies.visibility.value.get(dependency?.value ?? '');

    return isVisible ? datapoint : undefined;
  }

  if (dependencies.override) {
    const overrideValue = getDatapoint(dependencies.override.datapointName)?.value;
    const displayedOverrideValue = dependencies.override.value.get(overrideValue ?? '');

    return {
      ...datapoint,
      value: (displayedOverrideValue ? overrideValue : datapoint?.value) ?? '',
      displayedValue: displayedOverrideValue ? displayedOverrideValue : datapoint?.displayedValue,
    };
  }

  if (dependencies.statusMapping) {
    const statusMappingValue = dependencies.statusMapping.get(datapoint?.value ?? '');

    return {
      ...datapoint,
      displayedValue: statusMappingValue ? statusMappingValue : datapoint?.displayedValue,
      controlState:
        statusMappingValue && datapoint
          ? getControlState(statusMappingValue, datapoint?.controlType, datapoint?.unitOfMeasure)
          : datapoint?.controlState,
    };
  }

  if (dependencies.additionalDatapointWhenActive) {
    if (datapoint.controlState === 'active') {
      const additionalDatapoint = getDatapoint(dependencies.additionalDatapointWhenActive.datapointName);
      if (additionalDatapoint) {
        return [datapoint, additionalDatapoint];
      }
    }
    return datapoint;
  }

  return datapoint;
};

export const content = {
  LOCATIONS_FETCHING_ERROR: "Something went wrong. Locations haven't been fetched.",
  GATEWAY_NAME: 'Hub Name',
  GATEWAY_LABEL: 'Hub Details',
  LOCATION_NAME: 'Location',
  REQUIRED: 'This is a required field',
  REGISTRATION_CODE: 'Registration Code',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  ADDITIONAL_INFORMATION_LABEL: 'Additional Information',
  DESCRIPTION: 'Description',
  COMMISSION_DATE: 'Commission Date',
  MIN_MAX: 'Must be between 3 and 200 characters',
  CONFIRMATION_MESSAGE_UPDATE_HUB_ERROR: `Something went wrong. Your hub hasn't been updated`,
  CONFIRMATION_MESSAGE_UPDATE_HUB_SUCCESS: 'Your hub has been updated',
  SUBTITLE: 'Use the form below to edit your hub.',

  MODAL: {
    UNSAVED_CHANGES_MODAL_TITLE: 'You have unsaved changes',
    UNSAVED_CHANGES_MODAL_BODY: 'Are you sure you want to leave?',
    CANCEL: 'Cancel',
    LEAVE: 'Leave',
  },
};

import { MarlinTheme } from '@marlin/shared/theme';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  connector: {
    display: 'flex',
    alignSelf: 'center',
    height: theme.typography.pxToRem(16),
  },
  shorter: {
    height: theme.typography.pxToRem(8),
  },
}));

interface ILineConnector {
  variant?: 'shorter' | 'regular';
}

export const LineConnector = ({ variant = 'regular' }: ILineConnector) => {
  const { cx, classes } = useStyles();

  return (
    <Divider
      className={cx(classes.connector, variant === 'shorter' && classes.shorter)}
      orientation="vertical"
      variant="fullWidth"
    />
  );
};

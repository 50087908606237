import type { TValveState } from '@marlin/asset/data-access/equipment';
import { getDatapoints } from '@marlin/asset/data-access/equipment';
import { useObservableState } from 'observable-hooks';
import { Subject, map, mergeWith } from 'rxjs';

const lastCommand$ = new Subject<TValveState | undefined>();

const isValveState = (value: unknown): value is TValveState => {
  return value === 'Closed' || value === 'Open';
};

export const useObservableLastCommand = () => {
  const [lastCommand] = useObservableState<TValveState | undefined>(
    () =>
      lastCommand$.pipe(
        mergeWith(getDatapoints()),
        map((value) => (isValveState(value) ? value : undefined))
      ),
    undefined
  );

  const setLastCommand = (command: TValveState | undefined) => {
    lastCommand$.next(command);
  };

  return {
    lastCommand,
    setLastCommand,
  };
};

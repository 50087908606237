import { IconSensor } from '@marlin/asset/shared/ui/assets';
import SensorsIcon from '@mui/icons-material/Sensors';
import ToggleOnRounded from '@mui/icons-material/ToggleOnRounded';

export const chartTypesIcons = {
  temperature: IconSensor.Temperature,
  pressure: IconSensor.Pressure,
  onOff: ToggleOnRounded,
  flow: IconSensor.FlowMeter,
  leak: IconSensor.LeakDetector,
  custom: SensorsIcon,
};

import { IRecipient } from '@marlin/account-data-access-recipient';
import { Paper } from '@marlin/shared/ui-page';

import { IRecipientContextMenuActionsProps, RecipientContextMenu } from '../recipient-context-menu.component';
import { ContactInfo } from './contact-info.component';
import { useStyles } from './recipient-card-styles';
import { RecipientName } from './recipient-name.component';

interface IRecipientCardProps extends IRecipientContextMenuActionsProps {
  person: IRecipient;
}

export const RecipientCard = ({ person, onDelete, onEdit }: IRecipientCardProps) => {
  const { classes } = useStyles();
  const { id, firstName, lastName, phoneNumber, email } = person;

  return (
    <Paper className={classes.wrapper} testId="recipient-card">
      <div className={classes.nameRow}>
        <RecipientName firstName={firstName} lastName={lastName} />
        <div className={classes.contextMenuWrapper}>
          <RecipientContextMenu recipientId={id} onEdit={onEdit} onDelete={onDelete} />
        </div>
      </div>

      <div className={classes.contactRow}>
        {phoneNumber && <ContactInfo value={phoneNumber} type={'phone'} />}
        <ContactInfo value={email} type={'email'} />
      </div>
    </Paper>
  );
};

import { useDeviceDetails } from '@marlin/asset/data-access/device';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { sensorConfig } from '../../shared/config/sensor/sensor.config';
import { useHubNavigation } from '../../shared/hooks/navigation/use-hub-navigation.hook';
import { CommonAssetDetails } from '../common-dashboard/common-asset-details-page.component';

export const SensorDetails = () => {
  const { sensorId = '' } = useParams();

  const navigation = useHubNavigation();
  const sensorQuery = useDeviceDetails({
    deviceId: sensorId,
    datapoints: ['FirmwareVersion'],
    enabled: !!sensorId,
  });
  const sensor = useMemo(() => sensorQuery?.data, [sensorQuery?.data]);
  const isEditable = usePermission(PERMISSIONS.EDIT_DEVICE);

  return (
    <CommonAssetDetails
      config={sensorConfig}
      navigation={navigation}
      isLoading={sensorQuery.isLoading}
      asset={sensor}
      isError={sensorQuery.isError}
      assetId={sensorId}
      isEditable={isEditable}
      assetName={'hub'}
    />
  );
};

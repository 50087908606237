import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useMemo } from 'react';

export const useSettingsPermissions = () => {
  const allowedToSettings = usePermission(PERMISSIONS.SHOW_SETTINGS_PAGE);
  const allowedToOrgPage = usePermission(PERMISSIONS.SHOW_ORGANIZATION_SETTINGS_PAGE);
  const allowedToOrgsSection = usePermission(PERMISSIONS.SHOW_ORGANIZATION_ADDRESS_SETTINGS_PAGE);
  const allowedToWebhooks = usePermission(PERMISSIONS.VENDOR_WEBHOOKS);
  const allowedToExternalApi = usePermission(PERMISSIONS.VENDOR_API);
  const allowedToIntegrations = useMemo(
    () => allowedToSettings && (allowedToWebhooks || allowedToExternalApi),
    [allowedToSettings, allowedToWebhooks, allowedToExternalApi]
  );

  return {
    allowedToSettings,
    allowedToOrgPage,
    allowedToOrgsSection,
    allowedToWebhooks,
    allowedToExternalApi,
    allowedToIntegrations,
  };
};

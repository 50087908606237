import { renderEllipsisCell } from '@marlin/data-grid';
import { TUnitOfMeasure, datapointToUom, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';

import { useMetadataContext } from '../metadata.context';

export const useDatapointCell = () => {
  const { getDatapointFromMetadata } = useMetadataContext();

  const renderDatapointCell = useCallback(
    (cellValues: GridRenderCellParams, uomFromProps?: TUnitOfMeasure | null) => {
      const datapoint = getDatapointFromMetadata(cellValues.field);
      const uom = datapointToUom(cellValues.field, uomFromProps ?? datapoint?.unitOfMeasure);
      return renderEllipsisCell({ ...cellValues, value: parseDisplayedValue(cellValues.value, uom) });
    },
    [getDatapointFromMetadata]
  );

  return { renderDatapointCell };
};

import { LineConnector, SectionWrapper } from '@marlin/alert/ui/automation-builder-components';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton } from '@mui/material';

import { useSectionsStore$ } from '../hooks/use-observable-sections-store';
import { useSectionValidation } from '../hooks/use-section-validation.hook';
import { ActionsContentEdit } from './actions-content-edit/actions-content-edit';
import { actionsEnabledSchema } from './actions.validation.schema';
import { InAppNotificationMessage } from './components/in-app-notifications-message.component';
import { content } from './content';

export const ActionsSection = () => {
  const { setSection, isSectionActive } = useSectionsStore$();
  const { isValid } = useSectionValidation(actionsEnabledSchema);

  const editModeToggle = () => {
    setSection('actions');
  };

  return (
    <>
      {isValid && (
        <>
          <InAppNotificationMessage />
          <LineConnector />
        </>
      )}
      <SectionWrapper
        sectionExpanded={isSectionActive('actions')}
        editMode={isSectionActive('actions')}
        buttonTitle={content.SELECT_ACTIONS}
        sectionTitle={content.ACTION}
        buttonDisabled={!isValid}
        onToggle={editModeToggle}
        viewModeChildren={<div style={{ padding: 16 }}>TODO</div>}
        actions={
          <IconButton onClick={editModeToggle}>
            <EditRoundedIcon color={'primary'} />
          </IconButton>
        }
        testId="actions"
      >
        <ActionsContentEdit />
      </SectionWrapper>
    </>
  );
};

import { TDeviceSchema } from '@marlin/asset/data-access/device';

import { TDevice } from '../triggers/triggers.validation.schema';

export const mapSensorOption = (sensor: TDeviceSchema): TDevice => {
  return {
    id: sensor.id,
    name: sensor.name,
    manufacturerId: sensor.manufacturerId,
    deviceType: sensor.deviceType,
    model: sensor.model,
    lastReadingValues: sensor.lastReadingValues,
  };
};

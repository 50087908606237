import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    marginRight: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: 'nowrap',
  },

  value: {
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  detailsRow: {
    display: 'flex',
    alignItems: 'baseline',
    paddingRight: theme.typography.pxToRem(20),
  },
}));

interface IDetailsItemProps {
  label: string;
  value?: string | number | JSX.Element | null | ReactNode;
  testId?: string;
  tooltip?: boolean;
}

export function DetailsItem({ label, value, testId, tooltip }: IDetailsItemProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.detailsRow} data-testid={testId}>
      <Typography className={classes.label} variant="overline" data-testid={`${testId}-label`}>
        {label}:
      </Typography>
      {tooltip ? (
        <Tooltip text={value ?? ''} placement="top">
          <span className={classes.value} data-testid={`${testId}-value`}>
            {value}
          </span>
        </Tooltip>
      ) : (
        <span className={classes.value} data-testid={`${testId}-value`}>
          {value}
        </span>
      )}
    </div>
  );
}

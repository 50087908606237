import { useCurrentUser } from '@marlin/account-data-access-organization';
import { environment } from '@marlin/environment';
import { Paper, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { Typography } from '@mui/material';

import { content } from '../shared/content';
import { AppearanceCard } from './appearance-card/appearance-card.component';
import { useStyles } from './settings-appearance.styles';
import { ThemeSwitcher } from './theme-switcher/theme-switcher.component';
import { UomSettings } from './uom-settings/uom-settings.component';

export const SettingsAppearance = () => {
  const { classes } = useStyles();
  const { data, isError } = useCurrentUser();

  const isDashboardActive = data?.settings.defaultHomePage === 'DASHBOARD';
  const isLocationsActive = data?.settings.defaultHomePage === 'LOCATIONS';

  if (isError) {
    return <SystemConstraintsPage onlyContent />;
  }

  return (
    <>
      {environment.module.features.darkMode && <ThemeSwitcher />}
      <Paper className={classes.container} data-testid="card">
        <Typography className={classes.title}>{content.DEFAULT_HOME_VIEW}</Typography>
        <Typography className={classes.subtitle}>{content.SELECT_LAYOUT_SUBTITLE}</Typography>
        <div className={classes.cardsContainer}>
          <AppearanceCard variant="DASHBOARD" isActive={isDashboardActive} />
          <AppearanceCard variant="LOCATIONS" isActive={isLocationsActive} />
        </div>
      </Paper>
      <UomSettings userSettings={data?.settings} />
    </>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { IDevice } from '@marlin/asset/data-access/device';
import { useMemo, useState } from 'react';
import { ValidationMode, useForm } from 'react-hook-form';

import { TUpsertHubUpdateForm, updateHubFormSchema } from '../utils/hub-form.utils';

export const useHubUpsertForm = ({
  defaultValues,
  mode = 'onTouched',
}: {
  defaultValues?: IDevice;
  mode?: keyof ValidationMode | undefined;
}) => {
  const form = useForm<TUpsertHubUpdateForm>({
    mode,
    resolver: zodResolver(updateHubFormSchema),
    defaultValues: defaultValues ? updateHubFormSchema.parse(defaultValues) : undefined,
  });

  const {
    formState: { errors },
  } = form;

  const [dateValidationErrorMessage, setDateValidationErrorMessage] = useState<string | undefined>(undefined);

  const disableSubmitButton = useMemo(
    () =>
      !form.formState.isDirty ||
      !form.formState.isValid ||
      !!Object.keys(form.formState.errors).length ||
      Boolean(dateValidationErrorMessage),
    [dateValidationErrorMessage, form.formState]
  );

  return {
    form,
    errors,
    dateValidationErrorMessage,
    disableSubmitButton,
    setDateValidationErrorMessage,
  };
};

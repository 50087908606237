import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    background: theme.palette.background.primary,
    width: '100%',
    minWidth: theme.typography.pxToRem(240),
  },
}));

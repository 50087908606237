import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { validationContent } from '../content';

const booleanCondition = z.object({
  type: z.literal('boolean'),
  threshold: z.boolean(),
  operator: z.union([z.literal('eq'), z.literal('neq')]),
});

const condition = z.object({
  threshold: z.coerce.number().nullable(),
  deadband: z.coerce.number().nullable(),
  uom: UnitOfMeasureType.nullish(),
});

const higherCondition = condition
  .extend({
    operator: z.literal('gt'),
  })
  .refine(
    ({ threshold, deadband }) => {
      if (threshold && deadband) {
        return threshold > deadband;
      }

      return true;
    },
    {
      message: validationContent.DEADBAND_VALUE_MAX,
      path: ['deadband'],
    }
  );

const lowerCondition = condition
  .extend({
    operator: z.literal('lt'),
  })
  .refine(
    ({ threshold, deadband }) => {
      if (threshold && deadband) {
        return threshold < deadband;
      }

      return true;
    },
    {
      message: validationContent.DEADBAND_VALUE_MIN,
      path: ['deadband'],
    }
  );

const numberCondition = z.object({
  type: z.literal('number'),
  rules: z
    .object({
      lt: lowerCondition.nullish(),
      gt: higherCondition.nullish(),
    })
    .refine(
      ({ gt, lt }) => {
        return gt?.threshold || lt?.threshold;
      },
      {
        message: validationContent.REQUIRED_ONE,
      }
    )
    .refine(
      ({ gt, lt }) => {
        if (gt?.threshold && lt?.threshold) {
          return lt.threshold < gt.threshold;
        }

        return true;
      },
      { message: validationContent.MIN_MAX_RANGE }
    )
    .refine(
      ({ gt, lt }) => {
        if (gt?.deadband && lt?.deadband) {
          return lt.deadband < gt.deadband;
        }

        return true;
      },
      {
        message: validationContent.DEADBAND_VALUE_MAX_GREATER_MIN,
      }
    )
    .refine(
      ({ gt, lt }) => {
        if (gt?.deadband && lt?.threshold) {
          return gt.deadband > lt.threshold;
        }

        return true;
      },
      { message: validationContent.DEADBAND_VALUE_MAX_LOWER_THAN_THRESHOLDMIN }
    )
    .refine(
      ({ gt, lt }) => {
        if (gt?.threshold && lt?.deadband) {
          return gt.threshold > lt.deadband;
        }

        return true;
      },
      { message: validationContent.DEADBAND_VALUE_MIN_LOWER_THAN_THRESHOLDMAX }
    ),
});

export const conditionSchema = z.discriminatedUnion('type', [booleanCondition, numberCondition]);

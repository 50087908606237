import { Paper, Tab, Tabs } from '@mui/material';
import { useCallback, useState } from 'react';

import { content } from '../../../../../../shared/content';
import { useSubdevicesList } from '../../../../../../shared/hooks/use-subdevices-list.hook';
import { ConnectedDevicesList } from './connected-devices-list.component';

interface IHubsAndSensorsListProps {
  manufacturerId: string;
  gatewayId: string;
}

export enum SUB_TABS {
  HUB = 'hub',
  SENSOR = 'sensor',
  VALVE_CONTROLLER = 'valveController',
}

export const ConnectedDevicesDesktop = ({ manufacturerId, gatewayId }: IHubsAndSensorsListProps) => {
  const [tab, setTab] = useState<SUB_TABS>(SUB_TABS.HUB);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newTab: SUB_TABS) => {
    setTab(newTab);
  }, []);
  const asset = useSubdevicesList(manufacturerId, gatewayId, tab);

  return (
    <Paper>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label={content.HUBS_AND_SENSORS.HUB_TAB(asset.counters.hub)} value={SUB_TABS.HUB} />
        <Tab label={content.HUBS_AND_SENSORS.SENSOR_TAB(asset.counters.sensor)} value={SUB_TABS.SENSOR} />
        <Tab
          label={content.HUBS_AND_SENSORS.VALVE_CONTROLLERS(asset.counters.valveController)}
          value={SUB_TABS.VALVE_CONTROLLER}
        />
      </Tabs>
      <ConnectedDevicesList
        key={tab}
        parentManufacturerId={manufacturerId}
        gatewayId={gatewayId}
        asset={asset}
        tab={tab}
      />
    </Paper>
  );
};

import { IConfig, globalConfigPartition } from '@marlin/asset/shared/equipment-config';
import { bradleyLogo, partitionImage } from '@marlin/asset/shared/ui/assets';
import { TDatapoint, TDatapointWithMetadata, isOccupancySensorDatapoint } from '@marlin/shared/utils/datapoint-mappers';

import { detailsConfig } from './details-config';

export const partitionConfig: IConfig = {
  tabs: {},
  details: detailsConfig,
  headerBanner: null,
  dashboard: {
    ...globalConfigPartition.dashboard,
    productDetails: {
      datapoints: [],
      imageSrc: partitionImage,
      logoSrc: bradleyLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: false,
    sections: [
      {
        sectionDatapoints: [
          {
            sectionName: 'Partition Controller',
            title: '',
            items: getPartitionSectionDatapoints(),
          },
        ],
        title: 'Partition Controller',
        style: {
          fullWidth: true,
          rowMaxLength: 4,
        },
        dataTestId: 'partition-controller',
      },
    ],
    isLastReadingTimeCounter: false,
    datapointCallback: partitionDatapointCallback,
  },
};

function partitionDatapointCallback(
  datapoint: TDatapointWithMetadata,
  allDatapoints: TDatapoint[]
): TDatapointWithMetadata {
  if (isOccupancySensorDatapoint(datapoint.name)) {
    const occupancyNumber = Number(datapoint.name.replace(/[^0-9]/g, ''));
    const occupancyDeps = allDatapoints.filter(
      (dp) =>
        dp.name === `Sensor_${occupancyNumber}_OccupancyCount` || dp.name === `Sensor_${occupancyNumber}_OccupancyTime`
    );

    if (occupancyDeps.length !== 2 || occupancyDeps.every((dp) => !dp.value || dp.value === '0')) {
      return {
        ...datapoint,
        value: 'notUsed',
        controlState: 'inactive',
      };
    }
  }
  return datapoint;
}

function getPartitionSectionDatapoints() {
  return Array.from(new Array(10)).map((_, index) => ({
    label: `Door ${index + 1}`,
    name: `Sensor_${index + 1}_Occupancy`,
  }));
}

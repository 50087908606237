import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteInvitation } from '../infrastructure/delete-invitation';
import { QUERY_KEY } from './query-key.enum';

export const invalidateUserQueries = async () => {
  return Promise.all([queryClient.invalidateQueries({ queryKey: [QUERY_KEY.INFINITE_USER] })]);
};

export const useDeleteInvitation = () => {
  return useMutation({
    mutationFn: deleteInvitation,
    onSettled: () => invalidateUserQueries(),
  });
};

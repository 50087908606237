import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  conditionRow: {
    display: 'flex',
    gap: theme.typography.pxToRem(32),
  },
  conditionElement: {
    flexGrow: 1,
    flexBasis: 0,
  },
  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
}));

declare var config: any;

export type TAppName = (string & {}) | 'Nexa' | 'Marlin';

export interface IEnvConfig {
  baseUrl: string;
  environment: string;
  app: {
    name: TAppName;
  };
  apiUrl: {
    admin: string;
    workflow: string;
    etl: string;
    realTimeCommunication: string;
    warranty: string;
    gateway: string;
    eventLog: string;
    insights: string;
    vendor: string;
    deviceIntegration: string;
  };
  auth: {
    instance: string;
    clientId: string;
    redirectUrl: string;
    logoutUri: string;
    scopes: string[];
    policyName: string;
  };
  mobile: {
    instance: string;
    scopes: string[];
    policyName: string;
    buildNumber: string;
    ios: {
      clientId: string;
      redirectUri: string;
    };
    android: {
      clientId: string;
      redirectUri: string;
    };
    appVersionUrl: string;
  };
  analytics: {
    helpPageUrl: string;
  };
  features: {
    hotSoS: boolean;
    portfolio: boolean;
    support: boolean;
    supportSettings: boolean;
    waterHeater: boolean;
    boiler: boolean;
    equipmentFlow: boolean;
    mockMapsApi: boolean;
    plant: boolean;
    gateway: boolean;
    europeTimezones: boolean;
    sentinel: boolean;
    analyticsv2: boolean;
    mobileDevQa: boolean;
    mobileAercoBoiler: boolean;
    mobileTekmarSmartBoiler: boolean;
    mobileTekmarSmartSteam: boolean;
    tekmarSbs: boolean;
    tekmarSsc: boolean;
    tekmarShp: boolean;
    mobileForceUpdate: boolean;
    sustainability: boolean;
    trackSpecificOrgs: boolean;
    waterTraceability: boolean;
    tagAsSource: boolean;
    automationBuilder: boolean;
    darkMode: boolean;
  };
  automationHelper: {
    dataTestId?: string;
    allowedAttributes?: string[];
    snackbarAutoHideDuration?: number;
    extendedQaLogging?: boolean;
  };
  msBookingsUrl: string;
  googleMapsApiKey: string;
  apiDeveloperPortalUrl: string;
  logger: {
    token: string;
    apiHost: string;
  };
}

const getEnvironment = (): string => {
  if (process.env.NX_ENVIRONMENT?.length) {
    return process.env.NX_ENVIRONMENT;
  }

  if (config?.environment?.length) {
    return config?.environment;
  }

  return '';
};

const getAutoHideDuration = (): number => {
  if (
    process.env.NX_FEATURES_SNACKBAR_AUTO_HIDE_DURATION?.length &&
    !isNaN(parseInt(process.env.NX_FEATURES_SNACKBAR_AUTO_HIDE_DURATION))
  ) {
    return parseInt(process.env.NX_FEATURES_SNACKBAR_AUTO_HIDE_DURATION);
  }

  if (
    config?.automationHelper?.snackbarAutoHideDuration?.length &&
    !isNaN(parseInt(config?.automationHelper?.snackbarAutoHideDuration))
  ) {
    return parseInt(config?.automationHelper?.snackbarAutoHideDuration);
  }

  return 5000;
};

export const getEnvConfig = (): IEnvConfig => ({
  environment: getEnvironment(),
  app: {
    name: process.env.NX_APP_NAME || config?.app?.name || 'Marlin',
  },
  apiUrl: {
    admin: process.env.NX_API_URL || config?.apiUrl.admin || '',
    workflow: process.env.NX_WORKFLOW_API_URL || config?.apiUrl.workflow || '',
    etl: process.env.NX_ETL_API_URL || config?.apiUrl.etl || '',
    realTimeCommunication: process.env.NX_WEB_SOCKET_URL || config?.apiUrl.realTimeCommunication || '',
    warranty: process.env.NX_WARRANTY_API_URL || config?.apiUrl.warranty || '',
    gateway: process.env.NX_GATEWAY_URL || config?.apiUrl.gateway || '',
    eventLog: process.env.NX_EVENT_LOG_URL || config?.apiUrl.eventLog || '',
    insights: process.env.NX_INSIGHTS_API_URL || config?.apiUrl.insights || '',
    vendor: process.env.NX_VENDOR_API_URL || config?.apiUrl.vendor || '',
    deviceIntegration: process.env.NX_DEVICE_INTEGRATION_API_URL || config?.apiUrl.deviceIntegration || '',
  },
  auth: {
    instance: process.env.NX_AUTH_INSTANCE || config?.auth?.instance || '',
    clientId: process.env.NX_AUTH_CLIENT_ID || config?.auth?.clientId || '',
    redirectUrl: process.env.NX_AUTH_REDIRECT_URL || config?.auth?.redirectUrl || `https://${window?.location?.host}`,
    logoutUri: process.env.NX_AUTH_LOGOUT_URI || config?.auth?.logoutUri || `https://${window?.location?.host}`,
    scopes: process.env.NX_AUTH_SCOPES?.split(' ') || config?.auth?.scopes || [],
    policyName: process.env.NX_AUTH_POLICY_NAME || config?.auth?.policyName || 'B2C_1A_UnifiedSignUpOrSignIn',
  },
  mobile: {
    instance: process.env.NX_AUTH_NATIVE_INSTANCE || '',
    scopes: process.env.NX_AUTH_NATIVE_SCOPES?.split(' ') || [],
    policyName: process.env.NX_AUTH_NATIVE_POLICY || '',
    buildNumber: process.env.RUN_NUMBER || 'local',
    ios: {
      clientId: process.env.NX_AUTH_CLIENT_IOS_ID || '',
      redirectUri: process.env.NX_AUTH_REDIRECT_IOS_URL || '',
    },
    android: {
      clientId: process.env.NX_AUTH_CLIENT_ANDROID_ID || '',
      redirectUri: process.env.NX_AUTH_REDIRECT_ANDROID_URL || '',
    },
    appVersionUrl: process.env.NX_APP_VERSION_URL || '',
  },
  baseUrl: process.env.NX_BASE_URL || config?.baseUrl || '/',
  analytics: {
    helpPageUrl: process.env.NX_HELP_PAGE_URL || config?.analytics?.helpPageUrl || '',
  },
  features: {
    hotSoS: process.env.NX_FEATURES_HOTSOS === 'true' || config?.features?.hotSoS === 'true',
    portfolio: process.env.NX_FEATURES_PORTFOLIO === 'true' || config?.features?.portfolio === 'true',
    support: process.env.NX_FEATURES_SUPPORT === 'true' || config?.features?.support === 'true',
    supportSettings:
      process.env.NX_FEATURES_SUPPORT_SETTINGS === 'true' || config?.features?.supportSettings === 'true',
    boiler: process.env.NX_FEATURES_BOILER === 'true' || config?.features?.boiler === 'true',
    equipmentFlow: process.env.NX_FEATURES_EQUIPMENT_FLOW === 'true' || config?.features?.equipmentFlow === 'true',
    tekmarSbs: process.env.NX_FEATURES_TEKMAR_SBS === 'true' || config?.features?.tekmarSbs === 'true',
    tekmarSsc: process.env.NX_FEATURES_TEKMAR_SSC === 'true' || config?.features?.tekmarSsc === 'true',
    tekmarShp: process.env.NX_FEATURES_TEKMAR_SHP === 'true' || config?.features?.tekmarShp === 'true',
    waterHeater: process.env.NX_FEATURES_WATER_HEATER === 'true' || config?.features?.waterHeater === 'true',
    mockMapsApi: process.env.NX_FEATURES_MOCK_MAPS === 'true' || config?.features?.mockMapsApi === 'true',
    plant: process.env.NX_FEATURES_PLANT === 'true' || config?.features?.plant === 'true',
    gateway: process.env.NX_FEATURES_GATEWAY === 'true' || config?.features?.gateway === 'true',
    europeTimezones:
      process.env.NX_FEATURES_EUROPE_TIMEZONES === 'true' || config?.features?.europeTimezones === 'true',
    sentinel: process.env.NX_FEATURES_SENTINEL === 'true' || config?.features?.sentinel === 'true',
    analyticsv2: process.env.NX_FEATURES_ANALYTICS_V2 === 'true' || config?.features?.analyticsv2 === 'true',
    mobileDevQa: process.env.NX_MOBILE_DEV_QA === 'true',
    mobileAercoBoiler: process.env.NX_AERCO_BOILER === 'true',
    mobileForceUpdate: process.env.NX_MOBILE_DEV_QA !== 'true',
    mobileTekmarSmartBoiler: process.env.NX_FEATURES_TEKMAR_SBS === 'true',
    mobileTekmarSmartSteam: process.env.NX_FEATURES_TEKMAR_SSC === 'true',
    sustainability: process.env.NX_FEATURES_SUSTAINABILITY === 'true' || config?.features?.sustainability === 'true',
    trackSpecificOrgs:
      process.env.NX_FEATURES_TRACK_SPECIFIC_ORGS === 'true' || config?.features?.trackSpecificOrgs === 'true',
    waterTraceability:
      process.env.NX_FEATURES_WATER_TRACEABILITY === 'true' || config?.features?.waterTraceability === 'true',
    tagAsSource: process.env.NX_FEATURES_TAG_AS_SOURCE === 'true' || config?.features?.tagAsSource === 'true',
    automationBuilder:
      process.env.NX_FEATURES_AUTOMATION_BUILDER === 'true' || config?.features?.automationBuilder === 'true',
    darkMode: process.env.NX_FEATURES_DARK_MODE === 'true' || config?.features?.darkMode === 'true',
  },
  automationHelper: {
    dataTestId: process.env.NX_FEATURES_AUTOMATION_TEST_ID || config?.automationHelper?.dataTestId || '',
    allowedAttributes:
      process.env.NX_FEATURES_ALLOWED_ATTRIBUTES?.split(' ') || config?.automationHelper?.allowedAttributes || [],
    snackbarAutoHideDuration: getAutoHideDuration(),
    extendedQaLogging:
      process.env.NX_FEATURES_EXTENDED_QA_LOGGING === 'true' ||
      config?.automationHelper?.extendedQaLogging === 'true' ||
      false,
  },
  googleMapsApiKey: process.env.NX_GOOGLE_MAPS_API_KEY || config?.googleMapsApiKey || '',
  msBookingsUrl: process.env.NX_MS_BOOKING_URL || config?.msBookingsUrl || '',
  apiDeveloperPortalUrl: process.env.NX_FEATURES_API_DEVELOPER_PORTAL || config?.apiDeveloperPortalUrl || '',
  logger: {
    token: process.env.NX_LOGGER_TOKEN || config?.logger.token || '',
    apiHost: process.env.NX_LOGGER_API_HOST || config?.logger.apiHost || '',
  },
});

import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  TDeviceChildrenDatapointsParams,
  TDeviceChildrenDatapointsResponse,
  deviceChildrenDatapointsParamsSchema,
  deviceChildrenDatapointsResponseSchema,
} from './schema/device-children-datapoints.schema';

export const getDeviceChildrenDatapoints = async (
  params: TDeviceChildrenDatapointsParams
): Promise<TDeviceChildrenDatapointsResponse> => {
  const { deviceId, ...body } = safeParseData(params, deviceChildrenDatapointsParamsSchema);

  const response = await getHttpClient().post(paths.DEVICE_CHILDREN_DATAPOINTS_FILTER, body, {
    params: { deviceId },
  });

  return safeParseData(response, deviceChildrenDatapointsResponseSchema);
};

import { useParams } from 'react-router-dom';

import { MetadataModelWrapper } from './components/metadata-model-wrapper';
import { LdsValveCardList } from './sentinel/lds-valve/lds-valve-card-list.component';
import { PoldCardList } from './sentinel/pold/pold-card-list.component';

export const SystemEquipmentRwd = () => {
  const { equipmentId } = useParams();

  return (
    <>
      <MetadataModelWrapper modelName={'POLD'}>
        <PoldCardList deviceId={equipmentId || ''} />
      </MetadataModelWrapper>
      <MetadataModelWrapper modelName={'LDSValve'}>
        <LdsValveCardList deviceId={equipmentId || ''} />
      </MetadataModelWrapper>
    </>
  );
};

import { IDeleteUser, IResendInvite } from '@marlin/account-ui-user-list';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';

import { useUserActionModals } from '../use-user-action-modals.hook';

export interface IUserContextMenuActions {
  onResend: (user: IResendInvite) => void;
  onEdit: (userId: string) => void;
  onDelete: (user: IDeleteUser) => void;
  onDeleteInvitation: (invitationId: string) => void;
}

export interface IUserContextMenu {
  actions: IUserContextMenuActions;
  resendModal: {
    user: IResendInvite | null;
    close: () => void;
  };
  deleteModal: {
    user: IDeleteUser | null;
    close: () => void;
  };
  handleBack: () => void;
}

export const useUserContextMenu = (): IUserContextMenu => {
  const {
    resendModalUser,
    deleteModalUser,
    openResendModal,
    openDeleteModal,
    closeResendModal,
    closeDeleteModal,
    deleteCurrentInvitation,
  } = useUserActionModals();

  const { goTo, goBack } = useRouter();

  const navigateToDetails = (userId: string) => {
    goTo(routes.settings.editUser.details.url(userId));
  };

  return {
    actions: {
      onResend: openResendModal,
      onEdit: navigateToDetails,
      onDelete: openDeleteModal,
      onDeleteInvitation: (invitationId: string) => deleteCurrentInvitation(invitationId),
    },
    resendModal: {
      user: resendModalUser,
      close: closeResendModal,
    },
    deleteModal: {
      user: deleteModalUser,
      close: closeDeleteModal,
    },
    handleBack: goBack,
  };
};

export const content = {
  TRIGGER: {
    CLEAR_ALL: 'Clear All',
    FILTERS: (count: number) => (count > 0 ? `Filters (${count})` : 'Filters'),
    LABEL: 'Sensor',
  },
  CONDITIONS: {
    LEAK_DETECTED: 'Leak Detected',
    HEADER: `Condition`,
    LOWER_THAN: (datapointLabel: string) => `${datapointLabel} Lower Than`,
    HIGHER_THAN: (datapointLabel: string) => `${datapointLabel} Higher Than`,
    LOWER_PLACEHOLDER: 'Lower Value',
    HIGHER_PLACEHOLDER: 'Higher Value',
    DEADBAND: 'Deadband',
    DEADBAND_PLACEHOLDER: 'Deadband Value',
  },
  OPERATOR: {
    AND: 'AND',
    OR: 'OR',
  },
};

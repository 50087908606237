import { IConfig } from '@marlin/asset/shared/equipment-config';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import { sentinelConfig as sharedSentinelConfig } from '../../../../shared/config/sentinel/config';
import { DynamicSettings } from '../../components/dynamic-settings/dynamic-settings.component';
import { SystemEquipment } from '../../components/system-equipment/system-equipment.component';

export const sentinelConfig: IConfig = {
  ...sharedSentinelConfig,
  tabs: {
    [EQUIPMENT_TAB_VALUES.SYSTEM_EQUIPMENT]: <SystemEquipment />,
    [EQUIPMENT_TAB_VALUES.SETTINGS]: <DynamicSettings />,
  },
};

import { useMetadataByModels } from '@marlin/asset/data-access/equipment';
import { PropsWithChildren } from 'react';

import { MetadataContextProvider } from '../metadata.context';
import { TSentinelSystemEquipment } from '../types';

interface IMetadataModelWrapper extends PropsWithChildren {
  modelName: TSentinelSystemEquipment;
}

export const MetadataModelWrapper = ({ children, modelName }: IMetadataModelWrapper) => {
  const { data } = useMetadataByModels([modelName]);

  return <MetadataContextProvider metadata={data?.[0].metadata}>{children}</MetadataContextProvider>;
};

import { TExtractFnReturnType, queryClient, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';

import { getDeviceChildrenDatapoints } from '../infrastructure/get-device-children-datapoints';
import { TDeviceChildrenDatapointsParams } from '../infrastructure/schema/device-children-datapoints.schema';
import { QUERY_KEY, queryKey } from './query-key.enum';

interface IQueryParams extends TDeviceChildrenDatapointsParams {
  isEnabled?: boolean;
}

type TQueryFnType = TExtractFnReturnType<typeof getDeviceChildrenDatapoints>;

export const useDeviceChildrenDatapoints = ({ isEnabled, ...params }: IQueryParams) => {
  return useQuery<TQueryFnType>({
    queryKey: queryKey.DEVICE_CHILDREN_DATAPOINTS_FILTER(params),
    queryFn: () => getDeviceChildrenDatapoints(params),
    enabled: isEnabled,
  });
};

export const useDeviceChildrenDatapointsWithLoadMore = ({ isEnabled, ...queryParams }: Omit<IQueryParams, 'page'>) => {
  return useInfiniteQuery<TQueryFnType>({
    queryKey: queryKey.INFINITE_DEVICE_CHILDREN_DATAPOINTS_FILTER(queryParams),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getDeviceChildrenDatapoints({ ...queryParams, page: (pageParam as number) || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled: isEnabled,
  });
};

export const refetchDeviceChildrenDatapoints = () => {
  return Promise.all([
    queryClient.refetchQueries({ queryKey: [QUERY_KEY.DEVICE_CHILDREN_DATAPOINTS_FILTER], type: 'all' }),
    queryClient.refetchQueries({ queryKey: [QUERY_KEY.INFINITE_DEVICE_CHILDREN_DATAPOINTS_FILTER], type: 'all' }),
  ]);
};

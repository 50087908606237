import { RecipientList as RecipientListUI, RemoveRecipientModal } from '@marlin/account-ui-recipient-list';
import { Search } from '@marlin/shared/ui-form-common';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button, Paper } from '@mui/material';
import { useCallback } from 'react';

import { content } from '../../shared/content';
import { useRecipientList } from '../../shared/hooks/use-recipient-list.hook';
import { useRemoveRecipient } from '../../shared/hooks/user-remove-recipient.hook';
import { ListHeader } from '../components/list-header.component';
import { ListTitle } from '../components/list-title.component';
import { useStyles } from './recipient-list.component.styles';

// ToDo: Integrate editing recipient
export const RecipientList = () => {
  const { classes } = useStyles();
  const { goTo } = useRouter();
  const { data, isLoading, isError, term, search, pagination, changePageSize, changePage, onSortChange } =
    useRecipientList();
  const { showModal, openModal, removeRecipient, closeModal } = useRemoveRecipient({
    search: term,
    page: pagination.page,
    pageSize: pagination.pageSize,
  });

  const goToAddRecipientPage = useCallback(() => {
    goTo(routes.settings.addRecipient.url());
  }, [goTo]);

  const navigateToDetails = (recipientId: string) => {
    goTo(routes.settings.editRecipient.details.url(recipientId));
  };

  return (
    <>
      <Paper data-testid="card">
        <ListHeader>
          <ListTitle tooltipContent={content.RECIPIENT_TABLE_TOOLTIP}>{content.RECIPIENT_TABLE_TITLE}</ListTitle>
          <div className={classes.listActions}>
            <div className={classes.actionItem}>
              <Search value={term} onChange={search} label={content.RECIPIENT_TABLE_SEARCH_LABEL} prefix="recipient" />
            </div>
            <Button variant="contained" onClick={goToAddRecipientPage}>
              {content.ADD_NEW_RECIPIENT_BTN}
            </Button>
          </div>
        </ListHeader>
        <RecipientListUI
          rows={data}
          isLoading={isLoading}
          isError={isError}
          pagination={pagination}
          changePageSize={changePageSize}
          changePage={changePage}
          onDelete={openModal}
          onEdit={navigateToDetails}
          onSortModelChange={onSortChange}
        />
      </Paper>
      <RemoveRecipientModal onCancel={closeModal} onSubmit={removeRecipient} isOpen={showModal} />
    </>
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Link } from '@marlin/shared/ui-page';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useAlertTooltip } from './use-alert-tooltip.hook';

interface IAlertTooltip {
  isAlert: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tooltip: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.typography.pxToRem(6),
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

export const AlertTooltip = ({ isAlert }: IAlertTooltip): JSX.Element | null => {
  const { classes } = useStyles();
  const { alertTabLink } = useAlertTooltip();

  if (!isAlert) {
    return null;
  }

  return (
    <Link to={alertTabLink} className={classes.tooltip}>
      <Tooltip placement="top" text={<div>{content.TOOLTIP}</div>}>
        <ErrorRoundedIcon />
      </Tooltip>
    </Link>
  );
};

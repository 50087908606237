import { ISettingsCommandGroup } from '@marlin/asset/shared/equipment-config';
import { Button, Icon, Menu, MenuItem } from '@mui/material';
import { SyntheticEvent } from 'react';

import { content } from '../../../content';
import { useDeviceCommands } from './hooks/use-device-commands-menu.hook';

export interface ICommandsMenuProps {
  valveNames: string[];
  manufacturer: string;
  manufacturerId: string;
  model: string;
  commandGroup: ISettingsCommandGroup;
}

export const CommandsMenu = ({ valveNames, manufacturer, manufacturerId, model, commandGroup }: ICommandsMenuProps) => {
  const { handleClose, handleClick, deviceCommands, anchorEl } = useDeviceCommands({
    valveNames,
    manufacturer,
    manufacturerId,
    model,
    commands: commandGroup.commands,
  });

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        endIcon={
          <Icon baseClassName="material-symbols-outlined" data-testid="more-icon">
            more_horiz
          </Icon>
        }
        onClick={(event) => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        {content.ACTUATE_VALVES.BUTTON_LABEL}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(event: SyntheticEvent) => {
          event.stopPropagation();
          handleClose();
        }}
        MenuListProps={{ dense: true }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {deviceCommands.map((command) => (
          <MenuItem
            key={command.label}
            onClick={(event) => {
              event.stopPropagation();
              command.onClick();
            }}
          >
            <span>{command.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

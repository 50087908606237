import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { BooleanCondition } from './boolean-condition.component';
import { NumberCondition } from './number-condition.component';
import { useConditionDatapoints } from './use-condition-datapoints.hook';
import { useDatapointSelect } from './use-datapoint-select.hook';

interface IConditionBuilderProps {
  triggerIndex: number;
}

export const ConditionBuilder = ({ triggerIndex }: IConditionBuilderProps) => {
  const { datapoints, isLoading } = useConditionDatapoints(triggerIndex);
  const { datapoint } = useDatapointSelect(triggerIndex, datapoints);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!datapoint) {
    return null;
  }

  switch (datapoint.type) {
    case 'bool': {
      return <BooleanCondition datapoint={datapoint} triggerIndex={triggerIndex} />;
    }
    case 'number': {
      return <NumberCondition datapoint={datapoint} triggerIndex={triggerIndex} />;
    }
    default: {
      return null;
    }
  }
};

import { GATEWAY_TAB_VALUES } from '@marlin/shared/utils-routes';

import { IGatewayDetailsConfig } from '../../../shared/config/model';
import { SmartBaseStationConfig as SmartBaseStationConfigShared } from '../../../shared/config/smart-base-station/smart-base-station.config';
import { ConnectedDevices } from '../../components/dashboard/lists/connected-devices/connected-devices.component';

export const SmartBaseStationConfig: IGatewayDetailsConfig = {
  ...SmartBaseStationConfigShared,
  tabs: {
    [GATEWAY_TAB_VALUES.CONNECTED_DEVICES]: <ConnectedDevices />,
  },
};

import { useMemo } from 'react';

import { content } from '../../content';

interface IConditionLabelsProps {
  datapointLabel: string;
  operator: 'lt' | 'gt';
}

export const useConditionLabels = ({ datapointLabel, operator }: IConditionLabelsProps) => {
  const label = useMemo(() => {
    switch (operator) {
      case 'lt': {
        return content.CONDITIONS.LOWER_THAN(datapointLabel);
      }
      case 'gt': {
        return content.CONDITIONS.HIGHER_THAN(datapointLabel);
      }
      default: {
        return '';
      }
    }
  }, [datapointLabel, operator]);

  const inputLabel = useMemo(() => {
    switch (operator) {
      case 'lt': {
        return content.CONDITIONS.LOWER_PLACEHOLDER;
      }
      case 'gt': {
        return content.CONDITIONS.HIGHER_PLACEHOLDER;
      }
      default: {
        return '';
      }
    }
  }, [operator]);

  return {
    label,
    inputLabel,
  };
};

import React from 'react';
import Plot from 'react-plotly.js';

import { IPlotComponentProps } from '../../model';

export const PlotComponent = React.memo(
  ({ data, layout, config, handleRelayout, handleHover, handleUnhover, height }: IPlotComponentProps) => {
    return (
      <Plot
        data={data}
        layout={layout}
        config={config}
        useResizeHandler={true}
        style={{ width: '100%', height }}
        onRelayout={handleRelayout}
        onHover={handleHover}
        onUnhover={handleUnhover}
      />
    );
  }
);

import { TCommand, TGatewayFilters, TOperation } from './gateway.model.schema';

export enum QUERY_KEY {
  GATEWAY = 'gateway',
  GATEWAY_BRANDS = 'gateway_brands',
  GATEWAY_MODE = 'gateway_mode',
  LOAD_CONFIGURATION = 'gateway_load_configuration',
  DEREGISTER_GATEWAY = 'deregister_gateway',
  EXECUTE_OPERATION = 'execute_operation',
  COMMAND = 'command',
  FILTER = 'filter',
  GATEWAY_CONFIGURATION = 'gateway_configuration',
  GET_GATEWAY = 'get_gateway',
  UPDATE_GATEWAY = 'update_gateway',
  SETTINGS_GROUP = 'gateway-settings-group',
  HUB = 'hub',
}

export const queryKey = {
  GATEWAY_FILTER: (params?: TGatewayFilters | Omit<TGatewayFilters, 'page'>) => [
    QUERY_KEY.GATEWAY,
    QUERY_KEY.FILTER,
    params,
  ],
  GATEWAY: (params?: TGatewayFilters | Omit<TGatewayFilters, 'page'>) => [QUERY_KEY.GATEWAY, params],
  DEREGISTER_GATEWAY: [QUERY_KEY.DEREGISTER_GATEWAY],
  GATEWAY_BRANDS: [QUERY_KEY.GATEWAY_BRANDS],
  GATEWAY_CONFIGURATION: (deviceId: string) => [QUERY_KEY.GATEWAY_CONFIGURATION, deviceId],
  GET_GATEWAY: (deviceId: string) => [QUERY_KEY.GET_GATEWAY, deviceId],
  UPDATE_GATEWAY: (deviceId: string) => [QUERY_KEY.UPDATE_GATEWAY, deviceId],
  GATEWAY_MODE: (params: { deviceId: string }) => [QUERY_KEY.GATEWAY_MODE, params],
  LOAD_CONFIGURATION: (params: { deviceId: string; configurationId: string }) => [QUERY_KEY.LOAD_CONFIGURATION, params],
  EXECUTE_OPERATION: (params: TOperation) => [QUERY_KEY.EXECUTE_OPERATION, params],
  COMMAND: (params: TCommand) => [QUERY_KEY.COMMAND, params],
  SETTINGS: (id?: string) => [QUERY_KEY.SETTINGS_GROUP, id],
};

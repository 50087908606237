import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IOrganizationsListStyles {
  width?: number;
}

export const useStyles = makeStyles<IOrganizationsListStyles>()((theme: MarlinTheme, { width }) => ({
  container: {
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: theme.typography.pxToRem(384),
      width: width && theme.typography.pxToRem(width),
      maxHeight: theme.typography.pxToRem(400),
    },
    zIndex: 1303, // it should be lower than material Dialog component zIndex: 1300
  },

  searchInput: {
    width: '100%',
    '& .Mui-focused .MuiInputAdornment-root svg': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-root': {
      padding: theme.typography.pxToRem(10),
    },
    '& .Mui-focused': {
      backgroundColor: '#0029B90A', //TODO ADD COLORS TO PALLETE
    },
  },
  loadingContainer: {
    height: theme.typography.pxToRem(70),
  },
  groupContainer: {
    backgroundColor: theme.palette.background.primary,
    paddingTop: theme.typography.pxToRem(12),
    paddingBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
  },
  groupName: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    color: theme.palette.text.disabled,
    textTransform: 'uppercase',
    lineHeight: theme.typography.pxToRem(20),
  },
}));

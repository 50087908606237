import { useDeleteInvitation } from '@marlin/account-data-access-invitation';
import { invalidateUserList } from '@marlin/account-data-access-user';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCallback, useMemo } from 'react';

import { content } from '../content';
interface IUseDeleteCurrentInvitation {
  deleteCurrentInvitation: (id: string) => Promise<void>;
}

export const useDeleteCurrentInvitation = (): IUseDeleteCurrentInvitation => {
  const { mutateAsync } = useDeleteInvitation();
  const { enqueueSnackbar } = useSnackbar();

  const deleteCurrentInvitation = useCallback(
    async (id: string) => {
      try {
        await mutateAsync(id);
        enqueueSnackbar(content.DELETE_INVITATION_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
        await invalidateUserList();
      } catch (error) {
        enqueueSnackbar(content.DELETE_INVITATION_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [enqueueSnackbar, mutateAsync]
  );

  return useMemo(() => ({ deleteCurrentInvitation }), [deleteCurrentInvitation]);
};

import { z } from 'zod';

import { CRITICALITY } from '../../../automation.model';
import { channelsSchema } from './channel.schema';

const operatorSchema = z.union([
  z.literal('eq'),
  z.literal('neq'),
  z.literal('gt'),
  z.literal('gte'),
  z.literal('lt'),
  z.literal('lte'),
]);

const logicalOperatorSchema = z.union([z.literal('and'), z.literal('or')]);

const ruleSchema = z.object({
  datapoint: z.string(),
  threshold: z.string(),
  operator: operatorSchema,
  id: z.string(),
});

const operationSchema = z.object({
  id: z.string(),
  logicalOperator: logicalOperatorSchema,
  operands: z.lazy(() => operandsSchema),
});

const operandsSchema = z.union([
  z.tuple([ruleSchema, ruleSchema]),
  z.tuple([operationSchema, ruleSchema]),
  z.tuple([ruleSchema, operationSchema]),
  z.tuple([operationSchema, operationSchema]),
]);

const triggerSchema = z.object({
  devices: z.array(z.string()),
  operation: z.lazy(() => z.union([operationSchema, ruleSchema])),
});

export const automationBuilderSchema = z.object({
  id: z.string(),
  name: z.string(),
  manufacturerIdList: z.array(z.string().nullable()),
  isEnabled: z.boolean(),
  snoozeIntervalInMinutes: z.number(),
  criticality: z.nativeEnum(CRITICALITY),
  channels: channelsSchema,
  trigger: triggerSchema,
});

export const automationsBuilderResponseSchema = z.array(automationBuilderSchema);

export type TAutomationBuilder = z.infer<typeof automationBuilderSchema>;

export type TAutomationsBuilderResponse = z.infer<typeof automationsBuilderResponseSchema>;

export type TTrigger = z.infer<typeof triggerSchema>;
export type TOperation = z.infer<typeof operationSchema>;
export type TRule = z.infer<typeof ruleSchema>;
export type TOperands = z.infer<typeof operandsSchema>;
export type TOperator = z.infer<typeof operatorSchema>;
export type TLogicalOperator = z.infer<typeof logicalOperatorSchema>;

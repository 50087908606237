import { TExtractFnReturnType, queryClient, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';

import { getFilteredDevices } from '../infrastructure/device';
import { TDeviceParamsSchema } from '../infrastructure/schema/device.request.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredDevices;

type TUseDevicesOptions = {
  params: TDeviceParamsSchema;
};

export const useFilteredDevicesWithPaging = ({ params }: TUseDevicesOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.DEVICES(params),
    queryFn: () => getFilteredDevices(params),
    placeholderData: (previousData, previousQuery) => previousData,
  });
};

type TUseInfiniteDevicesProps = {
  params: Omit<TDeviceParamsSchema, 'page'>;
  enabled?: boolean;
};

export const useFilteredDevicesWithLoadMore = ({ params, enabled = true }: TUseInfiniteDevicesProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.DEVICES(params),
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getFilteredDevices({ ...params, page: (pageParam as number) || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};

export const invalidateDevicesQuery = () => {
  queryClient.resetQueries({ queryKey: queryKey.DEVICES() });
};

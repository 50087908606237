import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: theme.typography.pxToRem(16),
    backgroundColor: alpha(theme.palette.alternative.main, 0.08),
    marginBottom: theme.typography.pxToRem(16),
  },
  filters: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  clearButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

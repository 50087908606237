import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  clearBottomMargin?: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { clearBottomMargin }) => ({
  reading: {
    padding: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.alternative,
    borderRadius: theme.typography.pxToRem(4),
    width: '100%',
    marginBottom: clearBottomMargin ? 0 : theme.typography.pxToRem(16),
  },
  overline: {
    color: theme.palette.text.secondary,
  },
}));

export interface IReadingCardProps {
  title: ReactNode;
  testId: string;
  clearBottomMargin?: boolean;
}

export const ReadingCard = ({ title, children, testId, clearBottomMargin }: PropsWithChildren<IReadingCardProps>) => {
  const { classes } = useStyles({ clearBottomMargin });

  return (
    <div className={classes.reading} data-testid={testId}>
      <Typography className={classes.overline} variant="overline" data-testid={`${testId}-title`}>
        {title}
      </Typography>
      <Typography variant="body2" data-testid={`${testId}-value`}>
        {children}
      </Typography>
    </div>
  );
};

import { HierarchyBreadcrumbs } from '@marlin/asset/shared/ui/hierarchy-breadcrumbs';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageHeader } from '@marlin/shared/ui-page';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../shared/content';
import { useRouter } from '../hooks/use-router.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.primary,
      gap: 0,
    },
  },

  editDeleteButtonsWrapper: {
    display: 'flex',
  },

  editDeleteButton: {
    width: theme.typography.pxToRem(56),
    marginLeft: theme.typography.pxToRem(16),
  },
}));

export const Header = ({ id, name }: { id: string; name: string }) => {
  const { classes } = useStyles();
  const { goToWithPrevious } = useRouter();

  const locationEditLink = useMemo(() => routes.locations.update.url(id), [id]);
  const handleEditButtonClick = useCallback(
    () => goToWithPrevious(locationEditLink),
    [goToWithPrevious, locationEditLink]
  );

  return (
    <div className={classes.headerWrapper}>
      <HierarchyBreadcrumbs locationId={id} selectedItemId={id} />
      <PageHeader
        prefix="plant"
        title={name}
        actions={
          <>
            <div className={classes.editDeleteButtonsWrapper}>
              <Restricted to={PERMISSIONS.EDIT_LOCATION}>
                <Button className={classes.editDeleteButton} variant="outlined" onClick={handleEditButtonClick}>
                  {content.EDIT_BUTTON}
                </Button>
              </Restricted>
            </div>
          </>
        }
      />
    </div>
  );
};

import { IAlert } from '@marlin/alert/data-access/alert-action';
import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { useExtendedDeviceDrawer } from '../container/context';
import { ManageAutomations } from '../manage-automations/manage-automations.component';
import { Chart } from './charts/chart.component';
import { ChartRangeContextProvider } from './context/chart-range.context';
import { ExtendedWrapper } from './extended-wrapper.component';
import { useRange } from './hooks/use-range.hook';
import { useReadings } from './hooks/use-readings.hook';
import { useStyles } from './readings.component.styles';
import { ReadingsHeader } from './readings/readings-header.component';

interface IDeviceDrawerReading {
  isAlert: boolean;
  communicationLostAlert?: IAlert;
  deviceId: string;
}

export const DeviceDrawerReadings = ({ isAlert, communicationLostAlert, deviceId }: IDeviceDrawerReading) => {
  const isExtended = useExtendedDeviceDrawer();

  const { classes } = useStyles({ isExtended });
  const rangeControl = useRange();
  const {
    isLoading,
    isFirstTimeLoading,
    isLeakUnitOfMeasure,
    recentReading,
    lowReading,
    highReading,
    refreshData,
    telemetry,
  } = useReadings({ range: rangeControl.range, refreshRange: rangeControl.refreshRange });

  if (isLoading && !isExtended) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className={classes.deviceReadingsSection} data-testid="drawer-readings">
        <ExtendedWrapper className={classes.paper}>
          <ChartRangeContextProvider value={rangeControl}>
            <ReadingsHeader
              isExtended={isExtended}
              refreshData={refreshData}
              isLoading={isFirstTimeLoading}
              isAlert={isAlert}
              recentReading={recentReading}
              lowReading={lowReading}
              highReading={highReading}
              isLeakUnitOfMeasure={isLeakUnitOfMeasure}
              communicationLostAlert={communicationLostAlert}
            />
            <Chart telemetry={telemetry} />
          </ChartRangeContextProvider>
        </ExtendedWrapper>
        {!isExtended && (
          <div className={classes.automationContainer}>
            <ManageAutomations />
          </div>
        )}
      </div>
    </>
  );
};

import { content } from '../../content';

export enum VALVE_STATUS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  OPENING = 'VALVE_OPENING',
  CLOSING = 'VALVE_CLOSING',
}

export const valveStatusMap = {
  [VALVE_STATUS.OPEN]: content.LDS_VALVE_STATUS.OPEN,
  [VALVE_STATUS.CLOSED]: content.LDS_VALVE_STATUS.CLOSED,
  [VALVE_STATUS.OPENING]: content.LDS_VALVE_STATUS.OPENING,
  [VALVE_STATUS.CLOSING]: content.LDS_VALVE_STATUS.CLOSING,
};

export const valveStatusControlMap = {
  [VALVE_STATUS.OPEN]: 'success' as const,
  [VALVE_STATUS.CLOSED]: 'error' as const,
  [VALVE_STATUS.OPENING]: 'active' as const,
  [VALVE_STATUS.CLOSING]: 'active' as const,
};

export const getValveStatus = ({ isOpen, isMoving }: { isOpen: boolean; isMoving: boolean }) => {
  if (isOpen && !isMoving) {
    return VALVE_STATUS.OPEN;
  } else if (!isOpen && !isMoving) {
    return VALVE_STATUS.CLOSED;
  } else if (isOpen && isMoving) {
    return VALVE_STATUS.CLOSING;
  }
  return VALVE_STATUS.OPENING;
};

import { Button, ButtonGroup } from '@mui/material';

import { content } from '../content';
import { useStyles } from './logic-operator.styles';

type TOperator = 'AND' | 'OR';

interface ILogicOperator {
  selectedOperator: TOperator;
  onToggleOperator: () => void;
}

export const LogicOperator = ({ selectedOperator, onToggleOperator }: ILogicOperator) => {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(classes.container)}>
      <ButtonGroup className={classes.btnGroup} variant="outlined">
        <Button
          className={cx(classes.button, { [classes.active]: selectedOperator === 'AND' })}
          onClick={onToggleOperator}
          disableElevation
        >
          {content.OPERATOR.AND}
        </Button>
        <Button
          className={cx(classes.button, { [classes.active]: selectedOperator === 'OR' })}
          onClick={onToggleOperator}
          disableElevation
        >
          {content.OPERATOR.OR}
        </Button>
      </ButtonGroup>
    </div>
  );
};

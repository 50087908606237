import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { EQUIPMENT_ENUM, EQUIPMENT_STATUS, TEquipment, TRegistrationFlow } from '../../../onboarding.schema';

export const defaultEquipmentValues: TEquipment = {
  registrationFlow: 'Tekmar',
  equipmentName: null,
  registrationCode: null,
  serialNumber: null,
  status: EQUIPMENT_STATUS.NEW,
  equipmentType: EQUIPMENT_ENUM.INTELLISTATION,
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.typography.pxToRem(32),
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      gap: theme.typography.pxToRem(16),
    },
  },
  input: {
    backgroundColor: theme.palette.background.primary,
    '&> .MuiFormHelperText-root': {
      paddingLeft: theme.typography.pxToRem(5),
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: theme.palette.background.secondary,
    },
  },
  button: {
    width: theme.typography.pxToRem(243),
    height: theme.typography.pxToRem(42),
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(16),
    },
  },
  cancelButton: {
    marginRight: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  formContainer: {
    width: '100%',
  },
  icon: {
    marginLeft: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(8),
  },
  smallIcon: {
    width: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(18),
  },
  link: {
    marginTop: theme.typography.pxToRem(3),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    width: '100%',
    display: 'block',
    textAlign: 'end',
    cursor: 'pointer',
  },
  buttonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: theme.typography.pxToRem(16),
    },
  },
}));

export interface IEquipmentFormProps {
  resetSwitcher: () => void;
  openInstructionsModal: (defaultStep?: number) => void;
  isEditForm?: boolean;
  onCancel?: () => void;
  registrationFlow?: TRegistrationFlow;
  isAnotherEquipment?: boolean;
  equipmentType: EQUIPMENT_ENUM;
  equipment?: TEquipment | null;
  index: number;
}

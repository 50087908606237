import { ReactNode } from 'react';

export const content = {
  AUTOMATION_NAME: 'Automation name',
  MIN_1_CHARACTER_REQUIRED: 'Minimum 1 character required',
  MAX_200_CHARTACTERS_ALLOWED: 'Maximum 200 characters allowed',
  DETAILS: 'Details',
  CRITICALITY: {
    TITLE: 'Criticality',
    LABEL: 'Automation Criticality *',
    VALUE: {
      LOW: 'Low',
      HIGH: 'High',
    },
    TOOLTIP: {
      INTRO:
        'Criticality allows for customization of the way alerts appear on your homescreen, and provide prioritization to an alert.',
      HIGH: 'Alerts appear as red on the homescreen, and a banner will also be triggered.',
      LOW: 'Alerts appear as orange on the homescreen.',
    },
  },
  AUTOMATION_NAME_HAS_CRITICALITY: (
    name: string,
    criticality: ReactNode,
    classNameWrapper: string,
    classNameText: string,
    onEditMode: () => void
  ) => (
    <div className={classNameWrapper}>
      Automation name{' '}
      {
        <span onClick={onEditMode} className={classNameText}>
          {' '}
          {name}{' '}
        </span>
      }{' '}
      has criticality{'  '}
      <span style={{ cursor: 'pointer' }} onClick={onEditMode}>
        {criticality}
      </span>
    </div>
  ),
};

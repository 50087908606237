import { IConfig, globalConfigSentinel } from '@marlin/asset/shared/equipment-config';
import { sentinelImage, sentinelLogo } from '@marlin/asset/shared/ui/assets';

import { arrayOfMappers } from './alert-mappers';
import { detailsConfig } from './details-config';
import { functionsMapping, settingsFunctionsMapping } from './functions-mapping';

export const sentinelConfig: IConfig = {
  headerBanner: null,
  tabs: {},
  details: detailsConfig,
  dashboard: {
    ...globalConfigSentinel.dashboard,
    dynamic: true,
    productDetails: {
      datapoints: [
        {
          name: 'InPoldAlert',
          label: '',
        },
        {
          name: 'valvesCount',
          label: '',
        },
        {
          name: 'poldsCount',
          label: '',
        },
        {
          name: 'apisCount',
          label: '',
        },
      ],
      imageSrc: sentinelImage,
      logoSrc: sentinelLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'datapoint',
      datapointsMappers: arrayOfMappers,
    },
    enableStartStopData: false,
    commonSectionSettings: {
      style: {
        fullWidth: true,
      },
    },
    sections: [],
    functionsMapping,
    isLastReadingTimeCounter: false,
  },
  settings: {
    dynamicSettingsSections: [
      {
        id: 'valveStates',
        title: 'Valve States',
        commandGroup: {
          label: 'Actuate Valves',
          commands: [
            {
              label: 'Open Valves',
              value: 'set-valve-state',
              payload: {
                valveState: 'Open',
              },
            },
            {
              label: 'Close Valves',
              value: 'set-valve-state',
              payload: {
                valveState: 'Closed',
              },
            },
          ],
        },
        settings: [
          {
            match: 'LDS_(\\d+)_Id',
            name: 'LDS_{x}_ValveState',
            label: 'LDS_{x}_Id',
            dependencies: {
              aggregation: {
                datapointNames: ['LDS_{x}_WaterOn', 'LDS_{x}_ValveMoving'],
                function: 'other',
              },
            },
          },
        ],
      },
    ],
    settingGroups: [],
    functionsMapping: settingsFunctionsMapping,
  },
};

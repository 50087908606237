import { SystemConstraintsPage } from '@marlin/shared/ui-page';
import { useMediaQuery, useTheme } from '@mui/material';

import { useGatewayDatapointsContext } from '../../../../../shared/context/gateway-datapoints.context';
import { ConnectedDevicesDesktop } from './desktop/connected-devices-desktop.component';
import { ConnectedDevicesMobile } from './mobile/connected-devices.mobile';

export const ConnectedDevices = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { gateway } = useGatewayDatapointsContext();

  if (!gateway) {
    return <SystemConstraintsPage />;
  }

  if (isMobile) {
    return <ConnectedDevicesMobile manufacturerId={gateway.manufacturerId} gatewayId={gateway.id ?? ''} />;
  }

  return <ConnectedDevicesDesktop manufacturerId={gateway.manufacturerId} gatewayId={gateway.id ?? ''} />;
};

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },
}));

export const EmptyList = () => {
  const { classes } = useStyles();
  return <div className={classes.emptyList}>{content.USER_TABLE_NO_DATA}</div>;
};

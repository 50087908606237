import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { TEquipment } from '@marlin/shared/utils/zod';

import { content } from '../../../../../../shared/content';
import { useMobileListStyles } from '../../../../../hooks/use-list-styles.hook';
import { Card, TCardVariant } from './card.component';

interface ISensorListMobileProps {
  assets: TDeviceSchema[] | TEquipment[];
  variant: TCardVariant;
  isError: boolean;
  isLoading: boolean;
  hasNextPage?: boolean;
  gatewayId: string;
  fetchNextPage: () => void;
  totalItems?: number;
}

export const AssetListMobile = ({
  assets,
  variant,
  isLoading,
  isError,
  totalItems,
  gatewayId,
  hasNextPage,
  fetchNextPage,
}: ISensorListMobileProps) => {
  const { classes } = useMobileListStyles();

  if (isLoading) {
    return (
      <div className={classes.emptyList}>
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return <div className={classes.emptyList}>{content.HUBS_AND_SENSORS.ERROR}</div>;
  }

  if (!assets.length) {
    return <div className={classes.emptyList}>{content.HUBS_AND_SENSORS.NO_DATA}</div>;
  }

  return (
    <div className={classes.wrapper}>
      {assets.map((asset) => {
        return <Card variant={variant} asset={asset} gatewayId={gatewayId} />;
      })}

      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={assets.length}
        data-testid="asset-pagination"
      />
    </div>
  );
};

import { Tooltip } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { HeaderDatapoint } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { SelectDuration } from '@marlin/shared/ui-form';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import { useDashboardChartOptions } from '@marlin/shared/ui/chart-options';
import { RANGE_FILTER, isOfChartRangeType, plotlyAdapterSchema, useRangeFilter } from '@marlin/shared/utils-chart';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TEquipment } from '@marlin/shared/utils/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from '@mui/material';
import uniq from 'lodash/uniq';
import React, { useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../shared/content';
import { useEquipmentsTelemetryContext } from '../../shared/context/equipments-telemetry.context';
import { usePlantConfigContext } from '../../shared/context/plant-config.context';
import { useChartData } from '../../shared/hooks/use-chart-data.hook';
import { IPerformance, getManufacturerId, usePerformanceValue } from '../../shared/performance';
import { getDatapoint } from '../../shared/utils/get-datapoint';
import { useConverterChartData } from '../hooks/use-equipment-dashboard-chart-data.hook';
import { TopAlertsWrapper } from './top-alerts-wrapper.component';

const chartHeight = {
  mobile: 200,
  desktop: 300,
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  accordion: {
    boxShadow: 'none',
    '&:before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: 0,
  },
  productDetailsWrapper: {
    display: 'grid',
    gap: theme.typography.pxToRem(8),
    width: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  container: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    margin: ` 0 0 ${theme.typography.pxToRem(24)} 0`,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      flexDirection: 'column',
      gap: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.primary,
    flexBasis: '50%',
  },
  paper: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(24),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      columnGap: theme.typography.pxToRem(16),
    },
  },
  headerWrapper: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.typography.pxToRem(16),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      lineHeight: theme.typography.pxToRem(32),
      margin: 0,
      marginBottom: theme.typography.pxToRem(12),
    },
  },
  titleMobile: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  overviewTitle: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(4),
    },
  },
  mainChart: {
    minHeight: theme.typography.pxToRem(chartHeight.desktop),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: theme.typography.pxToRem(chartHeight.mobile),
    },
  },
  chartContainer: {
    position: 'relative',
    width: '100%',
    '.nsewdrag.drag.cursor-pointer': {
      cursor: 'default',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.typography.pxToRem(16),
    },
  },
  chartTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
  },
  alertsWrapper: {
    flexBasis: '80%',
  },
  chartWrapper: {
    marginBottom: 0,
    padding: 0,
    backgroundColor: theme.palette.background.primary,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      border: 'none',
    },
  },
  durationWrapper: {
    padding: `0 ${theme.typography.pxToRem(16)}`,
    '& > div': {
      width: '100%',
    },
  },
  section: {
    backgroundColor: theme.palette.background.alternative,
    padding: theme.typography.pxToRem(12),
  },
  alertsAndChartWrapper: {
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    gap: theme.typography.pxToRem(24),
  },
}));

export const Performance = ({ equipmentList }: IPerformance) => {
  const { classes, cx, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const equipmentIds = equipmentList.map((equipment) => equipment.id);
  const { equipmentsTelemetry } = useEquipmentsTelemetryContext();
  const fireRate =
    equipmentList.reduce((acc, equipment) => {
      const [{ manufacturerId = '', lastReadingValues = [] }] = equipment.devices || [];
      const telemetry = equipmentsTelemetry[manufacturerId];
      const fireRate = getDatapoint('FireRateOut', lastReadingValues, telemetry);

      const currentFireRate = fireRate ? parseInt(fireRate) : 0;

      return acc + currentFireRate;
    }, 0) / equipmentList.length;

  const {
    config: { title, overviewTitle, managerDatapointName, performance, calculatedValue },
  } = usePlantConfigContext();

  const manager = equipmentList.find(({ devices }) => {
    const managerId =
      devices?.[0].lastReadingValues?.find(
        (value: { name: string }) => value.name.toLowerCase() === managerDatapointName.toLowerCase()
      )?.value || '';

    if (managerId === devices?.[0].manufacturerId) return true;

    const currentEquipmentDatapointName = 'commAddr';
    const commAddr =
      devices?.[0].lastReadingValues?.find(
        (value: { name: string }) => value.name.toLowerCase() === currentEquipmentDatapointName.toLowerCase()
      )?.value || '';

    return managerId === commAddr;
  });

  const [{ lastReadingValues = [] } = {}] = manager?.devices || [];
  const { performanceTilesData } = usePerformanceValue({
    performance,
    lastReadingValues,
    manufacturerId: getManufacturerId(manager),
  });

  return (
    <div className={classes.container}>
      <div className={classes.wrapper} data-testid="equipment-product-details">
        <Box className={cx(classes.paper, classes.headerWrapper)}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.section}>
            <Typography variant="overline" className={classes.overviewTitle}>
              {overviewTitle}
            </Typography>
            <div className={classes.productDetailsWrapper}>
              {performanceTilesData.map(({ name, label, value }) => (
                <HeaderDatapoint
                  key={name}
                  label={label}
                  value={value ? value : content.EMPTY_DATAPOINT_VALUE}
                  controlState={value ? 'active' : 'inactive'}
                  isLoading={false}
                />
              ))}
              {calculatedValue && (
                <HeaderDatapoint
                  key={'firing-rate'}
                  label={content.DATAPOINT_LABELS.FIRING_RATE}
                  value={getFormattedValue(`${fireRate}`, '%')}
                  controlState={'active'}
                  isLoading={false}
                />
              )}
            </div>
          </div>
        </Box>
        {isMobile && <Divider />}
      </div>
      <div className={classes.alertsAndChartWrapper}>
        <Chart manager={manager} />
        {!isTablet && (
          <TopAlertsWrapper equipmentList={equipmentList} equipmentIds={equipmentIds} classes={classes.alertsWrapper} />
        )}
      </div>
    </div>
  );
};

const Chart = ({ manager }: { manager: TEquipment | undefined }) => {
  const {
    config: {
      chartData: { datapointNames, datapointGroupNames },
    },
  } = usePlantConfigContext();

  const { handleRangeFilterChange, rangeFilter } = useRangeFilter(RANGE_FILTER.HOURS_8);
  const onRangeFilterChange = useCallback(
    (event: SelectChangeEvent<RANGE_FILTER>) => {
      const newRangeFilter = event.target.value;

      if (isOfChartRangeType(newRangeFilter)) {
        handleRangeFilterChange(newRangeFilter);
      }
    },
    [handleRangeFilterChange]
  );

  const { isLoading, refetch, datapointsGroupMetadata, telemetryData } = useChartData({
    manufacturerId: getManufacturerId(manager),
    datapointGroupNames,
    datapointNames,
    rangeFilter,
    equipmentId: manager?.id,
  });
  const { chartData, from, to } = useConverterChartData({
    rangeFilter: rangeFilter.range,
    datapointNames,
    datapointsGroupMetadata,
    telemetryData,
  });
  const uomsForSeries = useMemo(() => uniq([...chartData].map((s) => s?.uom)).filter(Boolean), [chartData]);
  const data = useMemo(() => {
    return plotlyAdapterSchema.parse({
      series: chartData,
      uoms: uomsForSeries,
    });
  }, [chartData, uomsForSeries]);

  const { classes, cx, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    chartOptions: { layout, config, handleUnhover, handleHover },
    tooltip,
  } = useDashboardChartOptions({ chartData, from, to });

  return (
    <Accordion className={classes.accordion} defaultExpanded expanded={!isMobile ? true : undefined}>
      {isMobile && (
        <AccordionSummary expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
          <Typography variant="h5" className={classes.titleMobile}>
            {content.MOBILE_CHART_TITLE}
          </Typography>
        </AccordionSummary>
      )}
      <AccordionDetails className={classes.accordionDetails}>
        <Box className={cx(classes.paper, classes.chartWrapper)}>
          <div className={classes.mainChart} data-testid="equipment-dashboard-chart">
            {isMobile ? (
              <div className={classes.durationWrapper}>
                <SelectDuration
                  rangeFilter={rangeFilter.range}
                  onRangeFilterChange={onRangeFilterChange}
                  onRefresh={refetch}
                />
              </div>
            ) : (
              <div className={classes.chartTitle}>
                <Typography variant="h5" className={classes.title}>
                  {content.CHART_TITLE}
                </Typography>
                <SelectDuration
                  rangeFilter={rangeFilter.range}
                  onRangeFilterChange={onRangeFilterChange}
                  onRefresh={refetch}
                />
              </div>
            )}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className={classes.chartContainer}>
                <Plot
                  data={data}
                  layout={layout}
                  config={config}
                  useResizeHandler={true}
                  style={{
                    width: '100%',
                    height: chartHeight[isMobile ? 'mobile' : 'desktop'],
                  }}
                  onHover={handleHover}
                  onUnhover={handleUnhover}
                />
                <Tooltip {...(tooltip || {})} />
                <EmptyChartData chartData={chartData} from={from} to={to} />
              </div>
            )}
          </div>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

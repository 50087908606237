import { TEquipmentAlert } from '@marlin/asset/data-access/equipment';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { CRITICALITY } from '@marlin/shared/utils/zod';

export const mapToEquipmentAlert = (datObjects: TDatapointWithUiMetadata[]): TEquipmentAlert[] => {
  return datObjects.map((dataObject) => {
    return {
      deviceEventCode: dataObject.name,
      startTime: dataObject.lastReadingTime,
      title: dataObject.label,
      criticality: CRITICALITY.HIGH,
      uoM: dataObject.unitOfMeasure,
      value: dataObject.value,
    };
  });
};

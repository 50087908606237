import { TUserSchema } from '@marlin/account-data-access-user';
import { createColumns } from '@marlin/account-ui-user-list';
import { MarlinTable } from '@marlin/marlin-table';

import { content } from '../../shared/content';
import { useUserContextMenu } from '../../shared/hooks/use-user-context-menu/use-user-context-menu.hook';
import { useUsersList } from '../../shared/hooks/use-user-list/use-users-list.hook';
import { UserListMenu } from '../components/user-list-menu.component';
import { DeleteUserModal } from '../modals/delete-user-modal.component';
import { ResendModal } from '../modals/resend-modal.component';

export const UserList = () => {
  const { actions, resendModal, deleteModal } = useUserContextMenu();

  return (
    <>
      <MarlinTable<TUserSchema>
        useDataHook={useUsersList}
        createColumns={createColumns(actions)}
        buttons={<UserListMenu />}
        texts={{
          searchTitle: content.USER_TABLE_SEARCH_LABEL,
          tableTitle: content.USER_TABLE_TITLE,
          tableTitleTooltip: content.USER_TABLE_TOOLTIP,
        }}
      />
      <ResendModal user={resendModal.user} onClose={resendModal.close} />
      <DeleteUserModal user={deleteModal.user} onClose={deleteModal.close} />
    </>
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  settingsSection: {
    maxWidth: theme.typography.pxToRem(746),
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
      borderRadius: theme.typography.pxToRem(4),
    },
  },
  accordionSummary: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      '&.Mui-expanded:hover': {
        cursor: 'default',
      },
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  commandsMenu: {
    marginRight: theme.typography.pxToRem(16),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginBottom: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(24),
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
}));

import { RecipientCard, RecipientListWrapper, RemoveRecipientModal } from '@marlin/account-ui-recipient-list';
import {
  FilteredResultsChip,
  MobileFilterModal,
  useMobileFilterForm,
} from '@marlin/asset/shared/ui/mobile-filter-modal';
import { Search } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { useSearchFilter } from '@marlin/shared/utils/url-params';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, IconButton } from '@mui/material';

import { content } from '../../shared/content';
import { useInfiniteRecipientList } from '../../shared/hooks/use-infinite-recipient-list.hook';
import { useRecipientContextMenu } from '../../shared/hooks/use-recipient-context-menu/use-recipient-context-menu.hook';
import { AddRecipientButton } from '../components/add-recipient-button.component';
import { IPeopleTabSwitcherProps, PeopleTabSwitcher } from '../components/people-tab-switcher.component';
import { SettingsPageHeader } from '../components/settings-page-header';
import { useStyles } from '../user-card-list/user-card-list.styles';

interface IRecipientCardListProps extends IPeopleTabSwitcherProps {}

export const RecipientCardList = ({ activeTab, onTabChange }: IRecipientCardListProps) => {
  const { classes } = useStyles();
  const { term, debouncedTrimmedTerm, search } = useSearchFilter();
  const { rows, totalItems, hasNextPage, fetchNextPage, isFetching, isFetched, isFetchingNextPage } =
    useInfiniteRecipientList({ search: debouncedTrimmedTerm });
  const { actions, deleteModal, onAdd, handleBack } = useRecipientContextMenu();
  const initialLoading = !isFetched && isFetching;
  const mobileFilterForm = useMobileFilterForm<'', { search: string }>({
    defaultFilters: { search: '' },
    sortList: [],
  });

  return (
    <>
      <SettingsPageHeader
        icon={<SettingsIcon />}
        title={content.PEOPLE}
        prefix="settings-header-people"
        topActions={
          <Button
            className={classes.button}
            startIcon={<ArrowBackRoundedIcon />}
            variant="outlined"
            onClick={handleBack}
          >
            {content.BACK}
          </Button>
        }
        marginBottom={false}
        inlineRightSideActions={
          <div className={classes.listActions}>
            <IconButton onClick={mobileFilterForm.toggleOpen}>
              <SearchRoundedIcon color="action" />
            </IconButton>
            <AddRecipientButton handleAddRecipient={onAdd} />
          </div>
        }
      />
      <PeopleTabSwitcher activeTab={activeTab} onTabChange={onTabChange} />
      {initialLoading ? (
        <LoadingSpinner />
      ) : (
        <RecipientListWrapper rowsCount={rows.length}>
          {term.length > 0 && (
            <div className={classes.filterChipWrapper}>
              <FilteredResultsChip
                onDelete={() => {
                  search('');
                }}
              />
            </div>
          )}
          {rows.map((recipient) => (
            <RecipientCard person={recipient} {...actions} />
          ))}
        </RecipientListWrapper>
      )}
      {isFetchingNextPage && <LoadingSpinner />}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={rows.length}
        data-testid="devices-pagination"
      />
      <MobileFilterModal<'', { search: string }>
        {...mobileFilterForm}
        title={content.RECIPIENT_SEARCH_MODAL_TITLE}
        rows={
          initialLoading ? (
            <LoadingSpinner />
          ) : (
            <RecipientListWrapper rowsCount={rows.length}>
              {rows.map((recipient) => (
                <RecipientCard person={recipient} {...actions} />
              ))}
            </RecipientListWrapper>
          )
        }
      >
        <Search value={term} onChange={search} label={content.RECIPIENT_TABLE_SEARCH_LABEL} prefix="recipient" />
      </MobileFilterModal>
      <RemoveRecipientModal
        onCancel={deleteModal.close}
        onSubmit={deleteModal.removeRecipient}
        isOpen={deleteModal.show}
      />
    </>
  );
};

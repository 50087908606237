import { gatewayImage, nexaLogo } from '@marlin/asset/shared/ui/assets';

import { content } from '../../content';
import { IGatewayDetailsConfig } from '../model';
import { NexaGatewayDetailsConfig } from './details-config';

export const NexaGatewayConfig: IGatewayDetailsConfig = {
  details: NexaGatewayDetailsConfig,
  tabs: {},
  dashboard: {
    productDetails: {
      logoSrc: nexaLogo,
      imageSrc: gatewayImage,
      modelName: content.NEXA_GATEWAY,
      datapoints: {
        connectionStatus: {
          display: true,
          signalStrengthDatapointName: 'Rssi',
        },
        connectionType: {
          display: true,
          connectionTypeDatapointName: 'ConnectionType',
        },
      },
    },
    displayEquipmentAndSensorsList: true,
    displayTopAlerts: true,
    displayGatewayMode: true,
  },
};

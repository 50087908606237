export const content = {
  IS_MECHANICAL_ROOM_LABEL: 'This location is a Mechanical Room',
  POSTAL_CODE: 'Postal Code',
  ZIP_CODE: 'Zip Code',
  BUTTON_CANCEL: 'Cancel',
  LOCATION_DETAILS: 'Location Details',
  LOCATION_NAME_LABEL: 'Location Name',
  PLANT_DETAILS: 'Plant Details',
  PLANT_NAME_LABEL: 'Plant Name',
  PARENT_LOCATION_LABEL: 'Parent Location',
  DESCRIPTION_LABEL: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Add a short description about the new location',
  LOCATION_ADDRESS: 'Location Address',
  PLANT_ADDRESS: 'Plant Address',
  COUNTRY_LABEL: 'Country',
  ADDRESS_1_LABEL: new Map([
    ['Mexico', 'Street Address'],
    ['Canada', 'Address 1'],
    ['United States', 'Address 1'],
    ['', 'Address 1'],
  ]),
  ADDRESS_2_LABEL: new Map([
    ['Mexico', 'Apt / Suite / Other'],
    ['Canada', 'Address 2'],
    ['United States', 'Address 2'],
    ['', 'Address 2'],
  ]),
  APT_SUITE_OTHER: '',
  CITY_LABEL: 'City',
  STATE_LABEL: 'State',
  BUTTON_UPDATE: 'Update',
  BUTTON_CREATE: 'Create',
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,
  PLANT_WARNING: 'This location is a Plant',
  PLANT_WARNING_TOOLTIP:
    'Plant locations are automatically generated during the Nexa registration of a managing water heater or boiler with sequencing technology, given proper configuration on the controllers.',
  ADDITIONAL_INFORMATION: 'Additional Information',
};

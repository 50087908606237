import { DEVICE_TYPE } from '@marlin/asset/data-access/device';
import * as Yup from 'yup';

import { content } from './content';
import { IUpsertDeviceForm } from './use-upsert-device-form.hook';

type TFormData = Omit<
  IUpsertDeviceForm,
  | 'id'
  | 'equipmentId'
  | 'createdByUserId'
  | 'createdDateTime'
  | 'modifiedByUserId'
  | 'modifiedDateTime'
  | 'lastReadingValue'
  | 'lastReadingValues'
  | 'gatewayId'
  | 'isCompound'
  | 'devices'
>;

const DeviceFormDataSchema: Yup.SchemaOf<TFormData> = Yup.object({
  name: Yup.string().required(content.REQUIRED).min(3, content.MIN_MAX).max(200, content.MIN_MAX),
  manufacturerId: Yup.string().required(content.REQUIRED),
  deviceType: Yup.mixed<DEVICE_TYPE>().oneOf(Object.values(DEVICE_TYPE)).required(content.REQUIRED),
  locationId: Yup.string().required(content.REQUIRED),
  linkedEquipment: Yup.string(),
  brand: Yup.string().required(content.REQUIRED),
  model: Yup.string().required(content.REQUIRED),
  description: Yup.string().optional().nullable(),
  pulseMeterManufacturer: Yup.mixed().when('deviceType', {
    is: (deviceType: string) => {
      return deviceType === 'PULSE_METER';
    },
    then: Yup.string().required(content.REQUIRED),
  }),
  pulseMeterModel: Yup.mixed().when('deviceType', {
    is: (deviceType: string) => {
      return deviceType === 'PULSE_METER';
    },
    then: Yup.string().required(content.REQUIRED),
  }),
  signalReceiverType: Yup.string().optional().nullable(),
  pulseMeterTypeId: Yup.string().optional().nullable(),
  pulseMeterTypeIds: Yup.array()
    .of(
      Yup.object({
        pulseMeterTypeId: Yup.string().required(),
        order: Yup.number().required(),
      })
    )
    .optional()
    .nullable(),
  equipmentId: Yup.string().optional().nullable(),
  pinned: Yup.boolean().optional(),
  commissionDate: Yup.string().optional().nullable(),
  assignmentDate: Yup.string().optional().nullable(),
  meterType: Yup.mixed().oneOf(['Gas', 'Water']).optional(),
  systemTag: Yup.mixed()
    .oneOf([
      null,
      '',
      'DOMESTIC_HOT_WATER',
      'DOMESTIC_COLD_WATER',
      'HEATING_HOT_WATER',
      'CHILLED_WATER',
      'CONDENSER_WATER',
    ])
    .nullable(),
  sourceSinkTag: Yup.mixed().oneOf([null, '', 'POE_WATER_SOURCE']).optional().nullable(),
});

export { DeviceFormDataSchema };

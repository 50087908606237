import { TDeviceChildrenDatapointsParams, TTotalItems } from '@marlin/asset/data-access/device';
import { TabTable } from '@marlin/marlin-table';

import { useSystemEquipment } from './hooks/use-system-equipment.hook';
import { MetadataContextProvider } from './metadata.context';
import { content } from './sentinel/content';
import { PoldFilters } from './sentinel/pold/pold-filters.component';
import { tabs } from './sentinel/tabs';
import { TSentinelSystemEquipment, TSystemEquipmentData } from './types';

export const SystemEquipmentDesktop = () => {
  const {
    tab,
    changeTab,
    dataHookFilters,
    metadata,
    showOnlyAlertsPolds,
    setShowOnlyAlertsPolds,
    inAlertPoldsCount,
    clearAllAlerts,
    isSendingDeviceCommand,
  } = useSystemEquipment();

  return (
    <MetadataContextProvider metadata={metadata}>
      <TabTable<TSentinelSystemEquipment, TSystemEquipmentData, TDeviceChildrenDatapointsParams, TTotalItems>
        tabs={tabs}
        selectedTab={tab}
        changeTab={changeTab}
        dataHookFilters={dataHookFilters}
        renderTabLabel={(tab, metadata) => {
          return content.TABS.TAB_WITH_COUNT(tab.name, metadata?.[tab.id]);
        }}
      >
        {tab === 'POLD' ? (
          <PoldFilters
            setShowOnlyInAlerts={setShowOnlyAlertsPolds}
            showOnlyInAlerts={showOnlyAlertsPolds}
            inAlertPoldsCount={inAlertPoldsCount}
            clearAllAlerts={clearAllAlerts}
            isSendingDeviceCommand={isSendingDeviceCommand}
          />
        ) : null}
      </TabTable>
    </MetadataContextProvider>
  );
};

import {
  AUTOMATION_RULE_TYPE,
  RULE_CATEGORY,
  TCreateAutomation,
  useAutomations,
} from '@marlin/alert/data-access/automated-action';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { content } from '../../../content/validation-content';
import { isGatewayDurationLonger } from './utils';
import { Warning } from './warning.component';

// TODO: Fix this when the backend solution for lost communication is refactored
const sensorLostCommunicationAutomationName = 'Sensor Lost Communication';

export function GatewayDurationWarning() {
  const { watch } = useFormContext<TCreateAutomation>();

  const automationsQuery = useAutomations({
    params: {
      locationId: [],
      equipmentId: [],
      deviceId: [],
      isEnabled: null,
      page: 1,
      pageSize: 10,
      ruleCategory: RULE_CATEGORY.HEALTH,
      term: sensorLostCommunicationAutomationName,
    },
  });

  const gatewayDurationValue = watch('duration.value');
  const gatewayDurationUnit = watch('duration.unit');
  const deviceDuration = useMemo(
    () =>
      automationsQuery.data?.data.find(({ ruleType }) => ruleType === AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION)
        ?.duration,
    [automationsQuery.data?.data]
  );

  const showWarning = useMemo(
    () =>
      isGatewayDurationLonger({
        gatewayDuration: { value: gatewayDurationValue, unit: gatewayDurationUnit },
        deviceDuration,
      }),
    [deviceDuration, gatewayDurationValue, gatewayDurationUnit]
  );

  return showWarning ? <Warning message={content.GATEWAY_DURATION_WARNING} /> : null;
}

import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { DeviceTypeSelect } from '@marlin/shared/ui-form';
import { InfiniteAutocomplete } from '@marlin/shared/ui-form-common';

import { FilterContainer } from './components/filter-container.component';
import { ISensorFilterStore } from './hooks/use-sensor-filters.store';
import { useStyles } from './sensor-trigger-filters.styles';

export const SensorTriggerFilters = ({ filters, dispatchOnChange, clear }: ISensorFilterStore) => {
  const { classes } = useStyles();

  return (
    <FilterContainer handleClearAll={clear}>
      <div className={classes.select}>
        <LocationSearchWrapper enableSearch={false}>
          {(props) => (
            <InfiniteAutocomplete
              {...props}
              multiple={false}
              testId="sensor-trigger-filters-location"
              label="Location"
              onChange={(value) => {
                dispatchOnChange({ type: 'SET_LOCATION', payload: value });
              }}
              value={filters.location}
              size="small"
              disableClearable={false}
            />
          )}
        </LocationSearchWrapper>
      </div>
      <div className={classes.select}>
        <DeviceTypeSelect
          value={filters.deviceType}
          onChange={(event) => {
            dispatchOnChange({ type: 'SET_DEVICE_TYPE', payload: event.target.value });
          }}
          size="small"
        />
      </div>
    </FilterContainer>
  );
};

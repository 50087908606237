import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { TDeviceMetadataDatapointsResponse, TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { ReactNode, useCallback } from 'react';

interface IMetadataContext {
  metadata?: TDeviceMetadataResponse;
  getDatapointFromMetadata: (name: string) => TDeviceMetadataDatapointsResponse | undefined;
}

export const MetadataContext = createContext<IMetadataContext>();

interface IMetadataContextProviderProps {
  metadata?: TDeviceMetadataResponse;
  children: ReactNode;
}

export const MetadataContextProvider = ({ metadata, children }: IMetadataContextProviderProps) => {
  const getDatapointFromMetadata = useCallback(
    (name: string) => {
      return metadata?.datapoints.find((metadata) => metadata.name === name);
    },
    [metadata?.datapoints]
  );
  return <MetadataContext.Provider value={{ metadata, getDatapointFromMetadata }}>{children}</MetadataContext.Provider>;
};

export const useMetadataContext = () => useContext(MetadataContext);

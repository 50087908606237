import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { DeviceName } from './device-name.component';
import { formatBooleanCondition, formatNumberConditions } from './formatter.utils';
import { useStyles } from './triggers-content-view.styles';

interface ITriggersContentViewProps {
  triggerIndex: number;
  editModeToggle: () => void;
}

export const TriggersContentView = ({ triggerIndex, editModeToggle }: ITriggersContentViewProps) => {
  const { classes } = useStyles();
  const { watch } = useFormContext<TAutomationBuilder>();
  const device = watch(`triggers.${triggerIndex}.device`);
  const datapoint = watch(`triggers.${triggerIndex}.datapoint`);
  const conditions = watch(`triggers.${triggerIndex}.conditions`);

  const formattedConditions = useMemo(() => {
    switch (conditions?.type) {
      case 'boolean': {
        return formatBooleanCondition(conditions, editModeToggle);
      }
      case 'number': {
        return formatNumberConditions(conditions, datapoint, editModeToggle);
      }
      default: {
        return '';
      }
    }
  }, [conditions, datapoint, editModeToggle]);

  if (!device) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="body2" data-testid="triggers-content-view" className={classes.triggersText}>
        <DeviceName deviceType={device.deviceType} name={device.name} editModeToggle={editModeToggle} />
        {formattedConditions}
      </Typography>
    </div>
  );
};

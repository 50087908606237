import { CRITICALITY, TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { CriticalityButton } from '@marlin/shared/ui-criticality';
import { FormField, Input } from '@marlin/shared/ui-form-common';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { content } from '../content';
import { CriticalityTooltip } from '../criticality-tooltip.component';
import { useStyles } from './details-content-edit.styles';

export const DetailsContentEdit = () => {
  const { classes } = useStyles();
  const { setValue } = useFormContext<TAutomationBuilder>();

  return (
    <div className={classes.container}>
      <FormField<TAutomationBuilder> fieldName="details.name">
        {(props) => <Input {...props} label={content.AUTOMATION_NAME} required testId="automation-name" />}
      </FormField>
      <div className={classes.criticalityWrapper}>
        <div data-testid="automation-criticality-header" className={classes.tooltipIcon}>
          <Typography className={classes.text} variant="body2">
            {content.CRITICALITY.TITLE}
          </Typography>
          <span className={classes.tooltip}>
            <Tooltip placement="right" text={<CriticalityTooltip />} />
          </span>
        </div>
        <div>
          <FormField<TAutomationBuilder> fieldName="details.criticality">
            {(props) => {
              return (
                <div className={classes.criticalityChipsWrapper}>
                  <CriticalityButton
                    onClick={() => setValue('details.criticality', CRITICALITY.HIGH)}
                    severity={CRITICALITY.HIGH}
                    selected={props.value === CRITICALITY.HIGH}
                    className={classes.criticalityButton}
                  />
                  <CriticalityButton
                    onClick={() => setValue('details.criticality', CRITICALITY.LOW)}
                    severity={CRITICALITY.LOW}
                    selected={props.value === CRITICALITY.LOW}
                    className={classes.criticalityButton}
                  />
                </div>
              );
            }}
          </FormField>
        </div>
      </div>
    </div>
  );
};

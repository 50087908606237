import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useUpdateUser } from './hooks/use-update-user.hook';
import { UserUpdateForm } from './user-update-form/user-profile-upsert-form.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
  },
}));

export const SettingsEditUser = () => {
  const { classes } = useStyles();
  const { userId } = useParams();
  const { goTo } = useRouter();

  const { user, handleSubmit } = useUpdateUser({
    userId: userId ?? '',
    onSuccess: () => goTo(routes.settings.people.url()),
  });

  const handleCancel = () => {
    goTo(routes.settings.people.url());
  };

  if (!user) {
    return null;
  }

  return (
    <Paper className={classes.formContainer} data-testid="edit-user-form">
      <UserUpdateForm user={user} onSubmit={handleSubmit} onCancel={handleCancel} />
    </Paper>
  );
};

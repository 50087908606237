import { InformationCard } from '@marlin/alert/shared/ui/automation-card';
import { AutomatedActionFilters, IFilterParams, RULE_CATEGORY, content } from '@marlin/alert/ui/automated-action-list';
import { IExtendedAutomation } from '@marlin/alert/ui/automated-action-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageLoader } from '@marlin/shared/ui-loader';
import { IRequestPagination, PageContainer, PageHeader, Pagination } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AutomatedActionListTabs } from '../automated-action-list-tabs.component';
import { defaultFilterParams, defaultPaginationParams } from '../automation-default-params';
import { useNavigation } from '../use-navigation.hook';
import { useStore } from '../use-store.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: theme.static.defaultWidth,
  },
}));

export function Information() {
  const { classes } = useStyles();
  const allowEdit = usePermission(PERMISSIONS.EDIT_AUTOMATION);

  const { getUpdatePageLink } = useNavigation();
  const router = useRouter();
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [params, setParams] = useState<IFilterParams>(defaultFilterParams);
  const [requestPagination, setRequestPagination] = useState<IRequestPagination>(defaultPaginationParams);
  const { automations, isLoading, updateAutomation, pagination, isListLoading } = useStore({
    ...params,
    ...requestPagination,
    term: params?.term?.trim(),
    ruleCategory: RULE_CATEGORY.INFORMATION,
  });

  const handleAutomationEdit = useCallback(
    (automationId: string) => {
      router.goTo(getUpdatePageLink(automationId));
    },
    [getUpdatePageLink, router]
  );

  const handlePaginationChange = useCallback((requestPagination: IRequestPagination) => {
    setRequestPagination(requestPagination);
  }, []);

  const handleParamsChange = useCallback(
    (newParams: IFilterParams) => {
      setRequestPagination({
        pageSize: requestPagination.pageSize,
        page: defaultPaginationParams.page,
      });
      setParams(newParams);
    },
    [setParams, requestPagination.pageSize]
  );

  const handleClearParams = useCallback(() => {
    setRequestPagination(defaultPaginationParams);
    setParams(defaultFilterParams);
  }, []);

  useEffect(() => {
    if (!isListLoading) {
      setEmptyState(automations.length === 0);
    }
  }, [setEmptyState, automations, isListLoading]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageContainer prefix="automations-device-health-page" className={classes.container}>
      <PageHeader
        icon={<MiscellaneousServicesRoundedIcon />}
        title={content.AUTOMATIONS_TITLE}
        subtitle={content.AUTOMATIONS_SUBTITLE}
        prefix="automations-header"
      />
      <AutomatedActionListTabs />
      <AutomatedActionFilters onClearParams={handleClearParams} onChangeParams={handleParamsChange} />
      <Grid container>
        {automations?.map((item: IExtendedAutomation, index: number) => (
          <InformationCard
            item={item}
            key={index}
            onItemEdit={handleAutomationEdit}
            onItemToggle={updateAutomation}
            allowEdit={allowEdit}
          />
        ))}
      </Grid>
      {!emptyState && <Pagination pagination={pagination} onPaginationChange={handlePaginationChange} />}
    </PageContainer>
  );
}

export default Information;

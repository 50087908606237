import { MarlinTheme } from '@marlin/shared/theme';
import BatteryFullRoundedIcon from '@mui/icons-material/BatteryFullRounded';
import { makeStyles } from 'tss-react/mui';

import { highBatteryLevel, mediumBatteryLevel } from '../../consts';
import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(6),
  },

  successIcon: {
    color: theme.palette.success.light,
  },

  warningIcon: {
    color: theme.palette.warning.main,
  },

  errorIcon: {
    color: theme.palette.error.main,
  },
}));

type TBatteryProps = {
  variant: 'number' | 'text';
  batteryLevel: number | 'LOW' | 'HIGH';
  isExtendedDrawer?: boolean;
};

export function Battery({ batteryLevel, isExtendedDrawer = false, variant }: TBatteryProps) {
  const { classes } = useStyles();

  const getBatteryIconClass = (): string => {
    if (variant === 'number') {
      const battery = batteryLevel as number;
      if (battery >= highBatteryLevel) {
        return classes.successIcon;
      }
      if (battery >= mediumBatteryLevel) {
        return classes.warningIcon;
      }

      return classes.errorIcon;
    }

    if (batteryLevel === 'HIGH') {
      return classes.successIcon;
    }

    return classes.errorIcon;
  };

  const getBatteryLevelText = (): string => {
    if (variant === 'number') {
      const battery = batteryLevel as number;
      return isExtendedDrawer
        ? content.EXTENDED_POWER_CELL_BATTERY_LEVEL(battery)
        : content.POWER_CELL_BATTERY_LEVEL(battery);
    }
    const battery = batteryLevel as 'LOW' | 'HIGH';
    return isExtendedDrawer
      ? content.TEXT_EXTENDED_POWER_CELL_BATTERY_LEVEL(battery)
      : content.TEXT_POWER_CELL_BATTERY_LEVEL(battery);
  };

  return (
    <div className={classes.cell}>
      <BatteryFullRoundedIcon className={`${classes.icon} ${getBatteryIconClass()}`} />
      {getBatteryLevelText()}
    </div>
  );
}

import { RULE_CATEGORY } from '@marlin/alert/ui/automated-action-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { defaultFilterParams, defaultPaginationParams } from './automation-default-params';
import { content } from './content';
import { useInformationStore } from './use-information-store.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tabs: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export const AutomatedActionListTabs = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const { goTo } = useRouter();

  const { automations } = useInformationStore({
    ...defaultFilterParams,
    ...defaultPaginationParams,
    term: defaultFilterParams?.term?.trim(),
    ruleCategory: RULE_CATEGORY.INFORMATION,
  });

  const handleTabChange = (event: SyntheticEvent, pathname: string) => {
    goTo(pathname);
  };

  return (
    <Tabs value={pathname} onChange={handleTabChange} className={classes.tabs}>
      <Tab value={routes.automations.root} label={content.PERFORMANCE} data-testid={'automations-performance-tab'} />
      <Tab value={routes.automations.health.root} label={content.HEALTH} data-testid={'automations-health-tab'} />
      {automations && automations.length > 0 && (
        <Tab
          value={routes.automations.information.root}
          label={content.INFORMATION}
          data-testid={'automations-information-tab'}
        />
      )}
    </Tabs>
  );
};

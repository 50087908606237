import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

import { viewerPermissions } from './viewer.permission';

export const csmPermissions: TPermission[] = [
  ...viewerPermissions.filter((permission) => permission !== PERMISSIONS.SHOW_SETTINGS_PROFILE_PAGE),
  PERMISSIONS.CREATE_ORGANIZATION,
  PERMISSIONS.SHOW_SUPPORT_PAGE,
  PERMISSIONS.SHOW_MONNIT_API_KEY_SETTINGS_PAGE,
  PERMISSIONS.SHOW_SUPPORT_PAGE_ANALYTICS,
  PERMISSIONS.INVITE_OTHER_SUPPORT_USERS,
  PERMISSIONS.SHOW_SUPPORT_SETTINGS_ORGANIZATION_TAB,
];

import { TIconEquipment } from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as UISelect,
  SelectChangeEvent,
  SvgIconProps,
} from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import { FC, useMemo, useState } from 'react';
import { ControllerRenderProps, FieldError, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    width: '100%',
  },

  active: {
    color: theme.palette.action.active,
  },

  disabled: {
    color: theme.palette.text.disabled,
  },

  empty: {
    height: theme.typography.pxToRem(32),
    color: theme.palette.text.secondary,
  },
  helper: {
    marginLeft: theme.typography.pxToRem(5),
  },
  name: {
    overflowWrap: 'anywhere',
    width: '100%',
    whiteSpace: 'wrap',
    maxWidth: theme.typography.pxToRem(500),
  },
}));

export interface IFilter {
  id: string | number;
  name: string;
  Icon?: FC<SvgIconProps> | TIconEquipment;
  disabled?: boolean;
}

interface ISelectProps extends Partial<ControllerRenderProps> {
  data: IFilter[];
  label: string;
  emptyOption?: string;
  fullWidth?: boolean;
  prefix: string;
  className?: string;
  error?: FieldError;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  collapsed?: boolean;
  filterSelected?: boolean;
  requiredErrorMessageOnBlur?: string;
  defaultValue?: string | undefined;
}

export function Select({
  data,
  label,
  emptyOption,
  fullWidth = true,
  prefix,
  className,
  error,
  value,
  onChange,
  disabled,
  required,
  multiple = false,
  filterSelected = false,
  collapsed,
  onBlur,
  // TODO: we should think to make validation on blur as a global behavior for all selects, depend on required prop only
  requiredErrorMessageOnBlur,
  name,
  defaultValue,
}: ISelectProps) {
  const { classes } = useStyles();
  const { validateOnBlur, handleOnChange } = useSelectWithBlurValidation({
    fieldName: name,
    requiredErrorMessageOnBlur,
    onChange,
    error,
  });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (collapsed) {
      setOpen(false);
    }

    onBlur?.();
  };

  const handleOpen = () => {
    if (options.length) {
      setOpen(true);
    }
  };

  const options = useMemo(
    () => (filterSelected ? data.filter((option) => !value?.includes(option.id)) : data),
    [data, filterSelected, value]
  );

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (validateOnBlur) {
      validateOnBlur(event.target.value);
    }
  };

  return (
    <FormControl error={!!error} className={className}>
      <InputLabel id={`${prefix}-multi-select-label`} required={required} shrink={collapsed ? false : undefined}>
        {label}
      </InputLabel>
      <UISelect
        multiple={multiple}
        fullWidth={fullWidth}
        data-testid={`select-filters-${kebabCase(label).toLowerCase()}`}
        labelId={`${prefix}-multi-select-label`}
        id={`${prefix}-multi-select`}
        label={label}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        required={required}
        notched={collapsed ? false : undefined}
        onClose={handleClose}
        onOpen={collapsed ? handleOpen : undefined}
        open={collapsed ? open : undefined}
        onBlur={handleOnBlur}
        defaultValue={defaultValue}
      >
        {emptyOption && (
          <MenuItem key="empty" className={classes.empty} value={''}>
            {emptyOption}
          </MenuItem>
        )}
        {options?.map(({ name, id, Icon, disabled: disabledOption }) => {
          return (
            <MenuItem key={id} value={id} onClick={collapsed ? handleClose : undefined} disabled={disabledOption}>
              <div className={classes.container}>
                {Icon && <Icon className={disabled ? classes.disabled : classes.active} />}
                <span className={classes.name}>{name}</span>
              </div>
            </MenuItem>
          );
        })}
      </UISelect>
      <FormHelperText className={classes.helper}>{error?.message}</FormHelperText>
    </FormControl>
  );
}

export const useSelectWithBlurValidation = ({
  fieldName,
  error,
  onChange,
  requiredErrorMessageOnBlur,
}: {
  fieldName?: string;
  requiredErrorMessageOnBlur?: string;
  onChange?: ISelectProps['onChange'];
  error?: FieldError;
}) => {
  const form = useFormContext();

  const validateOnBlur = (value: string | undefined) => {
    if (form && !value && fieldName && !!requiredErrorMessageOnBlur) {
      form.setError(fieldName, { message: requiredErrorMessageOnBlur });
    }
  };

  const handleOnChange = (e: SelectChangeEvent<string>) => {
    if (!error) return onChange?.(e);

    onChange?.(e);

    if (e.target.value && form) {
      form.clearErrors(fieldName);
    }
  };

  return { validateOnBlur, handleOnChange };
};

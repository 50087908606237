import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { IconEquipment } from '@marlin/asset/shared/ui/assets';
import { useRouter } from '@marlin/asset/shared/ui/equipment-list';
import {
  FilteredResultsChip,
  MobileFilterModal,
  MobileFilterToggleButton,
  useMobileFilterForm,
} from '@marlin/asset/shared/ui/mobile-filter-modal';
import { MarlinTheme } from '@marlin/shared/theme';
import { EquipmentTypeControl, IEquipmentTypeOption } from '@marlin/shared/ui-device-type';
import { FieldFiltersChips, FormFilterChips } from '@marlin/shared/ui-form';
import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IEquipmentsHubFilters, useEquipmentsHub } from '../shared/hooks/use-equipments-hub.hook';
import { EquipmentList } from './equipment-list.component';
import { FilteredEquipmentList } from './filtered-equipment-list.component';
import { TEquipmentSort, sortList } from './sorting';

interface IEquipmentFilter {
  search: string | undefined;
  locations: { id: string; name: string }[];
  equipmentType: IEquipmentTypeOption[];
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  filteredResultsChip: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(8),
  },
  createButton: {
    color: theme.palette.action.active,
  },
}));

const defaultFilters: IEquipmentsHubFilters = {
  locations: [],
  equipmentType: [],
};

export function MobileEquipmentHub() {
  const { classes } = useStyles();
  const mobileFilterForm = useMobileFilterForm<TEquipmentSort, IEquipmentsHubFilters>({ defaultFilters, sortList });
  const { goToNewEquipmentPage } = useRouter();
  const { rows, totalItems, hasNextPage, fetchNextPage, isLoading } = useEquipmentsHub(mobileFilterForm.filters);

  return (
    <PageContainer prefix="equipment-hub-page" data-testid="equipment-hub-container">
      <PageHeader
        icon={<IconEquipment.Other />}
        title={content.EQUIPMENT_HUB_TITLE}
        prefix="equipment-hub-header"
        data-testid="equipment-hub-header"
        actions={
          <>
            <IconButton disableRipple color="primary" onClick={goToNewEquipmentPage} className={classes.createButton}>
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
            <MobileFilterToggleButton onToggle={mobileFilterForm.toggleOpen} isOpen={mobileFilterForm.open} />
          </>
        }
      />
      {mobileFilterForm.isAnyFilterSet ? (
        <div className={classes.filteredResultsChip}>
          <FilteredResultsChip onDelete={mobileFilterForm.clearFilters} />
        </div>
      ) : undefined}
      <EquipmentList
        rows={rows}
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isListLoading={isLoading}
      />
      <MobileFilterModal<TEquipmentSort, IEquipmentsHubFilters>
        {...mobileFilterForm}
        rows={<FilteredEquipmentList />}
        title={content.EQUIPMENT_HUB_TITLE}
        chips={
          <FormFilterChips<IEquipmentsHubFilters> defaultFilters={defaultFilters}>
            <FieldFiltersChips<IEquipmentsHubFilters, 'locations'>
              fieldName="locations"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
            <FieldFiltersChips<IEquipmentFilter, 'equipmentType'>
              fieldName="equipmentType"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
          </FormFilterChips>
        }
      >
        <LocationSearchWrapper>
          {(props) => (
            <InfiniteAutocompleteControl<IEquipmentsHubFilters>
              fieldName="locations"
              testId="location-search"
              label="Location"
              {...props}
            />
          )}
        </LocationSearchWrapper>
        <EquipmentTypeControl<IEquipmentFilter> fieldName="equipmentType" />
      </MobileFilterModal>
    </PageContainer>
  );
}

import { IconEquipment } from '@marlin/asset/shared/ui/assets';
import { CardInfiniteList } from '@marlin/marlin-table';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

import { CardListAccordion } from '../../components/card-list-accordion.component';
import { SystemEquipmentCard } from '../../components/system-equipment-card.component';
import { useInfiniteSystemEquipmentList } from '../../hooks/use-infinite-system-equipment-data.hook';
import { TSentinelSystemEquipment } from '../../types';
import { content } from '../content';
import { useLdsValveCard } from './use-create-lds-valve-cards';

const modelName: TSentinelSystemEquipment = 'LDSValve';

interface ILdsValveCardListProps {
  deviceId: string;
}

export const LdsValveCardList = ({ deviceId }: ILdsValveCardListProps) => {
  const query = useInfiniteSystemEquipmentList({ modelName, deviceId, pageSize: 5 });
  const cardConfig = useLdsValveCard();

  const queryParams = useQueryParameters();
  const defaultExpanded = queryParams.get('model') === modelName;

  const title = useMemo(() => {
    return content.TABS.TAB_WITH_COUNT(content.TABS.LDS_VALVE, query?.systemEquipmentTotal?.[modelName]);
  }, [query.systemEquipmentTotal]);

  return (
    <CardListAccordion title={title} defaultExpanded={defaultExpanded}>
      <CardInfiniteList
        isFetchingNextPage={query.isFetchingNextPage}
        isError={query.isError}
        fetchNextPage={query.fetchNextPage}
        hasNextPage={query.hasNextPage}
        totalItems={query.totalItems}
        loadedItems={query.rows.length}
      >
        {query.rows.map((row) => (
          <SystemEquipmentCard row={row} cardConfig={cardConfig} icon={<IconEquipment.ShutOffValve />} />
        ))}
      </CardInfiniteList>
    </CardListAccordion>
  );
};

import { IDeleteUser, IResendInvite } from '@marlin/account-ui-user-list';
import { useCallback, useState } from 'react';

import { useDeleteCurrentInvitation } from './use-delete-invitation.hook';

export const useUserActionModals = () => {
  const { deleteCurrentInvitation } = useDeleteCurrentInvitation();

  const [resendModalUser, setResendModalOpen] = useState<IResendInvite | null>(null);
  const [deleteModalUser, setDeleteModalOpen] = useState<IDeleteUser | null>(null);

  const openResendModal = useCallback((user: IResendInvite): void => {
    setResendModalOpen(user);
  }, []);

  const openDeleteModal = useCallback((user: IDeleteUser): void => {
    setDeleteModalOpen(user);
  }, []);

  const closeResendModal = useCallback(() => setResendModalOpen(null), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(null), []);

  return {
    resendModalUser,
    deleteModalUser,
    openResendModal,
    openDeleteModal,
    closeResendModal,
    closeDeleteModal,
    deleteCurrentInvitation,
  };
};

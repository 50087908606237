import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { IFilterParams } from '../automation.model';
import {
  TAutomationHistoryList,
  TAutomationHistoryParams,
  automationHistoryListSchema,
  automationHistoryParamsSchema,
} from '../schemas/automation-history/automation-history.schema';
import {
  TAutomationCreateResponse,
  TCreateAutomation,
  automationUpdateSchema,
  createAutomationSchema,
  updateAutomationSchema,
} from '../schemas/automation-upsert.schema';
import { TAutomation, automationSchema, pagedAutomationSchema } from '../schemas/automation.schema';
import { paths } from './api';

export const createAutomation = async (data: TCreateAutomation): Promise<TAutomationCreateResponse> => {
  const res = await getHttpClient().post(paths.AUTOMATION_CREATE, createAutomationSchema.parse(data));
  return automationUpdateSchema.parse(res);
};

class TUpdateAutomation {}

export const updateAutomation = async (data: TUpdateAutomation): Promise<TAutomationCreateResponse> => {
  const automation = updateAutomationSchema.parse(data);
  const res = await getHttpClient().put(paths.AUTOMATION, automation, {
    params: { automationId: automation.id },
  });
  return automationUpdateSchema.parse(res);
};

export const deleteAutomation = async (automationId: string): Promise<void> => {
  return await getHttpClient().delete<unknown, void>(paths.AUTOMATION_DELETE, {
    params: { automationId },
  });
};

export const getAutomations = async (params?: IFilterParams): Promise<z.infer<typeof pagedAutomationSchema>> => {
  const res = await getHttpClient().post(paths.AUTOMATION_LIST, {
    page: params?.page,
    pageSize: params?.pageSize,
    devices: params?.deviceId,
    search: params?.term?.length ? params.term : undefined,
    locations: params?.locationId,
    equipments: params?.equipmentId,
    isEnabled: params?.isEnabled !== null ? params?.isEnabled : undefined,
    ruleCategory: params?.ruleCategory,
  });
  return safeParseData(res, pagedAutomationSchema);
};

export const getAutomationsHistory = async (params: TAutomationHistoryParams): Promise<TAutomationHistoryList> => {
  const requestParams = safeParseData(params, automationHistoryParamsSchema);
  const res = await getHttpClient().get(paths.AUTOMATION_HISTORY, { params: requestParams });
  return safeParseData(res, automationHistoryListSchema);
};

export const getAutomation = async (automationId: string): Promise<TAutomation> => {
  const res = await getHttpClient().get(paths.AUTOMATION, {
    params: { automationId },
  });
  return automationSchema.parse(res);
};

import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';

import { content } from '../../content';
import { datapointCreatorUtil } from './datapoint-creator.util';
import { getValveStatus, valveStatusControlMap, valveStatusMap } from './valve-status';

export const functionsMapping = {
  InPoldAlert: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const leakDatapoints = datapointsFromDeps.filter((datapoint) => datapoint.unitOfMeasure === 'WaterDetect');
    const hasLeak = leakDatapoints.some((datapoint) => datapoint.value === 'True');
    if (hasLeak) {
      return datapointCreatorUtil({
        configDatapoint,
        value: content.LDS_SYSTEM_STATUS.LEAK,
        displayedValue: content.LDS_SYSTEM_STATUS.LEAK,
        controlState: 'error',
      });
    }

    const poldDatapoints = datapointsFromDeps.filter((datapoint) => datapoint.name.includes('POLD'));
    const alertDatapoints = poldDatapoints.filter(
      (datapoint) =>
        datapoint.name.includes('InTemperatureAlert') ||
        datapoint.name.includes('InRelativeHumidityAlert') ||
        datapoint.name.includes('HasLowBatteries')
    );
    const communicationDatapoints = poldDatapoints.filter((datapoint) =>
      datapoint.name.includes('CommunicationStatusActive')
    );
    const hasPoldAlert = alertDatapoints.some((datapoint) => datapoint.value === 'True');
    const hasPoldLostComm = communicationDatapoints.some((datapoint) => datapoint.value === 'False');
    if (hasPoldAlert || hasPoldLostComm) {
      return datapointCreatorUtil({
        configDatapoint,
        value: content.LDS_SYSTEM_STATUS.CHECK_POLDS,
        displayedValue: content.LDS_SYSTEM_STATUS.CHECK_POLDS,
        controlState: 'error',
      });
    }

    const ldsDatapoints = datapointsFromDeps.filter((datapoint) => datapoint.name.includes('LDS'));
    const communicationLdsDatapoints = ldsDatapoints.filter((datapoint) =>
      datapoint.name.includes('CommunicationStatusActive')
    );
    const hasLdsLostComm = communicationLdsDatapoints.some((datapoint) => datapoint.value === 'False');
    if (hasLdsLostComm) {
      return datapointCreatorUtil({
        configDatapoint,
        value: content.LDS_SYSTEM_STATUS.CHECK_LDS,
        displayedValue: content.LDS_SYSTEM_STATUS.CHECK_LDS,
        controlState: 'error',
      });
    }
    const waterOnDatapoint = datapointsFromDeps.find((datapoint) => datapoint.name.includes('WaterOn'));
    if (waterOnDatapoint?.value === 'off') {
      return datapointCreatorUtil({
        configDatapoint,
        value: content.LDS_SYSTEM_STATUS.WATER_OFF,
        displayedValue: content.LDS_SYSTEM_STATUS.WATER_OFF,
        controlState: 'error',
      });
    }

    return datapointCreatorUtil({
      configDatapoint,
      value: content.LDS_SYSTEM_STATUS.SYSTEM_ACTIVE,
      displayedValue: content.LDS_SYSTEM_STATUS.SYSTEM_ACTIVE,
      controlState: 'active',
    });
  },
  API_CommunicationStatusActive: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const isOperational = datapointsFromDeps.every((datapoint) => datapoint.value === 'True');
    const lostCommCount = datapointsFromDeps.filter((datapoint) => datapoint.value === 'False').length;
    return datapointCreatorUtil({
      configDatapoint,
      value: isOperational ? content.ALL_OPERATIONAL : content.LOST_COMM(lostCommCount),
      displayedValue: isOperational ? content.ALL_OPERATIONAL : content.LOST_COMM(lostCommCount),
      controlState: isOperational ? 'active' : 'error',
    });
  },
  API_InAlert: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const noAlert = datapointsFromDeps.every((datapoint) => datapoint.value === 'False');
    return datapointCreatorUtil({
      configDatapoint,
      value: noAlert ? content.NO_ALERT : content.ALERT,
      displayedValue: noAlert ? content.NO_ALERT : content.ALERT,
      controlState: noAlert ? 'active' : 'error',
    });
  },
  API_InPoldAlert: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const hasLeak = datapointsFromDeps.some((datapoint) => datapoint.value === 'True');
    const leakCount = datapointsFromDeps.filter((datapoint) => datapoint.value === 'True').length;
    return datapointCreatorUtil({
      configDatapoint,
      value: hasLeak ? content.LEAK(leakCount) : content.NO_LEAK,
      displayedValue: hasLeak ? content.LEAK(leakCount) : content.NO_LEAK,
      controlState: hasLeak ? 'error' : 'active',
    });
  },
  POLD_InAlert: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const noAlert = datapointsFromDeps.every((datapoint) => datapoint.value === 'False');
    return datapointCreatorUtil({
      configDatapoint,
      value: noAlert ? content.NO_ALERT : content.ALERT,
      displayedValue: noAlert ? content.NO_ALERT : content.ALERT,
      controlState: noAlert ? 'active' : 'error',
    });
  },
  POLD_CommunicationStatusActive: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const communicationStatusDatapoints = datapointsFromDeps.filter((datapoint) =>
      datapoint.name.includes('CommunicationStatusActive')
    );
    const lowBatteryDatapoints = datapointsFromDeps.filter((datapoint) => datapoint.name.includes('HasLowBatteries'));
    const isOperational =
      communicationStatusDatapoints.every((datapoint) => datapoint.value === 'True') &&
      lowBatteryDatapoints.every((datapoint) => datapoint.value === 'False');
    const lostCommCount = communicationStatusDatapoints.filter((datapoint) => datapoint.value === 'False').length;
    const lowBatteryCount = lowBatteryDatapoints.filter((datapoint) => datapoint.value === 'True').length;
    return datapointCreatorUtil({
      configDatapoint,
      value: isOperational
        ? content.ALL_OPERATIONAL
        : lostCommCount > 0
          ? content.LOST_COMM(lostCommCount)
          : content.LOW_BATTERY(lowBatteryCount),
      displayedValue: isOperational
        ? content.ALL_OPERATIONAL
        : lostCommCount > 0
          ? content.LOST_COMM(lostCommCount)
          : content.LOW_BATTERY(lowBatteryCount),
      controlState: isOperational ? 'active' : 'error',
    });
  },
  POLD_InPoldAlert: (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const hasLeak = datapointsFromDeps.some((datapoint) => datapoint.value === 'True');
    const leakCount = datapointsFromDeps.filter((datapoint) => datapoint.value === 'True').length;
    return datapointCreatorUtil({
      configDatapoint,
      value: hasLeak ? content.LEAK(leakCount) : content.NO_LEAK,
      displayedValue: hasLeak ? content.LEAK(leakCount) : content.NO_LEAK,
      controlState: hasLeak ? 'error' : 'active',
    });
  },
};

export const settingsFunctionsMapping = {
  'LDS_(\\d+)_ValveState': (
    configDatapoint: Partial<TDatapointWithUiMetadata> & { name: string },
    datapointsFromDeps: TDatapointWithUiMetadata[]
  ) => {
    const waterOnDatapoint = datapointsFromDeps.find((datapoint) => datapoint.name.toLowerCase().includes('wateron'));
    const valveMovingDatapoint = datapointsFromDeps.find((datapoint) =>
      datapoint.name.toLowerCase().includes('valvemoving')
    );
    const isValveMoving = valveMovingDatapoint?.value === 'True';
    const isValveOpen = waterOnDatapoint?.value === 'True';
    const valveState = getValveStatus({ isMoving: isValveMoving, isOpen: isValveOpen });
    return datapointCreatorUtil({
      configDatapoint: {
        ...configDatapoint,
        unitOfMeasure: 'ValvePosition' as const,
      },
      value: valveState,
      displayedValue: valveStatusMap[valveState],
      controlState: valveStatusControlMap[valveState],
    });
  },
};

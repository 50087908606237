import { TDatapoint } from '@marlin/shared/utils/datapoint-mappers';

export const getManagerAddresses = (
  datapoints: Array<TDatapoint | undefined>,
  managerDatapointName: string,
  backupManagerDatapointName: string
) => {
  const addresses = datapoints.filter(
    (datapoint) =>
      datapoint?.name.toLowerCase() === managerDatapointName.toLowerCase() ||
      datapoint?.name.toLowerCase() === backupManagerDatapointName.toLowerCase()
  );

  if (addresses.length) {
    const managerAddress = addresses.find(
      (datapoint) => datapoint?.name.toLowerCase() === managerDatapointName.toLowerCase()
    );
    const backupManagerAddress = addresses.find(
      (datapoint) => datapoint?.name.toLowerCase() === backupManagerDatapointName.toLowerCase()
    );
    return { managerAddress: managerAddress?.value, backupManagerAddress: backupManagerAddress?.value };
  }

  return { managerAddress: undefined, backupManagerAddress: undefined };
};

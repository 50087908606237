import { IconEquipment, IconSensor } from '@marlin/asset/shared/ui/assets';
import { CardHeader, DetailsItem, LinkedItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import RouterRoundedIcon from '@mui/icons-material/RouterRounded';
import { IconButton } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../../../shared/content';
import { getHubSerialNumber } from '../../../../../../shared/utils/get-hub-serial-number.utils';
import { useNavigation } from '../../../../../hooks/use-navigation.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(12),
    borderRadius: theme.typography.pxToRem(4),
    '& svg': {
      fontSize: theme.typography.body1.fontSize,
      marginRight: theme.typography.pxToRem(8),
    },
    '& div': {
      marginBottom: 0,
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.action.active,
    marginRight: theme.typography.pxToRem(5),
  },
}));

export type TCardVariant = 'hub' | 'sensor' | 'valveController';

interface ICardProps<T extends TCardVariant> {
  variant: T;
  asset: ICardAsset<T>;
  gatewayId: string;
}

export interface ICardAsset<T extends TCardVariant> {
  name: string;
  id: string;
  locationName?: string | null;
  locationId?: string | null;
  displayManufacturerId: string;
  model?: T extends 'sensor' ? string : never;
  serialNumber: T extends 'valveController' ? string : never;
}

export function Card<T extends TCardVariant>({ variant, gatewayId, asset }: ICardProps<T>) {
  const { classes } = useStyles();
  const isEditable = usePermission(PERMISSIONS.EDIT_GATEWAY_HUB);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const serialNumber = useMemo(() => {
    switch (variant) {
      case 'hub':
        return getHubSerialNumber({
          manufacturerId: asset.displayManufacturerId ?? '',
        });
      case 'valveController':
        return asset.serialNumber;
      default:
        return undefined;
    }
  }, [asset.displayManufacturerId, asset.serialNumber, variant]);

  const icon = useMemo(() => {
    switch (variant) {
      case 'hub':
        return <RouterRoundedIcon className={classes.icon} />;
      case 'sensor':
        return <IconSensor.LeakDetector className={classes.icon} />;
      case 'valveController':
        return <IconEquipment.ValveController className={classes.icon} />;
      default:
        return undefined;
    }
  }, [classes.icon, variant]);

  const getLink = useCallback(
    (links: { sensorLink: string; hubLink: string; equipmentLink: string }) => {
      switch (variant) {
        case 'sensor':
          return links.sensorLink;
        case 'hub':
          return links.hubLink;
        case 'valveController':
          return links.equipmentLink;
        default:
          return '';
      }
    },
    [variant]
  );

  const onEditClick = () => {
    const sensorLink = navigation.getEditSensorPageLink(asset.id ?? '');
    const hubLink = navigation.getEditHubPageLink(gatewayId, asset.id ?? '');
    const equipmentLink = navigation.getEditEquipmentPageLink(asset.id ?? '');

    navigate(getLink({ sensorLink, hubLink, equipmentLink }));
  };

  const cardDisplayProperties = useMemo(() => {
    const properties: string[] = [];

    if (['hub', 'valveController'].includes(variant)) {
      properties.push('serialNumber');
    }

    if (['sensor', 'valveController'].includes(variant)) {
      properties.push('model');
    }

    if (variant === 'sensor') {
      properties.push('manufacturerId');
    }

    return properties;
  }, [variant]);

  return (
    <Paper className={classes.card} data-testid="card">
      <CardHeader
        asset={asset}
        goTo={(id: string) => {
          const sensorLink = navigation.getDetailsSensorPageLink(id);
          const hubLink = navigation.getDetailsHubPageLink(gatewayId, id ?? '');
          const equipmentLink = navigation.getEquipmentDetailsPageLink(id);
          return navigate(getLink({ sensorLink, hubLink, equipmentLink }));
        }}
        icon={icon}
        data-testid={`${variant}-card-header`}
      >
        {isEditable ? (
          <IconButton data-testid={`${variant}-card-menu`} onClick={onEditClick}>
            <EditRoundedIcon fontSize="small" />
          </IconButton>
        ) : undefined}
      </CardHeader>
      {/*//TODO: Uncomment when status and battery level is available*/}
      {/*<StatusBar isOperational={asset.isOperational} />*/}
      {/*<ReadingCard title={content.HUBS_AND_SENSORS.COLUMNS.POWER_SOURCE} testId={'power-source'}>*/}
      {/*  <PowerSource batteryLevel={asset.batteryLevel} />*/}
      {/*</ReadingCard>*/}
      <DetailsItem
        label={content.HUBS_AND_SENSORS.COLUMNS.LOCATION}
        testId={`${variant}-location`}
        value={
          <LinkedItem
            id={asset.locationId}
            name={asset.locationName}
            goTo={(id: string) => routes.locations.details.url(id)}
          />
        }
      />
      {cardDisplayProperties.includes('model') && (
        <DetailsItem label={content.HUBS_AND_SENSORS.COLUMNS.MODEL} testId={`${variant}-model`} value={asset.model} />
      )}
      {cardDisplayProperties.includes('serialNumber') && (
        <DetailsItem
          label={content.HUBS_AND_SENSORS.COLUMNS.SERIAL_NUMBER}
          testId={`${variant}-serial-number`}
          value={serialNumber}
        />
      )}
      {cardDisplayProperties.includes('manufacturerId') && (
        <DetailsItem
          label={content.HUBS_AND_SENSORS.COLUMNS.MANUFACTURER_ID}
          testId={`${variant}-manufacturer-id`}
          value={asset.displayManufacturerId}
        />
      )}
    </Paper>
  );
}

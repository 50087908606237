import { TDeviceType } from '@marlin/shared/ui-form';
import { useReducer, useState } from 'react';

interface IOption {
  id: string;
  name: string;
}

interface IFilterState {
  deviceType?: TDeviceType | null;
  location?: IOption | null;
}

type TPublicFilterAction =
  | { type: 'SET_DEVICE_TYPE'; payload: TDeviceType }
  | { type: 'SET_LOCATION'; payload: IOption };

type TFilterAction =
  | TPublicFilterAction
  | { type: 'SET_DEBOUNCED_SEARCH_TERM'; payload: string }
  | { type: 'CLEAR'; payload: IFilterState };

const initialState: IFilterState = {
  deviceType: undefined,
  location: undefined,
};

export interface ISensorFilterStore {
  filters: IFilterState;
  dispatchOnChange: (action: TPublicFilterAction) => void;
  clear: () => void;
}

export interface IUserFilterStore extends ISensorFilterStore {
  toggleFiltersOpen: (isOpen?: boolean) => void;
  areFiltersOpen: boolean;
  selectedFiltersCount?: number;
}

const filterReducer = (state: IFilterState, action: TFilterAction): IFilterState => {
  switch (action.type) {
    case 'SET_DEVICE_TYPE':
      return { ...state, deviceType: action.payload };
    case 'SET_LOCATION':
      return { ...state, location: action.payload };
    case 'CLEAR':
      return { ...action.payload };
    default:
      return state;
  }
};

export const useSensorFilterStore = (): IUserFilterStore => {
  const [filters, dispatch] = useReducer(filterReducer, initialState);
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  const dispatchOnChange = (action: TPublicFilterAction) => {
    dispatch(action);
  };

  const clear = () => {
    dispatch({ type: 'CLEAR', payload: { ...initialState } });
  };

  const toggleFiltersOpen = () => {
    setAreFiltersOpen((prev) => !prev);
  };

  const selectedFiltersCount = Object.values(filters).filter(Boolean).length;

  return {
    filters,
    dispatchOnChange,
    clear,
    toggleFiltersOpen,
    selectedFiltersCount,
    areFiltersOpen,
  };
};

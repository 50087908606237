import { TValveState } from '@marlin/asset/data-access/equipment';

import { content } from '../../../../content';

interface IValveCommandModalTitleProps {
  valveState: TValveState;
}

export const ValveCommandModalTitle = ({ valveState }: IValveCommandModalTitleProps) => {
  return (
    <div data-testid="valve-command-modal-title">
      {valveState === 'Open' ? content.ACTUATE_VALVES.MODAL.TITLE.OPEN : content.ACTUATE_VALVES.MODAL.TITLE.CLOSE}
    </div>
  );
};

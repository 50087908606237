import { useSystemEquipmentDataHook } from '../hooks/use-system-equipment-data.hook';
import { ISystemEquipmentTab } from '../types';
import { content } from './content';
import { useCreateLdsValveColumns } from './lds-valve/use-create-lds-valve-columns';
import { useCreatePoldColumns } from './pold/use-create-pold-columns.hook';

export const tabs: ISystemEquipmentTab[] = [
  {
    name: content.TABS.LDS_VALVE.toUpperCase(),
    id: 'LDSValve',
    useDataHook: useSystemEquipmentDataHook,
    useColumns: useCreateLdsValveColumns,
  },
  {
    name: content.TABS.POLD,
    id: 'POLD',
    useDataHook: useSystemEquipmentDataHook,
    useColumns: useCreatePoldColumns,
  },
];

import { renderEllipsisCell } from '@marlin/data-grid';
import { formatPhoneNumber } from '@marlin/shared/ui-form';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';

import { IRecipientContextMenuActionsProps, RecipientContextMenu } from './components/recipient-context-menu.component';
import { rowsPerPageOptions } from './constants';
import { content } from './content';
import { useDataGridSx, useStyles } from './recipient-list.component.styles';

export interface IRecipientRow {
  id: string;
  FirstName: string;
  Email: string;
  phone: string;
}

export interface IRecipientListProps {
  rows: IRecipientRow[];
  isLoading: boolean;
  onDelete: (rowId: string) => void;
  isError: boolean;
  pagination: {
    page: number;
    pageSize: number;
    totalItems: number;
  };
  changePage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  onEdit: (recipientId: string) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

interface IColumnsProps extends IRecipientContextMenuActionsProps {
  columnProps: Partial<GridColDef>;
}

const dataGridPageCounterOffset = 1;

const createColumns = ({ columnProps, onDelete, onEdit }: IColumnsProps): GridColDef[] => [
  {
    field: 'FirstName',
    headerName: content.USER_TABLE_HEADER_NAME,
    flex: 2,
    ...columnProps,
    renderCell: renderEllipsisCell,
  },
  {
    field: 'Email',
    headerName: content.USER_TABLE_HEADER_EMAIL,
    flex: 2,
    ...columnProps,
    renderCell: renderEllipsisCell,
  },
  {
    field: 'phone',
    headerName: content.USER_TABLE_HEADER_PHONE,
    flex: 1.5,
    ...columnProps,
    renderCell: (cellValues) => {
      return formatPhoneNumber(cellValues.row.phone);
    },
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    hideable: false,
    filterable: false,
    flex: 0.5,
    align: 'center',
    ...columnProps,
    renderCell: (cellValues) => {
      const recipientId = cellValues.row.id;

      return <RecipientContextMenu recipientId={recipientId} onEdit={onEdit} onDelete={onDelete} />;
    },
  },
];

export const RecipientList = ({
  rows,
  isLoading,
  isError,
  pagination,
  changePage,
  changePageSize,
  onEdit,
  onDelete,
  onSortModelChange,
}: IRecipientListProps) => {
  const { classes } = useStyles();
  const sx = useDataGridSx();

  const dataGridPage = useMemo(() => pagination.page - dataGridPageCounterOffset, [pagination.page]);

  const dataGridChangePageSize = useCallback(
    (page: number) => changePage(page + dataGridPageCounterOffset),
    [changePage]
  );

  return (
    <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
      <DataGrid
        sx={sx}
        loading={isLoading}
        onPageChange={dataGridChangePageSize}
        onPageSizeChange={changePageSize}
        localeText={{
          noRowsLabel: isError ? content.USER_TABLE_ERROR : content.USER_TABLE_NO_DATA,
        }}
        page={dataGridPage}
        pageSize={pagination.pageSize}
        rowCount={pagination.totalItems}
        rows={rows}
        columns={createColumns({ columnProps: { headerClassName: classes.column }, onEdit, onDelete })}
        rowsPerPageOptions={rowsPerPageOptions}
        paginationMode="server"
        disableSelectionOnClick
        disableColumnMenu
        onSortModelChange={onSortModelChange}
        sortingMode="server"
      />
    </div>
  );
};

import { useFilteredDevicesWithPaging } from '@marlin/asset/data-access/device';
import { useFilteredEquipmentsWithPaging } from '@marlin/asset/data-access/equipment';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { SUB_TABS } from '../../desktop/components/dashboard/lists/connected-devices/desktop/connected-devices-desktop.component';
import { usePagination } from '../../desktop/hooks/use-pagination.hook';
import { content } from '../content';

export const useSubdevicesList = (manufacturerId: string, gatewayId: string, tab: SUB_TABS) => {
  const hubsPagination = usePagination();
  const sensorPagination = usePagination();
  const [sortingParams, setSortingParams] = useState<{ sortBy?: string; direction?: string } | undefined>();

  const {
    data: hubs,
    isError: isHubError,
    isLoading: isHubsLoading,
  } = useFilteredDevicesWithPaging({
    params: {
      parentManufacturerId: manufacturerId,
      deviceTypes: ['HUB'],
      page: hubsPagination.paginationModel.page + 1,
      pageSize: hubsPagination.paginationModel.pageSize,
      ...sortingParams,
    },
  });

  const {
    data: sensors,
    isError: isSensorError,
    isLoading: isSensorLoading,
  } = useFilteredDevicesWithPaging({
    params: {
      parentManufacturerId: manufacturerId,
      deviceTypes: ['LEAK'],
      page: hubsPagination.paginationModel.page + 1,
      pageSize: hubsPagination.paginationModel.pageSize,
      ...sortingParams,
    },
  });

  const {
    data: valveControllers,
    isError: isValveControllerError,
    isLoading: isValveControllerLoading,
  } = useFilteredEquipmentsWithPaging({
    params: {
      gatewayIds: [gatewayId],
      equipmentType: [EQUIPMENT_TYPE.VALVE_CONTROLLER],
      page: hubsPagination.paginationModel.page + 1,
      pageSize: hubsPagination.paginationModel.pageSize,
    },
  });

  const hubCount = useMemo(() => hubs?.pagination.totalItems ?? 0, [hubs]);
  const sensorCount = useMemo(() => sensors?.pagination.totalItems ?? 0, [sensors]);
  const valveControllerCount = useMemo(() => valveControllers?.pagination.totalItems ?? 0, [valveControllers]);

  const counters = useMemo(
    () => ({
      hub: hubCount,
      sensor: sensorCount,
      valveController: valveControllerCount,
    }),
    [hubCount, sensorCount, valveControllerCount]
  );

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel[0] || !sortModel[0].sort) {
      setSortingParams(undefined);
    } else {
      setSortingParams({
        sortBy: sortModel[0]?.field,
        direction: mapSortDirection(sortModel[0]?.sort),
      });
    }
  }, []);

  switch (tab) {
    case SUB_TABS.HUB:
      return {
        data: hubs,
        isError: isHubError,
        isLoading: isHubsLoading,
        pagination: hubsPagination,
        onSortChange,
        config: {
          name: content.HUBS_AND_SENSORS.COLUMNS.HUB,
          locationName: content.HUBS_AND_SENSORS.COLUMNS.LOCATION,
          model: content.HUBS_AND_SENSORS.COLUMNS.MODEL,
          serialNumber: content.HUBS_AND_SENSORS.COLUMNS.SERIAL_NUMBER,
        },
        counters,
      };
    case SUB_TABS.SENSOR:
      return {
        data: sensors,
        isError: isSensorError,
        isLoading: isSensorLoading,
        pagination: sensorPagination,
        onSortChange,
        config: {
          name: content.HUBS_AND_SENSORS.COLUMNS.SENSOR_NAME,
          locationName: content.HUBS_AND_SENSORS.COLUMNS.LOCATION,
          deviceType: content.HUBS_AND_SENSORS.COLUMNS.SENSOR_TYPE,
          model: content.HUBS_AND_SENSORS.COLUMNS.MODEL,
          serialNumber: content.HUBS_AND_SENSORS.COLUMNS.MANUFACTURER_ID,
        },
        counters,
      };
    case SUB_TABS.VALVE_CONTROLLER:
      return {
        data: valveControllers,
        isError: isValveControllerError,
        isLoading: isValveControllerLoading,
        pagination: hubsPagination,
        onSortChange,
        config: {
          name: content.HUBS_AND_SENSORS.COLUMNS.VALVE_CONTROLLER,
          locationName: content.HUBS_AND_SENSORS.COLUMNS.LOCATION,
          model: content.HUBS_AND_SENSORS.COLUMNS.MODEL,
          serialNumber: content.HUBS_AND_SENSORS.COLUMNS.SERIAL_NUMBER,
        },
        counters,
      };
  }
};

export const content = {
  EDIT: 'Edit',
  NEXA_GATEWAY: 'Nexa Gateway',
  CONNECTION_STATUS: 'Connection Status',
  CONNECTION_TYPE: 'Connection Type',
  POWER_SOURCE: 'Power Source',
  ACTIVE: 'Active',
  STANDBY: 'Standby',
  INACTIVE: 'Inactive',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  CELLULAR: 'Cellular',
  ETHERNET: 'Ethernet',
  WIFI: 'Wi-Fi',
  GATEWAY_ID: 'Gateway ID',
  SENSORS_AND_EQUIPMENT: {
    TABLE_TITLE: 'Connected Sensors & Equipment',
    CONFIGURE_CONNECTION: 'Configure Connection',
  },
  HUBS_AND_SENSORS: {
    TABLE_TITLE: 'Connected Hubs & Sensors',
    CONNECTED_HUBS: (count: number) => `Connected Hubs (${count})`,
    CONNECTED_SENSORS: (count: number) => `Connected Sensors (${count})`,
    VALVE_CONTROLLERS: (count: number) => `Valve Controllers (${count})`,
    HUB_TAB: (count: number) => `Hubs (${count})`,
    SENSOR_TAB: (count: number) => `Sensors (${count})`,
    NO_DATA: 'No results found.',
    ERROR: 'Something went wrong. Please try again later.',
    COLUMNS: {
      HUB: 'Hub',
      LOCATION: 'Location',
      MODEL: 'Model',
      STATUS: 'Status',
      SERIAL_NUMBER: 'Serial Number',
      SENSOR_TYPE: 'Type',
      SENSOR_NAME: 'Sensor',
      POWER_SOURCE: 'Power Source',
      SENSOR: 'Sensor',
      TYPE: 'Type',
      MANUFACTURER_ID: 'Manufacturer ID',
      VALVE_CONTROLLER: 'Valve Controller',
    },
  },
  ERROR: 'Something went wrong. Please try again later.',
  GATEWAY_DETAILS: 'Gateway Details',
  EMPTY_DATAPOINT_VALUE: '--',
  FIRMWARE_VERSION: 'Firmware Version',
  COMMISSION_DATE: 'Commission Date',
  CONFIGURATION_ID: 'Current Configuration File ID',
  DESCRIPTION: 'Description',
  NETWORK_RF_PAN_ID: 'Network RF PAN ID',
  NETWORK_RF_CHANNEL: 'Network RF Channel',
  SMART_BASE_STATION: 'Smart Base Station',
  MODE: {
    ACTIVE: 'Active Mode',
    STANDBY: 'Standby',
    INACTIVE: 'Inactive',
  },
  SYSTEM: 'System',
  SAVE_SUCCESS_MSG: (gatewayName: string) =>
    `The settings change has been accepted and in the process of being updated on ${gatewayName}. This process may take some time to update.`,
  SETTINGS_CHANGE_INFO: 'Settings change will be in pending state until the gateway is online and accepts the change.',
};

import {
  aercoDescriptionSteps,
  intellistationDescriptionSteps,
  tekmarSbcDescriptionSteps,
  tekmarShpDescriptionSteps,
  tekmarSscDescriptionSteps,
} from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { StepperModal } from '@marlin/shared/ui-modal';
import { warrantyStorageService } from '@marlin/shared/utils-common-warranty-storage';
import { getLogger } from '@marlin/shared/utils/logger';
import { Button, Divider, FormHelperText, Icon, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content as loggerContent } from '../../../content';
import { EQUIPMENT_ENUM, TEquipment } from '../../onboarding.schema';
import { useOnboardingState } from '../../use-onboarding-state.hook';
import { content } from './content';
import { EquipmentCard } from './equipment-card.component';
import { EquipmentTiles } from './equipment-tiles.component';
import { AercoInnovation } from './forms/aerco-innovation.component';
import { EquipmentLimit } from './forms/equipment-limit.component';
import { NoForm } from './forms/no-form.component';
import { TekmarForm } from './forms/tekmar-form.component';
import { checkIfDuplicatedNames, checkIfDuplicatedSerialNumbers } from './utils';

const equipmentLimit = 3;

export interface IEquipmentFormProps {
  equipmentType: string | null;
  equipment: TEquipment | null;
  isEditForm: boolean;
  onCancel?: () => void;
  index: number;
  isAnotherEquipment?: boolean;
  resetCallback?: () => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  divider: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
  },
}));

const useRegisterAnotherEquipmentStyles = makeStyles()((theme: MarlinTheme) => ({
  registerAnotherEquipmentButton: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  container: {
    padding: theme.typography.pxToRem(16),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
  },
  registerAnotherEquipmentTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tilesContainer: {
    margin: `${theme.typography.pxToRem(32)} 0`,
  },
}));

export const EquipmentSwitcher = () => {
  const { classes } = useStyles();
  const [selectedEquipment, setSelectedEquipment] = useState<EQUIPMENT_ENUM | null>(null);
  const [selectedEditEquipment, setSelectedEditEquipment] = useState<EQUIPMENT_ENUM | null>(null);
  const [disableFurtherRegistration, setDisableFurtherRegistration] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState<{ isOpen: boolean; defaultStep?: number }>({
    isOpen: false,
  });
  const { onboardingState } = useOnboardingState();

  const warrantyModel = warrantyStorageService.get();

  if (warrantyModel === 'intellistation2' && selectedEquipment !== EQUIPMENT_ENUM.INTELLISTATION) {
    setSelectedEquipment(EQUIPMENT_ENUM.INTELLISTATION);
  }

  if (
    warrantyModel === 'innovationedge' &&
    selectedEquipment !== EQUIPMENT_ENUM.AERCO_INNOVATION &&
    !onboardingState.equipment.length
  ) {
    setSelectedEquipment(EQUIPMENT_ENUM.AERCO_INNOVATION);
  }

  const openInstructionsModal = useCallback((defaultStep?: number) => {
    setIsInstructionsModalOpen({ isOpen: true, defaultStep });
  }, []);

  const closeInstructionsModal = useCallback(() => {
    setIsInstructionsModalOpen({ isOpen: false });
  }, []);

  const onEquipmentCardDeleteClick = useCallback(() => {
    getLogger()?.track(loggerContent.LOGGER.SELF_SERVICE.REMOVE_EQUIPMENT);
    if (disableFurtherRegistration) {
      setDisableFurtherRegistration(false);
    }
  }, [disableFurtherRegistration]);

  const resetSwitcher = useCallback(() => {
    setSelectedEquipment(null);

    if (warrantyModel && warrantyModel !== 'innovationedge') {
      setDisableFurtherRegistration(true);
    }
  }, [warrantyModel]);

  const onFormCancel = useCallback(() => {
    setSelectedEquipment(null);
  }, []);

  const equipmentForm = (data: IEquipmentFormProps) => {
    const { equipmentType, equipment, isEditForm, onCancel, index, isAnotherEquipment, resetCallback } = data;

    const onReset = () => {
      resetSwitcher();
      if (resetCallback) {
        resetCallback();
      }
    };

    switch (equipmentType) {
      case EQUIPMENT_ENUM.INTELLISTATION: {
        return (
          <TekmarForm
            resetSwitcher={onReset}
            isEditForm={isEditForm}
            registrationFlow={'Tekmar'}
            onCancel={onCancel}
            equipment={equipment}
            equipmentType={EQUIPMENT_ENUM.INTELLISTATION}
            openInstructionsModal={openInstructionsModal}
            index={index}
            isAnotherEquipment={isAnotherEquipment}
            key={EQUIPMENT_ENUM.INTELLISTATION}
          />
        );
      }
      case EQUIPMENT_ENUM.TEKMAR_SBC: {
        return (
          <TekmarForm
            resetSwitcher={onReset}
            isEditForm={isEditForm}
            registrationFlow={'tekmarSmartBoiler'}
            onCancel={onCancel}
            equipment={equipment}
            equipmentType={EQUIPMENT_ENUM.TEKMAR_SBC}
            openInstructionsModal={openInstructionsModal}
            index={index}
            isAnotherEquipment={isAnotherEquipment}
            key={EQUIPMENT_ENUM.TEKMAR_SBC}
          />
        );
      }
      case EQUIPMENT_ENUM.TEKMAR_SSC: {
        return (
          <TekmarForm
            resetSwitcher={onReset}
            isEditForm={isEditForm}
            registrationFlow={'tekmarSmartSteam'}
            onCancel={onCancel}
            equipment={equipment}
            equipmentType={EQUIPMENT_ENUM.TEKMAR_SSC}
            openInstructionsModal={openInstructionsModal}
            index={index}
            isAnotherEquipment={isAnotherEquipment}
            key={EQUIPMENT_ENUM.TEKMAR_SSC}
          />
        );
      }
      case EQUIPMENT_ENUM.TEKMAR_SHP: {
        return (
          <TekmarForm
            resetSwitcher={onReset}
            isEditForm={isEditForm}
            registrationFlow={'tekmarSmartHeatPump'}
            onCancel={onCancel}
            equipment={equipment}
            equipmentType={EQUIPMENT_ENUM.TEKMAR_SHP}
            openInstructionsModal={openInstructionsModal}
            index={index}
            isAnotherEquipment={isAnotherEquipment}
            key={EQUIPMENT_ENUM.TEKMAR_SHP}
          />
        );
      }
      case EQUIPMENT_ENUM.AERCO_INNOVATION: {
        return (
          <AercoInnovation
            resetSwitcher={onReset}
            isEditForm={isEditForm}
            onCancel={onCancel}
            equipment={equipment}
            equipmentType={EQUIPMENT_ENUM.AERCO_INNOVATION}
            openInstructionsModal={openInstructionsModal}
            index={index}
            isAnotherEquipment={isAnotherEquipment}
            key={EQUIPMENT_ENUM.AERCO_INNOVATION}
          />
        );
      }
      // HIDE BENCHMARK FORM
      // case EQUIPMENT_ENUM.AERCO_BENCHMARK: {
      //   return (
      //     <AercoBenchmark
      //       resetSwitcher={resetSwitcher}
      //       isEditForm={isEditForm}
      //       onCancel={onCancel}
      //       equipment={equipment}
      //       openInstructionsModal={openInstructionsModal}
      //       index={index}
      //     />
      //   );
      // }
      default: {
        return <NoForm equipmentLength={onboardingState.equipment.length} />;
      }
    }
  };

  const instructionsModalContent = useMemo(() => {
    switch (selectedEditEquipment ? selectedEditEquipment : selectedEquipment) {
      case EQUIPMENT_ENUM.INTELLISTATION: {
        return {
          withStepCount: true,
          steps: intellistationDescriptionSteps,
        };
      }
      case EQUIPMENT_ENUM.TEKMAR_SSC: {
        return {
          withStepCount: true,
          steps: tekmarSscDescriptionSteps,
        };
      }
      case EQUIPMENT_ENUM.TEKMAR_SBC: {
        return {
          withStepCount: true,
          steps: tekmarSbcDescriptionSteps,
        };
      }
      case EQUIPMENT_ENUM.TEKMAR_SHP: {
        return {
          withStepCount: true,
          steps: tekmarShpDescriptionSteps,
        };
      }
      case EQUIPMENT_ENUM.AERCO_INNOVATION: {
        return {
          withStepCount: false,
          steps: aercoDescriptionSteps,
        };
      }
      // case EQUIPMENT_ENUM.AERCO_BENCHMARK: {
      //   return {
      //     withStepCount: false,
      //     steps: aercoDescriptionSteps,
      //   };
      // }
      default: {
        return {
          withStepCount: undefined,
          steps: [],
        };
      }
    }
  }, [selectedEditEquipment, selectedEquipment]);

  useEffect(() => {
    if (isInstructionsModalOpen.isOpen) {
      getLogger()?.track(
        loggerContent.LOGGER.SELF_SERVICE.HOW_DO_I_FIND(
          instructionsModalContent.steps[isInstructionsModalOpen.defaultStep || 0].title
        )
      );
    }
  }, [instructionsModalContent, isInstructionsModalOpen]);

  return (
    <div>
      {onboardingState.equipment.map((equipment, index) => (
        <EquipmentCard
          equipment={equipment}
          key={`${equipment.equipmentName ?? ''}${equipment.registrationCode ?? ''}${equipment.serialNumber ?? ''}`}
          index={index}
          onDeleteClick={onEquipmentCardDeleteClick}
          equipmentForm={equipmentForm}
          setSelectedEditEquipment={setSelectedEditEquipment}
        />
      ))}
      {checkIfDuplicatedNames(onboardingState.equipment) && (
        <FormHelperText error>{content.DUPLICATED_EQUIPMENT_NAME}</FormHelperText>
      )}
      {checkIfDuplicatedSerialNumbers(onboardingState.equipment) && (
        <FormHelperText error>{content.DUPLICATED_SERIAL_NUMBER}</FormHelperText>
      )}
      {!!onboardingState.equipment?.length && !disableFurtherRegistration && <Divider className={classes.divider} />}
      <EquipmentRegistration
        equipmentAmount={onboardingState.equipment.length}
        disableFurtherRegistration={disableFurtherRegistration}
        equipmentForm={equipmentForm}
        tilesProps={{ selectedEquipment, onChange: setSelectedEquipment }}
        onFormCancel={onFormCancel}
      />
      <StepperModal handleClose={closeInstructionsModal} {...isInstructionsModalOpen} {...instructionsModalContent} />
    </div>
  );
};

interface IEquipmentRegistrationProps {
  tilesProps: { onChange: (id: EQUIPMENT_ENUM) => void; selectedEquipment: EQUIPMENT_ENUM | null };
  equipmentAmount: number;
  disableFurtherRegistration: boolean;
  equipmentForm: (data: IEquipmentFormProps) => JSX.Element;
  onFormCancel?: () => void;
}

const EquipmentRegistration = ({
  equipmentAmount,
  disableFurtherRegistration,
  equipmentForm,
  tilesProps,
  onFormCancel,
}: IEquipmentRegistrationProps) => {
  if (disableFurtherRegistration) {
    return null;
  }

  if (equipmentAmount === 0) {
    return (
      <>
        <EquipmentTiles {...tilesProps} />
        {equipmentForm({
          equipmentType: tilesProps.selectedEquipment,
          equipment: null,
          index: 0,
          isEditForm: false,
        })}
      </>
    );
  }

  if (equipmentAmount < equipmentLimit) {
    return (
      <RegisterAnotherEquipment equipmentForm={equipmentForm} tilesProps={tilesProps} onFormCancel={onFormCancel} />
    );
  }

  return <EquipmentLimit />;
};

const RegisterAnotherEquipment = ({
  equipmentForm,
  tilesProps,
  onFormCancel,
}: Omit<IEquipmentRegistrationProps, 'equipmentAmount' | 'disableFurtherRegistration'>) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useRegisterAnotherEquipmentStyles();

  if (!isOpen) {
    return (
      <Button className={classes.registerAnotherEquipmentButton} onClick={() => setIsOpen(true)}>
        <Icon baseClassName="material-symbols-outlined" data-testid="add_circle">
          add_circle
        </Icon>
        {content.REGISTER_ANOTHER_EQUIPMENT}
      </Button>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.registerAnotherEquipmentTitle}>
        {content.REGISTER_ANOTHER_EQUIPMENT}
      </Typography>
      <div className={classes.tilesContainer}>
        <EquipmentTiles {...tilesProps} />
      </div>
      {equipmentForm({
        equipmentType: tilesProps.selectedEquipment,
        equipment: null,
        index: 0,
        isEditForm: false,
        onCancel: onFormCancel,
        isAnotherEquipment: true,
        resetCallback: () => setIsOpen(false),
      })}
    </div>
  );
};

import { alertDeviceTypeSchema } from '@marlin/alert/data-access/automated-action';
import { lastReadingValuesOutputSchema } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { detailsSchema } from '../details/details.validation.schema';
import { conditionSchema } from './conditions/conditions.schema';
import { validationContent } from './content';

export const deviceSchema = z.object({
  id: z.string().trim().min(1, validationContent.REQUIRED),
  name: z.string().trim().min(1, validationContent.REQUIRED),
  manufacturerId: z.string().nullable(),
  deviceType: alertDeviceTypeSchema.optional(),
  model: z.string().trim().min(1, validationContent.REQUIRED),
  lastReadingValues: z.array(lastReadingValuesOutputSchema),
});

export const deviceValidationSchema = deviceSchema.nullable().refine((device) => !!device, {
  message: validationContent.REQUIRED,
});

export const triggerSchema = z.object({
  device: deviceValidationSchema,
  datapoint: z.string().trim().min(1),
  conditions: conditionSchema,
});

export const triggersEnabledSchema = z.object({
  details: detailsSchema,
  triggers: z.array(triggerSchema),
});

export type TTriggerValidation = z.infer<typeof triggersEnabledSchema>;
export type TDevice = z.infer<typeof deviceSchema>;
export type TDeviceValidation = z.infer<typeof deviceValidationSchema>;

export const content = {
  TABS: {
    LDS_VALVE: 'Valves',
    POLD: 'POLDs',
    TAB_WITH_COUNT: (tabName: string, count?: number) => `${tabName}${count ? ` (${count})` : ''}`,
  },
  COLUMNS: {
    POLD: {
      NAME: 'Name',
      LEAK_STATUS: 'Leak Status',
      AIR_TEMPERATURE: 'Air Temperature',
      AIR_HUMIDITY: 'Air Humidity',
      IN_ALERT: 'In Alert?',
      LAST_READING_TIME: 'Last Reading Time',
      STATUS: 'Status',
      POWER_SOURCE: 'Power Source',
    },
    LDS_VALVE: {
      NAME: 'Name',
      VALVE_STATE: 'Valve State',
      LEAK_STATUS: 'Leak Status',
      FLOW_RATE: 'Flow',
      TEMPERATURE: 'Temperature',
      PRESSURE: 'Pressure',
      IN_ALERT: 'In Alert?',
      LAST_READING_TIME: 'Last Reading Time',
      STATUS: 'Status',
    },
  },
  VALUES: {
    IN_ALERT: {
      TRUE: 'Yes',
      FALSE: 'No',
    },
    VALVE_STATE: {
      OPEN: 'Open',
      CLOSED: 'Closed',
    },
  },
  FILTERS: {
    SHOW_ONLY_ALERTS: (inAlertCount?: number) =>
      inAlertCount === undefined ? 'Show Only In Alert' : `Show Only In Alert (${inAlertCount})`,
    CLEAR_ALL_ALERTS: 'Clear All Alerts',
  },
  COMMANDS: {
    CLEAR_ALL_ALERTS_SUCCESS: 'Command sent to clear all alerts',
    CLEAR_ALL_ALERTS_ERROR: 'Something went wrong while clearing all alerts',
  },
};

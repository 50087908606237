export const content = {
  TRIGGER: (index: number) => `Trigger ${index}`,
  ADD_TRIGGER: 'Add Trigger',
  VIEW_MODE: {
    HAS: ' has ',
    LEAK_DETECTED: 'leak detected',
    OR: ' or ',
    LOWER: ' < ',
    HIGHER: ' > ',
    DEADBAND: ' and has deadband of ',
  },
};

export const validationContent = {
  REQUIRED: 'This is a required field', // TODO: move to common validationRules
  REQUIRED_ONE: 'At least one of the conditions must be defined',
  MIN_MAX_RANGE: 'Lower Value cannot be higher than Higher Value',
  MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,
  MAX: (max: number) => `Value should be lower than ${max}`,
  MIN: (min: number) => `Value should be higher than ${min}`,
  EMPTY_RECIPIENTS: 'Please select recipients in order to continue.',
  DEADBAND_VALUE_MIN: 'Deadband Value should be higher than Lower Value',
  DEADBAND_VALUE_MAX: 'Deadband Value should be lower than Higher Value',
  DEADBAND_VALUE_MAX_GREATER_MIN: 'Deadband max must be greater than Deadband min',
  DEADBAND_VALUE_MIN_LOWER_THAN_THRESHOLDMAX: 'Deadband min must be lower than threshold max',
  DEADBAND_VALUE_MAX_LOWER_THAN_THRESHOLDMIN: 'Deadband max must be higher than threshold min',
  MIN_DURATION: 'Minimum duration is 5 minutes',
  MAX_DURATION: 'Maximum duration is 7 days',
  SENSOR_DURATION_WARNING:
    'If the Sensor Inactivity Duration is shorter than the Gateway Inactivity Duration, you may receive many alerts if a gateway fails to send data to the cloud.',
  GATEWAY_DURATION_WARNING:
    'If the Gateway Inactivity Duration is longer than the Sensor Inactivity Duration, you may receive many alerts if a gateway fails to send data to the cloud.',
};

import { useMediaQuery, useTheme } from '@mui/material';

import { SystemEquipmentDesktop } from './system-equipment-desktop.component';
import { SystemEquipmentRwd } from './system-equipment-rwd.component';

export const SystemEquipment = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  if (isTablet) {
    return <SystemEquipmentRwd />;
  }

  return <SystemEquipmentDesktop />;
};

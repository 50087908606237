import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    position: 'relative',
    width: '100%',
  },
  containerClosed: {
    paddingTop: theme.typography.pxToRem(12),
    paddingBottom: theme.typography.pxToRem(12),
  },
  headerClosed: {
    position: 'absolute',
    top: `-${theme.typography.pxToRem(14)}`,
    backgroundColor: theme.palette.background.primary,
  },
  contentClosed: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& span.MuiIcon-root': {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  actionsClosed: {
    maxHeight: theme.typography.pxToRem(24),
  },
}));

import { EQUIPMENT_UPSERT_ERROR, SENTINEL_ERROR, SentinelErrorEnumSchema } from '@marlin/asset/data-access/equipment';
import { AxiosError } from 'axios';

import { content } from '../content';
import { getSentinelErrorMessage } from './get-sentinel-error-message';

interface IError {
  errorCode: SENTINEL_ERROR;
  message: string;
}

interface IHandleSentinelErrorProps {
  error: AxiosError<IError>;
  setRegistrationErrorCode: (errorCode: EQUIPMENT_UPSERT_ERROR) => void;
  enqueueSnackbar: (message: string, options: { variant: 'error'; preventDuplicate: boolean }) => void;
}

export const handleSentinelError = ({
  error,
  setRegistrationErrorCode,
  enqueueSnackbar,
}: IHandleSentinelErrorProps): void => {
  const errorCode = SentinelErrorEnumSchema.safeParse(error?.response?.data?.errorCode);
  let errorMessage = error?.response?.data?.message || content.ERROR_MESSAGE;

  if (errorCode.success) {
    if (errorCode.data === SENTINEL_ERROR.SENTINEL_UNREGISTRATION_DEVICE_NOT_REGISTERED) {
      setRegistrationErrorCode(EQUIPMENT_UPSERT_ERROR.EQUIPMENT_REGISTRATION_CODE_DOES_NOT_EXIST);
      return;
    }

    errorMessage = getSentinelErrorMessage(errorCode.data) || errorMessage;
  }

  enqueueSnackbar(errorMessage, {
    variant: 'error',
    preventDuplicate: true,
  });
  return;
};

import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';

import { useRemoveRecipient } from '../user-remove-recipient.hook';

export interface IRecipientContextMenuActions {
  onEdit: (userId: string) => void;
  onDelete: (userId: string) => void;
}

export interface IUserContextMenu {
  actions: IRecipientContextMenuActions;
  deleteModal: {
    show: boolean;
    removeRecipient: () => void;
    close: () => void;
  };
  onAdd: () => void;
  handleBack: () => void;
}

export const useRecipientContextMenu = (): IUserContextMenu => {
  const { goTo, goBack } = useRouter();
  const { showModal, openModal: openDeleteModal, removeRecipient, closeModal } = useRemoveRecipient();

  const onEdit = (recipientId: string) => {
    goTo(routes.settings.editRecipient.details.url(recipientId));
  };

  const onAdd = () => {
    goTo(routes.settings.addRecipient.url());
  };

  return {
    actions: {
      onEdit,
      onDelete: openDeleteModal,
    },
    deleteModal: {
      show: showModal,
      removeRecipient,
      close: closeModal,
    },
    onAdd,
    handleBack: goBack,
  };
};

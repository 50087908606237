import { INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { getUomSymbol } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { content } from '../../content';
import { useStyles } from './generic-deadband-condition.styles';
import { useDeadbandField } from './use-deadband-field.hook';

const conditionValueMask = /^\d*$/;

interface IGenericNumberConditionProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
  conditionIndex: number;
  operator: 'lt' | 'gt';
}

export const GenericDeadbandCondition = ({ datapoint, triggerIndex, operator }: IGenericNumberConditionProps) => {
  const { classes } = useStyles();
  const field = useDeadbandField({
    triggerIndex,
    operator,
    datapoint,
  });

  const [enabled, setEnabled] = useState<boolean>(field.value?.length > 0);

  const endAdornment = useMemo(
    () => <span className={classes.endAdornment}>{getUomSymbol(datapoint.unitOfMeasure) || ''}</span>,
    [classes.endAdornment, datapoint.unitOfMeasure]
  );

  const handleEnable = useCallback(() => {
    setEnabled(true);
  }, []);

  const handleDisable = useCallback(() => {
    setEnabled(false);
    field.handleClear();
  }, [field]);

  if (!enabled) {
    return (
      <div className={classes.conditionContainer} data-testid={`disabled-number-deadband-${triggerIndex}-${operator}`}>
        <div className={classes.conditionRow}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enabled}
                onChange={handleEnable}
                disabled={!field.isThresholdValid}
                data-testid={`enable-deadband-${triggerIndex}-${operator}`}
              />
            }
            label={content.CONDITIONS.DEADBAND}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.conditionContainer} data-testid={`enabled-number-deadband-${triggerIndex}-${operator}`}>
      <div className={classes.conditionRow}>
        <FormControlLabel
          className={classes.conditionElement}
          control={
            <Checkbox
              checked={enabled}
              onChange={handleDisable}
              disabled={!field.isThresholdValid}
              data-testid={`disable-deadband-${triggerIndex}-${operator}`}
            />
          }
          label={content.CONDITIONS.DEADBAND}
        />
        <Input
          className={classes.conditionElement}
          onBlur={field.handleBlur}
          label={content.CONDITIONS.DEADBAND_PLACEHOLDER}
          type={INPUT_TYPE.NUMBER}
          onChange={field.handleChange}
          value={field.value}
          mask={conditionValueMask}
          error={field.error}
          externalEndAdornment={{
            endAdornment,
          }}
          testId={`deadband-input-${datapoint.name}-${operator}`}
        />
      </div>
    </div>
  );
};

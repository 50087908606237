import { useMediaQuery, useTheme } from '@mui/material';

import { RecipientList } from '../recipients/recipient-list.component';
import { UserList } from '../users/user-list.component';
import { PeopleMobile } from './people-rwd.component';
import { useStyles } from './people.component.styles';

export const People = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  if (isTablet) {
    return <PeopleMobile />;
  }

  return (
    <>
      <div className={classes.topTableWrapper}>
        <UserList />
      </div>
      <div className={classes.tableWrapper}>
        <RecipientList />
      </div>
    </>
  );
};

import { Icon, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Button } from '@mui/material';

import { content } from '../../shared/content';

interface IAddRecipientButtonProps {
  handleAddRecipient: () => void;
}

export const AddRecipientButton = ({ handleAddRecipient }: IAddRecipientButtonProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <IconButton onClick={() => handleAddRecipient()}>
        <Icon baseClassName="material-symbols-outlined" data-testid="add-recipient" color="primary">
          add_circle
        </Icon>
      </IconButton>
    );
  }

  return (
    <Button variant="contained" color="primary" onClick={() => handleAddRecipient()} data-testid="add-recipient">
      {content.ADD_NEW_RECIPIENT_BTN}
    </Button>
  );
};

import { Button, Typography } from '@mui/material';

import { content } from './content';
import { useStyles } from './pagination-mobile.styles';

export interface IPaginationMobile {
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  loadedItems?: number;
}

export const PaginationMobile = ({ loadedItems, totalItems, hasNextPage, fetchNextPage }: IPaginationMobile) => {
  const { classes } = useStyles();

  if (!hasNextPage || !loadedItems || !totalItems) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Button variant="text" onClick={fetchNextPage}>
        {content.LOAD_MORE}
      </Button>
      <div>
        <Typography className={classes.itemCounterText} variant="body2">
          {loadedItems} {content.OF.toLowerCase()} {totalItems}
        </Typography>
      </div>
    </div>
  );
};

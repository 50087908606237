import { TIconEquipment } from '@marlin/asset/shared/ui/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  active: {
    color: theme.palette.action.active,
  },

  disabled: {
    color: theme.palette.text.secondary,
  },

  emptyOption: {
    height: '36px',
  },
}));

interface IDeviceTypeOption {
  name: string;
  Icon?: FC<SvgIconProps> | TIconEquipment | null;
  disabled?: boolean;
}

export const DeviceTypeOption = ({ name, Icon, disabled }: IDeviceTypeOption) => {
  const { classes } = useStyles();

  return (
    <div className={classes.deviceTypeOption}>
      {Icon && <Icon className={disabled ? classes.disabled : classes.active} />}
      {name}
    </div>
  );
};

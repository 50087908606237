import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getGatewayDetailsPageLink: (gatewayId: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getGatewayDetailsPageLink: (gatewayId: string) => routes.gateway.details.url(gatewayId),
  };
};

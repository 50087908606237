import { PersonCard, PersonList } from '@marlin/account-ui-user-list';
import {
  FilteredResultsChip,
  MobileFilterModal,
  useMobileFilterForm,
} from '@marlin/asset/shared/ui/mobile-filter-modal';
import { Search } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { useSearchFilter } from '@marlin/shared/utils/url-params';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, IconButton } from '@mui/material';

import { content } from '../../shared/content';
import { useInfiniteUserList } from '../../shared/hooks/use-infinite-user-list.hook';
import { useUserContextMenu } from '../../shared/hooks/use-user-context-menu/use-user-context-menu.hook';
import { IPeopleTabSwitcherProps, PeopleTabSwitcher } from '../components/people-tab-switcher.component';
import { SettingsPageHeader } from '../components/settings-page-header';
import { UserListMenu } from '../components/user-list-menu.component';
import { DeleteUserModal } from '../modals/delete-user-modal.component';
import { ResendModal } from '../modals/resend-modal.component';
import { useStyles } from './user-card-list.styles';

interface IUserCardListProps extends IPeopleTabSwitcherProps {}

export const UserCardList = ({ activeTab, onTabChange }: IUserCardListProps) => {
  const { classes } = useStyles();
  const { term, debouncedTrimmedTerm, search } = useSearchFilter();
  const { rows, totalItems, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage, isFetched } =
    useInfiniteUserList({ search: debouncedTrimmedTerm });
  const { actions, resendModal, deleteModal, handleBack } = useUserContextMenu();
  const initialLoading = !isFetched && isFetching;
  const mobileFilterForm = useMobileFilterForm<'', { search: string }>({
    defaultFilters: { search: '' },
    sortList: [],
  });

  return (
    <>
      <SettingsPageHeader
        icon={<SettingsIcon />}
        title={content.PEOPLE}
        prefix="settings-header-people"
        topActions={
          <Button
            className={classes.button}
            startIcon={<ArrowBackRoundedIcon />}
            variant="outlined"
            onClick={handleBack}
          >
            {content.BACK}
          </Button>
        }
        marginBottom={false}
        inlineRightSideActions={
          <div className={classes.listActions}>
            <IconButton onClick={mobileFilterForm.toggleOpen} data-testid="filter-button">
              <SearchRoundedIcon color="action" />
            </IconButton>
            <UserListMenu />
          </div>
        }
      />
      <PeopleTabSwitcher activeTab={activeTab} onTabChange={onTabChange} />
      {initialLoading ? (
        <LoadingSpinner />
      ) : (
        <PersonList rowsCount={rows.length}>
          {term.length > 0 && (
            <div className={classes.filterChipWrapper}>
              <FilteredResultsChip
                onDelete={() => {
                  search('');
                }}
              />
            </div>
          )}
          {rows.map((user) => (
            <PersonCard person={user} {...actions} />
          ))}
        </PersonList>
      )}
      {isFetchingNextPage && <LoadingSpinner />}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={rows.length}
        data-testid="users-pagination"
      />
      <MobileFilterModal<'', { search: string }>
        {...mobileFilterForm}
        title={content.USER_SEARCH_MODAL_TITLE}
        rows={
          initialLoading ? (
            <LoadingSpinner />
          ) : (
            <PersonList rowsCount={rows.length}>
              {rows.map((user) => (
                <PersonCard person={user} {...actions} />
              ))}
            </PersonList>
          )
        }
      >
        <Search value={term} onChange={search} label={content.USER_TABLE_SEARCH_LABEL} prefix="user" />
      </MobileFilterModal>
      <ResendModal user={resendModal.user} onClose={resendModal.close} />
      <DeleteUserModal user={deleteModal.user} onClose={deleteModal.close} />
    </>
  );
};

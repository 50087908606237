export const content = {
  TIME_IN_ALERT: 'Time in alert:',
  CREATE_AN_AUTOMATION: 'Create an automation to receive alerts',
  ADD_A_SENSOR: 'Add a sensor & create an automation to receive alerts',
  NO_OPEN_ALERTS: 'You Have No Open Alerts',
  ALERTS: 'Alerts',
  TOP_OPEN_ALERTS: 'Top Open Alerts',
  VIEW_ALERTS: 'View Alerts',
  LEAK: `detected a leak`,
  LOST_COMMUNICATION: 'has lost communication',
  LOW_BATTERY: 'has low battery',
  AIR_TEMPERATURE: 'air temperature alert',
  AIR_HUMIDITY: 'air humidity alert',
  DETECTED_LEAK_CLOSING_VALVE: 'detected a leak. Closing valve',
  AIR_TEMPERATURE_CLOSING_VALVE: 'air temperature alert. Closing valve',
  AIR_HUMIDITY_CLOSING_VALVE: 'air humidity alert. Closing valve',
  NOT_SUPPORTED_DATAPOINT_ALERT: 'Not supported datapoint alert',
};

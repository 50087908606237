import { TDateString, getTimeFromNow } from '@marlin/shared/utils-common-date';
import { TLastReadingValuesSchema, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import isNil from 'lodash/isNil';

const telemetryRangeDays = 7;

export const mapLastReadingValues = (lastReadingValues?: TLastReadingValuesSchema[]) => {
  const defaultDatapoint = lastReadingValues?.find((reading) => reading.isDefault);
  if (!defaultDatapoint) {
    return 'N/A';
  }
  if (defaultDatapoint && !isRecentReading(defaultDatapoint.lastReadingTime)) {
    return 'N/A';
  }
  const uom = defaultDatapoint.name === 'Leak' ? 'WaterDetect' : defaultDatapoint.unitOfMeasure;
  return (
    defaultDatapoint.formattedReading ?? parseDisplayedValue(defaultDatapoint.value, uom ?? '', undefined, 'detail')
  );
};

const isRecentReading = (lastReadingTime: TDateString): boolean => {
  const daysSinceLastReading = getTimeFromNow(lastReadingTime, 'days');

  return !isNil(daysSinceLastReading) && daysSinceLastReading <= telemetryRangeDays;
};

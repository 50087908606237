import { TRole } from '@marlin/shared/utils-role';
import z from 'zod';

export enum INVITATION_STATUS {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface IPaginationResponse {
  totalItems: number;
  page: number;
  pageSize: number;
}

export interface IUserResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roles: string[];
  title?: string;
  invitationStatus: string | null;
}

export interface IUserListResponse {
  pagination: IPaginationResponse;
  data: IUserResponse[];
}

export interface IPagination {
  totalItems: number;
  page: number;
  pageSize: number;
}

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: TRole | null;
  title: string;
  invitationStatus: INVITATION_STATUS | null;
}

export interface IUserListFilters {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

export interface IUserList {
  pagination: IPagination;
  data: IUser[];
}

export const sortingSchema = z.object({
  direction: z.enum(['Ascending', 'Descending']),
  sortBy: z.string(),
});

export const UserParamsSchema = z.object({
  search: z.string().trim().optional(),
  page: z.number().int().positive(),
  pageSize: z.number().int().positive(),
  sorting: sortingSchema.optional(),
});

export const InfiniteUserParamsSchema = UserParamsSchema.omit({ page: true });

export type TUserParamsSchema = z.infer<typeof UserParamsSchema>;
export type TInfiniteUserParamsSchema = z.infer<typeof InfiniteUserParamsSchema>;

import { useChartingColors } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { Dot } from '../../shared/dot.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface ITotalWaterChartComponent {
  uoM?: TUnitOfMeasure | null;
  totalVolume: number;
  previousPeriodTotalVolume: number;
  selectedUnit: 'volume' | 'dollars';
}
export const TotalWaterChartLegend = ({
  uoM,
  totalVolume,
  previousPeriodTotalVolume,
  selectedUnit,
}: ITotalWaterChartComponent) => {
  const { classes } = useStyles();
  const colors = useChartingColors();

  const dots = useMemo(() => {
    return [
      {
        total: totalVolume,
        name: content.TOTAL_WATER_CONSUMPTION_CURRENT_SERIES,
      },
      {
        total: previousPeriodTotalVolume,
        name: content.TOTAL_WATER_CONSUMPTION_PREVIOUS_SERIES,
      },
    ];
  }, [previousPeriodTotalVolume, totalVolume]);

  return (
    <div className={classes.wrapper}>
      {dots.map(({ total, name }, index) => {
        return (
          <Dot color={colors[index % colors.length]} key={index}>
            {`${name} `}
            <b>
              {selectedUnit === 'dollars'
                ? content.TOTAL_AMOUNT_DOLLARS(total)
                : content.TOTAL_AMOUNT(total, uoM || '')}
            </b>
          </Dot>
        );
      })}
    </div>
  );
};

import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { getConquestLogo, pviBrigadeImage, pviLogo } from '@marlin/asset/shared/ui/assets';

import { content } from '../../content';
import { detailsConfig } from './details-config';
import { brigadeSectionConfig } from './section-config-brigade';
import { conquestSectionConfig } from './section-config-conquest';

export const pviBrigadeConfig: IConfig = {
  headerBanner: null,
  details: detailsConfig,
  tabs: {},
  dashboard: {
    liveDataExpirationSeconds: 720,
    productDetails: {
      datapoints: [
        { name: 'Setpoint', label: content.SETPOINT },
        { name: 'OutletTemperature', label: content.DATAPOINT_LABELS.OUTLET },
      ],
      imageSrc: pviBrigadeImage,
      logoSrc: pviLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: false,
    chart: {
      datapointNames: ['Setpoint', 'OutletTemperature'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: true,
    sections: brigadeSectionConfig,
  },
};

export const pviConquestConfig: IConfig = {
  headerBanner: null,
  details: detailsConfig,
  tabs: {},
  dashboard: {
    liveDataExpirationSeconds: 720,
    productDetails: {
      datapoints: [
        { name: 'Setpoint', label: content.SETPOINT },
        { name: 'OutletTemperature', label: content.DATAPOINT_LABELS.OUTLET },
      ],
      imageSrc: getConquestLogo,
      logoSrc: pviLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
      alertSource: 'default',
    },
    enableStartStopData: false,
    chart: {
      datapointNames: ['Setpoint', 'OutletTemperature'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
    },
    isLastReadingTimeCounter: true,
    sections: conquestSectionConfig,
  },
};

import { TUserSchema } from '@marlin/account-data-access-user';
import { renderEllipsisCell } from '@marlin/data-grid';
import { formatPhoneNumber } from '@marlin/shared/ui-form';
import { TRole } from '@marlin/shared/utils-role';
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';

import { IUserContextMenuActionsProps, UserContextMenu } from './components/user-context-menu.component';
import { UserStatus } from './components/user-status.component';
import { content } from './content/content';

interface IBaseColumnsProps {
  columnProps: Partial<GridColDef>;
  rows: TUserSchema[];
}

export const createColumns =
  ({ onResend, onEdit, onDelete, onDeleteInvitation }: IUserContextMenuActionsProps) =>
  ({ columnProps, rows }: IBaseColumnsProps): GridColDef[] => [
    {
      field: 'FirstName',
      headerName: content.USER_TABLE_HEADER_NAME,
      flex: 1,
      ...columnProps,
      renderCell: renderEllipsisCell,
    },
    {
      field: 'title',
      headerName: content.USER_TABLE_HEADER_TITLE,
      flex: 1,
      ...columnProps,
      renderCell: renderEllipsisCell,
    },
    {
      field: 'Email',
      headerName: content.USER_TABLE_HEADER_EMAIL,
      flex: 2,
      ...columnProps,
      renderCell: renderEllipsisCell,
    },
    {
      field: 'Phone',
      headerName: content.USER_TABLE_HEADER_PHONE,
      flex: 1,
      ...columnProps,
      renderCell: (cellValues) => {
        return formatPhoneNumber(cellValues.row.phone);
      },
    },
    {
      field: 'role',
      headerName: content.USER_TABLE_HEADER_ROLE,
      flex: 0.5,
      ...columnProps,
      renderCell: (cellValues) => {
        const { invitationStatus, role } = cellValues.row as TUserSchema;

        if (invitationStatus || role) {
          return <UserStatus invitationStatus={invitationStatus} role={role} />;
        }

        return cellValues.value;
      },
      sortComparator: (roleA: TRole, roleB: TRole, cellParams1, cellParams2) => {
        const firstRowInvitationStatus = rows.find((row) => row.id === cellParams1.id)?.invitationStatus;
        const secondRowInvitationStatus = rows.find((row) => row.id === cellParams2.id)?.invitationStatus;
        if (firstRowInvitationStatus || secondRowInvitationStatus) {
          const cellValueA = (firstRowInvitationStatus || roleA).toUpperCase();
          const cellValueB = (secondRowInvitationStatus || roleB).toUpperCase();

          if (cellValueA < cellValueB) {
            return -1;
          } else if (cellValueA > cellValueB) {
            return 1;
          }
          return 0;
        }

        return gridStringOrNumberComparator(roleA, roleB, cellParams1, cellParams2);
      },
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 0.5,
      align: 'center',
      ...columnProps,
      renderCell: (cellValues) => {
        return (
          <UserContextMenu
            user={cellValues.row}
            onResend={onResend}
            onDeleteInvitation={onDeleteInvitation}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        );
      },
    },
  ];

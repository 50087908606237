import { MarlinTheme } from '@marlin/shared/theme';
import { CommonChip } from '@marlin/shared/ui/chips';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../shared/content';
import { usePlantConfigContext } from '../../../shared/context/plant-config.context';
import { legendContent } from '../../../shared/utils/get-legend-item-based-on-status';
import { StatusIcon } from './status-icon.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  modes: {
    margin: `${theme.typography.pxToRem(24)} 0`,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: 0,
    },
  },
  chips: {
    display: 'flex',
    marginRight: theme.typography.pxToRem(8),
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(16),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      margin: `${theme.typography.pxToRem(16)} 0`,
    },
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.typography.pxToRem(8),
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    marginRight: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    display: 'inline-block',
  },
  offlineDot: {
    backgroundColor: theme.palette.error.main,
  },
  operationalDot: {
    backgroundColor: theme.palette.success.main,
  },
  icon: {
    marginRight: theme.typography.pxToRem(8),
    color: theme.palette.action.active,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  divider: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

interface IPlantListHeaderProps {
  activeStatusChips: { operational: boolean; offline: boolean };
  toggleStatusChip: (status: 'operational' | 'offline') => void;
}

export const PlantListHeader = ({ activeStatusChips, toggleStatusChip }: IPlantListHeaderProps) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    config: { plantListTitle },
  } = usePlantConfigContext();

  const handleOfflineClick = () => {
    toggleStatusChip('offline');
  };

  const handleOperationalClick = () => {
    toggleStatusChip('operational');
  };

  const offlineLabel = (
    <span className={classes.chipLabel}>
      <span className={cx(classes.dot, classes.offlineDot)}></span>
      {content.PLANT_LIST.OFFLINE}
    </span>
  );

  const operationalLabel = (
    <span className={classes.chipLabel}>
      <span className={cx(classes.dot, classes.operationalDot)}></span>
      {content.PLANT_LIST.OPERATIONAL}
    </span>
  );

  return (
    <div>
      {!isMobile && (
        <Typography variant="h5" className={classes.title}>
          {plantListTitle}
        </Typography>
      )}
      <div className={classes.modes}>
        <div className={classes.chips}>
          <CommonChip
            testId="offline-chip"
            label={offlineLabel}
            clickable
            variant="outlined"
            active={activeStatusChips.offline}
            onClick={handleOfflineClick}
          />
          <CommonChip
            testId="operational-chip"
            label={operationalLabel}
            clickable
            variant="outlined"
            active={activeStatusChips.operational}
            onClick={handleOperationalClick}
          />
        </div>
        <div className={classes.legend}>
          {Object.values(legendContent)
            .sort((a, b) => a.order - b.order)
            .map((legendItem) => (
              <div className={classes.legendItem} key={legendItem.label}>
                <StatusIcon color={legendItem.color} icon={legendItem.icon} />
                <Typography variant="body1" className={cx({ [classes.errorColor]: legendItem.color === 'error' })}>
                  {legendItem.label}
                </Typography>
                {legendItem.divider && <Divider orientation="vertical" className={classes.divider} />}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

import { RecipientCreateForm } from '@marlin/account-feature-recipient-create';
import { RecipientEditForm } from '@marlin/account-feature-recipient-edit-form';
import { SettingsAppearance } from '@marlin/account-feature-settings-appearance';
import { SettingsHotsos } from '@marlin/account-feature-settings-hotsos';
import { IntegrationTab, WebhookCreate, WebhookUpdate } from '@marlin/account-feature-settings-integration';
import { SettingsOrganization } from '@marlin/account-feature-settings-organization';
import { People } from '@marlin/account-feature-settings-people';
import { SettingsInviteNewUser } from '@marlin/account-feature-user-invite';
import { SettingsOptions, SettingsPageWrapper } from '@marlin/account-ui-settings-page-wrapper';
import { SettingsEditUser } from '@marlin/account-ui-user-update';
import { UpdateProfile } from '@marlin/account/ui/profile-update';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const SettingsHubRoutes = (): JSX.Element => {
  return (
    <Route path={routes.settings.root}>
      <Route path={routes.settings.root} element={<SettingsOptions />} />
      <Route
        path={routes.settings.people.path}
        element={
          <PermissionGuard redirectTo={routes.settings.profile.url()} permissions={[PERMISSIONS.SHOW_SETTINGS_PAGE]}>
            <SettingsPageWrapper showTabs showHeaderOnMobile={false}>
              <People />
            </SettingsPageWrapper>
          </PermissionGuard>
        }
      />
      <Route
        path={routes.settings.hotsos.path}
        element={
          <PermissionGuard redirectTo={routes.settings.profile.url()} permissions={[PERMISSIONS.SHOW_SETTINGS_PAGE]}>
            <SettingsPageWrapper showTabs>
              <SettingsHotsos />
            </SettingsPageWrapper>
          </PermissionGuard>
        }
      />
      <Route
        path={routes.settings.orgSettings.path}
        element={
          <PermissionGuard
            redirectTo={routes.settings.people.url()}
            permissions={[PERMISSIONS.SHOW_ORGANIZATION_SETTINGS_PAGE]}
          >
            <SettingsPageWrapper showTabs>
              <SettingsOrganization />
            </SettingsPageWrapper>
          </PermissionGuard>
        }
      />
      <Route
        path={routes.settings.integration.path}
        element={
          <PermissionGuard redirectTo={routes.settings.people.url()} permissions={[PERMISSIONS.SHOW_SETTINGS_PAGE]}>
            <SettingsPageWrapper showTabs>
              <IntegrationTab />
            </SettingsPageWrapper>
          </PermissionGuard>
        }
      />
      <Route
        path={routes.settings.appearance.path}
        element={
          <SettingsPageWrapper showTabs>
            <SettingsAppearance />
          </SettingsPageWrapper>
        }
      />
      <Route
        path={routes.settings.addRecipient.path}
        element={
          <SettingsPageWrapper>
            <RecipientCreateForm />
          </SettingsPageWrapper>
        }
      />
      <Route
        path={routes.settings.profile.path}
        element={
          <PermissionGuard
            redirectTo={routes.settings.appearance.url()}
            permissions={[PERMISSIONS.SHOW_SETTINGS_PROFILE_PAGE]}
          >
            <SettingsPageWrapper showTabs>
              <UpdateProfile />
            </SettingsPageWrapper>
          </PermissionGuard>
        }
      />
      <Route
        path={routes.settings.editRecipient.path}
        element={
          <SettingsPageWrapper>
            <RecipientEditForm />
          </SettingsPageWrapper>
        }
      />
      <Route
        path={routes.settings.editUser.path}
        element={
          <SettingsPageWrapper>
            <SettingsEditUser />
          </SettingsPageWrapper>
        }
      />
      <Route
        path={routes.settings.inviteNewUser.path}
        element={
          <SettingsPageWrapper>
            <SettingsInviteNewUser />
          </SettingsPageWrapper>
        }
      />
      <Route
        path={routes.settings.createWebhook.path}
        element={
          <PermissionGuard
            redirectTo={routes.settings.people.url()}
            permissions={[PERMISSIONS.SHOW_SETTINGS_PAGE, PERMISSIONS.VENDOR_WEBHOOKS]}
          >
            <WebhookCreate />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.settings.editWebhook.path}
        element={
          <PermissionGuard
            redirectTo={routes.settings.people.url()}
            permissions={[PERMISSIONS.SHOW_SETTINGS_PAGE, PERMISSIONS.VENDOR_WEBHOOKS]}
          >
            <WebhookUpdate />
          </PermissionGuard>
        }
      />
    </Route>
  );
};

import { ILocation } from '@marlin/asset/data-access/location';

export const emptyDefaultValues: ILocation = {
  createdByUserId: '',
  createdDateTime: '',
  id: '',
  modifiedByUserId: '',
  modifiedDateTime: '',
  name: '',
  description: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  parentLocation: {
    id: '',
    name: '',
  },
};

export const countryList = [
  { id: 'United States', name: 'United States' },
  { id: 'Canada', name: 'Canada' },
  { id: 'Mexico', name: 'Mexico' },
];

export const maxLength = 100;

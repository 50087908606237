import { formatPhoneNumber } from '@marlin/shared/ui-form';
import { Link } from '@marlin/shared/ui-page';
import { Icon } from '@mui/material';

import { useStyles } from './contact-info-component.styles';

interface IContactInfoProps {
  value: string;
  type: 'email' | 'phone';
}

export const ContactInfo = ({ type, value }: IContactInfoProps) => {
  const { classes } = useStyles();
  const isEmail = type === 'email';

  const href = isEmail ? `mailto:${value}` : `tel:${value}`;

  return (
    <div data-testid={`contact-${type}-section`} className={classes.wrapper}>
      <Icon baseClassName="material-symbols-outlined" className={classes.icon} data-testid={`contact-${type}-icon`}>
        {isEmail ? 'email' : 'phone'}
      </Icon>
      <Link to={href} data-testid={`contact-${type}-link`} className={classes.link} external>
        {isEmail ? value : formatPhoneNumber(value)}
      </Link>
    </div>
  );
};

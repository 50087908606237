import { Typography } from '@mui/material';

interface IPersonNameProps {
  firstName: string;
  lastName: string;
  title?: string | null;
}

export const PersonName = ({ firstName, lastName, title }: IPersonNameProps) => {
  return (
    <div>
      <Typography variant="body2" fontWeight="bold">{`${firstName} ${lastName}`}</Typography>
      {title && <Typography variant="body2">{title}</Typography>}
    </div>
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => {
  const dotClass = {
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(12),
    borderRadius: '50%',
  };

  return {
    statusBar: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.typography.pxToRem(8),
      maxWidth: '100%',
    },

    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
    },

    redDot: {
      ...dotClass,
      backgroundColor: theme.palette.error.main,
    },

    greenDot: { ...dotClass, backgroundColor: theme.palette.success.light },

    blueDot: {
      ...dotClass,
      backgroundColor: theme.palette.primary.main,
    },
  };
});

interface IStatusBarProps {
  isOperational: boolean;
  tooltip?: boolean;
  labels?: {
    operational?: string;
    notOperational?: string;
  };
  isValueStatus?: boolean;
}

export const StatusBar = ({ isOperational, tooltip = false, labels, isValueStatus }: IStatusBarProps) => {
  const { classes, cx } = useStyles();
  const operationalLabel = labels?.operational ?? content.STATUS_OPERATIONAL;
  const notOperationalLabel = labels?.notOperational ?? content.STATUS_LOST_COMMUNICATION;
  const statusLabel = isOperational ? operationalLabel : notOperationalLabel;

  const dotClassesNames = cx({
    [classes.blueDot]: !!isValueStatus,
    [classes.redDot]: !isValueStatus && !isOperational,
    [classes.greenDot]: !isValueStatus && isOperational,
  });

  const status = (
    <div className={classes.statusBar}>
      <div className={dotClassesNames}></div>
      <div className={classes.text}>{statusLabel}</div>
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip text={statusLabel} placement="top">
        {status}
      </Tooltip>
    );
  }

  return status;
};

import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';

import { content } from '../content';
import { useStyles } from './delete-user-modal.component.styles';

export interface IDeleteUserModal {
  onCancel: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  user: {
    firstName: string;
    lastName: string;
  };
}

export const DeleteUserModal = ({ onCancel, onSubmit, isOpen, user }: IDeleteUserModal) => {
  const { classes } = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalMedium
      title={content.CONFIRM_DELETE_USER_TITLE}
      onClose={onCancel}
      body={
        <div>
          {content.CONFIRM_DELETE_USER_TEXT_PART_1}
          <span className={classes.userName}>{`${user.firstName} ${user.lastName}`}</span>
          {content.CONFIRM_DELETE_USER_TEXT_PART_2} {content.CONFIRM_DELETE_USER_CONFIRMATION_TEXT}
        </div>
      }
      footer={
        <>
          <Button variant="text" onClick={onCancel}>
            {content.CONFIRM_DELETE_USER_CANCEL_BUTTON}
          </Button>
          <Button className={classes.cancelButton} variant="contained" color="error" onClick={onSubmit}>
            {content.CONFIRM_DELETE_USER_DELETE_BUTTON}
          </Button>
        </>
      }
    />
  );
};

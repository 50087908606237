import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Icon, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { EditNameModal } from './edit-name-modal/edit-name-modal.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    '& svg': {
      fill: alpha(theme.palette.warning.contrastText, 0.54),
    },
    '& > span': {
      color: alpha(theme.palette.warning.contrastText, 0.54),
    },
    maxWidth: '50%',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
  },
}));

export const SectionHeader = ({
  title,
  icon,
  chartId,
}: {
  title: string;
  icon: JSX.Element | null;
  chartId?: string;
}) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.wrapper}>
      {icon}
      <Tooltip text={title}>
        <Typography className={classes.title}>{title}</Typography>
      </Tooltip>
      {chartId && (
        <IconButton onClick={() => setOpen(true)}>
          <Icon baseClassName="material-symbols-outlined" data-testid="edit-chart-name-icon">
            edit
          </Icon>
        </IconButton>
      )}
      {open && <EditNameModal title={title} id={chartId} onClose={() => setOpen(false)} />}
    </div>
  );
};

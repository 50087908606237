import { useInviteUser } from '@marlin/account-data-access-user-invite';
import { IResendInvite } from '@marlin/account-ui-user-list';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';

import { content } from '../content';

interface IUseInviteUser {
  handleResend: (user: IResendInvite) => void;
}

export const useResendInvite = (): IUseInviteUser => {
  const { enqueueSnackbar } = useSnackbar();

  const inviteUserMutation = useInviteUser();

  const handleResend = async (user: IResendInvite) => {
    if (!user) {
      return;
    }

    return inviteUserMutation
      .mutateAsync({ ...user, title: user.title || '' })
      .then(() => {
        enqueueSnackbar(content.RESEND_SUCCESS, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch(() => {
        enqueueSnackbar(content.RESEND_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  };

  return {
    handleResend,
  };
};

import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TEquipmentCommandParams, equipmentCommandParamsSchema } from '../schema/equipment-commands.schema';
import { paths } from './api';

export const sendDeviceCommand = async (params: TEquipmentCommandParams): Promise<unknown> => {
  const { command, ...body } = safeParseData(params, equipmentCommandParamsSchema);
  const res = await getHttpClient().post<unknown, unknown>(paths.SET_VALVE_STATE, body, {
    params: { command },
  });

  return res;
};

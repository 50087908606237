import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { BlockContainer } from '../block-container/block-container.component';
import { LineConnector } from '../line-connector/line-connector.component';
import { useStyles } from './section-wrapper.styles';

interface ISectionWrapper {
  sectionExpanded?: boolean;
  buttonDisabled?: boolean;
  sectionTitle: string;
  buttonTitle?: string;
  editMode: boolean;
  onToggle?: () => void;
  viewModeChildren?: ReactNode;
  actions?: ReactNode;
  buttonVariant?: 'regular' | 'array';
  editModeActions?: ReactNode;
  testId: string;
  className?: string;
}

export const SectionWrapper: FC<PropsWithChildren<ISectionWrapper>> = ({
  sectionExpanded = false,
  buttonDisabled = true,
  sectionTitle,
  buttonTitle = sectionTitle,
  editMode,
  onToggle,
  children,
  viewModeChildren,
  actions,
  buttonVariant = 'regular',
  editModeActions,
  testId,
  className,
}) => {
  const { cx, classes } = useStyles();
  const [toggleSection, setToggleSection] = useState<boolean>(sectionExpanded);

  const handleToggleSection = () => {
    setToggleSection((prev) => !prev);
    onToggle?.();
  };

  useEffect(() => {
    setToggleSection(sectionExpanded);
  }, [sectionExpanded]);

  if (!toggleSection) {
    return (
      <>
        {buttonVariant === 'array' && <LineConnector variant="shorter" />}
        <Button
          data-testid={`section-collapsed-${testId}`}
          className={cx(buttonVariant === 'array' ? classes.buttonArray : classes.button)}
          disabled={buttonDisabled}
          onClick={handleToggleSection}
          variant={buttonVariant === 'array' ? 'outlined' : 'contained'}
          startIcon={buttonVariant === 'array' && <AddOutlinedIcon />}
        >
          {buttonTitle}
        </Button>
      </>
    );
  }

  return (
    <BlockContainer
      testId={testId}
      editMode={editMode}
      title={sectionTitle}
      actions={actions}
      editModeActions={editModeActions}
      className={className}
    >
      {editMode ? children : viewModeChildren}
    </BlockContainer>
  );
};

import { Divider } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IChart } from '../collapsed-chart/chart-props';
import { BrushChart } from './brush-chart.component';
import { ExtendedChartProvider, TExtendedChart } from './context/extended-chart.context';
import { ExtendedChartForm } from './extended-chart-form.component';
import { useExtendedChart } from './hooks/use-extended-chart.hook';
import { MainChart } from './main-chart.component';

const useStyles = makeStyles()(() => ({
  chart: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ExtendedChart = (extendedChartProps: IChart): JSX.Element | null => {
  const { classes } = useStyles();
  const extendedChart = useExtendedChart({
    rangeFilter: extendedChartProps.rangeFilter,
  });

  const extendedChartContextValue: TExtendedChart = useMemo(() => {
    return {
      ...extendedChartProps,
      ...extendedChart,
    };
  }, [extendedChartProps, extendedChart]);

  if (!extendedChartProps.manufacturerId || !extendedChartProps.deviceType) {
    return null;
  }

  return (
    <ExtendedChartProvider value={extendedChartContextValue}>
      <div data-testid="extended-chart" className={classes.chart}>
        <Divider />
        <ExtendedChartForm />
        <MainChart />
        <BrushChart />
      </div>
    </ExtendedChartProvider>
  );
};

import { MarlinTheme } from '@marlin/shared/theme';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSectionValidation } from '../hooks/use-section-validation.hook';
import { createAutomationEnabledSchema } from './create-automation.validation.schema';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    alignSelf: 'center',
  },
}));

export const CreateAutomationBtn = () => {
  const { classes } = useStyles();
  const { isValid } = useSectionValidation(createAutomationEnabledSchema);

  return (
    <Button
      type="submit"
      data-testid="submit-automation"
      className={classes.button}
      disabled={!isValid}
      onClick={() => {}}
      variant={'contained'}
    >
      {content.CREATE_AUTOMATION}
    </Button>
  );
};

import { globalConfigAerco } from '@marlin/asset/shared/equipment-config';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { AERCO_UNIT_STATUS } from '@marlin/shared/utils/zod';
import moment from 'moment';

export const getStatus = (
  currentTime: moment.Moment | null,
  status: string,
  lastReadingsTime: string,
  telemetryTime?: string
) => {
  const typedStatus = status ? (status as AERCO_UNIT_STATUS) : AERCO_UNIT_STATUS.Fault;

  const isLiveData = (timestamp: string) =>
    moment.duration(currentTime?.diff(dateAdapter.date(timestamp))).asSeconds() <
    globalConfigAerco.dashboard.liveDataExpirationSeconds;

  if (!telemetryTime)
    return lastReadingsTime && isLiveData(lastReadingsTime) ? typedStatus : AERCO_UNIT_STATUS.Disabled;

  return isLiveData(telemetryTime) ? typedStatus : AERCO_UNIT_STATUS.Disabled;
};

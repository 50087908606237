import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { SystemConstraintsPage } from '@marlin/shared/ui-page';
import { Navigate } from 'react-router-dom';

import { EquipmentDetailsConfigContextProvider } from '../../shared/context/equipment-details-config-context';
import { useEquipmentConfig } from '../../shared/hooks/equipment-config/use-equipment-config.hook';
import { useEquipment } from '../hooks/use-equipment.hook';
import { useNavigation } from '../hooks/use-navigation.hook';
import { DetailsBuilder } from './builder/builder.component';

export const EquipmentDetails = () => {
  const { equipment, refetchEquipment, isLoading, isError } = useEquipment();
  const navigation = useNavigation();

  const config = useEquipmentConfig({ equipmentId: equipment?.id, equipmentModel: equipment?.model });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!equipment) {
    return <SystemConstraintsPage />;
  }

  if (!config) return <Navigate to={navigation.getEquipmentDetailsLink(equipment.id)} replace />;

  return (
    <EquipmentDetailsConfigContextProvider config={config}>
      <DetailsBuilder equipment={equipment} isEquipmentError={isError} refetchEquipment={refetchEquipment} />
    </EquipmentDetailsConfigContextProvider>
  );
};

import { InvitationStatusChip, RoleChip } from '@marlin/shared/ui/chips';

import { IUserStatusProps } from '../types';

export const UserStatus = ({ role, invitationStatus }: IUserStatusProps) => {
  if (invitationStatus) {
    return <InvitationStatusChip invitationStatus={invitationStatus} />;
  }

  if (role) {
    return <RoleChip role={role} />;
  }

  return null;
};

import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material';
import partition from 'lodash/partition';
import { useCallback, useMemo } from 'react';

import { TSelectedDatapoint } from '../types';

export const useSortDatapointsWithColor = () => {
  const theme = useTheme<MarlinTheme>();
  const chartColors = useMemo(() => Array.from(Object.values(theme.palette.charting)), [theme.palette.charting]);

  return useCallback(
    (data: TSelectedDatapoint[]): TSelectedDatapoint[] => {
      const [sensorsInOrder, equipmentDatapointsInOrder] = partition(
        orderByLabel(data),
        ({ parentType }) => parentType === 'sensor'
      );

      return [...sensorsInOrder, ...equipmentDatapointsInOrder].map((datapoint, index) => {
        const newColor = chartColors[index] ?? chartColors[chartColors.length - index];

        return { ...datapoint, color: newColor };
      });
    },
    [chartColors]
  );
};

const orderByLabel = (data: TSelectedDatapoint[]) => {
  return data.sort((a, b) => naturalSort(a.label, b.label));
};

function naturalSort(labelA: string, labelB: string) {
  const stringAndNumberSeparatorRegex = /(\d+)|(\D+)/g;
  const numberRegex = /^\d+$/;
  const aParts = labelA.match(stringAndNumberSeparatorRegex) || [];
  const bParts = labelB.match(stringAndNumberSeparatorRegex) || [];

  const numaIndex = aParts.findIndex((part) => numberRegex.test(part));
  const numbIndex = bParts.findIndex((part) => numberRegex.test(part));

  const sameNameWithoutNum =
    aParts.filter((_, i) => i !== numaIndex).join('') === bParts.filter((_, i) => i !== numbIndex).join('');

  if (!sameNameWithoutNum) {
    return aParts.join('').localeCompare(bParts.join(''));
  }

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    const aPart = aParts[i];
    const bPart = bParts[i];

    const checkIsNum = (part: string) => numberRegex.test(part);

    const aNum = checkIsNum(aPart);
    const bNum = checkIsNum(bPart);

    if (!aNum && !bNum) {
      const diff = aPart.localeCompare(bPart);
      if (diff !== 0) return diff;
    }

    if (aNum && bNum) {
      const diff = parseInt(aPart, 10) - parseInt(bPart, 10);
      if (diff !== 0) return diff;
    }

    if (aNum) return -1;
    if (bNum) return 1;
  }

  return aParts.length - bParts.length;
}

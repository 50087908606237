import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

export const adminPermissions: TPermission[] = [
  PERMISSIONS.SHOW_SETTINGS_PAGE,
  PERMISSIONS.DEVICE_DRAWER_MANAGE_AUTOMATION,
  PERMISSIONS.SHOW_DEVICE_DRAWER_EDIT_ICON,
  PERMISSIONS.SHOW_DEVICE_DRAWER_DELETE_ICON,
  PERMISSIONS.CREATE_NEW_DEVICE,
  PERMISSIONS.CREATE_NEW_GATEWAY,
  PERMISSIONS.EDIT_GATEWAY,
  PERMISSIONS.EDIT_GATEWAY_HUB,
  PERMISSIONS.EDIT_SETTINGS_GATEWAY,
  PERMISSIONS.CREATE_NEW_EQUIPMENT,
  PERMISSIONS.CREATE_NEW_LOCATION,
  PERMISSIONS.CREATE_NEW_AUTOMATION,
  PERMISSIONS.CREATE_NEW_MECHANICAL_ROOM,
  PERMISSIONS.EDIT_LOCATION,
  PERMISSIONS.DELETE_LOCATION,
  PERMISSIONS.SHOW_AUTOMATION_TOOGLE,
  PERMISSIONS.AUTOMATION_SNOOZE_ALERTS,
  PERMISSIONS.EDIT_ASSET,
  PERMISSIONS.DELETE_ASSET,
  PERMISSIONS.EDIT_DEVICE,
  PERMISSIONS.DELETE_DEVICE,
  PERMISSIONS.EDIT_EQUIPMENT,
  PERMISSIONS.DELETE_EQUIPMENT,
  PERMISSIONS.DEREGISTER_EQUIPMENT,
  PERMISSIONS.EDIT_AUTOMATION,
  PERMISSIONS.DELETE_AUTOMATION,
  PERMISSIONS.SAVE_SYSTEM_MAP,
  PERMISSIONS.ADD_SYSTEM_MAP_FLOW_LINK,
  PERMISSIONS.ADD_SYSTEM_MAP_ATTACHMENT,
  PERMISSIONS.EDIT_SYSTEM_MAP_FLOW_LINK,
  PERMISSIONS.DELETE_SYSTEM_MAP_FLOW_LINK,
  PERMISSIONS.ADD_NODE_TO_TEE,
  PERMISSIONS.RENAME_TEE,
  PERMISSIONS.ADD_TEE,
  PERMISSIONS.DELETE_TEE,
  PERMISSIONS.ADD_USER_TO_PORTFOLIO,
  PERMISSIONS.UPDATE_EQUIPMENT_SCHEDULE,
  PERMISSIONS.UPDATE_EQUIPMENT_SETTINGS,
  PERMISSIONS.SHOW_ORGANIZATION_SETTINGS_PAGE,
  PERMISSIONS.SHOW_ORGANIZATION_ADDRESS_SETTINGS_PAGE,
  PERMISSIONS.CREATE_TIER_ONE_ORGANIZATION,
  PERMISSIONS.SHOW_SETTINGS_PROFILE_PAGE,
];

import { TUserProfileForm } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../shared/translation-keys';
import { TUpsertProfileFormValidationSchema } from '../../shared/user-profile-update-form.schema';
import { useHideShowAccessCode } from './use-hide-show-access-code';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(25.6),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginBottom: theme.typography.pxToRem(8),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1,
    letterSpacing: theme.typography.pxToRem(0.15),
    marginBottom: theme.typography.pxToRem(32),
    color: theme.palette.text.secondary,
  },
  iconButton: {
    marginRight: theme.typography.pxToRem(4),
  },
}));

export const AccessCodeField = () => {
  const { classes } = useStyles();
  const { showAccessCode, handleClickShowAccessCode, showHideButtonRef, isInputFocused$ } = useHideShowAccessCode();
  const {
    formState: { defaultValues },
  } = useFormContext<TUserProfileForm>();

  return (
    <>
      <Typography data-testid="access-code-title" className={classes.title}>
        {content.ACCESS_CODE_TITLE}
      </Typography>
      <Typography className={classes.subtitle}>{content.ACCESS_CODE_SUBTITLE}</Typography>
      <FormField<TUpsertProfileFormValidationSchema> fieldName="deviceAccessCode">
        {(props) => (
          <Input
            label={content.ACCESS_CODE_LABEL}
            placeholder={content.ACCESS_CODE_LABEL}
            helperText={content.ACCESS_CODE_HELPER}
            maxLength={5}
            testId="access-code-field"
            type={showAccessCode || !defaultValues?.deviceAccessCode ? INPUT_TYPE.NUMBER : INPUT_TYPE.PASSWORD}
            mask="00000"
            externalEndAdornment={
              defaultValues?.deviceAccessCode
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={handleClickShowAccessCode}
                          className={classes.iconButton}
                          ref={showHideButtonRef}
                          data-testid="access-code-show-hide-button"
                        >
                          {showAccessCode ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            onFocus={() => isInputFocused$.next(true)}
            {...props}
            onBlur={() => {
              props.onBlur && props.onBlur();
              isInputFocused$.next(false);
            }}
          />
        )}
      </FormField>
    </>
  );
};

import { useUpdateUser as useUpdateUserMutation, useUser } from '@marlin/account-data-access-organization';
import { TUser } from '@marlin/account-data-access-organization';
import { invalidateUserList } from '@marlin/account-data-access-user';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useMemo } from 'react';

import { content } from '../content';

export const useUpdateUser = ({ userId, onSuccess }: { userId: string; onSuccess: () => void }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onError = () =>
    enqueueSnackbar(content.COMMON_ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });

  const userQuery = useUser({ userId: userId ?? '', onError });

  const { mutateAsync: updateUser, isPending, isError, reset } = useUpdateUserMutation({ userId: userId ?? '' });

  const user = useMemo(() => userQuery?.data, [userQuery?.data]);

  const handleSubmit = async (editedUser: Omit<TUser, 'id'>) => {
    try {
      const res = await updateUser({
        userId: userId ?? '',
        newUser: editedUser,
      });
      await invalidateUserList();
      enqueueSnackbar(content.SUCCESS_MESSAGE, {
        variant: 'success',
        preventDuplicate: true,
      });
      onSuccess();
      return res;
    } catch (error) {
      enqueueSnackbar(content.ERROR_MESSAGE, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
    return;
  };

  return { user, handleSubmit, isLoading: isPending, isError, reset };
};

import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { ControlType, DatapointWithMetadata } from './datapoint.schema';
import { MetadataDynamicOption } from './metadata-option.schema';
import { DatapointResponseType } from './metadata-response.schema';
import { MetadataStatus } from './statuses.schema';

export const SettingDatapoint = z
  .object({
    name: z.string(),
    uom: UnitOfMeasureType,
    value: z.string(),
    dynamicMetadata: z
      .object({
        def: z.string().nullable(),
        max: z.string().nullable(),
        min: z.string().nullable(),
        options: MetadataDynamicOption.array().nullable(),
        step: z.string().nullable(),
        status: MetadataStatus.array().nullable().optional(),
        type: DatapointResponseType.nullable(),
        uom: UnitOfMeasureType,
      })
      .nullable(),
  })
  .transform((item) => ({
    ...item,
    name: item.name,
    unitOfMeasure: item.uom,
    dynamicMetadata: item.dynamicMetadata
      ? {
          defaultValue: item.dynamicMetadata.def,
          min: item.dynamicMetadata.min,
          max: item.dynamicMetadata.max,
          step: item.dynamicMetadata.step,
          type: item.dynamicMetadata.options ? 'enum' : item.dynamicMetadata.type,
          unitOfMeasure: item.uom,
          availableStatuses: item.dynamicMetadata.status ?? [],
          options: item.dynamicMetadata.options ?? [],
        }
      : null,
  }));
export type TSettingDatapoint = z.infer<typeof SettingDatapoint>;

export const SettingsGroupResponse = z.object({
  datapoints: z.array(SettingDatapoint),
});
export type TSettingsGroupResponse = z.infer<typeof SettingsGroupResponse>;

export const SettingDatapointWithMetadata = DatapointWithMetadata.omit({
  controlType: true,
  unitOfMeasure: true,
  lastReadingTime: true,
}).extend({
  label: z.string(),
  displayedValue: z.string(),
  orderNumber: z.number().nullish(),
});
export type TSettingDatapointWithMetadata = z.infer<typeof SettingDatapointWithMetadata>;

export const DropdownSetting = SettingDatapointWithMetadata.extend({
  value: z.string(),
  min: z.number().optional(),
  max: z.number().optional(),
  options: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      label: z.string().optional(),
      labelDescription: z.string().optional(),
    })
  ),
  controlType: z.literal(ControlType.Enum.option),
});
export type TDropdownSetting = z.infer<typeof DropdownSetting>;

export const ValueSetting = SettingDatapointWithMetadata.extend({
  value: z.string(),
  min: z.number().optional(),
  max: z.number().optional(),
  minThreshold: z.number().optional(),
  maxThreshold: z.number().optional(),
  step: z.number().optional(),
  unitOfMeasure: UnitOfMeasureType,
  controlType: z.literal(ControlType.Enum.value),
});
export type TValueSetting = z.infer<typeof ValueSetting>;

export const BooleanSetting = SettingDatapointWithMetadata.extend({
  value: z.boolean(),
  controlType: z.literal(ControlType.Enum.boolean),
});
export type TBooleanSetting = z.infer<typeof BooleanSetting>;

export type TEquipmentSetting = TDropdownSetting | TValueSetting | TBooleanSetting;

import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { content } from '../content';
import { useStyles } from './details-content-view.styles';

interface IDetailsContentViewProps {
  editModeToggle: () => void;
}

export const DetailsContentView = ({ editModeToggle }: IDetailsContentViewProps) => {
  const { classes } = useStyles();
  const { watch } = useFormContext<TAutomationBuilder>();
  const automationName = watch('details.name');
  const criticality = watch('details.criticality');

  return (
    <div className={classes.container}>
      <MiscellaneousServicesRoundedIcon className={classes.iconColor} />
      <Typography variant="body2" data-testid="details-content-view">
        {content.AUTOMATION_NAME_HAS_CRITICALITY(
          automationName,
          <CriticalityControl isCriticalityButton={true} severity={criticality} className={classes.criticality} />,
          classes.detailsText,
          classes.textUnderline,
          editModeToggle
        )}
      </Typography>
    </div>
  );
};

import { useState } from 'react';

import { TPeopleTab } from '../components/people-tab-switcher.component';
import { RecipientCardList } from '../recipients/recipient-card-list.component';
import { UserCardList } from '../user-card-list/user-card-list.component';

export const PeopleMobile = () => {
  const [activeTab, onTabChange] = useState<TPeopleTab>('users');

  if (activeTab === 'users') {
    return <UserCardList activeTab={activeTab} onTabChange={onTabChange} />;
  }

  if (activeTab === 'recipients') {
    return <RecipientCardList activeTab={activeTab} onTabChange={onTabChange} />;
  }

  return null;
};

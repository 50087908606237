import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material/styles';

import { TPowerSource } from '../../types';
import { getPowerSourceType } from '../../utils';
import { Battery } from './battery.component';
import { Line } from './line.component';

type TNumericalPowerSource = {
  variant?: 'number';
  batteryLevel: number;
};

type TTextualPowerSource = {
  variant?: 'text';
  batteryLevel: 'LOW' | 'HIGH';
};

export type TPowerSourceProps = {
  powerSource?: TPowerSource;
  gatewayModel?: string;
} & (TNumericalPowerSource | TTextualPowerSource);

export const PowerSource = ({
  gatewayModel,
  batteryLevel,
  powerSource: powerSourceProps,
  variant = 'number',
}: TPowerSourceProps) => {
  const powerSource: TPowerSource =
    powerSourceProps ?? (variant === 'number' ? getPowerSourceType(batteryLevel as number) : 'BATTERY');
  const theme = useTheme<MarlinTheme>();

  if (gatewayModel === 'SmartBaseStationDT550') {
    switch (powerSource.toUpperCase()) {
      case 'LINE': {
        return <Line />;
      }

      case 'BATTERY': {
        return <Line disconnected color={theme.palette.error.main} />;
      }

      default: {
        return null;
      }
    }
  }

  switch (powerSource) {
    case 'LINE': {
      return <Line />;
    }

    case 'BATTERY': {
      return <Battery batteryLevel={batteryLevel} variant={variant ?? 'number'} />;
    }

    default: {
      return null;
    }
  }
};

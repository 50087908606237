import { environment } from '@marlin/environment';

export const content = {
  INVITE_NEW_USER_BTN: 'Invite New User',
  INVITE: 'Invite',
  DELETE_EXPIRED_INVITATIONS_BTN: 'Delete Expired Invitations',
  USER_TABLE_TITLE: 'Users',
  USER_TABLE_TOOLTIP: `A User within ${environment.app.name} has access to login and access all pages within the ${environment.app.name} platform.`,
  USER_TABLE_SEARCH_LABEL: 'Search by name, email or title',
  USER_SEARCH_MODAL_TITLE: 'Search Users',

  ADD_NEW_RECIPIENT_BTN: 'Add new recipient',
  RECIPIENT_TABLE_TITLE: 'Alert Recipients',
  RECIPIENT_SEARCH_MODAL_TITLE: 'Search Recipients',
  RECIPIENT_TABLE_TOOLTIP: `Alert recipients can be added to receive emails and texts when alerts are triggered. However, Alert Recipients will NOT have access to login to the ${environment.app.name} platform, but will only receive text and email alerts. Individuals that wish to login to the ${environment.app.name} platform, and receive text and email alerts, should instead be added as users.`,
  RECIPIENT_TABLE_SEARCH_LABEL: 'Search by name or email',

  DELETE_RECIPIENT_SUCCESS: 'Recipient has been removed',
  DELETE_RECIPIENT_ERROR: "Something went wrong. Recipient hasn't been removed.",
  RESEND_SUCCESS: 'Invite has been sent',
  RESEND_ERROR: "Something went wrong. Invitation hasn't been resent.",
  RESEND_MODAL_TITLE: 'Resend Invite',
  RESEND_MODAL_BODY: 'Invitation email will be resent to',
  RESEND_MODAL_BUTTON_CANCEL_TEXT: 'Cancel',
  RESEND_MODAL_BUTTON_SUBMIT_TEXT: 'Resend',
  DELETE_USER_SUCCESS: 'A user has been deleted',
  DELETE_USER_ERROR: "Something went wrong. User hasn't been deleted.",

  DELETE_INVITATION_SUCCESS: 'Invitation has been deleted',
  DELETE_INVITATION_ERROR: "Something went wrong. Invitation hasn't been deleted.",

  DELETE_ALL_EXPIRED_INVITATIONS_SUCCESS: 'All expired invitations have been deleted',
  DELETE_ALL_EXPIRED_INVITATIONS_ERROR: "Something went wrong. Expired invitations haven't been deleted.",

  PEOPLE: 'People',
  BACK: 'Back',
};

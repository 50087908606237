import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useState } from 'react';

import { GenericDeadbandCondition } from './generic-deadband-condition.component';
import { useStyles } from './generic-number-condition.styles';
import { GenericThresholdCondition } from './generic-threshold-condition.component';
import { useConditionField } from './use-condition-field.hook';

interface IGenericNumberConditionProps {
  datapoint: TDeviceMetadataDatapointsResponse;
  triggerIndex: number;
  conditionIndex: number;
  operator: 'lt' | 'gt';
}

export const GenericNumberCondition = ({
  datapoint,
  triggerIndex,
  conditionIndex,
  operator,
}: IGenericNumberConditionProps) => {
  const { threshold } = useConditionField({ datapoint, triggerIndex, operator });

  const [enabled, setEnabled] = useState<boolean>(!!threshold?.length);
  const { classes } = useStyles();

  return (
    <>
      <GenericThresholdCondition
        datapoint={datapoint}
        triggerIndex={triggerIndex}
        operator={operator}
        conditionIndex={conditionIndex}
        enabled={enabled}
        setEnabled={setEnabled}
      />
      {enabled && (
        <div className={classes.deadbandRow}>
          <GenericDeadbandCondition
            datapoint={datapoint}
            triggerIndex={triggerIndex}
            operator={operator}
            conditionIndex={conditionIndex}
          />
        </div>
      )}
    </>
  );
};

import { z } from 'zod';

const valveStateSchema = z.union([z.literal('Open'), z.literal('Closed')]);

const baseSchema = z.object({
  manufacturer: z.string(),
  manufacturerId: z.string(),
  model: z.string(),
  additionalData: z.record(z.string()).optional(),
});

const setValveStateSchema = baseSchema.extend({
  command: z.literal('set-valve-state'),
  valveState: valveStateSchema,
});

const otherCommandSchema = baseSchema.extend({
  command: z.string().refine((cmd) => cmd !== 'set-valve-state', {
    message: 'Invalid command for this schema',
  }),
});

export const equipmentCommandParamsSchema = z.union([setValveStateSchema, otherCommandSchema]);

export type TValveState = z.infer<typeof valveStateSchema>;
export type TEquipmentCommandParams = z.infer<typeof equipmentCommandParamsSchema>;

import { TValveState, useDatapointHeartbeatCounter, useSendDeviceCommand } from '@marlin/asset/data-access/equipment';
import { ISettingsCommandGroup } from '@marlin/asset/shared/equipment-config';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { useCommandSnackbar } from './use-command-snackbar.hook';
import { useErrorHandler } from './use-error-handler.hook';
import { useObservableLastCommand } from './use-observable-last-command.hook';
import { useValveCommandModal } from './use-valve-command-modal.hook';

export interface IUseDeviceCommandsMenuProps {
  valveNames: string[];
  manufacturer: string;
  manufacturerId: string;
  model: string;
  commands: ISettingsCommandGroup['commands'];
}

interface IDeviceCommand {
  label: string;
  onClick: () => void;
}

interface IUseDeviceCommandsMenu {
  anchorEl: HTMLElement | null;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  deviceCommands: IDeviceCommand[];
}

const isValveState = (valveState?: unknown): valveState is TValveState => {
  if (typeof valveState !== 'string') return false;

  return valveState === 'Open' || valveState === 'Closed';
};

export const useDeviceCommands = ({
  model,
  manufacturer,
  manufacturerId,
  valveNames,
  commands,
}: IUseDeviceCommandsMenuProps): IUseDeviceCommandsMenu => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mutateAsync: sendDeviceCommand } = useSendDeviceCommand();
  const { handleCloseModal, handleOpenModal } = useValveCommandModal();
  const { enqueueSuccessSnackbars, enqueueErrorSnackbar, enqueueSuccessSnackbar } = useCommandSnackbar();
  const { reset } = useDatapointHeartbeatCounter();
  const { handleValveCloseError, handleValveOpenError } = useErrorHandler();
  const { setLastCommand } = useObservableLastCommand();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleToggleValveState = useCallback(
    async (valveState: TValveState) => {
      try {
        await sendDeviceCommand({ model, manufacturer, manufacturerId, valveState, command: 'set-valve-state' });
        setLastCommand(valveState);
        reset(3, (data) => {
          valveNames.forEach((valveName) => {
            if (valveState === 'Open') {
              handleValveOpenError({ valveState, valveName, data });
            } else if (valveState === 'Closed') {
              handleValveCloseError({ valveState, valveName, data });
            }
          });
        });
        enqueueSuccessSnackbars({ valveNames, valveState });
        handleCloseModal();
      } catch (error) {
        enqueueErrorSnackbar();
      }
    },
    [
      sendDeviceCommand,
      model,
      manufacturer,
      manufacturerId,
      setLastCommand,
      reset,
      enqueueSuccessSnackbars,
      valveNames,
      handleCloseModal,
      handleValveOpenError,
      handleValveCloseError,
      enqueueErrorSnackbar,
    ]
  );

  const deviceCommands = useMemo((): IDeviceCommand[] => {
    return commands.map((command): IDeviceCommand => {
      const valveState: TValveState | null | undefined = isValveState(command?.payload?.valveState)
        ? command?.payload?.valveState
        : null;

      if (command.value === 'set-valve-state' && valveState) {
        return {
          label: command.label,
          onClick: () => {
            handleClose();
            handleOpenModal({
              valveState,
              valveNames,
              handleSendCommand: async () => handleToggleValveState(valveState),
            });
          },
        };
      }

      return {
        label: command.label,
        onClick: async () => {
          try {
            await sendDeviceCommand({
              model,
              manufacturer,
              manufacturerId,
              command: command.value,
              ...command.payload,
            });
            enqueueSuccessSnackbar();
            handleClose();
          } catch (error) {
            enqueueErrorSnackbar();
          }
        },
      };
    });
  }, [
    commands,
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar,
    handleClose,
    handleOpenModal,
    handleToggleValveState,
    manufacturer,
    manufacturerId,
    model,
    sendDeviceCommand,
    valveNames,
  ]);

  return useMemo(
    (): IUseDeviceCommandsMenu => ({
      anchorEl,
      handleClick,
      handleClose,
      deviceCommands,
    }),
    [anchorEl, handleClick, handleClose, deviceCommands]
  );
};

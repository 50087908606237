import { z } from 'zod';

import { content } from '../content';

export const updateHubFormSchema = z
  .object({
    name: z
      .string({ required_error: content.REQUIRED })
      .trim()
      .min(1, content.REQUIRED)
      .min(3, content.MIN_MAX)
      .max(200, content.MIN_MAX),
    locationId: z.string({ required_error: content.REQUIRED }),
    locationName: z.string().nullish().optional(),
    location: z.object({ id: z.string(), name: z.string() }).optional(),
    description: z.string().nullish(),
    commissionDate: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId ?? '', name: data.locationName ?? '' },
  }));

export type TUpsertHubUpdateForm = z.infer<typeof updateHubFormSchema>;

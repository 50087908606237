import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    maxWidth: theme.typography.pxToRem(724),
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(16),
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.typography.pxToRem(24),
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      padding: 0,
      gap: 0,
      paddingTop: theme.typography.pxToRem(8),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(8),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.primary,
  },
  uomTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(24),
  },
  themeOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    width: '50%',
    height: theme.typography.pxToRem(72),
    padding: theme.typography.pxToRem(24),
    borderRadius: theme.typography.pxToRem(4),
    '&:hover': {
      border: `${theme.typography.pxToRem(1)} solid ${theme.palette.primary.main}`,
    },
  },
  themeOptionActive: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  themeOptionTitle: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(12),
    alignContent: 'center',
    alignItems: 'center',
  },
  themeSwitcherContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(24),
  },
}));

import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { StatusBar, StatusLabel } from '@marlin/asset/shared/ui/equipment-list';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { getUomFromSettings } from '@marlin/shared/utils-format-reading';
import { ReactNode } from 'react';

import { useRenderDatapoint } from '../../hooks/use-render-datapoint.hook';
import { ICardConfig, TCardRow } from '../../types';
import { content } from '../content';

export const usePoldCard = (): ICardConfig => {
  const { renderDatapoint } = useRenderDatapoint();

  const poldColumns = [
    {
      field: 'InPoldAlert',
      headerName: content.COLUMNS.POLD.LEAK_STATUS,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { InPoldAlert } = row;

        return <StatusLabel isOperational={InPoldAlert === 'False'}>{renderDatapoint(field, row)}</StatusLabel>;
      },
    },
    {
      field: 'Temperature',
      headerName: content.COLUMNS.POLD.AIR_TEMPERATURE,
      renderValue: (field: string, row: TCardRow) => renderDatapoint(field, row, getUomFromSettings('TEMPERATURE')),
    },
    {
      field: 'RelativeHumidity',
      headerName: content.COLUMNS.POLD.AIR_HUMIDITY,
      renderValue: renderDatapoint,
    },
    {
      field: 'PoldInAlert',
      headerName: content.COLUMNS.POLD.IN_ALERT,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { InPoldAlert, InRelativeHumidityAlert, InTemperatureAlert } = row;
        const inAlert = [InPoldAlert, InRelativeHumidityAlert, InTemperatureAlert].some((alert) => alert === 'True');
        return (
          <StatusLabel isOperational={!inAlert}>
            {inAlert ? content.VALUES.IN_ALERT.TRUE : content.VALUES.IN_ALERT.FALSE}
          </StatusLabel>
        );
      },
    },
    {
      field: 'CommunicationStatusActive',
      headerName: content.COLUMNS.POLD.STATUS,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { CommunicationStatusActive } = row;
        return <StatusBar isOperational={CommunicationStatusActive === 'True'} tooltip={true} />;
      },
    },
    {
      field: 'HasLowBatteries',
      headerName: content.COLUMNS.POLD.POWER_SOURCE,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { IsWired, HasLowBatteries } = row;
        return (
          <PowerSource
            powerSource={IsWired === 'True' ? 'LINE' : 'BATTERY'}
            batteryLevel={HasLowBatteries === 'True' ? 'LOW' : 'HIGH'}
            variant={'text'}
          />
        );
      },
    },
  ];

  const poldFooter = [
    {
      field: 'LastReadingTime',
      headerName: content.COLUMNS.LDS_VALVE.LAST_READING_TIME,
      renderValue: (field: string, row: TCardRow): ReactNode => {
        const { LastReadingTime } = row;

        return (
          <Tooltip
            placement="top"
            text={`${content.COLUMNS.LDS_VALVE.LAST_READING_TIME}: ${formatDate(
              LastReadingTime as unknown as null,
              defaultDateTime
            )}`}
          >
            <>{formatDate(LastReadingTime as unknown as null, defaultDateTime)}</>
          </Tooltip>
        );
      },
    },
  ];
  return {
    columns: poldColumns,
    footer: poldFooter,
  };
};

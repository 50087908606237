import { MarlinTheme } from '@marlin/shared/theme';
import { SkeletonLoader } from '@marlin/shared/ui-loader';
import { AnnotationTooltip, IAnnotation } from '@marlin/shared/ui/annotation-tooltip';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import { getFormattedDate, useChartTooltip } from '@marlin/shared/ui/chart-options';
import {
  IChartSeries,
  IChartThresholdSeries,
  TBucketOption,
  TChartDisplayType,
  TChartType,
  getChartHeight,
  plotlyAdapterSchema,
} from '@marlin/shared/utils-chart';
import { createDateString, formatDate } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getFormattedValue } from '@marlin/shared/utils-format-reading';
import { CircularProgress, alpha } from '@mui/material';
import Plotly, { Config, Data, Datum, Layout, PlotDatum } from 'plotly.js';
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useThresholdData } from '../hooks/use-threshold-data.hook';
import { IPlotComponentProps } from '../model';
import { HeatMapPlotComponent } from './plots/heat-map-plot.component';
import { PlotComponent } from './plots/plot.component';
import { RangeBarPlotComponent } from './plots/range-bar-plot.component';
import { Tooltip } from './tooltip.component';

interface IPlotDatum extends PlotDatum {
  z: Datum;
  base?: string;
}

interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

export interface IChartOptions {
  layout?: Partial<Layout>;
  config?: Partial<Config>;
  handleRelayout?: (e: Plotly.PlotRelayoutEvent) => void;
  isEmpty?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(32),
    position: 'relative',

    'div.modebar-group [data-attr="disabled"]': {
      '&:hover > .icon path': {
        // This important is needed to override the default plotly fill color as plotly uses id selector internally
        fill: 'rgba(68, 68, 68, 0.3) !important',
      },
    },

    '.draglayer.cursor-move': {
      '.nsewdrag.drag.cursor-ew-resize': {
        cursor: 'grab',
      },
    },

    '.annotation': {
      '.annotation-text-g': {
        '.cursor-pointer': {
          cursor: 'default',
        },
      },
    },
  },
  barsChart: {
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.background.alternative, 0.7),
    zIndex: theme.zIndex.drawer,
  },

  skeleton: {
    height: '100%',
    width: '100%',
  },
}));

interface IChartWrapperProps extends IChartOptions {
  isLoading: boolean;
  isFetching: boolean;
  chartId: string;
  chartData: IChartSeries[];
  currentAnnotationTooltip: IAnnotation | null;
  to: number;
  from: number;
  chartDisplayType: TChartDisplayType;
  isFullScreen?: boolean;
  headerHeight?: number;
  rangeBarDatapoints?: string[];
  paperHeight?: number;
  className?: string;
  height?: number;
  chartOptions?: ApexCharts.ApexOptions;
  thresholdData?: IChartThresholdSeries[];
  hideUomOnAxis?: boolean;
  uoms: { uom: TUnitOfMeasure; additionalUom: TUnitOfMeasure | null } | null;
  chartType?: TChartType;
  bucketOption?: TBucketOption;
}

export const ChartSwitcherWrapper = ({
  isLoading,
  isFetching,
  chartId,
  chartData,
  currentAnnotationTooltip,
  to,
  from,
  chartDisplayType,
  className,
  layout,
  config,
  handleRelayout,
  isFullScreen,
  rangeBarDatapoints,
  isEmpty,
  thresholdData,
  hideUomOnAxis,
  height,
  uoms,
  chartType,
  bucketOption,
}: IChartWrapperProps) => {
  const { classes, cx } = useStyles();

  return (
    <SkeletonLoader loading={isLoading} skeletonsCount={5}>
      <div
        className={cx(classes.container, className, {
          [classes.barsChart]: chartDisplayType === 'bar' || chartDisplayType === 'rangeBar',
        })}
        data-testid={chartId}
      >
        {isFetching && !isLoading && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}
        <ChartSwitcher
          layout={layout}
          config={config}
          chartData={chartData}
          to={to}
          from={from}
          rangeBarDatapoints={rangeBarDatapoints}
          chartDisplayType={chartDisplayType}
          handleRelayout={handleRelayout}
          isFullScreen={isFullScreen}
          isEmpty={isEmpty}
          thresholdData={thresholdData}
          hideUomOnAxis={hideUomOnAxis}
          height={height}
          uoms={uoms}
          chartType={chartType}
          bucketOption={bucketOption}
        />
        {currentAnnotationTooltip && <AnnotationTooltip {...currentAnnotationTooltip} />}

        {!isLoading && (
          <EmptyChartData
            chartData={thresholdData ? [...chartData, ...thresholdData] : chartData}
            from={from}
            to={to}
            chartDisplayType={chartDisplayType}
          />
        )}
      </div>
    </SkeletonLoader>
  );
};

export const ChartSwitcher = ({
  chartData,
  chartDisplayType,
  to,
  from,
  layout,
  config,
  handleRelayout,
  thresholdData,
  rangeBarDatapoints,
  height,
  hideUomOnAxis,
  uoms,
  chartType,
  bucketOption,
}: Omit<IChartWrapperProps, 'chartId' | 'isLoading' | 'isFetching' | 'currentAnnotationTooltip'>) => {
  const uomsForSeries = useMemo(() => {
    return [uoms?.uom, uoms?.additionalUom].filter(Boolean);
  }, [uoms]);

  const data = useMemo(() => {
    return plotlyAdapterSchema.parse({
      series: chartData,
      uoms: uomsForSeries,
      options: {
        hideUomOnAxis,
      },
    });
  }, [chartData, hideUomOnAxis, uomsForSeries]);

  const { data: mappedThresholdData, annotations } = useThresholdData({
    thresholdData,
    yAxisRange: layout?.yaxis?.range,
  });

  const mergedData = useMemo<Partial<Data>[]>(() => [...data, ...mappedThresholdData], [data, mappedThresholdData]);
  const mergedLayout = useMemo(() => ({ ...layout, annotations }), [annotations, layout]);

  const rangeBarChartHeight = useMemo(
    () => getChartHeight(height, rangeBarDatapoints?.length, !!chartData.length),
    [height, rangeBarDatapoints?.length, chartData.length]
  );

  const { setShowTooltip, tooltip, setTooltip, showTooltip, tooltipDirection } = useChartTooltip();

  const handleHover = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      let eventPoints = event.points as IPlotDatum[];
      if (chartType === 'leak') {
        const mappedData = chartData.map((series) => ({
          ...series,
          data: series.data.map((point) => ({
            ...point,
            x: formatDate(createDateString(new Date(point.x))),
          })),
        }));
        const xPoint = formatDate(createDateString(new Date(eventPoints[0].x ?? '')));
        eventPoints = mappedData.map((series) => ({
          ...eventPoints[0],
          y: series.name,
          x: series.data.find((point) => point.x === xPoint)?.x ?? eventPoints[0].x ?? '',
          z: series.data.find((point) => point.x === xPoint)?.y ?? '',
          data: {
            ...eventPoints[0].data,
            marker: {
              ...eventPoints[0].data.marker,
              color: series.color,
            },
          },
        }));
      }

      const points: ITooltipData[] = eventPoints
        .filter(
          (point: IPlotDatum) =>
            point.data.mode === 'lines+markers' || point.data.type === 'heatmap' || point.data.type === 'bar'
        )
        .map((point: IPlotDatum): ITooltipData => {
          if (point.data.type === 'bar' && point.base && point.x) {
            const timeFormat = 'DD MMM HH:mm';
            return {
              color: point.data.marker.color?.toString() || '',
              name: point.y?.toString() || '',
              date: typeof point.x === 'string' ? point.x.toString() : '',

              formattedDate: `${formatDate(createDateString(new Date(point.base)), timeFormat)} - ${formatDate(
                createDateString(new Date(point.x)),
                timeFormat
              )}`,
              displayedValue: '',
            };
          }

          const uom = point.data.customdata?.[0] as TUnitOfMeasure;

          if (point.data.type === 'heatmap') {
            return {
              color: point.data.marker.color?.toString() || '',
              displayedValue: getFormattedValue(
                point.z?.toString() || '',
                chartType === 'leak' ? 'WaterDetect' : uom
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              name: point.y?.toString() || '',
              date: typeof point.x === 'string' ? point.x.toString() : '',
              formattedDate: getFormattedDate(point, bucketOption),
            };
          }

          return {
            color: point.data.marker.color?.toString() || '',
            displayedValue: getFormattedValue(point.y?.toString() || '', uom).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            name: point.data.name,
            date: typeof point.x === 'string' ? point.x.toString() : '',
            formattedDate: getFormattedDate(point, bucketOption),
          };
        });

      setTooltip({
        top: event.event.clientY,
        left: event.event.clientX,
        tooltipData: points,
      });
      setShowTooltip(true);
    },
    [bucketOption, chartData, chartType, setShowTooltip, setTooltip]
  );

  const handleUnhover = useCallback(() => {
    setShowTooltip(false);
    setTooltip(null);
  }, [setShowTooltip, setTooltip]);

  const tooltipProps = useMemo(
    () => ({
      tooltipData: tooltip?.tooltipData,
      top: tooltip?.top,
      left: tooltip?.left,
      direction: tooltipDirection,
      showTooltip,
    }),
    [showTooltip, tooltip?.left, tooltip?.tooltipData, tooltip?.top, tooltipDirection]
  );

  const plotComponentProps = useMemo<IPlotComponentProps>(() => {
    return {
      data: mergedData,
      layout: mergedLayout,
      config: config ?? {},
      handleRelayout,
      handleHover,
      handleUnhover,
      height: chartDisplayType === 'rangeBar' ? rangeBarChartHeight : height,
    };
  }, [
    chartDisplayType,
    config,
    handleHover,
    handleRelayout,
    handleUnhover,
    height,
    mergedData,
    mergedLayout,
    rangeBarChartHeight,
  ]);

  if (chartDisplayType === 'rangeBar') {
    return (
      <>
        <RangeBarPlotComponent {...plotComponentProps} to={to} />
        <Tooltip {...(tooltipProps || {})} isPeriodTooltip />
      </>
    );
  }

  if (layout && chartDisplayType === 'heatmap') {
    return (
      <>
        <HeatMapPlotComponent {...plotComponentProps} to={to} from={from} chartData={chartData} />
        <Tooltip {...(tooltipProps || {})} />
      </>
    );
  }

  if (layout) {
    return (
      <>
        <PlotComponent
          data={plotComponentProps.data}
          layout={plotComponentProps.layout}
          config={plotComponentProps.config}
          handleRelayout={plotComponentProps.handleRelayout}
          handleHover={plotComponentProps.handleHover}
          handleUnhover={plotComponentProps.handleUnhover}
          height={plotComponentProps.height}
        />
        <Tooltip {...(tooltipProps || {})} />
      </>
    );
  }

  return null;
};

import { zodResolver } from '@hookform/resolvers/zod';
import { TUser } from '@marlin/account-data-access-organization';
import omit from 'lodash/omit';
import { ValidationMode, useForm } from 'react-hook-form';

import { UpsertProfileFormValidationSchema } from '../user-update-form/user-profile-upsert-form.schema';

type TUserForm = Omit<TUser, 'id'>;

export const useUpsertUserForm = (user: TUser | undefined, mode?: keyof ValidationMode) => {
  return useForm<TUserForm>({
    defaultValues: omit(user, ['id']),
    mode: mode || 'onTouched',
    resolver: zodResolver(UpsertProfileFormValidationSchema),
  });
};

import { IChartSeries } from '@marlin/shared/utils-chart';
import Plotly from 'plotly.js';
import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';

import { IPlotComponentProps } from '../../model';

export const HeatMapPlotComponent = React.memo(
  ({
    data,
    handleRelayout,
    handleUnhover,
    handleHover,
    config,
    height,
    layout,
    to,
    from,
    chartData,
  }: IPlotComponentProps & {
    from: number;
    to: number;
    chartData: IChartSeries[];
  }) => {
    const yaxisConfig = useMemo(() => {
      return chartData.reduce(
        (acc, _, index) => ({
          ...acc,
          [index === 0 ? 'yaxis' : `yaxis${index + 1}`]: {
            side: 'right',
            automargin: true,
          },
        }),
        {}
      );
    }, [chartData]);

    const plotLayout = useMemo<Partial<Plotly.Layout>>(
      () => ({
        ...layout,
        xaxis: {
          ...layout.xaxis,
          type: 'date',
          range: [from, to],
          showgrid: false,
        },
        ...yaxisConfig,
        grid: { rows: data.length, columns: 1 },
      }),
      [data.length, from, layout, to, yaxisConfig]
    );

    return (
      <Plot
        data={data}
        useResizeHandler={true}
        layout={plotLayout}
        config={{
          ...config,
          responsive: false,
        }}
        style={{
          width: '100%',
          height: '100%',
        }}
        onRelayout={handleRelayout}
        onHover={handleHover}
        onUnhover={handleUnhover}
      />
    );
  }
);

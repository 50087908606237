export const content = {
  NEW_EQUIPMENT: 'New Equipment',
  SUBTITLE: 'Use the form below to create a new equipment.',
  BUTTON_CANCEL: 'Cancel',
  SUCCESS_MESSAGE: 'Your equipment has been created.',
  ERROR_MESSAGE: `Something went wrong. Your equipment hasn't been created`,
  UNIT_MOVED_MESSAGE: (plantName: string) => `Your equipment has been moved to ${plantName}`,
  SENTINEL_ERROR_MESSAGES: {
    INVALID_CONTROL_PANEL_ID: 'Invalid control panel ID. Check the number and try again.',
    DEVICE_OWNED_BY_ANOTHER_VENDOR: 'Control panel ID is already registered elsewhere. Check the number and try again.',
    REQUEST_FOR_MONITORING_EXISTS: 'Check the number and try again.',
    BLACKLISTED_BY_USER: 'Check the number and try again.',
    DEVICE_ALREADY_REGISTERED_FOR_MONITORING: 'Control panel ID is already registered. Check the number and try again',
  },
};

import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useEffect, useState } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

// TODO: This hook can be used for selecting datapoints for multi-datapoint conditions in the future
export const useDatapointSelect = (
  triggerIndex: number,
  datapoints: TDeviceMetadataDatapointsResponse[] | undefined
) => {
  const [datapoint, setDatapoint] = useState(datapoints?.[0]);
  const { setValue } = useFormContext<TAutomationBuilder>();

  useEffect(() => {
    if (!datapoints) {
      return;
    }

    setDatapoint(datapoints[0]);
  }, [datapoints]);

  useEffect(() => {
    setValue(`triggers[${triggerIndex}].datapoint` as FieldPath<TAutomationBuilder>, datapoint?.name, {
      shouldValidate: true,
    });
  }, [datapoint?.name, setValue, triggerIndex]);

  return {
    datapoint,
    setDatapoint,
  };
};

import { SENTINEL_ERROR } from '@marlin/asset/data-access/equipment';

import { content } from '../content';

const sentinelErrorMessages: Partial<Record<SENTINEL_ERROR, string>> = {
  [SENTINEL_ERROR.SENTINEL_REGISTRATION_DEVICE_DOES_NOT_EXIST]:
    content.SENTINEL_ERROR_MESSAGES.INVALID_CONTROL_PANEL_ID,
  [SENTINEL_ERROR.SENTINEL_REGISTRATION_DEVICE_OWNED_BY_OTHER_VENDOR]:
    content.SENTINEL_ERROR_MESSAGES.DEVICE_OWNED_BY_ANOTHER_VENDOR,
  [SENTINEL_ERROR.SENTINEL_REGISTRATION_REQUEST_FOR_MONITORING_EXISTS]:
    content.SENTINEL_ERROR_MESSAGES.REQUEST_FOR_MONITORING_EXISTS,
  [SENTINEL_ERROR.SENTINEL_REGISTRATION_DEVICE_BLACKLISTED]: content.SENTINEL_ERROR_MESSAGES.BLACKLISTED_BY_USER,
  [SENTINEL_ERROR.SENTINEL_REGISTRATION_DEVICE_ALREADY_REGISTERED]:
    content.SENTINEL_ERROR_MESSAGES.DEVICE_ALREADY_REGISTERED_FOR_MONITORING,
  [SENTINEL_ERROR.EQUIPMENT_DEVICE_ALREADY_REGISTERED]:
    content.SENTINEL_ERROR_MESSAGES.DEVICE_ALREADY_REGISTERED_FOR_MONITORING,
};

export const getSentinelErrorMessage = (errorCode: SENTINEL_ERROR): string | undefined => {
  return sentinelErrorMessages[errorCode];
};

import { TGateway } from '@marlin/asset/data-access/gateway';
import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { ConnectionStatus } from '@marlin/asset/shared/ui/connection-status';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { ActionEdit, ConfigureAction, ContextMenu } from '@marlin/shared/ui-context-menu';
import { Link, Paper } from '@marlin/shared/ui-page';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { StatusCell, StatusHeaderCell } from '../cells';
import { content } from '../content';
import { DeregisterAction } from './deregister-action.component';
import { useDataGridSx, useStyles } from './gateway-list.component.styles';
import { ListHeader } from './list-header.component';

export interface IGatewayListProps {
  rows: TGateway[];
  isLoading: boolean;
  isError: boolean;
  pagination: {
    page: number;
    pageSize: number;
    totalItems: number;
  };
  changePage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  refreshData: () => void;
}

const dataGridPageCounterOffset = 1;

interface IGatewayColumnDef extends GridColDef {
  field: keyof TGateway;
}

const useCreateColumns = ({ columnProps }: { columnProps: Partial<IGatewayColumnDef> }): IGatewayColumnDef[] => {
  const { classes } = useStyles();

  return [
    {
      field: 'id',
      headerName: content.GATEWAY_TABLE_ID_COLUMN,
      ...columnProps,
      flex: 1,
      renderCell: (cellValues) => (
        <Tooltip text={cellValues.row.gatewayId} placement="top">
          <div className={classes.cellStyle}>{cellValues.row.gatewayId}</div>
        </Tooltip>
      ),
    },
    {
      field: 'gatewayName',
      headerName: content.GATEWAY_TABLE_NAME_COLUMN,
      renderCell: (cellValues) => {
        return (
          <Tooltip text={cellValues.row.gatewayName} placement="top">
            {cellValues.row.model === 'NexaGateway' ? (
              <div className={classes.gatewayName}>{cellValues.row.gatewayName}</div>
            ) : (
              <Link className={classes.cellStyle} to={routes.gateway.details.url(cellValues.row.id)}>
                {cellValues.row.gatewayName}
              </Link>
            )}
          </Tooltip>
        );
      },
      ...columnProps,
      flex: 1,
    },
    {
      field: 'status',
      headerName: content.GATEWAY_TABLE_STATUS_COLUMN,
      renderHeader: () => <StatusHeaderCell />,
      renderCell: (cellValues) => (
        <StatusCell model={cellValues.row.model} status={cellValues.row.status} mode={cellValues.row.mode} />
      ),
      ...columnProps,
      flex: 1,
    },
    {
      field: 'powerSource',
      headerName: content.GATEWAY_TABLE_POWER_SOURCE_COLUMN,
      renderCell: (cellValues) => (
        <PowerSource
          gatewayModel={cellValues.row.model}
          powerSource={cellValues.row.powerSource}
          batteryLevel={cellValues.row.batteryLevel}
        />
      ),
      ...columnProps,
      flex: 1,
    },
    {
      field: 'signalStrength',
      headerName: content.GATEWAY_TABLE_SIGNAL_STRENGTH_COLUMN,
      renderCell: (cellValues) => (
        <ConnectionStatus
          signalStrength={cellValues.row.signalStrength}
          gatewayModel={cellValues.row.model}
          connectionType={cellValues.row.connectionType}
          isOnline={cellValues.row.status === 'ACTIVE'}
        />
      ),
      ...columnProps,
      flex: 1,
    },
    {
      field: 'lastDataReceived',
      headerName: content.GATEWAY_TABLE_LAST_DATA_RECEIVED_COLUMN,
      renderCell: (cellValues) => formatDate(cellValues.row.lastDataReceived, defaultDateTime) || '-',

      ...columnProps,
      flex: 1,
    },
    {
      field: 'activeDevices',
      headerName: content.GATEWAY_TABLE_ACTIVE_SENSORS_COLUMN,
      renderCell: (cellValues) =>
        cellValues.row.assignedDevices.length ? cellValues.row.assignedDevices.length : cellValues.row.activeDevices,
      ...columnProps,
      flex: 1,
    },
  ];
};

const gatewayActionMenu = (
  configureGateway: (deviceId: string) => void,
  refreshData: () => void,
  onEdit: (deviceId: string) => void
): IGatewayColumnDef => ({
  field: 'action',
  headerName: '',
  sortable: false,
  flex: 0.5,
  align: 'center',
  renderCell: (cellValues: GridRenderCellParams) => {
    const model = cellValues.row.model;

    if (model === 'NexaGateway') {
      return null;
    }

    return (
      <ContextMenu>
        <ActionEdit onClick={() => onEdit(cellValues.row.id)} />
        {model !== 'SmartBaseStationDT550' && <ConfigureAction onClick={() => configureGateway(cellValues.row.id)} />}
        <DeregisterAction refreshData={refreshData} gateway={cellValues.row} />
      </ContextMenu>
    );
  },
});

export const GatewayList = ({
  rows,
  isLoading,
  isError,
  pagination,
  changePage,
  changePageSize,
  refreshData,
}: IGatewayListProps) => {
  const { classes } = useStyles();
  const sx = useDataGridSx();

  const dataGridPage = useMemo(() => pagination.page - dataGridPageCounterOffset, [pagination.page]);
  const { search } = useLocation();
  const isEditable = usePermission(PERMISSIONS.EDIT_GATEWAY);

  const { gateway } = useFeatureFlagsContext();
  const gatewayId = new URLSearchParams(search).get('gatewayId');
  const { goTo } = useRouter();
  const configureGateway = useCallback(
    (deviceId: string) => {
      const configureGatewayUrl = routes.gateway.configuration.url(deviceId);
      goTo(configureGatewayUrl);
    },
    [goTo]
  );
  const baseColumn = useCreateColumns({ columnProps: { headerClassName: classes.column } });

  const onEditClick = useCallback(
    (deviceId: string) => {
      const editGatewayUrl = routes.gateway.edit.url(deviceId);
      goTo(editGatewayUrl);
    },
    [goTo]
  );

  const columns = useMemo(() => {
    if (!gateway || !isEditable) {
      return baseColumn;
    }

    return [...baseColumn, gatewayActionMenu(configureGateway, refreshData, onEditClick)];
  }, [isEditable, gateway, baseColumn, configureGateway, refreshData, onEditClick]);

  const dataGridChangePageSize = useCallback(
    (page: number) => changePage(page + dataGridPageCounterOffset),
    [changePage]
  );

  return (
    <Paper className={classes.paper}>
      <ListHeader />
      <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
        <DataGrid
          sx={sx}
          loading={isLoading}
          onPageChange={dataGridChangePageSize}
          onPageSizeChange={changePageSize}
          localeText={{
            noRowsLabel: isError ? content.GATEWAY_TABLE_ERROR : content.GATEWAY_TABLE_NO_DATA,
          }}
          getRowId={(row) => row.id}
          page={dataGridPage}
          pageSize={pagination.pageSize}
          rowCount={pagination.totalItems}
          rows={rows}
          columns={columns}
          paginationMode="server"
          disableSelectionOnClick
          rowsPerPageOptions={[5, 10, 50, 100]}
          selectionModel={gatewayId ? [gatewayId] : []}
          disableColumnMenu
        />
      </div>
    </Paper>
  );
};

import { useSearchParamsRepository } from '@marlin/shared/utils-router';
import { useCallback, useMemo } from 'react';

import { TSentinelSystemEquipment, isSentinelSystemEquipmentType, sentinelSystemEquipment } from '../types';

export interface ISystemEquipmentHook {
  tab: TSentinelSystemEquipment;
  changeTab: (tab: TSentinelSystemEquipment) => void;
}

interface ISystemEquipmentSearchParams {
  model: string | null;
}

export const useSystemEquipmentTabs = (): ISystemEquipmentHook => {
  const { getSearchParam, setSearchParam } = useSearchParamsRepository<ISystemEquipmentSearchParams>();
  const model = getSearchParam('model');

  const tab: TSentinelSystemEquipment = useMemo(
    (): TSentinelSystemEquipment => (isSentinelSystemEquipmentType(model) ? model : sentinelSystemEquipment[0]),
    [model]
  );

  const changeTab = useCallback(
    (tab: TSentinelSystemEquipment) => {
      setSearchParam('model', tab);
    },
    [setSearchParam]
  );

  return {
    tab,
    changeTab,
  };
};

import { createDateString } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

import { parseDisplayedValue } from '../datapoint-display/displayed-value-utils';
import { UnitOfMeasureType } from './uom.schema';

export const datapointsSchema = z.union([
  z.literal('Leak'),
  z.literal('Temperature'),
  z.literal('Pressure'),
  z.literal('FlowRate'),
  z.literal('FlowVolume'),
  z.literal('BatteryLevel'),
  z.literal('SignalStrength'),
  z.literal('Pulses'),
  z.literal('Voltage'),
  z.string(),
]);

export type TDataPointsSchema = z.infer<typeof datapointsSchema>;

export const lastReadingValuesSchema = z
  .object({
    name: datapointsSchema,
    value: z.string(),
    unitOfMeasure: UnitOfMeasureType.nullish(),
    isDefault: z.boolean(),
    lastReadingTime: z.string(),
  })
  .transform((data) => ({
    ...data,
    lastReadingTime: createDateString(data.lastReadingTime ?? undefined),
    formattedReading: data.unitOfMeasure
      ? parseDisplayedValue(data.value, data.unitOfMeasure, undefined, 'detail')
      : null,
  }));

export const lastReadingValuesOutputSchema = z.object({
  name: z.string(),
  value: z.string(),
  unitOfMeasure: UnitOfMeasureType.nullish(),
  isDefault: z.boolean(),
  lastReadingTime: z.coerce.string().nullable(),
  formattedReading: z.string().nullable(),
});

export type TLastReadingValuesOutputSchema = z.infer<typeof lastReadingValuesOutputSchema>;
export type TLastReadingValuesSchema = z.infer<typeof lastReadingValuesSchema>;

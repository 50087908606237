import { z } from 'zod';

import { CHANNEL_RESPONSE_TYPE, CHANNEL_TYPE } from '../../../automation.model';

export const RecipientTypeSchema = z.union([z.literal('Recipient'), z.literal('User')]);

const channelRecipientSchema = z
  .object({
    recipientId: z.string(),
    recipientType: RecipientTypeSchema,
    firstName: z.string(),
    lastName: z.string(),
  })
  .transform((data) => ({
    ...data,
    id: data.recipientId,
  }));

const channelSchema = z.object({
  recipients: z.array(channelRecipientSchema),
});

const emailChannelSchema = channelSchema.extend({
  type: z.literal(CHANNEL_TYPE.EMAIL),
});

type TEmailChannel = z.infer<typeof emailChannelSchema>;

const smsChannelSchema = channelSchema.extend({
  type: z.literal(CHANNEL_TYPE.SMS),
});

type TSmsChannel = z.infer<typeof smsChannelSchema>;

type TRecipientChannel = TEmailChannel | TSmsChannel;

const inAppChannelSchema = z.object({
  type: z.literal(CHANNEL_TYPE.IN_APP),
});

export const channelsSchema = z.object({
  [CHANNEL_TYPE.EMAIL]: emailChannelSchema.optional(),
  [CHANNEL_TYPE.SMS]: smsChannelSchema.optional(),
  [CHANNEL_TYPE.IN_APP]: inAppChannelSchema.optional(),
});

const emailChannelResponseSchema = channelSchema.extend({
  channelType: z.literal(CHANNEL_RESPONSE_TYPE.EMAIL),
});

const smsChannelResponseSchema = channelSchema.extend({
  channelType: z.literal(CHANNEL_RESPONSE_TYPE.SMS),
});

const inAppChannelResponseSchema = z.object({
  channelType: z.literal(CHANNEL_RESPONSE_TYPE.IN_APP),
});

const channelResponseSchema = z
  .discriminatedUnion('channelType', [emailChannelResponseSchema, smsChannelResponseSchema, inAppChannelResponseSchema])
  .transform((data) => {
    const { channelType, ...rest } = data;

    let type: CHANNEL_TYPE | null = null;

    switch (channelType) {
      case CHANNEL_RESPONSE_TYPE.SMS: {
        type = CHANNEL_TYPE.SMS;
        break;
      }

      case CHANNEL_RESPONSE_TYPE.IN_APP: {
        type = CHANNEL_TYPE.IN_APP;
        break;
      }

      case CHANNEL_RESPONSE_TYPE.EMAIL: {
        type = CHANNEL_TYPE.EMAIL;
        break;
      }
    }

    return {
      ...rest,
      type,
    };
  });

type TChannelResponse = z.infer<typeof channelResponseSchema>;

type TChannelAcc = {
  [key in CHANNEL_TYPE]: TChannelResponse;
};

const isRecipientChannel = (channel: TChannelResponse): channel is TRecipientChannel => {
  return (channel as TRecipientChannel).recipients !== undefined;
};

export const channelsResponseSchema = z.array(channelResponseSchema).transform((data) => {
  return data.reduce((acc: Partial<TChannelAcc>, val: TChannelResponse) => {
    if (!val) {
      return acc;
    }

    if (isRecipientChannel(val)) {
      if (val.recipients.length === 0) {
        return acc;
      }
    }

    return {
      ...acc,
      [val.type]: val,
    };
  }, {});
});

import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

interface IPoldFiltersProps {
  showOnlyInAlerts: boolean;
  setShowOnlyInAlerts: (showOnlyInAlerts: boolean) => void;
  inAlertPoldsCount?: number;
  clearAllAlerts: () => void;
  isSendingDeviceCommand: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  loader: {
    marginLeft: theme.typography.pxToRem(4),
    opacity: 0.5,
  },
}));

export const PoldFilters = ({
  showOnlyInAlerts,
  setShowOnlyInAlerts,
  inAlertPoldsCount,
  clearAllAlerts,
  isSendingDeviceCommand,
}: IPoldFiltersProps) => {
  const { classes } = useStyles();
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={showOnlyInAlerts}
            onChange={(event) => setShowOnlyInAlerts(event.target.checked)}
            color="primary"
          />
        }
        label={content.FILTERS.SHOW_ONLY_ALERTS(inAlertPoldsCount)}
      />
      {showOnlyInAlerts && (
        <>
          <Button
            color={'error'}
            variant={'outlined'}
            disabled={!inAlertPoldsCount || isSendingDeviceCommand}
            onClick={() => clearAllAlerts()}
            endIcon={
              isSendingDeviceCommand ? (
                <LoadingSpinner size={16} color={'error'} className={classes.loader} />
              ) : undefined
            }
          >
            {content.FILTERS.CLEAR_ALL_ALERTS}
          </Button>
        </>
      )}
    </>
  );
};

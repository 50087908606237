import { HierarchyBreadcrumbs } from '@marlin/asset/shared/ui/hierarchy-breadcrumbs';
import { DetailTabs, useTab } from '@marlin/asset/shared/ui/tabs';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { COMMON_ASSET_TAB_VALUES, routes } from '@marlin/shared/utils-routes';
import { Box, Button, useMediaQuery } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { CommonAssetDetailsConfigContextProvider } from '../../shared/context/common-asset-details-config.context';
import { ICommonAsset, ICommonAssetDetailsConfig, ICommonAssetNavigation } from '../../shared/model';
import { CommonAssetDashboard } from './tabs/dashboard/common-asset-dashboard';

interface IStyles {
  noHierarchyDisplayed: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { noHierarchyDisplayed }) => ({
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  page: {
    minHeight: `calc(100% - ${theme.typography.pxToRem(64)})`,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(16),
    width: '100%',
  },
  equipment: {
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(5),
  },
  time: {
    fontSize: theme.typography.pxToRem(14),
  },
  editDeleteButton: {
    width: theme.typography.pxToRem(96),
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: noHierarchyDisplayed ? 'flex-end' : 'space-between',
    padding: `0 0 ${theme.typography.pxToRem(16)} 0`,
    alignItems: 'flex-start',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: theme.typography.pxToRem(76),
    marginLeft: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(5),
  },
  buttons: {
    display: 'flex',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const tabs: {
  [key in COMMON_ASSET_TAB_VALUES]?: ReactElement;
} = {};

interface ICommonAssetDetailsProps {
  config: ICommonAssetDetailsConfig;
  navigation: ICommonAssetNavigation;
  isLoading: boolean;
  asset: ICommonAsset | undefined;
  isError: boolean;
  assetId: string;
  parentId?: string;
  noHierarchyDisplayed?: boolean;
  isEditable: boolean;
  assetName: string;
}

export const CommonAssetDetails = ({
  config,
  navigation,
  assetId,
  asset,
  parentId,
  isError,
  isLoading,
  isEditable,
  noHierarchyDisplayed = false,
  assetName,
}: ICommonAssetDetailsProps) => {
  const isMobile = useMediaQuery((theme: MarlinTheme) => theme.breakpoints.down('md'));
  const { classes } = useStyles({ noHierarchyDisplayed });
  const tab = useTab<COMMON_ASSET_TAB_VALUES>(COMMON_ASSET_TAB_VALUES.DASHBOARD);
  const navigate = useNavigate();
  const editLink = useMemo(() => {
    if (parentId) {
      return assetName === 'hub' ? navigation.getEditUrlWithParent(parentId, assetId) : navigation.getEditUrl(assetId);
    }

    return navigation.getEditUrl(assetId);
  }, [assetId, assetName, navigation, parentId]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!asset || isError) {
    return <SystemConstraintsPage />;
  }

  return (
    <CommonAssetDetailsConfigContextProvider config={config}>
      <PageContainer className={classes.page}>
        <div className={classes.actionsWrapper}>
          {asset?.locationId && <HierarchyBreadcrumbs locationId={asset.locationId} selectedItemId={asset.id} />}
          {!isMobile && isEditable && (
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => navigate(editLink)}
                data-testid={`${assetName}-gateway-button`}
              >
                {content.EDIT}
              </Button>
            </div>
          )}
        </div>
        <Box className={classes.headerWrapper}>
          {isMobile && isEditable ? (
            <div className={classes.wrapper}>
              <div className={classes.titleWrapper} data-testid="header-container">
                <h1 data-testid="header-title" className={classes.title}>
                  {asset?.name}
                </h1>
                <ContextMenu>
                  <ActionEdit onClick={() => navigate(editLink)} />
                </ContextMenu>
              </div>
            </div>
          ) : (
            <PageHeader prefix={`${assetName}-gateway-header"`} title={asset?.name ?? ''} />
          )}
        </Box>
        <DetailTabs tabs={tabs} getRoute={routes.gateway.details.url} currentTab={tab} />
        {tab === COMMON_ASSET_TAB_VALUES.DASHBOARD ? <CommonAssetDashboard asset={asset} /> : tabs[tab]}
      </PageContainer>
    </CommonAssetDetailsConfigContextProvider>
  );
};

import { useObservableState } from 'observable-hooks';
import { useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

type TTriggerIndex = `trigger-${number}`;

type TSection = 'details' | TTriggerIndex | 'actions';

const rulesStore$ = new BehaviorSubject<TSection>('details');

export const useSectionsStore$ = () => {
  const [currentState] = useObservableState<TSection>(() => rulesStore$);

  const setSection = useCallback((section: TSection) => rulesStore$.next(section), []);

  const isSectionActive = useCallback((section: TSection) => currentState === section, [currentState]);

  const clearStore = useCallback(() => {
    rulesStore$.next('details');
  }, []);

  return { setSection, isSectionActive, clearStore };
};

import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { z } from 'zod';

const SettingsCommand = z.object({
  label: z.string(),
  value: z.string(),
  payload: z
    .record(z.string())
    .nullish()
    .transform((payload) => payload ?? undefined)
    .transform((payload) => {
      if (!payload || !Object.keys(payload).length || !Object.keys(payload).includes('valveState')) {
        return payload;
      }
      return {
        ...payload,
        valveState: payload['valveState'] === 'Close' ? 'Closed' : payload['valveState'],
      };
    }),
});

const SettingsCommandGroup = z.object({
  label: z.string(),
  commands: z.array(SettingsCommand),
});

export const DynamicMetadataDatapoint = z.object({
  name: z.string(),
  label: z.string(),
  eventCodes: z.array(z.string()).nullish(),
  dependencies: z
    .object({
      aggregation: z
        .object({
          datapointNames: z.array(z.string()),
          function: z.string(),
        })
        .nullish(),
      statusMapping: z.record(z.string(), z.string()).nullish(),
    })
    .nullish(),
  link: z
    .object({
      url: z.string(),
      label: z.string(),
    })
    .nullish(),
});

export const DynamicMetadataDatapointTransformed = DynamicMetadataDatapoint.transform((datapoint) => ({
  ...datapoint,
  eventCodes: datapoint.eventCodes ?? undefined,
  dependencies: {
    aggregation: datapoint.dependencies?.aggregation ?? undefined,
    statusMapping: datapoint.dependencies?.statusMapping
      ? new Map(Object.entries(datapoint.dependencies.statusMapping))
      : undefined,
  },
}));

export const DynamicSettingsDatapoint = DynamicMetadataDatapoint.extend({
  command: SettingsCommandGroup.nullish(),
});

export const DynamicSettingsDatapointTransformed = DynamicSettingsDatapoint.transform((datapoint) => ({
  ...datapoint,
  command: datapoint.command ?? undefined,
  eventCodes: datapoint.eventCodes ?? undefined,
  dependencies: {
    aggregation: datapoint.dependencies?.aggregation ?? undefined,
    statusMapping: datapoint.dependencies?.statusMapping
      ? new Map(Object.entries(datapoint.dependencies.statusMapping))
      : undefined,
  },
}));

export const DynamicMetadataProductDetails = z.object({
  datapoints: z.array(DynamicMetadataDatapointTransformed),
});

export const DynamicMetadataChart = z.object({
  datapointNames: z.array(z.string()),
  datapointGroupNames: z.array(z.string()),
});

export const DynamicMetadataSectionDatapoint = z.object({
  sectionName: z.string(),
  title: z.string(),
  items: z.array(DynamicMetadataDatapointTransformed),
});

export const DynamicMetadataSection = z
  .object({
    title: z.string(),
    link: z
      .object({
        url: z.string(),
        label: z.string(),
      })
      .nullish(),
    sectionDatapoints: z.array(DynamicMetadataSectionDatapoint),
  })
  .transform((section) => ({ ...section, dataTestId: createDataTestId('dynamic-section', section.title) }));

export const DynamicMetadataSettingsSection = z.object({
  id: z.string(),
  title: z.string(),
  commandGroup: SettingsCommandGroup.nullish().transform((command) => command ?? undefined),
  settings: z.array(DynamicSettingsDatapointTransformed),
});

export const DynamicMetadataDashboardConfig = z.object({
  productDetails: DynamicMetadataProductDetails,
  chart: DynamicMetadataChart,
  sections: z.array(DynamicMetadataSection),
  settingsSections: z.array(DynamicMetadataSettingsSection).nullish(),
});

export type TDynamicMetadataDatapoint = z.infer<typeof DynamicMetadataDatapoint>;
export type TDynamicMetadataProductDetails = z.infer<typeof DynamicMetadataProductDetails>;
export type TDynamicMetadataChart = z.infer<typeof DynamicMetadataChart>;
export type TDynamicMetadataSectionDatapoint = z.infer<typeof DynamicMetadataSectionDatapoint>;
export type TDynamicMetadataSection = z.infer<typeof DynamicMetadataSection>;
export type TDynamicMetadataDashboardConfig = z.infer<typeof DynamicMetadataDashboardConfig>;

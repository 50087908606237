import { IUnitsOfMeasurementForm } from '../../shared/types';

export const defaultUsersSettingsValues: IUnitsOfMeasurementForm = {
  defaultHomePage: 'DASHBOARD' as 'LOCATIONS' | 'DASHBOARD',
  energyUnit: 'mbtu',
  pressureUnit: 'psi',
  temperatureUnit: 'f',
  timeFormat: 'H12',
  gasUnit: 'scf',
  gasFlowRateUnit: 'scfm',
  waterUnit: 'gal',
  waterFlowRateUnit: 'gpm',
};

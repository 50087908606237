import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(8),
  },
  icon: {
    color: theme.palette.action.active,
  },
  link: {
    wordBreak: 'break-word',
  },
}));

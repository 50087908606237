import { IListFilters, TInfiniteRecipientParams } from './recipient.model';

export enum QUERY_KEY {
  RECIPIENTS = 'recipients',
  INFINITE_RECIPIENTS = 'infinite_recipients',
  FILTER = 'filter',
}

export interface IFilterParams {
  deviceId?: string | null;
}

export interface IQueryParams extends IFilterParams {
  addDeleted?: boolean;
}

export const queryKey = {
  RECIPIENTS: (queryParams: IQueryParams = {}) => [QUERY_KEY.RECIPIENTS, queryParams],
  INFINITE_RECIPIENTS: (queryParams?: TInfiniteRecipientParams) => [QUERY_KEY.INFINITE_RECIPIENTS, queryParams],
  RECIPIENTS_IDS: (ids: string[]) => [QUERY_KEY.RECIPIENTS, ids],
  RECIPIENTS_ID: (id: string) => [QUERY_KEY.RECIPIENTS, id],
  RECIPIENTS_SEARCH: (search: string) => [QUERY_KEY.RECIPIENTS, search],
  RECIPIENTS_FILTER: (params?: IListFilters) => [QUERY_KEY.RECIPIENTS, QUERY_KEY.FILTER, params],
};

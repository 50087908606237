import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    gap: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(8),
    alignItems: 'center',
  },

  triggersText: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5ch',
  },

  textUnderline: {
    textDecoration: 'underline',
    marginLeft: theme.typography.pxToRem(4),
    marginRight: theme.typography.pxToRem(4),
    color: theme.palette.primary.main,
    fontWeight: 'normal',
  },
}));

import { z } from 'zod';

const registrationFlowValues = [
  'tekmarSmartSteam',
  'tekmarSmartBoiler',
  'tekmarSmartHeatPump',
  'Tekmar',
  'AercoWaterHeater',
  'AercoBoiler',
] as const;

export const EquipmentSchema = z.object({
  registrationFlow: z.enum(registrationFlowValues),
  equipmentName: z.string().nullish(),
  registrationCode: z.string().nullish(),
  serialNumber: z.string().nullish(),
});

export const onboardDataSchema = z.object({
  organizationName: z.string().nullish(),
  organizationId: z.string().nullish(),
  address: z
    .object({
      country: z.string().nullish(),
      address1: z.string().nullish(),
      address2: z.string().nullish(),
      city: z.string().nullish(),
      state: z.string().nullish(),
      postalCode: z.string().nullish(),
      latitude: z.number().nullish(),
      longitude: z.number().nullish(),
    })
    .nullish(),
  equipment: z.array(EquipmentSchema).nullish(),
});

export const onboardResponseSchema = z.object({
  organizationId: z.string(),
  locationId: z.string(),
});

export type TOnboardDataSchema = z.infer<typeof onboardDataSchema>;
